import { Box, Typography } from "@mui/material"

export function AttributeValueBox({title,value,titleSize,valueSize}){
    return <Box sx={{display:"flex",flexDirection:"column",gap:0.5,width:"100%"}}>
        <Typography sx={{fontFamily:"Figtree",fontSize:titleSize||"14px",fontWeight:"bold",color:"#2C358A"}}>{title}</Typography>
        <Typography sx={{fontFamily:"Figtree",fontSize:valueSize||"16px",color:"#555555",wordWrap:"break-word"}}>{value && value.length>0 ? value : "N/A"}</Typography>
    </Box>
}

export function AttributeDetails({details}){
    console.log(details);
    return <Box sx={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:1,width:"100%",justifyContent:"start"}}>
{details?.map((detail)=><Box sx={{minWidth:"250px",maxWidth:"250px"}}>
    <AttributeValueBox title={detail.title} value={detail.value} />
</Box>)}
    </Box>
}