import { SendBookingMailer } from "../pricing/pricing.screen"
import Api from "./Api"
import { bookingsStore, contractsStore, configStore } from "./CommonState"
const BOOKINGSURI = {
    GET4CONTRACT: (contractNum) => `${process.env.REACT_APP_API_SERVER}/api/v1/bookings/contract/${contractNum}`,
    GET: (bookingNum) => `${process.env.REACT_APP_API_SERVER}/api/v1/bookings/${bookingNum}`,
    BOOKING_WITH_ENTITIE: (bookingNum) => `${process.env.REACT_APP_API_SERVER}/api/v1/bookings/withentities/${bookingNum}`,
    GETALL: `${process.env.REACT_APP_API_SERVER}/api/v1/bookings`,
    POST: `${process.env.REACT_APP_API_SERVER}/api/v1/bookings`,
    CANCEL_BOOKING: (id) =>  `${process.env.REACT_APP_API_SERVER}/api/v1/bookings/cancelBooking/${id}`,
    SETCUSTREF_BOOKING: (id) =>  `${process.env.REACT_APP_API_SERVER}/api/v1/bookings/setCustref/${id}`,
    SENDLINK : (id)=>`${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/sharebooking/${id}`
}
export default class BookingsApi {
    static getAllBookings(callback = f => f) {
        Api.fetch(BOOKINGSURI.GETALL, (data) => {
            bookingsStore.list = data
            callback(data)
        })
    }

    static sendTrackBookingLink(bookingDetail){
        const {id,...reqBody} = bookingDetail;
        Api.post(BOOKINGSURI.SENDLINK(id),reqBody);
    }

    static getBookingWithEntitie(bookingNum, callback = f => f) {
        Api.fetch(BOOKINGSURI.BOOKING_WITH_ENTITIE(bookingNum), (data) => {
            callback(data)
        })
    }

    static getBooking(bookingNum, callback = f => f) {
        Api.fetch(BOOKINGSURI.GET(bookingNum), (data) => {
            let stype = data.contract.shipmentType.substr(0, 3).toLowerCase()
            if(stype=='air'){
                if(data.contract.airline != undefined && data.contract.airline.trim().length==0 && data.airline != undefined && data.airline.trim().length!=0){
                    data.contract.airline = data.airline
                }
            }
            bookingsStore.current = data
            callback(data)
        })
    }
    static getForContract(contractNum, callback = f => f) {
        Api.fetch(BOOKINGSURI.GET4CONTRACT(contractNum), (data) => {
            bookingsStore.list = data
            callback(data)
        })
    }
    
    static saveBooking(shipperConsignee,callback,errCallback) {
        const getcharge = (d,b)=>{
            let a = {}
            let l = []
            for (let i = 0; i < b.contract.chargesList.length; i++) {
                let c = b.contract.chargesList[i]
                const k = (c,a) =>{
                    a[c["chargeDescription"]] = {}
                    let o = Object.keys(c)
                    o = o.filter(item => !["chargeDescription","onaccount","isIncluded"].includes(item))
                    for (let j = 0; j < o.length; j++) {
                        a[c["chargeDescription"]][o[j]] = c[o[j]]
                    }
                    return a
                }
                if(c["onaccount"]==undefined && d=="On Your Account"){
                    a = k(c,a)
                    l.push(c)
                }
                if(c["onaccount"]!=undefined && c["onaccount"]==d){
                    a = k(c,a)
                    l.push(c)
                }
            }
            return {"charge":a,"list":l}
        }
        let b = {bookingData:bookingsStore.current, shipperConsignee: shipperConsignee}
   
        if(b.createdFromPricing!=undefined && b.createdFromPricing){
            if(b.contract.shippercharges==undefined){
                let k = getcharge("Shipper's Account",b)
                if(Object.keys(k["charge"]).length>0){
                    b.contract.shippercharges = k["charge"]
                }
                if(k["list"].length>0){
                    b.contract.shipperchargesList = k["list"]
                }
            }
            if(b.contract.consigneecharges==undefined){
                let k = getcharge("Consignee's Account",b)
                if(Object.keys(k["charge"]).length>0){
                    b.contract.consigneecharges = k["charge"]
                }
                if(k["list"].length>0){
                    b.contract.consigneechargesList = k["list"]
                }
            }
            if(b.contract.charges==undefined){
                let k = getcharge("On Your Account",b)
                if(Object.keys(k["charge"]).length>0){
                    b.contract.charges = k["charge"]
                }
                if(k["list"].length>0){
                    b.contract.chargesList = k["list"]
                }
            }
        }
        Api.post(BOOKINGSURI.POST, b, (data) => {
            //if (fromPricing) SendBookingMailer(data)
            configStore.setBookingModalMessage(data._id)
            callback(data)
        },errCallback)
    }

    static cancelBooking(bookingNum,reason,callback) {
        Api.put(BOOKINGSURI.CANCEL_BOOKING(bookingNum),{ reason },() => {
            // configStore.setModalMessage(`Booking ${bookingNum} Cancelled`)
            if(callback){
                callback();
            }
            configStore.setModalMessage(`Booking Cancellation request placed successfully`)
        } )
    }

    static cancelBookingfromqoute(bookingNum,reason) {
        Api.put(BOOKINGSURI.CANCEL_BOOKING(bookingNum),{ reason },() => {
            // configStore.setModalMessage(`Booking ${bookingNum} Cancelled`)
        } )
    }

    static setcustrefBooking(bookingNum,contract) {
        Api.put(BOOKINGSURI.SETCUSTREF_BOOKING(bookingNum),{ contract },() => {
            configStore.setModalMessage(`Booking ${bookingNum} - Cust Ref Changed.`)
        } )
    }
}