import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { AgmodalStore, EntityV2Store, bookingsStore, configStore, loginStore } from "../state/CommonState";
import Igmmanifesttable from "./igm-manifest-table";
import TaskDatetime from "./task-datetime";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import Api from "../state/Api";

export default function Igmmanifest(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    console.log("taskk",task.igmDetails[0]);
    let SbNo = task.igmDetails[0].text
    let SbDate = task.igmDetails[0].dt
    const VerifySBList =async () => {

        let wtPerContainer = 0
        bookingsStore.current.contract.containerList.map((d)=> wtPerContainer+=parseInt(d?.wtPerContainer))
        // console.log();
        let type = "igm"
        let body = {
            finalPlaceOfDelivery:bookingsStore.current.contract.finalPlaceOfDelivery || "",
            bookingData:bookingsStore.current._id || "",
            entityId:EntityV2Store.EntityRec.entityId || "",
            entityName:EntityV2Store.EntityRec.entityName || "",
            entityEmails:[EntityV2Store.EntityRec.customer.crossBorder.relationshipManager,EntityV2Store.EntityRec.customer.crossBorder.customerService] || [""],
            type,
            origin:bookingsStore.current.contract.origin || "",
            wtPerContainer:wtPerContainer || 0,
            SbNo,
            SbDate:SbDate.split('-').reverse().join(''),
        }
        // if (type=="sbc") {
        //     body = {...body,
        //         SbNo,
        //         SbDate:SbDate.split('-').reverse().join(''),
        //     }
        // }
        // if (type=="boe") {
        //     body = {...body,
        //         SbNo,
        //         SbDate:SbDate.split('-').reverse().join(''),
        //     }
        // }
        console.log(body);
       await Api.verifySbNummber(body,async(data)=>{
        console.log(data);
        if (data && data?.success) {
            if (data.mismatch) {
                taskStore.current.icegateReqData = {
                    mismatch : true,
                    no:SbNo,
                    date:SbDate.split('-').reverse().join(''),
                    type
                }
                taskStore.current.icegateResData = null
                await Taskapi.updateTask(taskStore.current)
                return configStore.setModalMessage(`Please enter a valid ${type.toUpperCase()} No. and ${type.toUpperCase()} Date //mismatch`)
             }
             taskStore.current.icegateReqData = {
                mismatch : false,
                no:SbNo,
                date:SbDate.split('-').reverse().join(''),
                type
            }
            taskStore.current.icegateIGMResData = data?.data
            taskStore.current.stage = 2
            taskStore.current.pendingWith = 'AGRAGA'
            taskStore.setComplete()
            taskStore.setCustomerComplete()
            await Taskapi.updateTask(taskStore.current)
            taskStore.triggerActionVisible = false
            taskStore.myActionVisible = false
            if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                AgmodalStore.pageVisible = false
                AgmodalStore.apiCall = true
            }
            configStore.setModalMessage("verified")
        }
        else {
            // callback(true)
            if (data.errMsg) {
                return configStore.setModalMessage(data.errMsg.error)
            }
            configStore.setModalMessage("failed")
        }
       })
    }
    return (
        <>
            <Igmmanifesttable viewOnly={true} />

            {
                (task.stage === 1) ?
                    <p>
                        <button className="button islink"
                            onClick={async (e) => {
                                // VerifySBList()
                                taskStore.current.stage = 2
                                taskStore.current.pendingWith = 'AGRAGA'
                                taskStore.setComplete()
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Acknowledge</button>
                    </p>
                    : <></>
            }
        </>
    )
}
export function IgmmanifestTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    const [disableButton, setDisableButton] = useState(true)
    useEffect(() => {
        let unsubscribe = subscribe(taskStore.current, () => {
            let disable = false
            if(task.preNum == 4){
                task.igmDetails.map((e) => {
                    if(e.name=='GIGM Number'){
                        if(e.text.length==0 || e.dt.length==0){
                            disable = true
                        }
                    }
                })
            }

            if(task.preNum == 1){
                task.igmDetails.map((e) => {
                    if(e.name=='Airline IGM Number'){
                        if(e.text.length==0 || e.dt.length==0){
                            disable = true
                        }
                    }
                })
            }
            
            setDisableButton(disable)
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            {/* <TaskDatetime /> */}
            <hr />
            <Igmmanifesttable />

            <p>
                <button className="button islink" disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}