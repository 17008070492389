import { Box, Checkbox, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { usePricing } from "../context/PricingContext";
import Utils from "../util/Utils";
import { breadCrumbStore } from "../state/CommonState";

function checkForCharges(charge){
    let isValidCharge = true;
     if((charge.chargeDescription === "Pick-up Charges" && charge.total ===0) || (charge.chargeDescription === "Delivery Charges" && charge.total ===0)){
        isValidCharge = false;
     }
    return isValidCharge;
}

let enabledCharges = [
    'Origin Customs Clearance',
    'Destination Customs Clearance',
    'Pick-up Charges',
    'Delivery Charges'
]
 
 function SelectableCharges({charge,backgroundColor}){
    const [value,setValue] = useState(charge?.isIncluded==="Yes");
    const {changeCharges,disableInclusion,isInidaDoor} = usePricing();
    const [checkForInValidCharge,setValidCharge] = useState(true);
    
    useEffect(()=>{
        if(isInidaDoor){
            setValidCharge(checkForCharges(charge));  
        }
    },[isInidaDoor])

    return <div className={`flex flex-row justify-between w-full py-[7px] items-center px-[17px] ${backgroundColor}`}>
  <span style={{display:"flex",flexDirection:"row",gap:0.6,alignItems:"center"}}>
   {checkForInValidCharge &&   <Checkbox  iconStyle={{fill: '#2C358A'}} disabled={disableInclusion} checked={value} onChange={(e)=>{
        setValue(e.target.checked);
        changeCharges(e.target.checked,charge.chargeDescription)  
    }} sx={{padding:0}} size="small"/>}
    <p className="text-[15px] text-[#1F1F1F]" >{charge.chargeDescription}</p></span>
    <p className="text-[15px] text-[#1F1F1F]" >{charge.isDiscount && charge.volumeDiscount > 0 ? Utils.N(charge.total-charge.volumeDiscount)  : !checkForInValidCharge ? "Charges Awaited" : Utils.N(charge.total,charge.currency)}</p>
   </div>
}

function PriceDetails({charge}){
    const [shipperPDA,setShipperPDA] = useState(false)
    const {showShipperPDA,setShowShipperPDA,changeCharges} = usePricing();
    return <div className="flex flex-col w-full">
        {charge.charges.map((chargeDetail,i)=> enabledCharges.includes(chargeDetail.chargeDescription) ? <SelectableCharges backgroundColor={i%2===0?"bg-[white]":"bg-[#F3F3F3]"} charge={chargeDetail}/> 
         : 
         <div className={`flex flex-row justify-between w-full py-[8px] items-center px-[17px] ${i%2===0?"bg-[white]":"bg-[#F3F3F3]"} gap-2.5`}>
    <p className="text-[15px] text-[#1F1F1F]">{chargeDetail.chargeDescription}</p>
    <p className="text-[15px] text-[#1F1F1F]">{Utils.N(chargeDetail.total,chargeDetail.currency)}</p>
   </div>
)}
<div className={`flex flex-row justify-between w-full h-[35px] items-center px-[18px] ${charge.charges?.length%2===0?"bg-[white]":"bg-[#F3F3F3]"}`}>
<p className="text-[15px] text-[#1F1F1F] font-bold">Total Cost</p>
<p className="text-[15px] text-[#1F1F1F] font-bold">{Utils.N(charge.total)}</p>
</div>
    </div>
}

export function PricingDetailsCard2({title = "Price Details",isAccordionView=false,charge}){
    const {rec,setShowShipperPDA} = usePricing();  
    useEffect(()=>{
      if(rec){
         if(rec?.shipmentType?.includes("FCL") && rec?.oih && charge?.account?.includes('Your')){
            setShowShipperPDA((prev)=>({...prev,include:true}));
         }
      }
    },[rec]) 
    return <div className="flex flex-col w-full">
          { !isAccordionView && <div className="flex flex-row justify-between w-full h-[35px] items-center px-[18px] bg-blue-dark">
            <p className="text-sm text-[white] font-bold">{title}</p>
            {/* <p className="text-sm text-[white] font-bold">Price in INR</p> */}
           </div>}
           <PriceDetails charge={charge}/>
    </div>
}