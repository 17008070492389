import { proxy } from 'valtio'
let menucountStore = proxy({
    MyActions : 0,
    Enquiries: 0,
    UploadSchedules:0,
    UploadSchedulesv2:0,
    Bookings:0,
    Bookingsv2:0,
    Entitiesv2:0,
    Users:0,
    pendingdsr:0,
    Visibility:0,
    Approvals:0,
    setcount: (mc) => {
        menucountStore.Bookings = mc.bookings
        menucountStore.Bookingsv2 = mc.bookings
        menucountStore.Enquiries = mc.enquiry
        menucountStore.UploadSchedules = mc.uploadschedules
        menucountStore.UploadSchedulesv2 = mc.uploadschedules
        menucountStore.MyActions = mc.myaction
        menucountStore.Entitiesv2 = mc.entities
        menucountStore.Users = mc.users
        menucountStore.pendingdsr = mc.pendingdsr
        menucountStore.Visibility = mc.pendingdsr
        menucountStore.Approvals = mc.approvals
        localStorage.removeItem("checkcount")
    }
})

export {
    menucountStore
}

