import { Col3, Col6, Col4, Col12, Col2, Col8 } from "../Controls";
import { useSnapshot } from "valtio";
import { AgUnloc, AgUnlocAll } from "../components/AgComponents";
import { useState } from "react";
import React,{ useEffect,useLayoutEffect } from "react";
import Api from "../state/Api";
import { Box, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import {
  AgSelectWithMap1,
} from "../components/AgCustomComponents";
import { dataListStore,multiSelectListStore } from "../state/CommonState";
import { AgInput } from "../components/AgComponents";
import { SchedulesValidation } from "../state/validation-stores";
import { portToPortSchedules } from "../state/CommonState";
import { loader } from "../util/loader";
import { FCLPPSchedule } from "./fcl.ppschedule";
import { exportToExcel } from "react-json-to-excel";
import Utils from "../util/Utils";
import LCLFCLSchedule from "./fcl-lcl-schedule"
import { dtactionStore } from "../dtactions/dtaction-store";
import MultiSelect from "./multi-select"
import ReactTablePagination from "../components/ag-table/ReactTablePagination";
import {useTable,usePagination,useSortBy} from "react-table"
import { DRS_COLUMNS } from "../components/ag-table/columns";
import {reducer,PAGE_CHANGED,PAGE_SIZE_CHANGED,PAGE_SORT_CHANGED,PAGE_FILTER_CHANGED,TOTAL_COUNT_CHANGED,PAYLOAD_CHANGED} from "../components/ag-table/ag-reducer"

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 20,
  totalCount: 0,
  queryPageFilter: "",
  queryPageSortBy: [],
  queryPayload: {}
};


export default function Schedules() {
  
  let columns = DRS_COLUMNS
  useSnapshot(dataListStore);
  let weeks = [
    {
      name: "2 weeks",
      value: 14,
    },
    {
      name: "4 weeks",
      value: 28,
    },
    {
      name: "6 weeks",
      value: 42,
    },
  ];
  const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
  React.useReducer(reducer, initialState);
  const totalPageCount = Math.ceil(totalCount / queryPageSize)
  const [data1, setData1] = useState({ results: [] })

  const consolidatorListKey = "carrierList";
  const [showCharges, setShowCharges] = useState();
  const [curDate, setCurDate] = useState();
  const [carrier, setCarrier] = useState();
  const [origin, setOrigin] = useState();
  const [next, SetNext] = useState();
  const [Destination, setDestination] = useState();
  let [MIN, SetMin] = useState(0);
  let [MAX, SetMax] = useState(40);
  const [value, setValue] = useState([MIN, MAX]);

  let [ppSchedules, SetppSchedules] = useState([]);
  let [dirppSchedules, SetdirppSchedules] = useState([]);
  let [p44Data, Setp44Data] = useState([]);

  const [allchecked, setAllChecked] = useState([]);
  const [selectAll,setSelectAll] = useState(true);
  let [checkedDirect, SetcheckedDirect] = useState(false);
  let [transhipment, Settranshipment] = useState(false);
  let [searchData, SetSearchdata] = useState(false);
  let [searchData1, SetSearchdata1] = useState(false);
  let [routing, SetRouting] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [downloadSchedules, SetdownloadSchedules] = useState([]);
  let [Direct,SetDirect]= useState([])
  let [Transhipment,SetTranshipment]= useState([])
  const [fileterData, setfileterData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionSelected, setSelected] = useState([]);


  useEffect(() => {
    if (transhipment == true && checkedDirect == true) {
      setAllChecked(["Direct", "Transhipment"]);
    }
  }, [transhipment, checkedDirect]);
  useEffect(() => {
    Api.getFCLCarrierList();
    ppSchedules = [];
    SchedulesValidation.schdeuleValidMap["origin"] = true;
    SchedulesValidation.schdeuleValidMap["destination"] = true;
    SchedulesValidation.schdeuleValidMap["scheduleDate"] = true;
    SchedulesValidation.schdeuleValidMap["carrier"] = true;
    portToPortSchedules.carrier = "";
    portToPortSchedules.destination = "";
    portToPortSchedules.origin = "";
    portToPortSchedules.scheduleDate = Utils.getCurrentDateString();
    portToPortSchedules.next = 42;
  }, []);

  useEffect(() => {
    if(searchData==true){
      if(ppSchedules.length==0){
          SetSearchdata1(true)
      }
    
    } 
    if(allchecked.indexOf("Direct") != -1 && allchecked.indexOf("Transhipment") != -1){
      let minValue = p44Data.reduce((acc, curvalue) => {
        if (acc < curvalue.sch.transitTime) {
          acc = acc;
        } else {
          acc = curvalue.sch.transitTime;
        }
  
        return acc;
      }, ppSchedules[0]?.sch.transitTime);
      console.log("Dd,", minValue);
      let maxValue = p44Data.reduce((acc, curvalue) => {
        if (acc > curvalue.sch.transitTime) {
          acc = acc;
        } else {
          acc = curvalue.sch.transitTime;
        }
        return acc;
      }, ppSchedules[0]?.sch.transitTime);
      console.log("Dd,", maxValue);
      if(maxValue != MAX){
        SetMax(maxValue);
      }
      if(minValue != MIN){
        SetMin(minValue);
      }
    }else{
      let minValue = dirppSchedules.reduce((acc, curvalue) => {
        if (acc < curvalue.sch.transitTime) {
          acc = acc;
        } else {
          acc = curvalue.sch.transitTime;
        }
  
        return acc;
      }, ppSchedules[0]?.sch.transitTime);
      console.log("Dd,", minValue);
      let maxValue = dirppSchedules.reduce((acc, curvalue) => {
        if (acc > curvalue.sch.transitTime) {
          acc = acc;
        } else {
          acc = curvalue.sch.transitTime;
        }
        return acc;
      }, ppSchedules[0]?.sch.transitTime);
      console.log("Dd,", maxValue);
      if(maxValue != MAX){
        SetMax(maxValue);
      } 
      if(minValue != MIN){
        SetMin(minValue);
      }
    }
    
  }, [p44Data,dirppSchedules]);

  useEffect(() => {
    if(ppSchedules.length>0){
      loader.show();

    }
    if(allchecked.indexOf("Direct") != -1 && allchecked.indexOf("Transhipment") != -1){
      if (p44Data.length > 0) {
        let f = p44Data.filter((e) => {
          if (e.sch.transitTime >= value[0] && e.sch.transitTime <= value[1]) {
            return e;
          }
        });
        setTimeout(() => {
          if(ppSchedules.length>0){
            loader.hide();

          }
        }, 1500);
          if(JSON.stringify(f)!=JSON.stringify(ppSchedules)){
          SetppSchedules(f);
        }
      }
    }else{
      if (dirppSchedules.length > 0) {
        let f = dirppSchedules.filter((e) => {
          if (e.sch.transitTime >= value[0] && e.sch.transitTime <= value[1]) {
            return e;
          }
        });
        if(JSON.stringify(f)!=JSON.stringify(ppSchedules)){
          SetppSchedules(f);
        }
      }
    }
    setTimeout(() => {
      if(ppSchedules.length>0){
        loader.hide();

      }    }, 1500);
  }, [value,allchecked]);
  useEffect(() => {}, [curDate, carrier, origin, Destination, ppSchedules,routing]);
  useEffect(() => {
    setValue([MIN, MAX]);
  }, [MAX, MIN]);
  const _params = (label, name, type = "text", dataList = []) => {
    return {
      type,
      name,
      label,
      record: portToPortSchedules,
      dataList,
    };
  };

  let data = {
    origin: portToPortSchedules.origin,
    destination: portToPortSchedules.destination,
    carrier: portToPortSchedules.carrier,
    curDate: portToPortSchedules.scheduleDate,
    next: portToPortSchedules.next,
  };

  const handleChange = (event, newValue) => {
    setSelectAll(true)
    setValue(newValue);
  };

  const handleChange2 = (selected) => {
  
    setSelected(selected);
    setCarrier(false)
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  function handleChange1(e) {
    setSelectAll(true)
    if (e.target.checked) {
      setAllChecked([...allchecked, e.target.value]);
      if (e.target.value == "Direct") {
        SetcheckedDirect(true);
      } else {
        Settranshipment(true);
      }
    } else {
      setAllChecked(
        allchecked.filter((item) => {
          if (item !== e.target.value) {
            if (item == "Direct") {
              SetcheckedDirect(true);
              Settranshipment(false);
              return item;
            } else {
              SetcheckedDirect(false);
              Settranshipment(true);
              return item;
            }
          }
        })
      );
    }
  }

  useEffect(() => {
    if(ppSchedules.length>0){
      loader.show();

    }
    let directPPSchedules;
    if(allchecked.indexOf("Direct") != -1 && allchecked.indexOf("Transhipment") != -1){
      if(JSON.stringify(p44Data) != JSON.stringify(ppSchedules)){
        SetppSchedules(p44Data);
        SetdirppSchedules(p44Data);
      }
    }else if(allchecked.indexOf("Direct") != -1 && allchecked.indexOf("Transhipment") == -1) {
      directPPSchedules = p44Data.filter((e) => {
        if (e.sch.ts1code == null || e.sch.ts1code.trim().length==0) {
          return e;
        }
      });
      if(JSON.stringify(ppSchedules) != JSON.stringify(directPPSchedules)){
        SetppSchedules(directPPSchedules);
        SetdirppSchedules(directPPSchedules);
      }
    }else if(allchecked.indexOf("Transhipment") != -1 && allchecked.indexOf("Direct") == -1) {
      directPPSchedules = p44Data.filter((e) => {
        if (e.sch.ts1code != null && e.sch.ts1code.trim().length>0) {
          return e;
        }
      });
      if(JSON.stringify(ppSchedules) != JSON.stringify(directPPSchedules)){
        SetppSchedules(directPPSchedules);
        SetdirppSchedules(directPPSchedules);
      }
    }
    setTimeout(() => {
      if(ppSchedules.length>0){
        loader.hide();

      }    }, 1500);  }, [allchecked]);
  useEffect(()=>{
    console.log("DC",ppSchedules)
    if(ppSchedules.length>0){
      let d =[]
      ppSchedules.map((e,index)=>{
        d.push(true)
      })
      console.log("DSsda",d)
      setCheckedState(d)
      if(ppSchedules==p44Data){
        let enableRouting  =ppSchedules.filter((e)=>{

          if (e.sch.ts1code != "" ) {
            return e
          } 
  
        })
        let enableRouting1  =ppSchedules.filter((e)=>{

          if (e.sch.ts1code == "" ) {
            return e
          } 
  
        })
       SetDirect(enableRouting1)
       SetTranshipment(enableRouting)
        if(enableRouting.length == ppSchedules.length || enableRouting1.length ==ppSchedules.length){
          SetRouting(false)
        } else{
          SetRouting(true)
        }
      }
      
    }
    
      if(p44Data.length>0 && ppSchedules.length==0){
        console.log("DSCS")
        SetSearchdata1(true)

      }
    
  },[ppSchedules])
   useEffect(()=>{
    console.log("checkedState",checkedState)
    console.log("checkedState",ppSchedules)
   let g = ppSchedules.map((e,index)=>{
      if(checkedState[index]==true){
        e.sch.checkboxEnabled=checkedState[index]
        return e
      }else{
        e.sch.checkboxEnabled=checkedState[index]
        return e
      }
    })
    console.log("asxS",g)
    SetdownloadSchedules(g)
   },[checkedState])
   useLayoutEffect(()=>{ 
    let f =[]
    multiSelectListStore.selectList[consolidatorListKey] =
    dataListStore["FCLCarrierList"].map((e) => ({
      id: e["SCAC code"] ,
      name: e["Carrier Name"],
      scacCode:e["SCAC code"],
      // checked:  dataListStore["FCLCarrierList"]
      //   .map((addedConsolidator) => addedConsolidator["Carrier Name"])
      //   .includes(e["Carrier Name"]),
      checked:false
    }))
    dataListStore["FCLCarrierList"].map((e)=>{
      let g  ={
        "label":e["Display Name"],
        "value": e["SCAC code"],
        id: e["SCAC code"] ,
        name: e["Carrier Name"],
        scacCode:e["SCAC code"],
      }
      f.push(g)
    })
    setSelectedOptions(f)
  },[dataListStore["FCLCarrierList"]])
  const handleOnChange = (position) => {
    console.log("checkedState",checkedState)
    const updatedCheckedState = checkedState.map((item, index) =>
     
      index === position ? !item : item
    );
    console.log("checkedState1",updatedCheckedState)
    let  d = updatedCheckedState.filter((e)=>e==true)
    if(d.length == checkedState.length){
      console.log("feewew")
      setSelectAll(true)

      setCheckedState(updatedCheckedState);

    } else{
      setSelectAll(false)
      setCheckedState(updatedCheckedState);
    }
  
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy }
} = useTable({
    columns,
    data: data1.results,
    initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
    },
    manualPagination: true,
    pageCount: data1 ? totalPageCount : null,
    autoResetSortBy: false,
    autoResetExpanded: false,
    autoResetPage: false
},
    useSortBy,
    usePagination,
);



const manualPageSize = []
useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
}, [pageIndex,gotoPage]);
useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
}, [pageSize, gotoPage]);
useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
    gotoPage(0);
}, [sortBy, gotoPage]);
React.useEffect(() => {
  if (data1?.count) {
      dispatch({
          type: TOTAL_COUNT_CHANGED,
          payload: data1.count,
      });
  }
}, [data1?.count]);

useEffect(() => {
 let ScheduleData;
  if(queryPageIndex>0){
    ScheduleData= ppSchedules.slice(queryPageSize*(queryPageIndex+1)-queryPageSize,queryPageSize*(queryPageIndex+1))

  } else{
    if(ppSchedules.length>10){
      ScheduleData= ppSchedules.slice(queryPageIndex,queryPageSize)

    }else{
      ScheduleData=ppSchedules

    }
  }
  setfileterData(ScheduleData)
  setData1({results:ScheduleData,count:ppSchedules.length})
},[queryPageIndex,queryPageSize,ppSchedules])

  
  portToPortSchedules.carrier = optionSelected
  let rec = fileterData;
  // console.log("AS", rec);
  // console.log("X", portToPortSchedules);
  // console.log("X1d", ppSchedules);
  // console.log("X1d", data);
  // console.log("X1a", allchecked);
  // console.log("downloadSchedules",downloadSchedules)
  // console.log("Direct",Direct)
  // console.log("Transhipment",Transhipment)
  // console.log("DC",dataListStore["FCLCarrierList"])
  // console.log("DCdf",selectedOptions)
  // console.log("Djjdf",  multiSelectListStore.selectList[consolidatorListKey])

  const download = () => {
    dtactionStore.modalVisible1=true
    // let data = [];
    // for (let z = 0; z < ppSchedules.length; z++) {
    //   let l = {};
    //   let e = { ...ppSchedules[z]._id, ...ppSchedules[z].sch };
    //   if (e["POR Name"]) {
    //     l["POR Name"] = e["POR Name"];
    //   } else {
    //     l["POR Name"] = "";
    //   }
    //   if (e["POR Code"]) {
    //     l["POR Code"] = e["POR Code"];
    //   } else {
    //     l["POR Code"] = "";
    //   }
    //   if (e["Departure from POR"]) {
    //     l["Departure from POR"] = e["Departure from POR"];
    //   } else {
    //     l["Departure from POR"] = "";
    //   }
    //   if (e["oname"]) {
    //     l["oname"] = e["oname"];
    //   } else {
    //     l["oname"] = "";
    //   }
    //   if (e["ocode"]) {
    //     l["ocode"] = e["ocode"];
    //   } else {
    //     l["ocode"] = "";
    //   }
    //   if (e["oetd"]) {
    //     l["oetd"] = e["oetd"];
    //   } else {
    //     l["oetd"] = "";
    //   }
    //   if (e["r1code"]) {
    //     l["r1code"] = e["r1code"];
    //   } else {
    //     l["r1code"] = "";
    //   }
    //   if (e["v1name"]) {
    //     l["v1name"] = e["v1name"];
    //   } else {
    //     l["v1name"] = "";
    //   }
    //   if (e["s1voy"]) {
    //     l["s1voy"] = e["s1voy"];
    //   } else {
    //     l["s1voy"] = "";
    //   }
    //   if (e["ts1name"] && e["ts1name"] != e["dname"]) {
    //     l["ts1name"] = e["ts1name"];
    //   } else {
    //     l["ts1name"] = "";
    //   }
    //   if (e["ts1code"] && e["ts1name"] != e["dname"]) {
    //     l["ts1code"] = e["ts1code"];
    //   } else {
    //     l["ts1code"] = "";
    //   }
    //   if (e["r1code"] && e["ts1name"] != e["dname"]) {
    //     l["r1code"] = e["r1code"];
    //   } else {
    //     l["r1code"] = "";
    //   }
    //   if (e["ts1eta"] && e["ts1name"] != e["dname"]) {
    //     l["ts1eta"] = e["ts1eta"];
    //   } else {
    //     l["ts1eta"] = "";
    //   }
    //   if (e["ts1etd"] && e["ts1name"] != e["dname"]) {
    //     l["ts1etd"] = e["ts1etd"];
    //   } else {
    //     l["ts1etd"] = "";
    //   }
    //   if (e["r2code"] && e["ts1name"] != e["dname"]) {
    //     l["r2code"] = e["r2code"];
    //   } else {
    //     l["r2code"] = "";
    //   }
    //   if (e["v2name"] && e["ts1name"] != e["dname"]) {
    //     l["v2name"] = e["v2name"];
    //   } else {
    //     l["v2name"] = "";
    //   }
    //   if (e["s2voy"] && e["ts1name"] != e["dname"]) {
    //     l["s2voy"] = e["s2voy"];
    //   } else {
    //     l["s2voy"] = "";
    //   }
    //   if (e["ts2name"] && e["r3code"] && e["r3code"].length>0 && e["ts2name"] != e["dname"]) {
    //     l["ts2name"] = e["ts2name"];
    //   } else {
    //     l["ts2name"] = "";
    //   }
    //   if (e["ts2code"] && e["r3code"] && e["r3code"].length>0 && e["ts2name"] != e["dname"]) {
    //     l["ts2code"] = e["ts2code"];
    //   } else {
    //     l["ts2code"] = "";
    //   }
    //   if (e["ts2eta"] && e["r3code"] && e["r3code"].length>0 && e["ts2name"] != e["dname"]) {
    //     l["ts2eta"] = e["ts2eta"];
    //   } else {
    //     l["ts2eta"] = "";
    //   }
    //   if (e["ts2etd"] && e["r3code"] && e["r3code"].length>0 && e["ts2name"] != e["dname"]) {
    //     l["ts2etd"] = e["ts2etd"];
    //   } else {
    //     l["ts2etd"] = "";
    //   }
    //   if (e["r3code"] && e["r3code"] && e["r3code"].length>0 && e["ts2name"] != e["dname"]) {
    //     l["r3code"] = e["r3code"];
    //   } else {
    //     l["r3code"] = "";
    //   }
    //   if (e["v3name"] && e["r3code"] && e["r3code"].length>0 && e["ts2name"] != e["dname"]) {
    //     l["v3name"] = e["v3name"];
    //   } else {
    //     l["v3name"] = "";
    //   }
    //   if (e["s3voy"] && e["r3code"] && e["r3code"].length>0 && e["ts2name"] != e["dname"]) {
    //     l["s3voy"] = e["s3voy"];
    //   } else {
    //     l["s3voy"] = "";
    //   }
    //   if (e["dname"]) {
    //     l["dname"] = e["dname"];
    //   } else {
    //     l["dname"] = "";
    //   }
    //   if (e["dcode"]) {
    //     l["dcode"] = e["dcode"];
    //   } else {
    //     l["dcode"] = "";
    //   }
    //   if (e["deta"]) {
    //     l["deta"] = e["deta"];
    //   } else {
    //     l["deta"] = "";
    //   }
    //   if (e["cscac"]) {
    //     l["cscac"] = e["cscac"];
    //   } else {
    //     l["cscac"] = "";
    //   }
    //   dataz.push(l);
    // }
    // exportToExcel(
    //   [
    //     {
    //       sheetName: "PP_Schedules",
    //       details: dataz,
    //     },
    //   ],
    //   "PP_Schedules",
    //   true
    // );
  };

  const callback = (data)=>{
    SetppSchedules(data)
    Setp44Data(data)
    SetdirppSchedules(data);
  }
  if (!rec) return <></>;
  console.log("SA",checkedState)
console.log("ss",fileterData)
  return (
    <div>
      <LCLFCLSchedule data={downloadSchedules} checkbox={checkedState}></LCLFCLSchedule>
      <div style={{display:"flex"}}>
      
      <h4 className="title is-3">{"Schedules -> Point to Point"} </h4>
          {ppSchedules.length > 0 ? (
            <div >
              <button
              disabled={!checkedState.some((item)=>item)}
                className="button is-link control"
                //style={{ backgroundColor: "#2c358a" }}
                style={{ backgroundColor: "green",marginLeft:"550px" }}
                onClick={download}
              >
                Download
              </button>
            </div>
          ) : (
            <></>
          )}
      </div>
      
      <div className="columns">
        {console.log("d", SchedulesValidation.schdeuleValidMap["origin"])}

        <Col4>
          <label
            style={{
              fontSize: "large",
              fontWeight: "bold",
            }}
          >
            * Origin
          </label>
          <AgUnlocAll
          record={portToPortSchedules}
            {..._params("", "origin")}
            isValid={SchedulesValidation.schdeuleValidMap["origin"]}
            callback={(value, list) => {
              console.log("D67", value);
              SchedulesValidation.schdeuleValidMap.origin =
                value.length > 3 ? true : false;
              setShowCharges(SchedulesValidation.schdeuleValidMap.origin);
            }}
            validationMsg={SchedulesValidation.originmsg}
          />
        </Col4>
        <Col4>
          <label
            style={{
              fontSize: "large",
              fontWeight: "bold",
            }}
          >
            * Destination
          </label>
          <AgUnlocAll
          record={portToPortSchedules}
            {..._params("", "destination")}
            isValid={SchedulesValidation.schdeuleValidMap["destination"]}
            callback={(value, list) => {
              SchedulesValidation.schdeuleValidMap.destination =
                value.length > 3 ? true : false;
              setShowCharges(SchedulesValidation.schdeuleValidMap.destination);
            }}
            validationMsg={SchedulesValidation.destinationmsg}
          />
        </Col4>
       
      </div>
      <div className="columns">
        <Col2>
          <label
            style={{
              fontSize: "large",
              fontWeight: "bold",
            }}
          >
            * From:
          </label>
          <br></br>
          <AgInput
          record={portToPortSchedules}
            {..._params("", "scheduleDate", "date")}
            isValid={SchedulesValidation.schdeuleValidMap["scheduleDate"]}
            callback={(value) => {
              if (value) {
                SchedulesValidation.schdeuleValidMap["scheduleDate"] = true;
                setCurDate(
                  SchedulesValidation.schdeuleValidMap["scheduleDate"]
                );
              }
            }}
            validationMsg="- Please pick a valid date."
          />
        </Col2>
        <Col2>
          <label
            style={{
              fontSize: "large",
              fontWeight: "bold",
            }}
          >
            Next:
          </label>
          <br></br>
          <AgSelectWithMap1
            record={portToPortSchedules}
            name="next"
            dataMap={weeks.map((e) => ({
              key: e["name"],
              value: e["value"],
            }))}
            isValid={SchedulesValidation.schdeuleValidMap["next"]}
            callback={(value) => {
              console.log("value", value);
              if (value) {
                SchedulesValidation.schdeuleValidMap["next"] = true;

                SetNext(SchedulesValidation.schdeuleValidMap["next"]);
              }
            }}
            validationMsg="- Please pick valid Weeks."
          />
        </Col2>
        <Col4>
          <label
            style={{
              fontSize: "large",
              fontWeight: "bold",
            }}
          >
            * Carrier:
          </label>
          <br></br>
          
          <MultiSelect
        key="example_id"
        options={selectedOptions}
        onChange={handleChange2}
        value={optionSelected}
        isSelectAll={true}
        menuPlacement={"bottom"}
      />          {/* <AgMultiSelectWithCheckBoxes
                  //label={"Consolidator"}
                  storeKey={consolidatorListKey}
                  callback={() => {
                    portToPortSchedules.carrier =
                      multiSelectListStore.selectList[consolidatorListKey]
                        .filter(({ checked }) => checked)
                        .map(({ name }) =>{
                          setCarrier(false)
                          return dataListStore["FCLCarrierList"].find(
                            ( e ) =>
                              e["Carrier Name"] === name
                          )
                          }
                          
                        );
                  }}
                  withAllShortcut></AgMultiSelectWithCheckBoxes> */}
                                  {carrier == true && portToPortSchedules?.carrier=="" && <p class="help is-danger"> {`* Please select atleast one consolidator`}</p>}
          {/* <AgSelectWithMap
            record={portToPortSchedules}
            name="carrier"
            dataMap={dataListStore["FCLCarrierList"].map((carrier) => ({
              key: carrier["Display Name"],
              value: `${carrier["Display Name"]} (${carrier["SCAC code"]})`,
            }))}
            isValid={SchedulesValidation.schdeuleValidMap["carrier"]}
            callback={() => {
              SchedulesValidation.schdeuleValidMap["carrier"] = true;
              setCarrier(SchedulesValidation.schdeuleValidMap["carrier"]);
            }}
            validationMsg="- Please pick a valid carrier."
          /> */}
        </Col4>
        <Col3>
          <label
            style={{
              fontSize: "large",
              fontWeight: "bold",
            }}
          >
            <br></br>
          </label>
          <button
            className="button is-link control"
            onClick={async () => {
              if (
                portToPortSchedules.origin.length > 0 &&
                portToPortSchedules.destination.length > 0 &&
                portToPortSchedules.carrier.length > 0 &&
                portToPortSchedules.scheduleDate.length > 0
              ) {
                Api.getppchedules(
                  {
                    origin: portToPortSchedules.origin,
                    destination: portToPortSchedules.destination,
                    carrier: portToPortSchedules.carrier.map(carrier => `${carrier["name"]} (${carrier["scacCode"]})`),
                    curDate: portToPortSchedules.scheduleDate,
                    next: portToPortSchedules.next,
                  },
                  callback
                );
                SetcheckedDirect(true);
                Settranshipment(true);
                SetSearchdata(true)
                setValue([MIN,MAX])
                setSelectAll(true)

              } else {
                if (portToPortSchedules.scheduleDate.length == 0) {
                  SchedulesValidation.schdeuleValidMap["scheduleDate"] = false;
                  setCurDate(
                    SchedulesValidation.schdeuleValidMap["scheduleDate"]
                  );
                }
                if (portToPortSchedules.carrier.length == 0) {
                  SchedulesValidation.schdeuleValidMap["carrier"] = true;
                  setCarrier(SchedulesValidation.schdeuleValidMap["carrier"]);
                }
                if (portToPortSchedules.origin.length == 0) {
                  SchedulesValidation.schdeuleValidMap.origin = false;
                  setShowCharges(SchedulesValidation.schdeuleValidMap.origin);
                }
                if (portToPortSchedules.destination.length == 0) {
                  SchedulesValidation.schdeuleValidMap.destination = false;
                  setShowCharges(
                    SchedulesValidation.schdeuleValidMap.destination.destination
                  );
                }
              }
            }}
          >
            Search
          </button>
        </Col3>
      </div>
      <br></br>
      <div className="columns">
        <col3>
          <div style={{ width: "180px" }}></div>
        </col3>
        <col3>
          <div style={{ width: "180px" }}></div>
        </col3>
         
        <col3>
       
          {ppSchedules.length > 0 ? (
            <div
              style={{ width: "550px", fontSize: "20px", fontWeight: "bold" }}
            >
              <br></br>
              <h1>{ppSchedules.length + "  " + "Results"}</h1>
            </div>
          ) : (
            <>
              {searchData1==true && (
                <h1>No Results Found.</h1>
              )}

            </>
          )}
        </col3>
      
      </div>
      {ppSchedules.length > 0  && checkedState.length>0 && <div style={{display:"flex",flexDirection:"row",gap:2,alignItems:"center",width:"100%",justifyContent:"start"}}>
          <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={(e)=>{
                     setSelectAll(e.target.checked);
                     setCheckedState(checkedState.map(()=>e.target.checked));
                  }}
                />
                 <p>Select All</p>
                </div>
                 }
      <br></br>
      <div style={{ display: "flex" }}>
        <div style={{ width: "800px" }}>
          {fileterData.length > 0 ? (
            rec.map((e, index) => (
              <>
               <input
                  type="checkbox"
                  id={index}
                  checked={checkedState[index]}
                  onChange={()=>{
                  handleOnChange(index)
                  }}
                />
               <FCLPPSchedule
                skipvalidation={true}
                data={{
                  ...e.sch,
                  ...e._id,
                  por: e.sch.oname,
                  pol: e.sch.oname,
                  pod: e.sch.dname,
                  fpod: e.sch.dname,
                  etdpol: e.sch.oetd,
                  etapod: e.sch.deta,
                }}
                contract={[]}
              />
              
              </>
             
              
            ))
            
          ) : (
            <>
              <div style={{ width: "800px" }}></div>
            </>
          )}
          <ReactTablePagination
                page={page}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageSize={pageSize}
                pageIndex={pageIndex}
                pageCount={pageCount}
                setPageSize={setPageSize}
                manualPageSize={manualPageSize}
                dataLength={totalCount}
                rows={rows}
          />
        </div>

        {ppSchedules.length > 0 || p44Data.length > 0 ? (
          <div style={{ width: "170px" }}>
            <div>
              <h1 style={{ marginLeft: "20px", font: "10px" }}>
                {" "}
                Filter your results
              </h1>
            </div>
            <br></br>
            <div style={{ marginLeft: "20px", font: "10px" }}>
              <label
                style={{
                  marginRight: "5px",
                  fontSize: "large",
                  fontWeight: "bold",
                }}
              >
                Transit:
              </label>
              <br></br>
              <Box sx={{ width: 190 }}>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={value}
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  min={MIN}
                  max={MAX}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="body2"
                    //onClick={() => setVal(MIN)}
                    sx={{ cursor: "pointer" }}
                  >
                    {MIN} Days
                  </Typography>
                  <Typography
                    variant="body2"
                    //onClick={() => setVal(MAX)}
                    sx={{ cursor: "pointer" }}
                  >
                    {MAX} Days
                  </Typography>
                </Box>
              </Box>
            </div>
            <br></br>
            {routing==true ?  
            <div style={{ marginLeft: "20px", font: "10px" }}>
              <label
                style={{
                  marginRight: "100px",
                  fontSize: "large",
                  fontWeight: "bold",
                }}
              >
                Routing:
              </label>

              <br></br>
             
              {
                Direct.length>0&& Transhipment.length>0 &&
                <>
                <div>
                <input
                  value="Direct"
                  type="checkbox"
                  onChange={handleChange1}
                  checked={checkedDirect}
                />
                <span> Direct </span>
              </div>
              <div>
                <input
                  value="Transhipment"
                  type="checkbox"
                  onChange={handleChange1}
                  checked={transhipment}
                />
                <span> Transhipment </span>
              </div>
                </>
              }
              
            </div>:<>
            <div style={{ marginLeft: "20px", font: "10px" }}>
              <label
                style={{
                  marginRight: "100px",
                  fontSize: "large",
                  fontWeight: "bold",
                }}
              >
                Routing:
              </label>

              <br></br>
             
              {Direct.length>0&& <div>
                <input
                  value="Direct"
                  type="checkbox"
                  //onChange={handleChange1}
                  checked={true}
                  disabled
                />
                <span> Direct </span>
              </div>}
              {Transhipment.length>0&&   <div>
                <input
                  value="Transhipment"
                  type="checkbox"
                  //onChange={handleChange1}
                  checked={true}
                  disabled
                />
                <span> Transhipment </span>
              </div>}
              
            </div>
           
            </>}
           
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
