import { useState } from "react"
import { useSnapshot } from "valtio"
import { AgSelect } from "../components/AgComponents"
import AgFile2 from "../components/agfile2"
import { configStore } from "../state/CommonState"
import { myactionsstore } from "../my-actions/store-myactions"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from "../util/constants"

export default function Visibility() {
    const store = useSnapshot(myactionsstore)
    const [data, setData] = useState({})

    let errors = data.validationErrors
    return (
        <div>
            <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                <tbody>
                    <tr id="td16">
                        <td>
                            <div className="title is-6">Visibility - LCL</div>
                        </td>
                        {
                            RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_UPLOADS) &&
                        <td>
                            <AgFile2 callback={setData} form_data={{ shipmentType: 'LCL' }} parent="td16"
                                url='/api/v1/myactions/uploaddsr' filetypes='.csv' />
                        </td>
                        }
                        <td><a  style={{fontSize:"12px",textDecoration:"underline"}} className="is-link" href="LCL DSR Upload Template.xlsx" download="LCL DSR Upload Template.xlsx">Download LCL Template</a></td>
                    </tr>
                    <tr id="td17">
                        <td>
                            <div className="title is-6">Visibility - Air</div>
                        </td>
                        {
                            RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_UPLOADS) &&
                        <td>
                            <AgFile2 callback={setData} form_data={{ shipmentType: 'Air' }} parent="td17"
                                url='/api/v1/myactions/uploaddsr' filetypes='.csv' />
                        </td>
                        }
                        <td><a  style={{fontSize:"12px",textDecoration:"underline"}} className="is-link" href="./Air DSR Upload Template.xlsx" download="Air DSR Upload Template.xlsx">Download Air Template</a></td>

                    </tr>
                    <tr id="td18">
                        <td>
                            <div className="title is-6">Visibility - FCL (Factory/Factory)</div>
                        </td>
                        {
                            RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_UPLOADS) &&
                        <td>
                            <AgFile2 callback={setData} form_data={{ shipmentType: 'FCL (Factory/Factory)' }} parent="td18"
                                url='/api/v1/myactions/uploaddsr' filetypes='.csv' />
                        </td>
                        }
                        <td><a  style={{fontSize:"12px",textDecoration:"underline"}} className="is-link" href="FCL (Factory_Factory) DSR Upload Template.xlsx" download="FCL (Factory_Factory) DSR Upload Template.xlsx">Download FCL(Factory/Factory) Template</a></td>
                    </tr>
                    <tr id="td19">
                        <td>
                            <div className="title is-6">Visibility - FCL (Dock/Factory)</div>
                        </td>
                        {
                            RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_UPLOADS) &&
                        <td>
                            <AgFile2 callback={setData} form_data={{ shipmentType: 'FCL (Dock/Factory)' }} parent="td19"
                                url='/api/v1/myactions/uploaddsr' filetypes='.csv' />
                        </td>
                        }
                        <td><a  style={{fontSize:"12px",textDecoration:"underline"}} className="is-link" href="./FCL (Dock_Factory) DSR Upload Template.xlsx" download="FCL (Dock_Factory) DSR Upload Template.xlsx">Download FCL(Dock/Factory) Template</a></td>
                    </tr>
                </tbody>
            </table>
            {
                (data.isError) ?
                    <div>
                        <h4 className="title is-6">Errors in DSR data</h4>
                        <table className="table">
                            <tbody>
                                {
                                    Object.keys(errors).map(e => {
                                        return (
                                            <tr>
                                                <td>{e}</td>
                                                <td>{errors[e]}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    : <><h4 className="title is-6">
                        {
                            (Object.keys(data).length > 0) ? "Data uploaded successfully!" : ""
                        }
                    </h4></>
            }
        </div>
    )
}