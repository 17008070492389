import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import logo from '../aglogo.svg'
import Api from '../state/Api'
import { bookingsStore, configStore, contractsStore, loginStore } from '../state/CommonState'
import Utils from '../util/Utils'
import LCLChargeClientView from '../enquiry/LCLChargeClientView'
import ShipmentGraph, { graphNode } from '../my-actions/shipment-graph'
import BookingSchedule from './booking.schedule'
import { rtpStore } from './pricing-store'
import { Button } from '@mui/material'
import { ArrowDownwardRounded, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import airlogoMap from '../schedules/air-logo'
import FCLChargeClientViewv2 from '../enquiryv2/FCLChargeClientView-v2'
import schLogoMap from '../enquiry/carrier-logo'
import AguserApi from '../state/AguserApi'
import EntityV2Api from '../state/entities-api'
import { ENTITY_STATUS } from '../util/constants'
import { GenericSwitch } from '../components/ag-switch'
import EntityCreWarnPopup from '../mui-components/EntityCreWarnPopup'

function _g(node) {
    let e = new graphNode()
    //Object.assign(e, { r1: 'r1', r2: 'r2', r3: 'r3', r4: 'r4' })
    Object.assign(e, node)
    e.isActive = true
    e.activelines = 2
    return e
}


export default function FCLPricingSchedule(props) {
    const [showCharges, setShowCharges] = useState(false)
    const [ptbVisible, setptbVisible] = useState(true)
    const [EntityCreationPopup, setEntityCreationPopup] = useState(false)

    useSnapshot(contractsStore)
    useSnapshot(bookingsStore)
    let brec = bookingsStore.current
    useSnapshot(configStore)
    useSnapshot(rtpStore)
    let hideme = props.hideme
    let cwfVisible = props.cwfVisible
    //if (!cwfVisible && !ptbVisible) setptbVisible(true)
    let rec = contractsStore.current
    if (props.contract) rec = props.contract
    let subscript = -1
    if (props.subscript >= 0) subscript = props.subscript
    let disableBookNow = false;
    let charges = props.pricingRec?.charges
    if( charges && ((charges["Pick-up Charges"]&& charges["Pick-up Charges"].total===0)||(charges["Delivery Charges"]&& charges["Delivery Charges"].total===0))){
disableBookNow = true
    }

    useEffect(() => {
        console.log('shipperpda TYPE=', rtpStore.shipperpda)
        if (rtpStore.shipperpda == ' ') {
            rec.quoteValueDeduction = rec.oih
        } else {
            rec.quoteValueDeduction = 0
        }
    }, [rtpStore.shipperpda])
    useEffect(() => {
        if (subscript < 0) return
        let filterType = rtpStore.dpd != 'Direct Port Delivery' ? 'NDPD' : 'DPD'
        let e = rtpStore.records[subscript]
        //console.log('\n\n\nDPD TYPE=', filterType, e.additionalType)
        if (rtpStore.additionalsets) {
            let recs = rtpStore.additionalsets.filter(e1 => e.ID == e1.ID && e1.additionalType == filterType)
            //console.log('LENGTH=', recs.length)
            if (recs && recs.length) {
                rtpStore.records[subscript] = recs[0]
            }
        }
    }, [rtpStore.dpd])

    if (!rec) return <></>

    let e = { ...props.data }
    let isDummy = props.isDummy ? true : false
    //if (props.isDummy) return <div>{JSON.stringify(rec)}</div>
    if (props.isDummy) {
        e = {
            'por': rec.portOfReceipt,
            'pol': rec.portOfLoading,
            'pod': rec.portOfDischarge,
            'fpod': rec.finalPlaceOfDelivery,
            'rep': rec.reworkingPort,
            'VESSEL NAME': '',
            'VOYAGE': '',
            'STUFFING CUT OFF DATE': '2020-01-01',
            'ETD (POL)': '2020-01-01',
            'ETA (POD)': '2020-01-01',
            'CO-LOADER': ''
        }
    }
    let theSchedule = e
    if (!e['POR (CFS / ICD / PORT)']) e['POR (CFS / ICD / PORT)'] = e['POL']
    let srec = {
        por: e.por,
        pol: e.pol,
        pod: e.pod,
        vessel: e['VESSEL NAME'],
        voyage: e['VOYAGE'],
        cutOffDt: e['STUFFING CUT OFF DATE'],
        etdPol: e['ETD (POL)'],
        etaPod: e['ETA (POD)'],
        coLoader: e['CO-LOADER']
    }
    const pod = { location: srec.pod }
    const por = { location: srec.por }
    const pol = { location: srec.pol }

    let showOrigin = false
    let showDestination = false
    if (rec.shipmentScope.toLowerCase().indexOf('door-') >= 0) showOrigin = true
    if (rec.shipmentScope.toLowerCase().indexOf('-door') >= 0) showDestination = true
    let etaDate = Utils.newDate(e.etapod)
    if (e.pod != e.fpod) etaDate = Utils.newDate(e.etafpod)
    let etdDate = Utils.newDate(e.etdpol)
    let days = Utils.getDays(etdDate, etaDate)

    let _date = (dt) => {
        return Utils.formatDateShort(new Date(dt))
    }

    //console.log('LCLCOMPONENT***',srec, srec.por, srec.pol, por.location, pol.location)
    let graphnodes = []
    if (showOrigin) {
        graphnodes.push(_g({
            r2: rec.origin,
            graphic: 'origin'
        }))
    }
    graphnodes.push(_g({
        r1: (props.isDummy || !e.cutoffpor) ? '' : 'Cut-off ' + _date(e.cutoffpor),
        r2: por.location,
        r3: (!props.isDummy) ? 'ETD ' +  _date(por.location !== pol.location ? e.etdpor : e.etdpol) : ''
    }))
    if (por.location !== pol.location) {
        graphnodes.push(_g({
            r1: (props.isDummy) ? '' : 'ETA ' + _date(e.etapol),
            r2: pol.location,
            r3: (props.isDummy) ? '' : 'ETD ' + _date(e.etdpol)
        }))
    }
    if (!isDummy) {
        graphnodes.push(_g({
            r1: e['v1name'] + ' (' + e['s1voy'] +')',
            r2: e.r1code,
            transparent: true
        }))
        if (e.ts1name && e.ts1name.length) {
            graphnodes.push(_g({
                r1: 'ETA ' + _date(e.ts1eta),
                r2: e.ts1name,
                r3: 'ETD ' + _date(e.ts1etd)
            }))
            graphnodes.push(_g({
                r1: e['v2name'] + ' (' + e['s2voy'] +')',
                r2: e.r2code,
                transparent: true
            }))
        }
        if (e.ts2name && e.ts2name.length) {
            graphnodes.push(_g({
                r1: 'ETA ' + _date(e.ts2eta),
                r2: e.ts2name,
                r3: 'ETD ' + _date(e.ts2etd)
            }))
            graphnodes.push(_g({
                r1: e['v3name'] + ' (' + e['s3voy'] +')',
                r2: e.r3code,
                transparent: true
            }))
        }
    }

    graphnodes.push(_g({
        r1: (props.isDummy || !e.etapod) ? '' : 'ETA ' + _date(e.etapod),
        r2: pod.location,
        r3: (props.isDummy || e.pod == e.fpod || !e.etdpod) ? '' : 'ETD ' + _date(e.etdpod),
    }))
    if (e.pod != e.fpod) {
        graphnodes.push(_g({
            r1: (props.isDummy || !e.etafpod) ? '' : 'ETA ' + _date(e.etafpod),
            r2: e.fpod
        }))
    }
    if (showDestination) {
        graphnodes.push(_g({
            r2: rec.destination,
            graphic: 'destination'
        }))
    }

    let grandTotal = 0.0
    let voldis = 0.0
    rec['chargesList'].map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            voldis = e.total * erate
            //grandTotal -= voldis
        }
        else if (e.isIncluded == 'Yes')
            grandTotal += e.total * erate
    })
    let usdRate = configStore.getExchangeRate('USD')
    let grandusd = grandTotal / usdRate
    let pricingRec = props.pricingRec
    let sss = props.pricingRec?.dummyContract
    let cscac
    if (rec.scac) cscac = rec.scac
    let airlogo = logo
    if (brec && brec.iata) airlogo = airlogoMap[brec.iata]
    let exrate = 0
    let perchargelable = ""
    let Ocean_Freight_amount_inr = 0
    let Ocean_Freight_amount_usd = 0
    if(rec.shipmentScope=="Port-to-Port"){
        for (let i = 0; i < rec.chargesList.length; i++) {
            if(rec.chargesList[i]['chargeDescription']=='Ocean Freight'){
                console.log("+++",rec.chargesList[i])
                exrate = configStore.getExchangeRate(rec.chargesList[i]['currency'])
                let k = Object.keys(rec.chargesList[i])
                for (let j = 0; j < k.length; j++) {
                    if(k[j].indexOf("perWM")!=-1){
                        perchargelable = k[j].split('perWM')[0]
                        Ocean_Freight_amount_inr = rec.chargesList[i][k[j]]*exrate
                        if(rec.chargesList[i]['currency']=='USD'){
                            Ocean_Freight_amount_usd = rec.chargesList[i][k[j]]
                        }
                        j = k.length+1
                    }
                }
            }
        }
    }

    const showContract = () => {
        if (loginStore.isClient()) {
            if (pricingRec.set != 5) return true
            if (pricingRec.validTo != '' && pricingRec.set != 5) return true
        }
        return false
    }
    return (
        <div className="box" style={ { background :"#2A6ED4 !important" }}>
         { !EntityCreationPopup ? (<>
            {
                (pricingRec && pricingRec.set == 5) && <div  style={{ fontSize: '1.0rem', fontWeight: 'bold', color: '#F64' }}>
                    {
                        (pricingRec.validTo == '')  ? <div ><span style={{border: '1px solid black'}}>SPOT</span></div> :
                        <div>Rates valid till {_date(pricingRec.validTo)}</div>
                    }
                </div>
            }
            {
                (pricingRec && pricingRec.validupto && pricingRec.validupto.trim().length>0)?
                <div>
                <div style={{ color: 'grey', width: '100%' }}>Valid for <strong>Shipped-on-Board</strong> upto</div>
                <div  style={{ fontSize: '1.0rem', fontWeight: 'bold', color: '#F64' }}>{Utils.formatDate(new Date(pricingRec.validupto))}</div>
            </div>:""
            }
            <div className="columns" style={{ fontSize: '0.8rem' }}>
                <div className="column is-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                    {
                        (schLogoMap[cscac]) ?
                            <img src={schLogoMap[cscac]} alt={cscac} />
                            : <strong>{cscac}</strong>
                    }
                </div>
                <div className="column is-9">
                    {
                        (days!=undefined && Number(days)>0) ?
                            <>
                                <div style={{ color: 'grey', width: '100%', textAlign: 'center' }}>Tentative Ocean Transit Time</div>
                                <div style={{ fontSize: '0.9rem', width: '100%', textAlign: 'center' }}><strong>{e.transitTime} days</strong></div>
                            </>
                            :
                            <div style={{ color: 'grey', width: '100%', textAlign: 'center' }}>Schedule Awaited</div>
                    }
                    <div >
                        <ShipmentGraph insidepricing={true} graphNodes={graphnodes} />
                    </div>
                </div>
                <div className='column is-2' style={{ textAlign: 'center' }}>
                    {
                        (rec.shipmentScope=="Port-to-Port")?<>
                        <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(Ocean_Freight_amount_usd, 'USD')}<span style={{ fontSize: '0.6rem' }}> Per {perchargelable}</span></strong></div>
                        <div style={{ fontSize: '0.8rem' }}>{Utils.N(Ocean_Freight_amount_inr)}</div>
                        </>:
                        <>
                        {
                        (rec.quoteValue <= 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(grandTotal)}</strong></div>
                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(grandusd, 'USD')}</div>
                                <div style={{ fontSize: '1.0rem' }}>(Less) Volume Discount <strong>{Utils.N(voldis)}</strong></div>
                            </> : <>
                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue - Utils.toNum(rec.quoteValueDeduction))}</strong></div>
                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD, 'USD')}</div>
                            </>
                    }
                        </>
                    }
                    {/*
                        (voldis > 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}>(Less) Volume Discount <strong>{Utils.N(voldis)}</strong></div>
                            </> : <></>
                */}
                    {/*
                        (voldis > 0 && grandTotal - voldis > 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}>(Final Discounted Offer <strong>{Utils.N(grandTotal - voldis)}</strong></div>
                            </> : <></>
            */}
                    <div style={{ fontSize: '0.6rem', color: 'grey' }}>(Taxes, as Applicable)</div>
                    <div className="columns m-0 p-0">
                    <div className='column is-6 m-0 p-0'>
                    {
                        (!props.nobutton && props.pricingRec) &&
                        <>
                            {
                                (!loginStore.isClient()) &&
                                <button className='button is-rounded is-small is-info' onClick={() => {
                                    if (rec.oih && rtpStore.shipperpda == ' ') {
                                        let pdatc = `Origin Inland Charges from ${rec.portOfReceipt} to ${rec.portOfLoading} are on Shipper's account`
                                        if (rec.oihScac != 'PRIVATE' && rec.oihScac != 'CONCUR') pdatc += ', subject to Carrier Approval'
                                        props.pricingRec.quote.quoteData.customTC += pdatc + "\n"
                                    }
                                    Api.saveQuoteNoTransform(props.pricingRec)
                                }}>Save Quote</button>
                            }
                            {
                                (showContract()) &&
                                <button className='button is-rounded is-small is-info' onClick={async () => {
                                     if (configStore.currentSelectedEntity.entityId.length === 0){
                                        setEntityCreationPopup(true)
                                        return
                                    }
                                    if (rec.oih && rtpStore.shipperpda == ' ') {
                                        let pdatc = `Origin Inland Charges from ${rec.portOfReceipt} to ${rec.portOfLoading} are on Shipper's account`
                                        if (rec.oihScac != 'PRIVATE' && rec.oihScac != 'CONCUR') pdatc += ', subject to Carrier Approval'
                                        props.pricingRec.quote.quoteData.customTC += pdatc + "\n"
                                    }
                                    await Api.saveQuoteAndContract(props.pricingRec, isDummy)
                                }}>Save Contract</button>
                                // <Button sx={{textTransform:"capitalize"}}>Save Contract</Button>
                            }
                        </>
                    }
                    </div>
                    <div className='column is-6 m-0 p-0'>
                    {
                        (!props.nobutton && rtpStore.customerTBA != 'TBA') ?
                            <>
                                <button disabled={disableBookNow} className='button is-rounded islink is-small' onClick={() => {
                                     if (loginStore.isClient() && configStore.currentSelectedEntity.entityId.length === 0){
                                        setEntityCreationPopup(true)
                                        return
                                    }

                                    EntityV2Api.viewEntity(rec.entityId, (data) => {
                                        if (data.status === ENTITY_STATUS.DEACTIVATED) {
                                            configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for Procced to booking `)
                                        }
                                        else if (data.entityCountry.toLowerCase() === 'india' && (data.branch[0].gst.length < 1 || data.branch[0].branchAddresses[0].address < 1)) {
                                            if (loginStore.isClient()) {
                                                let dataObject = {
                                                    cb: data?.customer?.crossBorder?.relationshipManager,
                                                    dt: data?.customer?.domesticTransport?.relationshipManager
                                                }

                                                AguserApi.getProfile(dataObject, (data) => {
                                                    let message = "Please update your KYC before proceeding for the Booking. Please reach out to your Relationship Manager if you face any challenges "

                                                    if (data?.CB_User || data?.DT_User) {
                                                        let user = data?.CB_User || data?.DT_User
                                                        message += `${user?.firstName} on ${user?.phonePrefix} ${user?.phone} or ${user?.email}`
                                                    } else if (data?.PAT_User) {
                                                        message += data?.PAT_User?.email
                                                    }

                                                    configStore.setModalMessage(message)
                                                })
                                            } else {
                                                configStore.setModalMessage('Please Update at least one GST and address to place the Booking')
                                            }
                                        }
                                        else if (data.entityCountry.toLowerCase() !== 'india' && data.tinNum.length < 1) {
                                            configStore.setModalMessage('Please Update at least tin Number to place the Booking')
                                        }
                                        else {
                                            rtpStore.routeSubscript = subscript
                                            bookingsStore.initialize()
                                            bookingsStore.current.schedule = theSchedule
                                            bookingsStore.current.graphNodes = graphnodes
                                            let rec1 = { ...rec }
                                            //rec1.tc.push(...rec.temptc)
                                            bookingsStore.current.contract = { ...rec }
                                            //bookingsStore.current.contract.temptc = []
                                            let filteredCharges = bookingsStore.current.contract.chargesList
                                            /*if (rec.oih && rtpStore.shipperpda == ' ') {
                                                filteredCharges = filteredCharges.filter(e => e.chargeDescription != 'Origin Inland Haulage')
                                                bookingsStore.current.contract.quoteValueDeduction = rec.oih
                                                bookingsStore.current.contract.oih = 0
                                            }*/
                                            if (rec.oih && rtpStore.shipperpda == ' ') {
                                                bookingsStore.current.contract.oih = rec.oih
                                            } else {
                                                bookingsStore.current.contract.oih = 0
                                            }
                                            bookingsStore.current.contract.chargesList = [...filteredCharges]
                                            bookingsStore.current.contract.quotationNum = 'SPOT'
                                            bookingsStore.current.contractNum = 'SPOT'
                                            bookingsStore.current.contractRefNum = 'SPOT'
                                            bookingsStore.current.hasToShowDummySchedule = false
                                            bookingsStore.current.emailid = loginStore.email
                                            bookingsStore.current.createdFromPricing = true
                                            console.log('\n\n***TC length=', bookingsStore.current.contract.tc.length, '***\n')
                                            if (isDummy) bookingsStore.current.hasToShowDummySchedule = true

                                            contractsStore.current = rec
                                            hideme(true)
                                            setptbVisible(false)
                                            configStore.scheduleOrCarrierform = 'carrierform'

                                        }
                                    })

                                }}>
                                    Book Now
                                </button>
                            </> : <></>
                    }
                    </div>
                    </div>
                    
                    
                    <button style={{ marginTop: '5px' }} className="button is-inverted is-rounded is-small is-link" onClick={() => {
                        let rec1 = JSON.parse(JSON.stringify(rec))
                        rec1.tc.push(...rec.temptc)
                        if (rec.oih && rtpStore.shipperpda == ' ') {
                            let pdatc = `Origin Inland Charges from ${rec.portOfReceipt} to ${rec.portOfLoading} are on Shipper's account`
                            if (rec.oihScac != 'PRIVATE' && rec.oihScac != 'CONCUR') pdatc += ', subject to Carrier Approval'
                            rec1.tc.push(pdatc)
                        }
                        configStore.setModalMessage("", [], false, rec1.tc)
                    }}>Terms and Conditions</button>

                </div>
            </div>
            <div className="columns" style={{ fontSize: '0.8rem' }}>
                {
                    (showCharges) ?
                        loginStore.isClient() ? <Button size='small' onClick={() => {
                            setShowCharges(false)
                        }} variant='contained' endIcon={<ArrowDropUp />} fullWidth sx={{
                            display: "flex", textTransform: "capitalize", alignItems: "center", backgroundColor: "#ffdc7d", color: "black", "&:hover": {
                                backgroundColor: "#ffdc7d"
                            }
                        }} >Hide Detailed Pricing</Button> : <button className='button is-warning is-small is-fullwidth' onClick={() => setShowCharges(false)}>
                            Hide Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-up"></i>
                            </span>
                        </button> :
                        loginStore.isClient() ? <Button size="small" onClick={() => {
                            setShowCharges(true)
                        }} variant='contained' endIcon={<ArrowDropDown />} fullWidth sx={{ textTransform: "capitalize", alignItems: "center" }} >View Detailed Pricing</Button> : <button className='button is-info is-small is-fullwidth'
                            onClick={() => {
                                setShowCharges(true)
                            }}>
                            View Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-down"></i>
                            </span>
                        </button>
                }

            </div>
            {
                (showCharges) ?
                    <>
                        <div className="columns" style={{
                            fontSize: '0.8rem',
                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            {
                                (rec.shipperchargesList?.length > 0 || rec.consigneechargesList?.length > 0) ?
                                    <>
                                        <div className='column is-6'>
                                            <FCLChargeClientViewv2 dummyContract={rec} subscript={subscript} cwfVisible={props.cwfVisible}/>
                                        </div>
                                        <div className='column is-6'>
                                            {
                                                rec.shipperchargesList?.length > 0 ?
                                                    <FCLChargeClientViewv2 dummyContract={rec} chargesName='shipperchargesList' />
                                                    :
                                                    <FCLChargeClientViewv2 dummyContract={rec} chargesName='consigneechargesList' />
                                            }
                                        </div>
                                    </>
                                    :
                                    <div className='column is-12'>
                                        <FCLChargeClientViewv2 dummyContract={rec} subscript={subscript} cwfVisible={props.cwfVisible}/>
                                    </div>
                            }
                        </div>
                        {
                            (props.pricingRec?.hasDPD || '' + props.pricingRec?.additionalType == 'DPD') &&
                            <div className='columns'>
                                <div className='column is-12'>
                                    <GenericSwitch store={rtpStore} property='dpd' leftValue='Direct Port Delivery' rightValue=' ' />
                                </div>
                            </div>
                        }
                    </> : <></>
            }
         </>) :  
         <EntityCreWarnPopup cancelfn={(i)=>setEntityCreationPopup(false)} />
        }
        </div>
    )
}