import React, {useEffect, useState} from 'react'
import { useSnapshot } from 'valtio'
import { EntityV2Store, configStore } from '../../state/CommonState'
import off from "../../components/off.png";
import on from "../../components/on2.png";
import { DSRObject } from '../../state/Types'
import { AgTimePicker } from '../../mui-components/AgTimePicker';
import EntityDSRApi from '../../state/entitiesDsr-api'
import dayjs from 'dayjs';
// import zIndex from '@mui/material/styles/zIndex';

const DSRScheduler = ({ fn, dsrData }) => {
  const [toSendReports, setToSendReports] = useState(false)
  const [ selectedTime, setSelectedTime] = useState(null)
  const [selectedHour, setSelectedHour] = useState(null)
  const [dsrObj, setDsrObj] = useState({})
  const DEFAULT_TIME =10

  useSnapshot(EntityV2Store)
  const Entity = EntityV2Store.EntityRec
  const title = `View (${Entity?._id}) ${Entity?.status}`

  useEffect(() => {
    if (dsrData.status === 200) {
      dsrData = JSON.parse(JSON.stringify({ ...dsrData.data }))
      setDsrObj(dsrData)
      if (dsrData?.sendReport !== undefined) {
        setToSendReports(dsrData.sendReport)
      }
      if(dsrData?.scheduler?.days?.length > 0) {
        const updatedCheckedDays = Object.keys(checkedDays).reduce((acc, day) => {
          acc[day] = dsrData.scheduler.days.includes(dayMapping[day]);
          return acc;
        }, {});
        
        setCheckedDays(updatedCheckedDays);
      }
      if (dsrData?.scheduler.time !== undefined && dsrData?.scheduler.time > 0) {
        const newTime = dayjs().set('hour', dsrData.scheduler.time).startOf('hour');
        setSelectedTime(newTime);
      }
        
    } else {
      dsrData = new DSRObject()
      setDsrObj(dsrData)
    }

  }, [dsrData])



  const handleTimeChange = (time) => {
    if (time) {
      console.log("Time: ", time, typeof(time))
      console.log("dsrData", dsrData, dsrObj)
      const hours = dayjs(time).hour();
      const newTime = dayjs().set('hour', hours).startOf('hour');
      setSelectedTime(newTime)
      setSelectedHour(hours)
      setDsrObj((prevDsrObj) => ({
        ...prevDsrObj,
        scheduler: { ...prevDsrObj.scheduler, time: hours },
      }));
    } else {
      setSelectedTime(null)
      setSelectedHour(null)
    }
  }

  async function handleSubmit() {
    console.log("DSR OBJ")
    let data = dsrObj
    console.log("Dsrobject", data, toSendReports)
    data.entityId = EntityV2Store.EntityRec.entityId
    data.entityName = EntityV2Store.EntityRec.entityName
    data._id = `DSR_${EntityV2Store.EntityRec.entityId}`
    // if(dsrObj.scheduler?.days?.length === 0){
      if(toSendReports && (dsrObj.scheduler?.days?.length === 0 || dsrObj.scheduler?.days === undefined )){
    return configStore.setModalMessage("Please select any one day for sending the mail")
    }
    await EntityDSRApi.createDsr(data, (data) => {
      if (data.status) {
        configStore.setModalMessage("Dsr creation sucessful")
        fn(false)
      }

    })
  }

  const toggleSendReports = () => {
    console.log("before toggle", toSendReports)
    const toggledReports = !toSendReports
    dsrObj.sendReport = toggledReports
    setToSendReports(toggledReports)
    setDsrObj(dsrObj)
    console.log("after toggle", dsrObj.sendReport)
    // const hours = dayjs(time).hour();
    if(toggledReports && (dsrObj?.scheduler?.time === undefined || dsrObj?.scheduler?.time === "")){
      const newTime = dayjs().set('hour', DEFAULT_TIME).startOf('hour');
      setSelectedTime(newTime)
      setDsrObj((prevDsrObj) => ({
        ...prevDsrObj,
        scheduler: { ...prevDsrObj.scheduler, time: DEFAULT_TIME },
      }));
    }else {
      // dsrObj.sendReport = false
      // setSelectedTime(null)
    }

  }

  const [checkedDays, setCheckedDays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  })

  const dayMapping = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target

    setCheckedDays((prevCheckedDays) => {
      const updatedCheckedDays = {
        ...prevCheckedDays,
        [name]: checked,
      }

      const updatedDays = Object.keys(updatedCheckedDays)
        .filter((day) => updatedCheckedDays[day])
        .map((day) => dayMapping[day])

      dsrObj.scheduler.days = updatedDays
      setDsrObj(dsrObj)

      return updatedCheckedDays
    })
  }

  return (
    <>
      <div id="usersPg">
        <div class="back-div is-flex" >
          <img src="./assets/Back.png" alt="Back" class="back" width="32px"
            onClick={() => {
              fn(false)
            }} />
          <h1 className="title ml-2">{title}</h1>
        </div>
      </div>
      <div className='is-flex is-justify-content-space-between'>
        <div className="mt-6 ml-6 label is-large">
          <p>Scheduler</p>
        </div>
        <div className='is-flex'>
          <div className='mr-6 mt-6'>
            <p
              className="label is-medium"
              style={{ marginBottom: "0px" }}>
              Send DSR Report to Customer
            </p>
            <button
              className="mt-1 is-clickable"
              type="button"
              style={{ border: "none", backgroundColor: "white" }}
              onClick={toggleSendReports}
            >
              {toSendReports ? (
                <img height="20px" width="36px" src={on}></img>
              ) : (
                <img height="20px" width="36px" src={off}></img>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className='is-flex' >
        <div className='mt-4 ml-6'>
          <p>Daily Status Report Schedule Timing:
            <AgTimePicker
              views={['hours']}
              className="ml-4"
              value={selectedTime}
              onChange={handleTimeChange}
              disabled={!toSendReports}
            ></AgTimePicker>
          </p>
        </div>
      </div>
      <div className='mt-6'>
        <div className="mt-4 ml-6">
          <p>Daily Status Report Scheduling :
            <span className='ml-4'>
              {Object.keys(checkedDays).map((day) => (
                <label key={day} className='mr-3 ml-3'>
                  <input
                    type="checkbox"
                    name={day}
                    className='mr-1'
                    checked={checkedDays[day]}
                    onChange={handleCheckboxChange}
                    disabled={!toSendReports}
                  />
                  {day}
                </label>
              ))}
            </span>
          </p>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', bottom: '20px' }}>
        <button className="button-cancel" style={{ marginRight: '1rem' }}
          onClick={() => {
            fn(false)
          }}>
          Cancel
        </button>
        <button className="button-submit"
          onClick={() => {
            console.log("Checked Days: ", checkedDays, "Time set: ", selectedHour)
            handleSubmit()
          }}>
          Submit
        </button>
      </div>
    </>
  )
}

export default DSRScheduler
