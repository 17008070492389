import { Box, Button, IconButton, Typography } from "@mui/material";
import uploadIcon from "../assets/images/upload.svg";
import { keyframes } from '@emotion/react';
import { useEffect, useRef, useState } from "react";
import closecontained from "../assets/images/closecontained.svg";
import { KeyDescription } from "./KeyDescription";
import { UploadFileComponent } from "./UploadFileComponent";
import { configStore } from "../state/CommonState";
import Upload from "./UploadButton";
import Upload2 from "./UploadButton2";
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/system';
// import './Upload.css';


const expand = keyframes`
  to {
    transform: scale(1);
  }
`;

const fillLeft = keyframes`
  to {
    transform: scale(4, 1.2);
  }
`;

const fadeUpOut = keyframes`
  to {
    opacity: 0;
    transform: translateY(-40%);
  }
`;

const fadeUpIn = keyframes`
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fillRight = keyframes`
  to {
    transform: scaleX(1);
  }
`;

const slideUp = keyframes`
  from {
    transform: scaleX(1) translateY(0);
  }
  to {
    transform: scaleX(1) translateY(-90%);
  }
`;

const strokeIn = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const slideDownInfo = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDownButton = keyframes`
  from {
    transform: scale(0.9) translateY(-100%);
  }
  to {
    transform: scale(0.9) translateY(0);
  }
`;

const slideDownProgress = keyframes`
  from {
    transform: scaleX(1) translateY(-90%);
  }
  to {
    transform: scaleX(1) translateY(10%);
  }
`;

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

const UploadBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  background: 'transparent',
  width:"200px",
  borderRadius: '7px',
//   boxShadow: '0 1.7px 1.4px rgba(0, 0, 0, 0.02), 0 4px 3.3px rgba(0, 0, 0, 0.028), 0 7.5px 6.3px rgba(0, 0, 0, 0.035), 0 13.4px 11.2px rgba(0, 0, 0, 0.042), 0 25.1px 20.9px rgba(0, 0, 0, 0.05), 0 60px 50px rgba(0, 0, 0, 0.07)',
  overflow: 'hidden',
  transform: 'rotate(0)',
}));

const UploadButton = styled(Button)(({ theme }) => ({
  display: 'none', // Hide the button
  position: 'relative',
  padding: '16px',
  margin: 0,
  fontSize: '100%',
  fontFamily: 'inherit',
  color: 'white',
  background: 'none',
  border: 'none',
  borderRadius: 'inherit',
  outline: 'none',
  cursor: 'pointer',
  transform: 'scale(0.9)',
  '&::before': {
    position: 'absolute',
    content: '""',
    zIndex: -1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#3bafda',
    borderRadius: 'inherit',
    transformOrigin: 'right',
  },
  '&.uploading::before': {
    animation: `${fillLeft} 1.2s 0.4s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
  '&.uploading': {
    animation: `${expand} 0.3s forwards`,
  },
  '&.uploaded-after': {
    animation: `${slideDownButton} 1s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
}));

const UploadProgress = styled(Box)(({ theme }) => ({
  position: 'absolute',
  content: '""',
  top: '90%',
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  color: 'white',
  background: '#2d334c',
  transform: 'scaleX(0)',
  transformOrigin: 'left',
  '&.uploading': {
    animation: `${fillRight} 2s 1s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
  '&.uploaded': {
    animation: `${slideUp} 1s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
  '&.uploaded .check': {
    strokeDasharray: '16px',
    strokeDashoffset: '16px',
    marginRight: '6px',
    animation: `${strokeIn} 0.6s 0.4s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
  '&.uploaded-after': {
    animation: `${slideDownProgress} 1s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
}));

const UploadHint = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  color: 'white',
  backgroundColor:"#2C358A",
//   transform: 'translateY(100%)',
transform: 'translateX(100%)',
  '&.uploading': {
    animation: `${slideInFromRight} 0.4s 0.8s forwards`,
  },
}));

const UploadInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '16px',
  marginRight: '40px',
  '&.uploading > *': {
    animation: `${fadeUpOut} 0.4s 0.4s forwards`,
  },
  '&.uploaded-after': {
    animation: `${slideDownInfo} 1s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
}));





export function ChooseFile({title,direction="column",onFileSelected,onFileDeleted,chooseBoxHeight,data,editLabel,isDeletable,isEditable,id,documentView}){
   

  

  const [status, setStatus] = useState({class:"",backgroundColor:"",text:""});
    const [fileList, setFileList] = useState(data?.length>0?data:data?.filename?.length>0?data.filename:[]);
    const fileInputRef = useRef(null);

    useEffect(()=>{
      // let files = [];
      // if(data && !data.filename){
      //   files = [...data];
      // }else if(data?.filename?.length>0){
      //   files = [...data.filename]
      // }
      console.log("the data from parent ",data)
      // setFileList([...files]);
    },[data])

    const updateFileState = (selectedFile)=>{
      if (selectedFile) {
      const pattern = /^[a-zA-Z0-9_ .()-]+$/;
      if(pattern.test(selectedFile['name'])){
        setStatus({class:"uploading",text:"Uploading..."});
      if(onFileSelected){
           onFileSelected(selectedFile,async(success)=>{
            await sleep(2000)
            setStatus({class:"uploaded",text:"Uploaded",backgroundColor:"#2C8A57"});
            await sleep(2000);
            setStatus({class:"uploaded-after",text:"Uploaded",backgroundColor:"#2C8A57"});
              // setFileList((prev)=>[...prev, key ? key : selectedFile.name]);
              if(success){
                success();
              }
              await sleep(1000);
              setStatus({class:"",backgroundColor:"",text:""});
           },async ()=>{
            await sleep(2000)
            setStatus({class:"uploaded",text:"Failed",backgroundColor:"#FE3839"});
            await sleep(2000);
            setStatus({class:"uploaded-after",text:"Failed",backgroundColor:"#FE3839"});
              await sleep(1000);
              setStatus({class:"",backgroundColor:"",text:""});
           })
      }
    }else{
      configStore.setModalMessage("Please Check File Name and Format");
      return
    }
    }else{
      configStore.setModalMessage("Please Check File Name and Format");
      return
    }
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const maxSize = 5 * 1024 * 1024;
        if(selectedFile.size <= maxSize){
          updateFileState(selectedFile);
        }else{
          configStore.setModalMessage(`File ${selectedFile.name} exceeds the maximum size of 5MB and will not be uploaded.`)
        }
      };
      const handleBoxClick = () => {
        fileInputRef.current.click();
      };

      // const removeFile = (i)=>{
      //   console.log(i,fileList,"sssssssss");
      //     setFileList((prev)=>{
      //         prev.splice(i,1);
      //         return [...prev];
      //     })
      // }
      
      const handleBoxDrop = async(event) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        if (droppedFiles.length > 0) {
          updateFileState(droppedFiles[0]);
        }
      };

    return <Box sx={{display:"flex",flexDirection : direction,gap:1,width:"100%",alignItems:"start"}}>
      <KeyDescription title={title} editLabel={editLabel} isEditable={isEditable} />
        <Box sx={{flex:1,width:"100%",height:chooseBoxHeight,display:"flex",flexDirection:direction,gap:1,flexWrap:"wrap"}}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Figtree'}}>
      <UploadBox className={`upload ${status.class}`}>
        <Box
          onClick={handleBoxClick}
          onDrop={handleBoxDrop}
          onDragOver={(e) => e.preventDefault()}
          sx={{
            border: '1px dashed #2C358A',
            borderRadius: '7px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40px',
            width: '200px',
            cursor: 'pointer',
            backgroundColor:'#FCFBFF'
          }}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <IconButton >
            <img src={uploadIcon} alt="Upload Icon" />
          </IconButton>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '8px' }}>
            <Typography sx={{ fontFamily: 'Figtree', fontWeight: 'bold', fontSize: '12px', color: '#2C358A' }}>Click to upload</Typography>
            <Typography sx={{ fontFamily: 'Figtree', fontSize: '11px', color: '#AAAAAA' }}>or just drag and drop</Typography>
          </Box>
        </Box>
        <UploadButton className={status.class} onClick={() => {}} />
        <UploadHint className={status.class}>
          <Typography variant="body1">{status.text}</Typography>
        </UploadHint>
        <UploadProgress sx={{backgroundColor:status.backgroundColor}} className={status.class}>
          {/* <CheckIcon className="check" /> */}
          <Typography variant="body1">{status.text}</Typography>
        </UploadProgress>
      </UploadBox>
    </Box>
    {/* <Upload2/> */}
        {/* <Box onClick={()=>{
           handleBoxClick();
        }}  onDrop={handleBoxDrop}
  onDragOver={(e) => e.preventDefault()} sx={{border:"1px dashed #2C358A",borderRadius:"7px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",height:"40px",width:"200px",cursor:"pointer"}}>
            <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
            <IconButton onClick={()=>{
           handleBoxClick();
        }}>
                <img  src={uploadIcon} />
            </IconButton>
            <Box sx={{display:"flex",flexDirection:'column',justifyContent:"center",alignItems:"center",padding:"8px"}}>
                <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"12px",color:"#2C358A"}} >Click to upload</Typography>
                <Typography sx={{fontFamily:"Figtree",fontSize:"11px",color:"#AAAAAA"}} >or just drag and drop</Typography>
            </Box>
        </Box> */}
       {fileList?.map((file,i)=>
       <UploadFileComponent isDeletable={isDeletable} onRemove={()=>{
        let file = fileList[i];
        setStatus({class:"uploading",text:"Removing..."});
        if(onFileDeleted){
          onFileDeleted(file,async (executeAfterSuccess)=>{
            // removeFile(i);
            await sleep(2000)
            setStatus({class:"uploaded",text:"Removed",backgroundColor:"#2C8A57"});
            await sleep(2000);
            setStatus({class:"uploaded-after",text:"Removed",backgroundColor:"#2C8A57"});
              await sleep(1000);
              setStatus({class:"",backgroundColor:"",text:""});
              if(executeAfterSuccess){
                  executeAfterSuccess();
              }
          },async ()=>{
            await sleep(2000)
            setStatus({class:"uploaded",text:"Failed",backgroundColor:"#FE3839"});
            await sleep(2000);
            setStatus({class:"uploaded-after",text:"Failed",backgroundColor:"#FE3839"});
              await sleep(1000);
              setStatus({class:"",backgroundColor:"",text:""});
              if(file){
                fileList.splice(i,0,file);
              }
          })
        }
        file = null
       }} documentTitle={file}  bookingId={id} view={documentView}   />
      )
       }
       </Box>
    </Box>
}

// export function ChooseFile({title,direction="column",onFileSelected,onFileDeleted,chooseBoxHeight}){
//   const [fileList, setFileList] = useState([]);
//   const fileInputRef = useRef(null);

//   const updateFileState = (selectedFile)=>{
//     if (selectedFile) {
//     const pattern = /^[a-zA-Z0-9_ .()-]+$/;
//     if(pattern.test(selectedFile['name'])){
//     if(onFileSelected){
//          onFileSelected(selectedFile,()=>{
        
//             setFileList((prev)=>[...prev, selectedFile]);
          
//          })
//     }
//   }
//   }else{
//     configStore.setModalMessage("Please Check File Name and Format");
//     return
//   }
//   }

//   const handleFileChange = (event) => {
//       const selectedFile = event.target.files[0];
//       updateFileState(selectedFile);
//     };
//     const handleBoxClick = () => {
//       fileInputRef.current.click();
//     };

//     const removeFile = (i)=>{
//         setFileList((prev)=>{
//             prev.splice(i,1);
//             return [...prev];
//         })
//     }
    
//     const handleBoxDrop = (event) => {
//       event.preventDefault();
//       const droppedFiles = event.dataTransfer.files;
//       if (droppedFiles.length > 0) {
//         updateFileState(droppedFiles[0]);
//       }
//     };

//   return <Box sx={{display:"flex",flexDirection : direction,gap:1,width:"100%",alignItems:"start"}}>
//     <KeyDescription title={title} />
//       <Box sx={{flex:1,width:"100%",height:chooseBoxHeight,display:"flex",flexDirection:direction,gap:1,flexWrap:"wrap"}}>
//       <Box onClick={()=>{
//          handleBoxClick();
//       }}  onDrop={handleBoxDrop}
// onDragOver={(e) => e.preventDefault()} sx={{border:"1px dashed #2C358A",borderRadius:"7px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",height:"40px",width:"200px",cursor:"pointer"}}>
//           <input
//         type="file"
//         ref={fileInputRef}
//         style={{ display: "none" }}
//         onChange={handleFileChange}
//       />
//           <IconButton onClick={()=>{
//          handleBoxClick();
//       }}>
//               <img  src={uploadIcon} />
//           </IconButton>
//           <Box sx={{display:"flex",flexDirection:'column',justifyContent:"center",alignItems:"center",padding:"8px"}}>
//               <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"12px",color:"#2C358A"}} >Click to upload</Typography>
//               <Typography sx={{fontFamily:"Figtree",fontSize:"11px",color:"#AAAAAA"}} >or just drag and drop</Typography>
//           </Box>
//       </Box>
//      {fileList?.map((file,i)=>
//      <UploadFileComponent onRemove={()=>{
//       if(onFileDeleted){
//         onFileDeleted(()=>{
//           removeFile(i);
//         })
//       }
//      }} documentTitle={file.name}  />
//     )
//      }
//      </Box>
//   </Box>
// }


