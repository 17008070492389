import { Typography } from "@mui/material";
import LabeledTextField from "./LabeledTextField";

export function DetailsText({ width, control, rules, name, endAdornmentText, type, allowNegative = false }) {
     return <LabeledTextField
          fontSize="12px"
          type={type || "number"}
          onKeyDown={(e) => {
              
                    ["e", "E", "+"].includes(e.key) && e.preventDefault();
               
               if(!allowNegative){
                    ["-"].includes(e.key) && e.preventDefault();
               }
          }
          }
          width={width} control={control} rules={{required : "Required", ...rules }} name={name} endAdornment={endAdornmentText ? <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "12px", whiteSpace: "nowrap" }}>{endAdornmentText}</Typography> : undefined} />
}

export function DetailsText2({ label, width, control, rules, name, endAdornmentText, type, allowNegative = false }){
    return <div className="flex flex-row gap-2.5 items-center">
      <p className="text-grey font-sm text-nowrap">{label}</p>
     <div className="w-[160px]">
     <LabeledTextField
    fontSize="12px"
    type={type || "number"}
    onKeyDown={(e) => {
        
              ["e", "E", "+"].includes(e.key) && e.preventDefault();
         
         if(!allowNegative){
              ["-"].includes(e.key) && e.preventDefault();
         }
    }
    }
    width={width} height="30px" control={control} rules={{required : "Required", ...rules }} name={name} 
    />
     </div>
    </div>
}
