import { useState } from "react"
import { AgInput, $c } from "../components/AgComponents"
import AgFile from "../components/AgFile"
import EntityApi from "../state/entity-api"

export default function MyProfileEntityDocs(props) {
    const [space, setSpace] = useState('')
    let entity = props.entity
    const attachmentNameMapping = {
        'IEC Code': 'iec_code',
        'PAN Card': 'pan_card',
        'GST Certificate': 'gst_cert',
        'Credit Application Form (CAF)': 'caf'
    }
    const renderLink = (fileLink , pKey) => {
        if (fileLink && fileLink.length > 3) {
            return (
                <a href={process.env.REACT_APP_API_SERVER + '/api/v1/filestore/getfile/'+   pKey + "/"  + fileLink} target="_blank">{fileLink}</a>
            )
        } else return <></>
    }
    console.log('ENTITY DOCUMENTS =', entity, entity.documents)

    return (
        <div className="columns" style={{ marginBottom: '1.5rem' }}>{space}
            <$c n='12'>
                <div className="box">
                    <div className="table-container " style={{}}>
                        <table className="table is-bordered is-hoverable is-fullwidth is-narrow">
                            <thead>
                                <tr>
                                    <th style={{ width: '16rem' }}>List of Required Documents</th>
                                    <th>Document Uploaded</th>
                                    {/* {
                                        (entity.status !== 'KYC-VERIFIED') ?
                                            <th style={{ width: '12rem' }}></th> :
                                            <></>
                                    } */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    entity.documents?.map((e, i) =>{
                                        if(e.name == "tinCert"){
                                            e.name = "Tin Certificate"
                                        }
                                        if(e.name == "UID"){
                                            e.name = "UID Certificate"
                                        }
                                        if(e.name == "panCard"){
                                            e.name = "PAN Card"
                                        }
                                        if(e.name == "gstDoc"){
                                            e.name = "GST Doc"
                                        }
                                        return (<tr>
                                        <td>{e.name}</td>
                                        <td>{renderLink(e.fileLink , e.pkey)}</td>
                                        {/* {
                                            (entity.status !== 'KYC-VERIFIED') ?
                                                <td>
                                                    <AgFile source='entity-upload' cat={entity.gst}
                                                        filetype={attachmentNameMapping[e.name]}
                                                        callback={(filename) => {
                                                            e.fileLink = filename
                                                            EntityApi.saveGivenEntity(entity)
                                                            setSpace(space === '' ? ' ' : '')
                                                        }} />
                                                </td> :
                                                <></>
                                        } */}
                                    </tr>)
                                    }
                                    )
                                }
                                {
                                   entity.entityCountry == "India" && entity.gstList?.map((e, i) =>
                                        <tr>
                                            <td>GST Doc- {e.gst}</td>
                                            
                                            <td>{renderLink(e.documents[1].fileLink , e.documents[1].pkey)}</td>
                                            {/* {
                                                (entity.status !== 'KYC-VERIFIED') ?
                                                    <td>
                                                        <AgFile source='entity-upload' cat={entity.gst}
                                                            filetype={attachmentNameMapping[e.name]}
                                                            callback={(filename) => {
                                                                e.fileLink = filename
                                                                EntityApi.saveGivenEntity(entity)
                                                                setSpace(space === '' ? ' ' : '')
                                                            }} />
                                                    </td> :
                                                    <></>
                                            } */}
                                        </tr>
                                        
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </$c>
        </div>
    )
}