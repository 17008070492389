import React, { useEffect, useState } from 'react'
import { DSRObject } from '../../state/Types'
import EntityDSRApi from '../../state/entitiesDsr-api'
import { EntityV2Store, configStore } from '../../state/CommonState'
import { SHIPMENT_TYPES_DSR } from '../../util/constants'


const DSRCustomization = ({fn,dsrData}) => {
    const [shipmentTypeSelected , setshipmentTypeSelected] =useState('LCL')
    const [dsrObjShow , setDsrObjShow] =useState({})
    const [dsrObj , setDsrObj] =useState({})
    const [originStuffing , setoriginStuffing] =useState("Factory")
    const [destinationStuffing , setdestinationStuffing] =useState("Factory")

    const shipmentTypes = ["LCL", "AIR" , "FCL_F_F" , "FCL_F_CFS" , "FCL_CFS_F" , "FCL_CFS_CFS" ]


    // useEffect(()=> {
    //     if(dsrData.status === 200){
    //         // setDsrObjShow(dsrData.data)
    //         setDsrObj(dsrData.data)
    //         //  obj = dsrData.data
    //     }else { 
           
    //         setDsrObj(new DSRObject())
    //         //  obj = new DSRObject()
    //     }

    // },[])
    useEffect( () => {
        let obj
        if(dsrData.status === 200){
            setDsrObjShow(JSON.parse(JSON.stringify({...dsrData.data})))
            setDsrObj(JSON.parse(JSON.stringify({...dsrData.data})))
             obj = JSON.parse(JSON.stringify({...dsrData.data}))
        }else { 
             setDsrObj(new DSRObject())
             obj = new DSRObject()
        }
        if (shipmentTypeSelected === "LCL") {
            let removeValFromIndexBookInf = [ 13, 14, 15, 16,17];
            let valuesArr = obj.milestones.bookingInformation.filter(function (value, index) {
                return !removeValFromIndexBookInf.includes(index);
            })
            obj.milestones.bookingInformation = valuesArr
            let removeValFromIndexOrgMil = [5,6,7,8,9,10,11,12,13,14,15,16,17,25,26,31,32,33,34,35,36];
            let valuesArrOrgMil = obj.milestones.originMilestones.filter(function (value, index) {
                return !removeValFromIndexOrgMil.includes(index);
            })
            obj.milestones.originMilestones = valuesArrOrgMil
            let removeValFromIndexInTransMil = [1,4,7,8 ,10,26,27,28 ,29,30];
            let valuesArrInTransMil = obj.milestones.inTransitMilestones.filter(function (value, index) {
                return !removeValFromIndexInTransMil.includes(index);
            })
            obj.milestones.inTransitMilestones = valuesArrInTransMil

            let removeValFromIndexDestinationMil = [2,4,6,15,16,17,20,21,24,25,26,27,28,29,30,31];
            let valuesArrDestinationMil = obj.milestones.destinationMilestones.filter(function (value, index) {
                return !removeValFromIndexDestinationMil.includes(index);
            })
            obj.milestones.destinationMilestones = valuesArrDestinationMil
        }
        if (shipmentTypeSelected === "AIR") {
            let removeValFromIndexBookInf = [6,9, 10, 11,12];
            let valuesArr = obj.milestones.bookingInformation.filter(function (value, index) {
                return !removeValFromIndexBookInf.includes(index);
            })
            obj.milestones.bookingInformation = valuesArr
            let removeValFromIndexOrgMil = [0,1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
            let valuesArrOrgMil = obj.milestones.originMilestones.filter(function (value, index) {
                return !removeValFromIndexOrgMil.includes(index);
            })
            obj.milestones.originMilestones = valuesArrOrgMil
            let removeValFromIndexInTransMil = [0,7,8,9,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38];
            let valuesArrInTransMil = obj.milestones.inTransitMilestones.filter(function (value, index) {
                return !removeValFromIndexInTransMil.includes(index);
            })
            obj.milestones.inTransitMilestones = valuesArrInTransMil
            let removeValFromIndexDestinationMil = [1,3,5,6,7,8,9,11,13,14,15,16,17,20,21,24,25,26,27,28,29,30];
            let valuesArrDestinationMil = obj.milestones.destinationMilestones.filter(function (value, index) {
                return !removeValFromIndexDestinationMil.includes(index);
            })
            obj.milestones.destinationMilestones = valuesArrDestinationMil
        }
        if (shipmentTypeSelected === "FCL_F_F") {
            let removeValFromIndexBookInf = [13,14,15,16,17];
            let valuesArr = obj.milestones.bookingInformation.filter(function (value, index) {
                return !removeValFromIndexBookInf.includes(index);
            })
            obj.milestones.bookingInformation = valuesArr
            let removeValFromIndexOrgMil = [0,1,2,3,4,8,10,12,15,20,21,22,24,31,32,33,34,35,36];
            let valuesArrOrgMil = obj.milestones.originMilestones.filter(function (value, index) {
                return !removeValFromIndexOrgMil.includes(index);
            })
            obj.milestones.originMilestones = valuesArrOrgMil
            let removeValFromIndexInTransMil = [1,4,10,15,16,17,18,19,20,21,22,23,24,25,26,27,28,31,32,33,34,35,36,37,38];
            let valuesArrInTransMil = obj.milestones.inTransitMilestones.filter(function (value, index) {
                return !removeValFromIndexInTransMil.includes(index);
            })
            obj.milestones.inTransitMilestones = valuesArrInTransMil
            let removeValFromIndexDestinationMil = [2,4,9,11,13,14,16,17,19,24,25,26,27,28,29,30,31];
            let valuesArrDestinationMil = obj.milestones.destinationMilestones.filter(function (value, index) {
                return !removeValFromIndexDestinationMil.includes(index);
            })
            obj.milestones.destinationMilestones = valuesArrDestinationMil
        }
        if (shipmentTypeSelected === "FCL_F_CFS") {
            let removeValFromIndexBookInf = [13,14,15,16,17];
            let valuesArr = obj.milestones.bookingInformation.filter(function (value, index) {
                return !removeValFromIndexBookInf.includes(index);
            })
            obj.milestones.bookingInformation = valuesArr
            let removeValFromIndexOrgMil = [0,1,2,3,4,8,10,12,15,20,21,22,24,31,32,33,34,35,36];
            let valuesArrOrgMil = obj.milestones.originMilestones.filter(function (value, index) {
                return !removeValFromIndexOrgMil.includes(index);
            })
            obj.milestones.originMilestones = valuesArrOrgMil
            let removeValFromIndexInTransMil = [1,4,10,15,16,17,18,19,20,21,22,23,24,25,26,27,28,31,32,33,34,35,36,37,38];
            let valuesArrInTransMil = obj.milestones.inTransitMilestones.filter(function (value, index) {
                return !removeValFromIndexInTransMil.includes(index);
            })
            obj.milestones.inTransitMilestones = valuesArrInTransMil
            let removeValFromIndexDestinationMil = [2,4,9,11,13,14,20,24,25,26,27,28,29,30,31];
            let valuesArrDestinationMil = obj.milestones.destinationMilestones.filter(function (value, index) {
                return !removeValFromIndexDestinationMil.includes(index);
            })
            obj.milestones.destinationMilestones = valuesArrDestinationMil
        }
        if (shipmentTypeSelected === "FCL_CFS_F") {
            let removeValFromIndexBookInf = [13,14,15,16,17];
            let valuesArr = obj.milestones.bookingInformation.filter(function (value, index) {
                return !removeValFromIndexBookInf.includes(index);
            })
            obj.milestones.bookingInformation = valuesArr
            let removeValFromIndexOrgMil = [0,1,,8,10,12,14 ,15,16,20,21,22,24,31,32,33,34,35,36];
            let valuesArrOrgMil = obj.milestones.originMilestones.filter(function (value, index) {
                return !removeValFromIndexOrgMil.includes(index);
            })
            obj.milestones.originMilestones = valuesArrOrgMil
            let removeValFromIndexInTransMil = [1,4,10,15,16,17,18,19,20,21,22,23,24,25,26,27,28, 31,32,33,34,35,36,37,38];
            let valuesArrInTransMil = obj.milestones.inTransitMilestones.filter(function (value, index) {
                return !removeValFromIndexInTransMil.includes(index);
            })
            obj.milestones.inTransitMilestones = valuesArrInTransMil
            let removeValFromIndexDestinationMil = [2,4,9,11,13,14,16,17,19,24,25,26,27,28,29,30,31];
            let valuesArrDestinationMil = obj.milestones.destinationMilestones.filter(function (value, index) {
                return !removeValFromIndexDestinationMil.includes(index);
            })
            obj.milestones.destinationMilestones = valuesArrDestinationMil
        }
        if (shipmentTypeSelected === "FCL_CFS_CFS") {
            let removeValFromIndexBookInf = [13,14,15,16,17];
            let valuesArr = obj.milestones.bookingInformation.filter(function (value, index) {
                return !removeValFromIndexBookInf.includes(index);
            })
            obj.milestones.bookingInformation = valuesArr
            let removeValFromIndexOrgMil = [0,1,2,3,,8,10,12,14 ,15,20,21,22,24,31,32,33,34,35,36];
            let valuesArrOrgMil = obj.milestones.originMilestones.filter(function (value, index) {
                return !removeValFromIndexOrgMil.includes(index);
            })
            obj.milestones.originMilestones = valuesArrOrgMil
            let removeValFromIndexInTransMil = [1,4,10,15,16,17,18,19,20,21,22,23,24,25,26,27,28, 31,32,33,34,35,36,37,38];
            let valuesArrInTransMil = obj.milestones.inTransitMilestones.filter(function (value, index) {
                return !removeValFromIndexInTransMil.includes(index);
            })
            obj.milestones.inTransitMilestones = valuesArrInTransMil
            let removeValFromIndexDestinationMil = [2,4,9,11,13,14,16 ,20,24,25,26,27,28,29,30,31];
            let valuesArrDestinationMil = obj.milestones.destinationMilestones.filter(function (value, index) {
                return !removeValFromIndexDestinationMil.includes(index);
            })
            obj.milestones.destinationMilestones = valuesArrDestinationMil
        }
        setDsrObjShow(obj)
    }, [])

    useEffect( () => {
        let obj = JSON.parse(JSON.stringify({...dsrObj}))
        if(obj.milestones){
            if (shipmentTypeSelected === "LCL") {
                let removeValFromIndexBookInf = [ 13, 14, 15, 16,17];
                let valuesArr = obj.milestones.bookingInformation.filter(function (value, index) {
                    return !removeValFromIndexBookInf.includes(index);
                })
                obj.milestones.bookingInformation = valuesArr
                let removeValFromIndexOrgMil = [5,6,7,8,9,10,11,12,13,14,15,16,17,25,26,31,32,33,34,35,36];
                let valuesArrOrgMil = obj.milestones.originMilestones.filter(function (value, index) {
                    return !removeValFromIndexOrgMil.includes(index);
                })
                obj.milestones.originMilestones = valuesArrOrgMil
                let removeValFromIndexInTransMil = [1,4,7,8 ,10,26,27,28,29,30];
                let valuesArrInTransMil = obj.milestones.inTransitMilestones.filter(function (value, index) {
                    return !removeValFromIndexInTransMil.includes(index);
                })
                obj.milestones.inTransitMilestones = valuesArrInTransMil
    
                let removeValFromIndexDestinationMil = [2,4,6,15,16,17,20,21,24,25,26,27,28,29,30,31];
                let valuesArrDestinationMil = obj.milestones.destinationMilestones.filter(function (value, index) {
                    return !removeValFromIndexDestinationMil.includes(index);
                })
                obj.milestones.destinationMilestones = valuesArrDestinationMil
            }
            if (shipmentTypeSelected === "AIR") {
                let removeValFromIndexBookInf = [6,9, 10, 11,12];
                let valuesArr = obj.milestones.bookingInformation.filter(function (value, index) {
                    return !removeValFromIndexBookInf.includes(index);
                })
                obj.milestones.bookingInformation = valuesArr
                let removeValFromIndexOrgMil = [0,1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
                let valuesArrOrgMil = obj.milestones.originMilestones.filter(function (value, index) {
                    return !removeValFromIndexOrgMil.includes(index);
                })
                obj.milestones.originMilestones = valuesArrOrgMil
                let removeValFromIndexInTransMil = [0,7,8,9,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38];
                let valuesArrInTransMil = obj.milestones.inTransitMilestones.filter(function (value, index) {
                    return !removeValFromIndexInTransMil.includes(index);
                })
                obj.milestones.inTransitMilestones = valuesArrInTransMil
                // let removeValFromIndexDestinationMil = [1,3,5,6,7,8,9,11,13,15,16,17,20,21,24,25,26,27,28,29,30,31];
                let removeValFromIndexDestinationMil = [1,3,5,6,7,8,9,11,13,14,15,16,17,20,21,24,25,26,27,28,29,30];
                let valuesArrDestinationMil = obj.milestones.destinationMilestones.filter(function (value, index) {
                    return !removeValFromIndexDestinationMil.includes(index);
                })
                obj.milestones.destinationMilestones = valuesArrDestinationMil
            }
            if (shipmentTypeSelected === "FCL_F_F") {
                let removeValFromIndexBookInf = [13,14,15,16,17];
                let valuesArr = obj.milestones.bookingInformation.filter(function (value, index) {
                    return !removeValFromIndexBookInf.includes(index);
                })
                obj.milestones.bookingInformation = valuesArr
                let removeValFromIndexOrgMil = [0,1,2,3,4,,8,10,12,15,20,21,22,24,31,32,33,34,35,36];
                let valuesArrOrgMil = obj.milestones.originMilestones.filter(function (value, index) {
                    return !removeValFromIndexOrgMil.includes(index);
                })
                obj.milestones.originMilestones = valuesArrOrgMil
                let removeValFromIndexInTransMil = [1,4,10,15,16,17,18,19,20,21,22,23,24,25,26,27,28,31,32,33,34,35,36,37,38];
                let valuesArrInTransMil = obj.milestones.inTransitMilestones.filter(function (value, index) {
                    return !removeValFromIndexInTransMil.includes(index);
                })
                obj.milestones.inTransitMilestones = valuesArrInTransMil
                let removeValFromIndexDestinationMil = [2,4,9,11,13,14,16,17,19,24,25,26,27,28,29,30,31];
                let valuesArrDestinationMil = obj.milestones.destinationMilestones.filter(function (value, index) {
                    return !removeValFromIndexDestinationMil.includes(index);
                })
                obj.milestones.destinationMilestones = valuesArrDestinationMil
            }
            if (shipmentTypeSelected === "FCL_F_CFS") {
                let removeValFromIndexBookInf = [13,14,15,16,17];
                let valuesArr = obj.milestones.bookingInformation.filter(function (value, index) {
                    return !removeValFromIndexBookInf.includes(index);
                })
                obj.milestones.bookingInformation = valuesArr
                let removeValFromIndexOrgMil = [0,1,2,3,4,,8,10,12,15,20,21,22,24,31,32,33,34,35,36];
                let valuesArrOrgMil = obj.milestones.originMilestones.filter(function (value, index) {
                    return !removeValFromIndexOrgMil.includes(index);
                })
                obj.milestones.originMilestones = valuesArrOrgMil
                let removeValFromIndexInTransMil = [1,4,10,15,16,17,18,19,20,21,22,23,24,25,26,27,28,31,32,33,34,35,36,37,38];
                let valuesArrInTransMil = obj.milestones.inTransitMilestones.filter(function (value, index) {
                    return !removeValFromIndexInTransMil.includes(index);
                })
                obj.milestones.inTransitMilestones = valuesArrInTransMil
                let removeValFromIndexDestinationMil = [2,4,9,11,13,14,20,24,25,26,27,28,29,30,31];
                let valuesArrDestinationMil = obj.milestones.destinationMilestones.filter(function (value, index) {
                    return !removeValFromIndexDestinationMil.includes(index);
                })
                obj.milestones.destinationMilestones = valuesArrDestinationMil
            }
            if (shipmentTypeSelected === "FCL_CFS_F") {
                let removeValFromIndexBookInf = [13,14,15,16,17];
                let valuesArr = obj.milestones.bookingInformation.filter(function (value, index) {
                    return !removeValFromIndexBookInf.includes(index);
                })
                obj.milestones.bookingInformation = valuesArr
                let removeValFromIndexOrgMil = [0,1,,8,10,12,14,15,16,20,21,22,24,31,32,33,34,35,36];
                let valuesArrOrgMil = obj.milestones.originMilestones.filter(function (value, index) {
                    return !removeValFromIndexOrgMil.includes(index);
                })
                obj.milestones.originMilestones = valuesArrOrgMil
                let removeValFromIndexInTransMil = [1,4,10,15,16,17,18,19,20,21,22,23,24,25,26,27,28, 31,32,33,34,35,36,37,38];
                let valuesArrInTransMil = obj.milestones.inTransitMilestones.filter(function (value, index) {
                    return !removeValFromIndexInTransMil.includes(index);
                })
                obj.milestones.inTransitMilestones = valuesArrInTransMil
                let removeValFromIndexDestinationMil = [2,4,9,11,13,14,16,17,19,24,25,26,27,28,29,30,31];
                let valuesArrDestinationMil = obj.milestones.destinationMilestones.filter(function (value, index) {
                    return !removeValFromIndexDestinationMil.includes(index);
                })
                obj.milestones.destinationMilestones = valuesArrDestinationMil
            }
            if (shipmentTypeSelected === "FCL_CFS_CFS") {
                let removeValFromIndexBookInf = [13,14,15,16,17];
                let valuesArr = obj.milestones.bookingInformation.filter(function (value, index) {
                    return !removeValFromIndexBookInf.includes(index);
                })
                obj.milestones.bookingInformation = valuesArr
                let removeValFromIndexOrgMil = [0,1,2,3,,8,10,12,14,15,20,21,22,24,31,32,33,34,35,36];
                let valuesArrOrgMil = obj.milestones.originMilestones.filter(function (value, index) {
                    return !removeValFromIndexOrgMil.includes(index);
                })
                obj.milestones.originMilestones = valuesArrOrgMil
                let removeValFromIndexInTransMil = [1,4,10,15,16,17,18,19,20,21,22,23,24,25,26,27,28, 31,32,33,34,35,36,37,38];
                let valuesArrInTransMil = obj.milestones.inTransitMilestones.filter(function (value, index) {
                    return !removeValFromIndexInTransMil.includes(index);
                })
                obj.milestones.inTransitMilestones = valuesArrInTransMil
                let removeValFromIndexDestinationMil = [2,4,9,11,13,14,16 ,20,24,25,26,27,28,29,30,31];
                let valuesArrDestinationMil = obj.milestones.destinationMilestones.filter(function (value, index) {
                    return !removeValFromIndexDestinationMil.includes(index);
                })
                obj.milestones.destinationMilestones = valuesArrDestinationMil
            }
            setDsrObjShow(obj)

        }
     

    },[shipmentTypeSelected])

    function setshipmentTypeFn (x,r , z) {
    if(x!=='FCL'){
        setshipmentTypeSelected(x)
    }else {
        if(r === 'Factory' && z === "Factory"){
            setshipmentTypeSelected("FCL_F_F")
        }
        if(r === 'Factory' && z === "CFS"){
            setshipmentTypeSelected("FCL_F_CFS")
        }
        if(r === 'CFS' && z === "Factory"){
            setshipmentTypeSelected("FCL_CFS_F")
        }
        if(r === 'CFS' && z === "CFS"){
            setshipmentTypeSelected("FCL_CFS_CFS")
        }  
    }
    }

    function handleChange (e,x,i,key,label) {
        let obj = {...dsrObj}
        // obj.milestones[x][i].typeSelection[shipmentTypeSelected] = e.target.checked
        // let input = obj.milestones[x].filter((x,i)=> x.key === key)
        // input[0].typeSelection[shipmentTypeSelected]=e.target.checked
        obj.milestones[x].filter((x,i)=> x.key === key)[0].typeSelection[shipmentTypeSelected] =e.target.checked
        setDsrObj(obj)
        let objNew = {...dsrObjShow}
        objNew.milestones[x][i].typeSelection[shipmentTypeSelected] = e.target.checked
        setDsrObjShow(objNew)
        console.log("obj", obj);
    }
    console.log("dsrObjShow" , dsrObjShow);

   async function handleSubmit () {
    let data = dsrObj
    data.entityId = EntityV2Store.EntityRec.entityId
    data.entityName = EntityV2Store.EntityRec.entityName
    data._id = `DSR_${EntityV2Store.EntityRec.entityId}`
    await EntityDSRApi.createDsr(data,(data)=> {
        if(data.status){
            configStore.setModalMessage("DSR updated sucessfully")
            fn(false)
        }
    })
    }

    function selectedOrgMilestones () {
        let selected = dsrObj?.milestones?.originMilestones.filter((x,i)=>x.typeSelection[shipmentTypeSelected] === true )
        return selected
    }
    function selectedIntransitMilestones () {
        let selected = dsrObj?.milestones?.inTransitMilestones.filter((x,i)=>x.typeSelection[shipmentTypeSelected] === true )
        return selected
    }
    function selectedDestinationMilestones () {
        let selected = dsrObj?.milestones?.destinationMilestones.filter((x,i)=>x.typeSelection[shipmentTypeSelected] === true )
        return selected
    }

    function title (){
        if(shipmentTypeSelected === "FCL_F_F"){
            return SHIPMENT_TYPES_DSR.FCL_F_F
        }else if(shipmentTypeSelected === "FCL_F_CFS"){
            return SHIPMENT_TYPES_DSR.FCL_F_CFS
        }else if(shipmentTypeSelected === "FCL_CFS_F"){
            return SHIPMENT_TYPES_DSR.FCL_CFS_F
        }else if(shipmentTypeSelected === "FCL_CFS_CFS"){
            return SHIPMENT_TYPES_DSR.FCL_CFS_CFS
        }else {
            return shipmentTypeSelected
        }
    }
  return (<>

    <div className="dsrConfigPg">
          
    <div
   class="back-div is-flex"
   >
   <img src="./assets/Back.png" alt="Back" class="back" width="32px" onClick={() => {
     fn(false);
   }} />
   <h1 className="title ml-2">DSR Settings </h1>
   </div>
   <div className='is-flex is-justify-content-space-between'>
     <div className="buttons are-small mt-4 ml-4">
        <button className={ shipmentTypeSelected === "LCL" ? "button is-link mr-0 is-warning is-dark": "button is-link mr-0" } onClick={()=>setshipmentTypeFn("LCL")}>LCL</button>
        <button className={ shipmentTypeSelected.includes("FCL")  ? "button is-link mr-0 is-warning is-dark": "button is-link mr-0" } onClick={()=>{setshipmentTypeSelected("FCL_F_F");setoriginStuffing("Factory"); setdestinationStuffing("Factory");setshipmentTypeFn("FCL" , "Factory" , "Factory")}} >FCL</button>
        <button className={ shipmentTypeSelected === "AIR" ? "button is-link mr-0 is-warning is-dark": "button is-link mr-0" } onClick={()=>setshipmentTypeFn("AIR")}>AIR</button>
     </div>
     {shipmentTypeSelected.includes("FCL") && (
        <>
        <div className='is-flex'>
      <div className='mr-2'>
        <p>Origin Stuffing</p>
        <div className='buttons are-small  p-1 pl-2 pr-2 '
         style={{ backgroundColor : "#f0f2f5" , borderRadius : "20px"}}
         >
            <button className={ originStuffing === "Factory" ? 'button is-link is-rounded mr-0 mb-0' : "button is-rounded mr-0 mb-0"} onClick={()=>{setoriginStuffing("Factory") ; setshipmentTypeFn("FCL" ,"Factory" , destinationStuffing )}}>Factory</button>
            <button className={ originStuffing === "CFS" ? 'button is-link is-rounded mr-0 mb-0' : "button is-rounded mr-0 mb-0"} onClick={()=>{setoriginStuffing("CFS") ; setshipmentTypeFn("FCL", "CFS" ,destinationStuffing )}}>CFS/Dock</button>
        </div>

      </div>
      <div>
        <p>Destination  De-Stuffing</p>
        <div className='buttons are-small  p-1 pl-2 pr-2 '
         style={{ backgroundColor : "#f0f2f5" , borderRadius : "20px"}}
         >
            <button className={ destinationStuffing === "Factory" ? 'button is-link is-rounded mr-0 mb-0' : "button is-rounded mr-0 mb-0"} onClick={()=>{setdestinationStuffing("Factory") ; setshipmentTypeFn("FCL" , originStuffing , "Factory")}}>Factory</button>
            <button className={ destinationStuffing === "CFS" ? 'button is-link is-rounded mr-0 mb-0' : "button is-rounded mr-0 mb-0"} onClick={()=>{setdestinationStuffing("CFS") ; setshipmentTypeFn("FCL", originStuffing , "CFS")}}>CFS/Dock</button>
        </div>

      </div>

     </div>
            
        </>
     )

     }
     
     </div>
    
    
        <h2 className='is-size-4 has-text-weight-medium'>Daily Status Report Milestones - {title()}</h2>
    <div className='is-flex'>
    <div style={{width: "25%"}} >
        <h4 className='is-size-5 has-text-weight-medium'>Booking Information</h4>
        {
            dsrObjShow?.milestones?.bookingInformation?.map((x,i)=> {
             return <p><input type='checkbox' name='checkboxField' checked disabled></input><label className='ml-2' for="checkboxField">{x.label}</label></p>
            })

        }
    </div>
    <div style={{width: "25%"}}>
        <h4 className='is-size-5 has-text-weight-medium'>Origin Milestones</h4>
        {
            dsrObjShow?.milestones?.originMilestones?.map((x,i)=> {
             return <p><input type='checkbox' name='checkboxField' onChange={(e)=>handleChange(e,"originMilestones",i , x.key,x.label)} 

              checked={dsrObj?.milestones?.originMilestones.filter((y,i)=> y.key === x.key)[0]?.typeSelection[shipmentTypeSelected]} ></input><label className='ml-2' for="checkboxField">{x.label}</label></p>
            })

        }
    </div>
    <div style={{width: "25%"}}>
        <h4 className='is-size-5 has-text-weight-medium'>InTransit Milestones</h4>
        {
            dsrObjShow?.milestones?.inTransitMilestones?.map((x,i)=> {
             return <p><input type='checkbox' name='checkboxField' onChange={(e)=>handleChange(e,"inTransitMilestones",i , x.key,x.label)}  checked={dsrObj?.milestones?.inTransitMilestones.filter((y,i)=> y.key === x.key)[0]?.typeSelection[shipmentTypeSelected]}  ></input><label className='ml-2' for="checkboxField">{x.label}</label></p>
            })

        }
    </div>
    <div style={{width: "25%"}}>
        <h4 className='is-size-5 has-text-weight-medium'>Destination Milestones</h4>
        {
            dsrObjShow?.milestones?.destinationMilestones?.map((x,i)=> {
             return <p><input type='checkbox' name='checkboxField' onChange={(e)=>handleChange(e,"destinationMilestones",i , x.key,x.label)}  checked={dsrObj?.milestones?.destinationMilestones.filter((y,i)=> y.key === x.key)[0]?.typeSelection[shipmentTypeSelected]} ></input><label className='ml-2' for="checkboxField">{x.label}</label></p>
            })

        }
    </div>
  
  
    </div>
    <div>
    <p className='has-text-weight-bold'><input type='checkbox' name='checkboxField' checked disabled></input><label className='ml-2' for="checkboxField">Default Milestone - uncheckable</label></p>
    <p className='has-text-weight-bold'>Default milestones and Selected milestones would be generated in DSR Report</p>
    </div>
    <p className='is-size-5 has-text-weight-bold'>Selected Milestones</p>
    <div className='selectedmilestonecon'>
    <div className='is-flex justify-content-space-between selectedmilestoneconheader'>
    <span style={{width: "33%", textAlign:"center"}}> <p className='is-size-6 has-text-weight-bold'>Origin Milestones</p></span>
    <span style={{width: "33%", textAlign:"center"}}><p className='is-size-6 has-text-weight-bold'>InTransit Milestones</p></span>
    <span style={{width: "33%", textAlign:"center"}}><p className='is-size-6 has-text-weight-bold'>Destination Milestones</p></span>
    </div>
    <div className='is-flex'>
        <div style={{width: "33%",  marginLeft: "5%"}}>{selectedOrgMilestones()?.map((r,i)=> {
            return <p><input type='checkbox' name='checkboxField' checked></input><label className='ml-2' for="checkboxField">{r.label}</label> </p>
        })}</div>
        <div style={{width: "33%", marginLeft: "5%"}}>{selectedIntransitMilestones()?.map((r,i)=> {
            return <p><input type='checkbox' name='checkboxField' checked></input><label className='ml-2' for="checkboxField">{r.label}</label> </p>
        })}</div>
        <div style={{width: "33%", marginLeft: "5%"}}>{selectedDestinationMilestones()?.map((r,i)=> {
            return <p><input type='checkbox' name='checkboxField' checked></input><label className='ml-2' for="checkboxField">{r.label}</label> </p>
        })}</div>
    </div>

    </div>
    <div>

        <button className="button-submit"  onClick={()=>handleSubmit()}
               >Submit</button>
                   <button className="button-cancel"  style={{ marginRight: '1rem' }} onClick={() => {
          fn(false)
        }}>
        Cancel
    </button>
    </div>
</div>
    </>
  )
}

export default DSRCustomization
