import { FormControl, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { CustomStyledTextField } from "./ColoredTextfield";
import { useEffect } from "react";
import dropdownicon from "../assets/images/dropdownicon.svg";

export function AgMuiSelect2({name,options,values,disableUnderline,disabled,onChangeHandler,backgroundColor,rules,iconColor,showIconBorder=true, fontSize,fontColor, width="max-content" ,size,style,control,padding,error,...restProps}) {
    return (
      <FormControl sx={{width:width}}>
         <Controller
          name={name} // Specify the name for the form field
          control={control} // Pass the control prop from useForm
          rules={rules}
          render={({ field , fieldState }) => (
            <CustomStyledTextField 
            SelectProps={{
              IconComponent : showIconBorder ? ()=> <div className={`flex h-full min-w-[25px] border-l-[0.5px] border-l-[#D4D4D4] items-center justify-center`}><img style={{height:"10px",width:"10px"}} src={dropdownicon} /></div> 
            : ()=><img style={{height:"10px",width:"10px"}} src={dropdownicon} />}}
            {...field}
            onChange={(e)=>{
                if(onChangeHandler){
                    onChangeHandler(e.target.value)
                }
                // console.log("this after if condition ")
                field.onChange(e);  
            }}
            fullWidth error={!!fieldState.error} helperText={fieldState?.error?.message}   InputProps={{
                disableUnderline,
                style: {
                    fontSize : fontSize || "16px",
                    color: fontColor || "black",
                    backgroundColor:backgroundColor || "white",
                    // textAlign:"center",
                    // alignItems:"center",
                    ...style
                }
            }} 
            
            sx={{
                maxWidth: width || "90px",
                "& .MuiSelect-select" : {
                    padding : padding 
                },
                '& .MuiSelect-icon': {
                    color: iconColor || "inherit",
                },
                "& .MuiOutlinedInput-root": {
                    padding : "0px !important",
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: '#2C358A !important', // Default focused color
                      },
                    },
                    "&.Mui-error": {
                      "&.Mui-focused": {
                        fieldset: {
                          borderColor: 'red !important', // Color for focused and error state
                        },
                      },
                    },
                  },
            }} size={size || "small"} {...restProps}  select disabled={disabled}
            >
                {options?.map((option,i) => <MenuItem value={ values?.length>0 ? values[i] : option}>{option}</MenuItem>)}
            </CustomStyledTextField>

          )}
        />
 </FormControl>
    );
  }
  