import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { Agyesno } from "../components/Agyesno";
import { configStore } from "../state/CommonState";
import MonApi from "../state/mongo-api";
import TaskDatetime from "./task-datetime";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import PhonePayPayout from "../my-actions/phone-pay";
import { dtactionStore } from "../dtactions/dtaction-store";
import { loader } from "../util/loader";
import { tsv } from "d3";

let server = process.env.REACT_APP_API_SERVER
export default function Taskfileapproval(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    const [taskcopy, settaskcopy] = useState({})
    const [approved, setApproved] = useState(false)
    let task = taskStore.current

    const [displayStatus, setDisplayStatus] = useState("OFF")
  let activeStyle = { backgroundColor: '#2c358f', color: 'white', border: '1px solid #2c358f' }
  let inactiveStyle = { backgroundColor: '#888', color: 'white', border: '1px solid #bbbbbb', margin: '1px' }

  let pendingStyle = inactiveStyle
  let completedStyle = inactiveStyle
  if (displayStatus === "ON") {
    pendingStyle = activeStyle;
  } else {
    completedStyle = activeStyle;
  }
    useEffect(() => {
        let f = async () => {
            let t = await Taskapi.getTaskCopy(taskStore.current)
            console.log('GOT THE TASK COPY=', t)
            if(JSON.stringify(t[0])!=JSON.stringify(taskcopy)){
                settaskcopy(t[0])
            }
        }
        f()
    }, [taskStore.current])

    useEffect(()=>{
        if(displayStatus=="ON"){
            if(task && task.currentPayment == undefined){
                task.currentPaymen = {}
                task.currentPayment.transNum=""
                task.currentPayment.transAmount=0
                task.currentPayment.transDate=""
            }else{
                task.currentPaymen = {}
                task.currentPayment.transNum=""
                task.currentPayment.transAmount=0
                task.currentPayment.transDate=""
            }
        }
    },[displayStatus])
    console.log('CHECKING FOR TASKCOPY=', taskcopy)
    if (task._id.bookingNum !== taskcopy?._id?.bookingNum || task._id.num !== taskcopy?._id?.num) return <></>
    let stage = task.stage
    let docs = task.files
    if (!docs) docs = []
    const check = () =>{
        let l = true
        docs.map((e, i) =>{
            if(e.approved != 'Approve'){
                l = false
            }
        })
        if(l!=approved){
            setApproved(l)
        }
    }
    check()
    if(JSON.stringify(task.financeInformation)!=JSON.stringify(taskcopy.financeInformation)){
        task.financeInformation = taskcopy.financeInformation
    }
    console.log('*************', task)
    return (

        <div>  
            {
                (task.actionName == "Invoice Acceptance & Payment Details (COD)")?<PhonePayPayout></PhonePayPayout>:""
            }
            

            <div>
                {task.actionName == "Invoice Acceptance & Payment Details (COD)"&&task.phonePay_paymentstatus=="Initiate"?<><p style={{color:"blue"}}>Your payment has been initiated.</p><br></br></>:""}
                {task.actionName == "Invoice Acceptance & Payment Details (COD)"&&task.phonePay_paymentstatus=="PENDING"?<><p style={{color:"blue"}}>Payment Verification Pending. Please check again later.</p><br></br></>:""}
                {task.actionName == "Invoice Acceptance & Payment Details (COD)"&&task.phonePay_paymentstatus=="FAILED"?<><p style={{color:"red"}}>This transaction request has failed. If the amount was debited from your Bank Account, please rest assured as it will be refunded to you within 5 working days.</p><br></br></>:""}

                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    <tr>
                        <th>Document</th>
                        <th>Link</th>
                        <th>Status</th>
                        <th></th>
                        {
                            (task.actionName == "Invoice Acceptance & Payment Details (COD)")?<>
                            {(approved && (task.phonePay_paymentstatus==undefined || task.phonePay_paymentstatus=="FAILED") )?  <th style={{textAlign:displayStatus=="OFF"?"right":"left"}}>Enter Manually Subject To Verification</th>:null}
                            </>:""  
                        }
                        
                    </tr>
                    {
                        docs.map((e, i) => <tr style={{ padding: '0.5rem' }}>
                            <td>{e.label}</td>
                            <td >
                                {
                                    (e.filename) ?
                                        <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + e.filename}>
                                            {e.filename}
                                        </a> : <></>
                                }
                            </td>
                            <td>
                                {
                                    (e.approved !== 'Approve') ?
                                        <select value={e.approved} onChange={async (e1) => {
                                            e.approved = e1.target.value
                                            let path1 = 'clearapproval'
                                            if (e1.target.value === 'Approve') path1 = 'approvefile'
                                            let url = server + '/api/v1/filestore/' + path1 + '/' + task.bookingNum + '/' + e.filename
                                            await MonApi.get(url)
                                        }}>
                                            <option value=''></option>
                                            <option value='Approve'>Approve</option>
                                            <option value='Reject'>Reject</option>
                                        </select>
                                        : e.approved
                                }
                            </td>
                            <td >
                                {
                                    (e.approved === 'Reject') ?
                                        <input style={{ width: '15rem' }} placeholder='Provide rejection reason'
                                            value={e.rejectReason} onChange={(e1) => {
                                                e.rejectReason = e1.target.value
                                            }} /> : <></>
                                }
                            </td>
                            {
                                (task.actionName == "Invoice Acceptance & Payment Details (COD)" && i==0)?<>
                                <td rowSpan={task.files.length}>
                                <div className="columns" >
                                    {approved && displayStatus === "OFF" && !task.currentPayment.transNum && task.phonePay_paymentstatus==undefined?<div className="column">
                                    <button className="button islink" onClick={async ()=>{
                                     dtactionStore.modalVisible1 = true
                                }}>Make Payment</button>
                                    </div>:null}
                                    {approved && displayStatus === "OFF" && (task.currentPayment.transNum||!task.currentPayment.transNum) && (task.phonePay_paymentstatus=="FAILED")?<div className="column"><button className="button islink" onClick={async ()=>{
                                     dtactionStore.modalVisible1 = true
                                }}>Pay Again</button></div>:null}
                                <div className="column">
                                {(approved && (task.phonePay_paymentstatus==undefined || task.phonePay_paymentstatus=="FAILED") )?<>
                            <button className="button is-small"
                                  onClick={async () => {
                                    setDisplayStatus('ON')
                                    console.log("displayStatus",displayStatus)
                                    task.phonepayEnabled = "ON"
                                    //MonApi.post(`${process.env.REACT_APP_API_SERVER}/api/v1/integration/apiPhonePayEnabled`,{bookingNum:task.bookingNum,actionName:task.actionName,displayStatus:"ON"})

                                   }}
                                  //{...(isViewMode() ? { disabled: true } : {})}
                                  style={{ borderRadius: '25px 0px 0px 25px',...pendingStyle  }}>ON</button>
                              <button className="button is-small"
                                  onClick={async() => { 
                                    task.phonepayEnabled = "OFF"

                                  setDisplayStatus('OFF')
                                  //MonApi.post(`${process.env.REACT_APP_API_SERVER}/api/v1/integration/apiPhonePayEnabled`,{bookingNum:task.bookingNum,actionName:task.actionName,displayStatus:"OFF"})

                                }}
                                  //{...(isViewMode() ? { disabled: true } : {})}
                                  style={{ borderRadius: '0px 25px 25px 0px',...completedStyle }}>OFF</button>
                            </>:null}
                                </div>
                                </div>
                            </td>
                                </>:""
                            }
                        </tr>)
                    }
                </table>
                <hr />
            </div>

       </div>
       
       

        
        
    )
}
export function validateFileApproval(task) {
    let ret = {
        rejected: false,
        errors: []
    }
    let files = task.files
    if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
            let f = files[i]
            if (f.filename && f.filename.length > 0 &&
                f.approved !== 'Approve' && f.approved !== 'Reject') {
                ret.errors.push('Uploaded file needs to be either Approved or Rejected')
                break
            }
            if (f.approved === 'Reject' && !f.rejectReason) {
                ret.errors.push('Rejection reason is mandatory')
                break
            }
            if (f.approved === 'Reject') ret.rejected = true
        }
    }
    return ret
}