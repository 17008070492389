import { useSnapshot } from "valtio";
import { DtStore } from "../dt/DtStore";
import { dtactionStore } from "./dtaction-store";
import Dtnewinput from "../dt/cps/Dtnewinput";
import { Agmongofilenew } from "../components/AgFile";
import { configStore, loginStore } from "../state/CommonState";
import MonApi from "../state/mongo-api";
import Utils from "../util/Utils";
import { Col2, Col3, Col4, Col6, Col8, Cols } from "../Controls";
import { Col } from "reactstrap";
import { useEffect } from "react";

let docverifyStages = {
    INITIAL: 0,
    ALL_APPROVED: 1,
    REJECTED: 2,
    COMPLETED: 9
}
export {
    docverifyStages
}
export default function DtactionsDocverify(props) {
    const { setSubmitCallback } = props
    useSnapshot(DtStore)
    useSnapshot(dtactionStore)
    useSnapshot(loginStore)
    useSnapshot(configStore)
    let dtStore = DtStore.DTBooking
    let action = dtactionStore.current
    let booking = action.booking
    let server = process.env.REACT_APP_API_SERVER
    let client = loginStore.isClient()

    const callback = () => {
        let t = 0.0;
        let k = {};
        let ton = 0;
        if (booking.details[0].delivery[0]["ewaybillno"] == undefined) {
            for (let lz = 0; lz < booking.details[0].delivery.length; lz++) {
                booking.details[0].delivery[lz]["ewaybillno"] = "";
                booking.details[0].delivery[lz]["ewaybilldate"] = "";
                booking.details[0].delivery[lz]["lorryrecieptNo"] = "";
            }
          }
          if (booking.details[0].delivery[0]["lorryrecieptNo"] == undefined) {
            for (let lz = 0; lz < booking.details[0].delivery.length; lz++) {
                booking.details[0].delivery[lz]["lorryrecieptNo"] = "";
            }
          }
        if (booking.pricing_type == "vehicle") {
            let t = booking.details[0].vehicle.map((v) => {
              if (v.vehicle_type == booking.vehicle) {
                return v.vehicle_amount;
              }
            });
            if (booking.bookingamount != undefined || booking.bookingamount != t) {
                booking.bookingamount = t;
            }
          }
          if (booking.pricing_type != "vehicle") {
            for (let i = 0; i < booking.details[0].vehicle.length; i++) {
              if (booking.details[0].vehicle[i].vehicle_type == booking.vehicle) {
                k = booking.details[0].vehicle[i];
              }
            }
            t = k["minimum"];
            if (booking.totel_weight.length > 0) {
              ton = Number(k["minimum"]) / Number(k["amt_per_ton"]);
              if (booking.weight_type == "tons") {
                let l = Number(booking.totel_weight);
                if (l > ton) {
                  t = (l * Number(k["amt_per_ton"])).toFixed(2);
                }
              }
              if (booking.weight_type == "kgs") {
                let l = Number(booking.totel_weight) / 1000;
                if (l > ton) {
                  t = (l * Number(k["amt_per_ton"])).toFixed(2);
                }
              }
            }
            if (booking.bookingamount != t) {
              booking.bookingamount = t;
            }
          }


        let delivery = booking.details[0].delivery
        let allapproved = true
        if (dtactionStore.current.stage == docverifyStages.ALL_APPROVED) {
            dtactionStore.current.stage = docverifyStages.COMPLETED
            dtactionStore.current.status = 'COMPLETED'
            dtactionStore.view = 'list'
            return 1
        }
        const checkstatus = (d,key)=>{
            if((d[key]!=undefined && d[key].length == 0) || d[key]==undefined){
                return true
            }else{
                return false
            }
        }
        const checklength = (d,key)=>{
            allapproved = false
            if((d[key]!=undefined && d[key].length < 3) || d[key]==undefined){
                return true
            }else{
                return false
            }
        }
        if (dtactionStore.current.stage == docverifyStages.INITIAL) {
            for (let i = 0; i < delivery.length; i++) {
                let d = delivery[i]
                if(checkstatus(d,"ebillApproved") ||  
                checkstatus(d,"lrApproved") || 
                checkstatus(d,"cvApproved") ||
                checkstatus(d,"ewnApproved") ||
                checkstatus(d,"ewdApproved") ||
                checkstatus(d,"lrnApproved")){
                    configStore.setModalMessage('Select Status Mandatory')
                    return 0
                }
                if ((d.ebillApproved == 'Reject' && checklength(d,"ebillRejectReason")) || 
                (d.lrApproved == 'Reject' && checklength(d,"lrRejectReason")) || 
                (d.cvApproved=="Reject" && checklength(d,"cvRejectReason")) || 
                (d.ewnApproved=="Reject" && checklength(d,"ewnRejectReason")) ||
                (d.ewdApproved=="Reject" && checklength(d,"ewdRejectReason")) ||
                (d.lrnApproved=="Reject" && checklength(d,"lrnRejectReason"))) {
                    configStore.setModalMessage('Reject Reason Mandatory (minimum 3 characters)')
                    return 0
                }
            }
            if (allapproved) {
                dtactionStore.current.stage = docverifyStages.ALL_APPROVED
                dtactionStore.current.stage = docverifyStages.COMPLETED
                dtactionStore.current.status = 'COMPLETED'
            } else {
                dtactionStore.current.stage = docverifyStages.REJECTED
                dtactionStore.current.pendingWithRole = 'SALES'
            }
            dtactionStore.view = 'list'
            return 1
        }
        if (dtactionStore.current.stage == docverifyStages.REJECTED) {
            for (let i = 0; i < delivery.length; i++) {
                let d = delivery[i]
                if(d.cvApproved=="Reject" && ((d.cargo_Value != undefined && d.cargo_Value.trim().length==0)|| d.cargo_Value == undefined)){
                    configStore.setModalMessage('Cargo Value Mandatory')
                    return 0
                }
                if(d.ewnApproved=="Reject" && ((d.ewaybillno != undefined && d.ewaybillno.trim().length==0)|| d.ewaybillno == undefined)){
                    configStore.setModalMessage('E-Way Bill No Mandatory')
                    return 0
                }
                if(d.ewdApproved=="Reject" && ((d.ewaybilldate != undefined && d.ewaybilldate.trim().length==0)|| d.ewaybilldate == undefined)){
                    configStore.setModalMessage('E-Way Bill Valid Upto Mandatory')
                    return 0
                }
                if(d.lrnApproved=="Reject" && ((d.lorryrecieptNo != undefined && d.lorryrecieptNo.trim().length==0)|| d.lorryrecieptNo == undefined)){
                    configStore.setModalMessage('Lorry Receipt No Mandatory')
                    return 0
                }
            }
            dtactionStore.current.stage = docverifyStages.INITIAL
            dtactionStore.current.pendingWithRole = 'OPERATIONS'
            dtactionStore.view = 'list'
            return 1
        }
    }
    useEffect(() => {
        console.log('\n\n\n$$$$$$$$$ setsubmitcallback calling')
        dtactionStore.submitCallback = callback
    }, [])

    if (action.stage == docverifyStages.INITIAL) {
        return (
            <>
                <section class="section section-padding">
                    <div class="blue-heading">Destination Details</div>

                    <div >
                        {
                          (booking.bookingamount!=undefined && booking.vendor_agreed_cost!=undefined)  ?
                          <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Gross Profit Amount</Col3>
                                            <Col4>{Number(booking.bookingamount)-Number(booking.vendor_agreed_cost)} (INR)</Col4>
                                            <Col2 style={{ color: 'darkblue' }}>GP %</Col2>
                                            <Col2>{(((Number(booking.bookingamount)-Number(booking.vendor_agreed_cost))/Number(booking.vendor_agreed_cost))*100).toFixed(2) }</Col2>
                                        </Cols>:""
                        }
                    
                        {
                            booking.details[0].delivery.map((e, i) => {
                                return (
                                    <div style={{ border: '1px solid #ccc' }}>
                                        
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Delivery Address {i + 1}</Col3>
                                            <Col4>{e.address}</Col4>
                                        </Cols>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Cargo Value</Col3>
                                            <Col4>{Utils.N(e.cargo_Value)}</Col4>
                                            <Col2>
                                                <div className="select">
                                                    <select value={e.cvApproved} onChange={(ev) => {
                                                        e.cvApproved = ev.target.value
                                                        console.log('****\n', e, '*******')
                                                    }}>
                                                        <option value=''>Select Status</option>
                                                        <option value='Approve'>Approve</option>
                                                        <option value='Reject'>Reject</option>
                                                    </select>
                                                </div>
                                            </Col2>
                                            <Col2>
                                                {
                                                    (e.cvApproved == 'Reject') ?
                                                        <input className="input" placeholder='Reject Reason'
                                                            value={e.cvRejectReason} onChange={(ev) => {
                                                                e.cvRejectReason = ev.target.value
                                                            }}></input>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>E-Way Bill / Commercial Invoice</Col3>
                                            <Col4>
                                                <a target="_blank"
                                                    href={server +
                                                        "/api/v1/filestore/getfile/" +
                                                        booking.details[0].contract_id +
                                                        "/" + e.ewaybill
                                                    }
                                                >{e.ewaybill}</a>
                                            </Col4>
                                            <Col2>
                                                <div className="select">
                                                    <select value={e.ebillApproved} onChange={(ev) => {
                                                        e.ebillApproved = ev.target.value
                                                        console.log('****\n', e, '*******')
                                                    }}>
                                                        <option value=''>Select Status</option>
                                                        <option value='Approve'>Approve</option>
                                                        <option value='Reject'>Reject</option>
                                                    </select>
                                                </div>
                                            </Col2>
                                            <Col2>
                                                {
                                                    (e.ebillApproved == 'Reject') ?
                                                        <input className="input" placeholder='Reject Reason'
                                                            value={e.ebillRejectReason} onChange={(ev) => {
                                                                e.ebillRejectReason = ev.target.value
                                                            }}></input>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>
                                        {
                                            (e.ewaybillno != undefined)?<Cols>
                                            <Col3 style={{ color: 'darkblue' }}>E-Way Bill No</Col3>
                                            <Col4>
                                               {e.ewaybillno}
                                            </Col4>
                                            <Col2>
                                                <div className="select">
                                                    <select value={e.ewnApproved} onChange={(ev) => {
                                                        e.ewnApproved = ev.target.value
                                                        console.log('****\n', e, '*******')
                                                    }}>
                                                        <option value=''>Select Status</option>
                                                        <option value='Approve'>Approve</option>
                                                        <option value='Reject'>Reject</option>
                                                    </select>
                                                </div>
                                            </Col2>
                                            <Col2>
                                                {
                                                    (e.ewnApproved == 'Reject') ?
                                                        <input className="input" placeholder='Reject Reason'
                                                            value={e.ewnRejectReason} onChange={(ev) => {
                                                                e.ewnRejectReason = ev.target.value
                                                            }}></input>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>:""
                                        }
                                        {
                                            (e.ewaybilldate != undefined)?<Cols>
                                            <Col3 style={{ color: 'darkblue' }}>E-Way Bill Valid Upto</Col3>
                                            <Col4>
                                               {e.ewaybilldate}
                                            </Col4>
                                            <Col2>
                                                <div className="select">
                                                    <select value={e.ewdApproved} onChange={(ev) => {
                                                        e.ewdApproved = ev.target.value
                                                        console.log('****\n', e, '*******')
                                                    }}>
                                                        <option value=''>Select Status</option>
                                                        <option value='Approve'>Approve</option>
                                                        <option value='Reject'>Reject</option>
                                                    </select>
                                                </div>
                                            </Col2>
                                            <Col2>
                                                {
                                                    (e.ewdApproved == 'Reject') ?
                                                        <input className="input" placeholder='Reject Reason'
                                                            value={e.ewdRejectReason} onChange={(ev) => {
                                                                e.ewdRejectReason = ev.target.value
                                                            }}></input>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>:""
                                        }
                                        
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Lorry Receipt</Col3>
                                            <Col4>
                                                <a target="_blank"
                                                    href={server +
                                                        "/api/v1/filestore/getfile/" +
                                                        booking.details[0].contract_id +
                                                        "/" + e.lorryreceipt
                                                    }
                                                >{e.lorryreceipt}</a>
                                            </Col4>
                                            <Col2>
                                                <div className="select">
                                                    
                                                    <select value={e.lrApproved} onChange={(ev) => {
                                                        e.lrApproved = ev.target.value
                                                        console.log('****\n', e, '*******')
                                                    }}>
                                                        <option value=''>Select Status</option>
                                                        <option value='Approve'>Approve</option>
                                                        <option value='Reject'>Reject</option>
                                                    </select>
                                                </div>
                                            </Col2>
                                            <Col2>
                                                {
                                                    (e.lrApproved == 'Reject') ?
                                                        <input className="input" placeholder='Reject Reason'
                                                            value={e.lrRejectReason} onChange={(ev) => {
                                                                e.lrRejectReason = ev.target.value
                                                            }}></input>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>
                                        {
                                            (e.lorryrecieptNo != undefined)?<Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Lorry Reciept No</Col3>
                                            <Col4>
                                               {e.lorryrecieptNo}
                                            </Col4>
                                            <Col2>
                                                <div className="select">
                                                    <select value={e.lrnApproved} onChange={(ev) => {
                                                        e.lrnApproved = ev.target.value
                                                        console.log('****\n', e, '*******')
                                                    }}>
                                                        <option value=''>Select Status</option>
                                                        <option value='Approve'>Approve</option>
                                                        <option value='Reject'>Reject</option>
                                                    </select>
                                                </div>
                                            </Col2>
                                            <Col2>
                                                {
                                                    (e.lrnApproved == 'Reject') ?
                                                        <input className="input" placeholder='Reject Reason'
                                                            value={e.lrnRejectReason} onChange={(ev) => {
                                                                e.lrnRejectReason = ev.target.value
                                                            }}></input>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>:""
                                        }

                                        <div style={{ height: '5px', backgroundColor: 'white' }}></div>
                                    </div>
                                )

                            })
                        }
                    </div>
                </section>
            </>
        )
    }
    if (action.stage == docverifyStages.REJECTED) {
        return (
            <>
                <section class="section section-padding">
                    <div class="blue-heading">Destination Details</div>
                    {
                          (booking.bookingamount!=undefined && booking.vendor_agreed_cost!=undefined)  ?
                          <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Gross Profit Amount</Col3>
                                            <Col4>{Number(booking.bookingamount)-Number(booking.vendor_agreed_cost)} (INR)</Col4>
                                            <Col2 style={{ color: 'darkblue' }}>GP %</Col2>
                                            <Col2>{(((Number(booking.bookingamount)-Number(booking.vendor_agreed_cost))/Number(booking.vendor_agreed_cost))*100).toFixed(2) }</Col2>
                                        </Cols>:""
                        }
                    <div >
                        {
                            booking.details[0].delivery.map((e, i) => {
                                return (
                                    <div style={{ border: '1px solid #ccc' }}>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Delivery Address {i + 1}</Col3>
                                            <Col4>{e.address}</Col4>
                                        </Cols>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Cargo Value</Col3>
                                            <Col4>
                                                {Utils.N(e.cargo_Value)}
                                            </Col4>
                                            <Col2>
                                                {
                                                    (e.cvApproved == 'Reject') ?
                                                        <div style={{ fontSize: '1.2rem' }}>Reject Reason: {e.cvRejectReason}</div>
                                                        :
                                                        <div style={{ fontSize: '1.2rem' }}>Approved</div>
                                                }
                                            </Col2>
                                            <Col2>
                                                {
                                                    e.cvApproved == 'Reject' ?
<div className="field">
<div className="control is-fullwidth">
                                                    <Dtnewinput
                                                    type="number"
                                                    record={e}
                                                    name="cargo_Value"
                                                    classnames="input is-small"
                                                  />
                                                  </div></div>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>E-Way Bill / Commercial Invoice</Col3>
                                            <Col4>
                                                <a target="_blank"
                                                    href={server +
                                                        "/api/v1/filestore/getfile/" +
                                                        booking.details[0].contract_id +
                                                        "/" + e.ewaybill
                                                    }
                                                >{e.ewaybill}</a>
                                            </Col4>
                                            <Col2>
                                                {
                                                    (e.ebillApproved == 'Reject') ?
                                                        <div style={{ fontSize: '1.2rem' }}>Reject Reason: {e.ebillRejectReason}</div>
                                                        :
                                                        <div style={{ fontSize: '1.2rem' }}>Approved</div>
                                                }
                                            </Col2>
                                            <Col2>
                                                {
                                                    e.ebillApproved == 'Reject' ?

                                                        <Agmongofilenew
                                                            parent={"DTEB___" + i}
                                                            label={"ewaybill" + i}
                                                            filekey={booking.details[0].contract_id}
                                                            callback={(data, id) => {
                                                                console.log(id);
                                                                e.ewaybill = data.key.filename;
                                                            }}
                                                        />
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>
                                        {
                                            (e.ewaybillno != undefined)?<Cols>
                                            <Col3 style={{ color: 'darkblue' }}>E-Way Bill No</Col3>
                                            <Col4>
                                               {e.ewaybillno}
                                            </Col4>
                                            <Col2>
                                            {
                                                    (e.ewnApproved == 'Reject') ?
                                                        <div style={{ fontSize: '1.2rem' }}>Reject Reason: {e.ewnRejectReason}</div>
                                                        :
                                                        <div style={{ fontSize: '1.2rem' }}>Approved</div>
                                                }
                                            </Col2>
                                            <Col2>
                                            {
                                                    e.ewnApproved == 'Reject' ?
<div className="field">
<div className="control is-fullwidth">
                                                    <Dtnewinput
                                                    record={e}
                                                    name="ewaybillno"
                                                    classnames="input is-small"
                                                  />
                                                  </div></div>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>:""
                                        }
                                        {
                                            (e.ewaybilldate != undefined)?<Cols>
                                            <Col3 style={{ color: 'darkblue' }}>E-Way Bill Valid Upto</Col3>
                                            <Col4>
                                               {e.ewaybilldate}
                                            </Col4>
                                            <Col2>
                                            {
                                                    (e.ewdApproved == 'Reject') ?
                                                        <div style={{ fontSize: '1.2rem' }}>Reject Reason: {e.ewdRejectReason}</div>
                                                        :
                                                        <div style={{ fontSize: '1.2rem' }}>Approved</div>
                                                }
                                            </Col2>
                                            <Col2>
                                            {
                                                    e.ewdApproved == 'Reject' ?
<div className="field">
<div className="control is-fullwidth">
                                                    <Dtnewinput
                                                    record={e}
                                                    type="date"
                                        name="ewaybilldate"
                                        mindate={booking.bookingdate}
                                                    classnames="input is-small"
                                                  />
                                                  </div></div>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>:""
                                        }
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Lorry Receipt</Col3>
                                            <Col4>
                                                <a target="_blank"
                                                    href={server +
                                                        "/api/v1/filestore/getfile/" +
                                                        booking.details[0].contract_id +
                                                        "/" + e.lorryreceipt
                                                    }
                                                >{e.lorryreceipt}</a>
                                            </Col4>
                                            <Col2>
                                                {
                                                    (e.lrApproved == 'Reject') ?
                                                        <div style={{ fontSize: '1.2rem' }}>Reject Reason: {e.lrRejectReason}</div>
                                                        :
                                                        <div style={{ fontSize: '1.2rem' }}>Approved</div>
                                                }
                                            </Col2>
                                            <Col2>
                                                {
                                                    e.lrApproved == 'Reject' ?

                                                        <Agmongofilenew parent={"DTLB___" + i}
                                                            label={"lorryreceipt" + i}
                                                            filekey={booking.details[0].contract_id}
                                                            callback={(data, id) => {
                                                                console.log(id);
                                                                e.lorryreceipt = data.key.filename;
                                                            }}
                                                        />
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>
                                        {
                                            (e.lorryrecieptNo != undefined)?<Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Lorry Reciept No</Col3>
                                            <Col4>
                                               {e.lorryrecieptNo}
                                            </Col4>
                                            <Col2>
                                            {
                                                    (e.lrnApproved == 'Reject') ?
                                                        <div style={{ fontSize: '1.2rem' }}>Reject Reason: {e.lrnRejectReason}</div>
                                                        :
                                                        <div style={{ fontSize: '1.2rem' }}>Approved</div>
                                                }
                                            </Col2>
                                            <Col2>
                                            {
                                                    e.lrnApproved == 'Reject' ?
<div className="field">
<div className="control is-fullwidth">
                                                    <Dtnewinput
                                                    type="text"
                                                    record={e}
                                                    name="lorryrecieptNo"
                                                    classnames="input is-small"
                                                  />
                                                  </div></div>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>:""
                                        }

                                        <div style={{ height: '5px', backgroundColor: 'white' }}></div>
                                    </div>
                                )

                            })
                        }
                    </div>
                </section>
            </>
        )
    }
    if (action.stage >= docverifyStages.ALL_APPROVED) {
        return (
            <>
                <section class="section section-padding">
                    <div class="blue-heading">Destination Details</div>
                    {
                          (booking.bookingamount!=undefined && booking.vendor_agreed_cost!=undefined)  ?
                          <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Gross Profit Amount</Col3>
                                            <Col4>{Number(booking.bookingamount)-Number(booking.vendor_agreed_cost)} (INR)</Col4>
                                            <Col2 style={{ color: 'darkblue' }}>GP %</Col2>
                                            <Col2>{(((Number(booking.bookingamount)-Number(booking.vendor_agreed_cost))/Number(booking.vendor_agreed_cost))*100).toFixed(2) }</Col2>
                                        </Cols>:""
                        }
                    <div >
                        {
                            booking.details[0].delivery.map((e, i) => {
                                return (
                                    <div style={{ border: '1px solid #ccc' }}>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Delivery Address {i + 1}</Col3>
                                            <Col4>{e.address}</Col4>
                                        </Cols>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Cargo Value</Col3>
                                            <Col4>
                                            {Utils.N(e.cargo_Value)}
                                            </Col4>
                                            <Col2>
                                                APPROVED
                                            </Col2>
                                        </Cols>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>E-Way Bill / Commercial Invoice</Col3>
                                            <Col4>
                                                <a target="_blank"
                                                    href={server +
                                                        "/api/v1/filestore/getfile/" +
                                                        booking.details[0].contract_id +
                                                        "/" + e.ewaybill
                                                    }
                                                >{e.ewaybill}</a>
                                            </Col4>
                                            <Col2>
                                                APPROVED
                                            </Col2>
                                        </Cols>
                                        {
                                            (e.ewaybillno != undefined)?
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>E-Way Bill No</Col3>
                                            <Col4>
                                            {e.ewaybillno}
                                            </Col4>
                                            <Col2>
                                                APPROVED
                                            </Col2>
                                        </Cols>:""}
                                        {
                                            (e.ewaybilldate != undefined)?
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>E-Way Bill Valid Upto</Col3>
                                            <Col4>
                                            {e.ewaybilldate}
                                            </Col4>
                                            <Col2>
                                                APPROVED
                                            </Col2>
                                        </Cols>:""}
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Lorry Receipt</Col3>
                                            <Col4>
                                                <a target="_blank"
                                                    href={server +
                                                        "/api/v1/filestore/getfile/" +
                                                        booking.details[0].contract_id +
                                                        "/" + e.lorryreceipt
                                                    }
                                                >{e.lorryreceipt}</a>
                                            </Col4>
                                            <Col2>
                                                APPROVED
                                            </Col2>
                                        </Cols>
                                        {
                                            (e.lorryrecieptNo != undefined)?
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Lorry Reciept No</Col3>
                                            <Col4>
                                            {e.lorryrecieptNo}
                                            </Col4>
                                            <Col2>
                                                APPROVED
                                            </Col2>
                                        </Cols>:""}

                                        <div style={{ height: '5px', backgroundColor: 'white' }}></div>
                                    </div>
                                )

                            })
                        }
                    </div>
                </section>
            </>
        )
    }
}