import { Box, Button, Typography } from "@mui/material";

import { PinnedText } from "../../mui-components/PinnedText";
import { FileViewComponent } from "../../mui-components/FileComponent";
import { useSnapshot } from "valtio";
import { taskStore } from "../../mytasks/task-store";
import Utils from "../../util/Utils";
import Taskapi from "../../mytasks/tasks-api";
import { Documents } from "../../mui-components/Documents";

let server = process.env.REACT_APP_API_SERVER
export function CarrierRelaeseOrder(){
    const task = useSnapshot(taskStore).current;
    const stage = task.stage;
    let validTillDate = ""
    if(task.validTill?.length > 0 ){
        let dateValues = task.validTill.split("-");
        validTillDate = `${dateValues[2]}-${dateValues[1]}-${dateValues[0]}`
    }
    return <Box sx={{height:"100%",width:"100%",display:"flex",flexDirection:"column",paddingX:"15px",gap:3}}>
     <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
<PinnedText content="Please download your Carrier Release Order (CRO)."/>
<PinnedText content="Please note that the same is valid till" highlightedContent={(validTillDate.length > 0) ? Utils.formatDateBooking(validTillDate,false) : "N/A"}/>
     </Box>
     {/* <Box sx={{display:"flex",flexDirection:"column",gap:2,marginBottom:"auto"}}>
        <Typography sx={{fontFamily:"Figtree",color:"black",fontWeight:"bold",fontSize:"18px"}}>Documents</Typography>
        <>
        {task.files.map((file)=><Box sx={{display:"flex",flexDirection:"row",gap:2,alignItems:"center"}}>
            <Typography sx={{fontFamily:"Figtree",color:"black"}}>{file.label}</Typography>
            <span>:</span>
            <FileViewComponent documentTitle={file.filename} documentUrl={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + file.filename} />
        </Box>)}
        </>
     </Box> */}
     {<Documents task={task}/>}
   {  stage === 1 && <Box sx={{display:"flex",width:"100%",flexDirection:"row",justifyContent:"end"}}>
        <Button onClick={async (e) => {
                                        taskStore.current.stage = 2
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        taskStore.setCustomerComplete()
                                        await Taskapi.updateTask(taskStore.current) 
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                    }} variant="contained" sx={{borderRadius:"5px",height:"40px",width:"150px",fontFamily:"Figtree",textTransform:"capitalize"}}>Acknowledge</Button>
     </Box>}
    </Box>
}