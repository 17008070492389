import { useRef } from "react";
import { CustomStyledTextField } from "../mui-components/ColoredTextfield";
import LabeledTextField from "../mui-components/LabeledTextField";
import { FormControl, IconButton, InputAdornment } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { Controller } from "react-hook-form";


export function Quantity({control,rules,name}){
    const inputRef = useRef(null);

  const handleUpArrowClick = () => {
    inputRef.current?.focus();
    const upArrowEvent = new KeyboardEvent('keydown', {
      key: 'ArrowUp',
      code: 'ArrowUp',
      keyCode: 38,
      which: 38,
      bubbles: true,
    });

    inputRef.current.dispatchEvent(upArrowEvent);
  };

  const handleDownArrowClick = () => {
    inputRef.current?.focus();
    const downArrowEvent = new KeyboardEvent('keydown', {
      key: 'ArrowDown',
      code: 'ArrowDown',
      keyCode: 40,
      which: 40,
      bubbles: true,
    });

    inputRef.current.dispatchEvent(downArrowEvent);
  };
    return <FormControl>
      <Controller
       control={control}
       name={name}
       rules={rules}   
       render={({field,fieldState})=>{
        return  <div className="flex flex-row h-[25px] items-center">
        {/* <div onClick={handleDownArrowClick} className="h-full w-[30px] flex items-center justify-center cursor-pointer">-</div> */}
        <CustomStyledTextField 
        error={!!fieldState.error} helperText={fieldState.error?.message} {...field}
        sx={{
          '& .MuiInputBase-input': {
      padding: '0',
      textAlign:'center'
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused": {
        fieldset: {
          borderColor: '#2C358A !important', // Default focused color
        },
      }},
        }} InputProps={{
          style:{
            padding:0,
            backgroundColor:"white",
            height:"25px",
            width:"90px",
            alignItems:"center",
            justifyContent:"center",
            display:"flex"
          },
          // disableUnderline:true,
          endAdornment:<div style={{padding:0,borderLeft:"0.5px solid #D4D4D4",height:"100%",minWidth:"25px",display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}}  onClick={handleUpArrowClick}>
            <Add sx={{fontSize:"16px",color:"#555555"}}/>
          </div>,
          startAdornment :  <div style={{padding:0,borderRight:"0.5px solid #D4D4D4",height:"100%",minWidth:"25px",display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}}  onClick={handleDownArrowClick}>
            <Remove sx={{fontSize:"16px",color:"#555555"}}/>
            </div>
          ,
        }} height="100%" type="number" ref={inputRef}  />
        {/* <div onClick={handleUpArrowClick} className="h-full w-[30px] flex items-center justify-center cursor-pointer">+</div> */}
    </div>
       }}
       />
    </FormControl>
   
}