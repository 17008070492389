import { useSnapshot } from "valtio";
import { configStore, viewBookingStore } from "../state/CommonState";
import { Box } from "@mui/material";
import { PricingDetailsCard2 } from "../mui-components/PricingDetailsCard2";
import Utils from "../util/Utils";
import { Accordions } from "../mui-components/AgMuiAccordion";
import { useEffect, useRef, useState } from "react";
import { PricingContextProvider, usePricing } from "../context/PricingContext";


function convertRate(e){
    let rate = 0;
    let volumeDiscount = 0.0;
    let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            volumeDiscount = (e.total * erate)
            // if (volumeDiscount > 0) volumeDiscount *= -1
        } else {
            rate = parseFloat(e.total) * erate
        }
        return {rate,volumeDiscount};
}


function getChargesList(chargesList){
    let chargeList = [];
    chargesList.forEach(element => {
        let index;
        if(((+element.perCBM) !== 0 || (+element.perShipment) !== 0 || (+element.perTon) !== 0 || (+element.minimum) !== 0 || (+element.maximum) !== 0 || (+element.total) !== 0 ) ){
            let convertedRate = convertRate(element).rate;
             if(element.onaccount==="On Your Account" ){
                 if(chargeList[0]){
                    chargeList[0].charges.push(element);
                 }else{
                    chargeList[0] = {account:"On Your Account",charges:[element],total:0.0,volumeDiscount:0.0,isDiscount:true}
                 }
                
                 index = 0;
                 chargeList[0].volumeDiscount = Math.abs(convertRate(element).volumeDiscount)
             }else if(element.onaccount==="Consignee's Account"){
                if(chargeList[1]){
                    chargeList[1].charges.push(element);
                 }else{
                    chargeList[1] = {account:"On Consignee Account",charges:[element],total:0.0,volumeDiscount:0.0,isDiscount:true}
                 }
               
                 index = 1;
                 chargeList[1].volumeDiscount = Math.abs(convertRate(element).volumeDiscount)
             }else if(element.onaccount?.startsWith('Ship')){
                if(chargeList[2]){
                    chargeList[2].charges.push({...element,total:convertedRate});
                 }else{
                    chargeList[2] = {account:"On Shipper Account",charges:[element],total:0.0,volumeDiscount:0.0,isDiscount:true}
                 }
                
                 index = 2;
                 chargeList[2].volumeDiscount = Math.abs(convertRate(element).volumeDiscount)
             }
             if(element?.isIncluded==="Yes"){
             chargeList[index].total += convertedRate;
             }
        }
    });
    return chargeList;
}


 function LCLPricingContextWrapped({contract,isAccordionView,needToDisableInclusion}){
    // const {booking} = useSnapshot(viewBookingStore).current;
    const {setRec,setDisable} = usePricing();
    useEffect(()=>{
        setRec(contract);
        setDisable(needToDisableInclusion);
    },[contract])
    const filteredChargesList = getChargesList(Utils.getChargeListFormatFromBooking(contract));
    return isAccordionView ? <AccordionViewPricing filteredChargesList={filteredChargesList} /> : <NonAccordionView filteredChargesList={filteredChargesList}/>
}

export function LCLBookingPricingDetail({contract,isAccordionView = false,disableInclusion=false}){
    return <PricingContextProvider>
        <LCLPricingContextWrapped contract={contract} isAccordionView={isAccordionView} needToDisableInclusion={disableInclusion}/>
    </PricingContextProvider>
}

function AccordionViewPricing({filteredChargesList}){
    const [selectedindex,setIndex] = useState(0);
    const accordions = [];
    filteredChargesList?.forEach((charge,i)=>{
        accordions.push({id: i,
        title: charge.account,
        price: Utils.N(charge.total),
        content: <PricingDetailsCard2 isAccordionView={true} charge={charge}/>})
    })
    return  filteredChargesList.length === 1 ? <Box sx={{display:"flex",height:"100%",width:"100%"}}>
        <Box sx={{width:"100%",height:"100%", backgroundColor:"white", borderRadius: "10px", paddingY: "8px", paddingX: "12px"}}> <PricingDetailsCard2 title={filteredChargesList[0].account} charge={filteredChargesList[0]}/></Box>
    </Box> : <Accordions accordions={accordions} selectedIndex={selectedindex} setIndex={setIndex} />
}


function NonAccordionView({filteredChargesList}){
    return <Box sx={{display:"flex",flexDirection:"row",width:"100%",gap:3,justifyContent:"center"}}>
    {filteredChargesList.map((charge)=><Box sx={{minWidth:"30%",paddingY: "8px"}}><PricingDetailsCard2 title={charge.account} charge={charge}/></Box>)}
  </Box>
}


