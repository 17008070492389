import { proxy, subscribe } from "valtio"
import { devtools } from'valtio/utils'
let myactionsstore = proxy({
    list: [],
    uploadShipmentType: 'LCL',
    uploadSchedulesType: 'LCL',
    navigateFromCharts: false,
    recheck: 0,
    tsunloc: "",
    isvalid: false,
    error: [],
    selectcontainer: [],
    exportClearance:[],
    importClearance:[],
    setList: (dsrList) => {
        myactionsstore.list = [...dsrList]
    },
    getList: () => {
        if (!myactionsstore.list || !myactionsstore.list.length) return []
        return myactionsstore.list
    },
    getList4ShipmentType: (shipmentType) => {
        if (!myactionsstore.list || !myactionsstore.list.length) return []
        return myactionsstore.list.map(e => e.shipmentType === shipmentType)
    },
    getShipmentTypes: () => {
        let shipmentType = {}
        myactionsstore.list.map(e => shipmentType[e.shipmentType] = '')
        return Object.keys(shipmentType)
    },
    tsModalVisible: false,
    cnModalVisible: false,
    vModalVisible: false,
    rwModalVisible: false,
    tsairModalVisible: false,
    cnviewModalVisible: false,
    vviewModalVisible: false,
    addts: (dd, ret) => {
        let l = ["ts1_port", "ts1_arrival_estimate", "ts1_arrival", "ts1_departure_estimate", "ts1_departure", "ts1_vessel_name", "ts1_vessel_number", "ts2_port", "ts2_arrival_estimate", "ts2_arrival", "ts2_departure_estimate", "ts2_departure", "ts2_vessel_name", "ts2_vessel_number", "ts3_port", "ts3_arrival_estimate", "ts3_arrival", "ts3_departure_estimate", "ts3_departure", "ts3_vessel_name", "ts3_vessel_number", "ts4_port", "ts4_arrival_estimate", "ts4_arrival", "ts4_departure_estimate", "ts4_departure", "ts4_vessel_name", "ts4_vessel_number", 'reworking_pol_arrival_estimate',
            "reworking_pol_arrival",
            'reworking_pol_departure_estimate',
            "reworking_pol_departure",
            "reworking_pol_vessel_name",
            "reworking_pol_vessel_number",
            'reworking_pol_destuffing',
            "reworking_pol_restuffing",
            "reworking_pod_container_number",
            'reworking_pod_arrival_estimate',
            "reworking_pod_arrival",
            'reworking_pod_departure_estimate',
            "reworking_pod_departure",

            "reworking_pod_vessel_name",
            "reworking_pod_vessel_number",

            'reworking_pod_destuffing',
            "reworking_pod_restuffing",
            "reworking_pol_container_number",]
        let lv = {}
        let lk = Object.keys(dd)
        for (let i = 0; i < lk.length; i++) {
            if (l.indexOf(lk[i]) == -1) {
                lv[lk[i]] = dd[lk[i]]
            }
        }
        dd = lv
        for (let i = 0; i < 4; i++) {

            if (ret[`ts${i + 1}_port`] != undefined && ret[`ts${i + 1}_port`] != null && ret[`ts${i + 1}_port`].trim().length > 0) {
                console.log(ret[`ts${i + 1}_port`] != undefined, ret[`ts${i + 1}_port`] != null, ret[`ts${i + 1}_port`].trim().length > 0, ret[`ts${i + 1}_port`])
                dd[`ts${i + 1}_port`] = `Transhipment ${i + 1} Port (if any)`
                dd[`ts${i + 1}_arrival_estimate`] = `ETA Transhipment ${i + 1}`
                dd[`ts${i + 1}_arrival`] = `ATA Transhipment ${i + 1}`
                dd[`ts${i + 1}_departure_estimate`] = `ETD Transhipment ${i + 1}`
                dd[`ts${i + 1}_departure`] = `ATD Transhipment ${i + 1}`
                if ((i + 2) == 2) {
                    dd[`ts${i + 1}_vessel_name`] = `${i + 2}nd Leg Vessel Name`
                    dd[`ts${i + 1}_vessel_number`] = `${i + 2}nd Leg Voyage #`
                }
                if ((i + 2) == 3) {
                    dd[`ts${i + 1}_vessel_name`] = `${i + 2}rd Leg Vessel Name`
                    dd[`ts${i + 1}_vessel_number`] = `${i + 2}rd Leg Voyage #`
                }
                if ((i + 2) == 4) {
                    dd[`ts${i + 1}_vessel_name`] = `${i + 2}th Leg Vessel Name`
                    dd[`ts${i + 1}_vessel_number`] = `${i + 2}th Leg Voyage #`
                }
                if ((i + 2) == 5) {
                    dd[`ts${i + 1}_vessel_name`] = `${i + 2}th Leg Vessel Name`
                    dd[`ts${i + 1}_vessel_number`] = `${i + 2}th Leg Voyage #`
                }

            }
        }
        if (ret[`reworking_pol_port`] != undefined && ret[`reworking_pol_port`] != null && ret[`reworking_pol_port`].trim().length > 0) {
            console.log(ret[`reworking_pol_port`] != undefined, ret[`reworking_pol_port`] != null, ret[`reworking_pol_port`].trim().length > 0, ret[`reworking_pol_port`])
            dd[`reworking_pol_port`] = `Re-working 1 Port (if any)`
            dd[`reworking_pol_arrival_estimate`] = `ETA Re-working 1`
            dd[`reworking_pol_arrival`] = `ATA Re-working 1`
            dd[`reworking_pol_departure_estimate`] = `ETD Re-working 1`
            dd[`reworking_pol_departure`] = `ATD Re-working 1`
            dd[`reworking_pol_vessel_name`] = `Re-working 1 Leg Vessel Name`
            dd[`reworking_pol_vessel_number`] = `Re-working 1 Leg Voyage #`
            dd[`reworking_pol_destuffing`] = `De-stuffing @ Re-working 1 Port`
            dd[`reworking_pol_restuffing`] = `Re-stuffing @ Re-working 1 Port`
            dd[`reworking_pol_container_number`] = `Re-working 1 Container #`
        }
        if (ret[`reworking_pod_port`] != undefined && ret[`reworking_pod_port`] != null && ret[`reworking_pod_port`].trim().length > 0) {
            console.log(ret[`reworking_pod_port`] != undefined, ret[`reworking_pod_port`] != null, ret[`reworking_pod_port`].trim().length > 0, ret[`reworking_pod_port`])
            dd[`reworking_pod_port`] = `Re-working 2 Port (if any)`
            dd[`reworking_pod_arrival_estimate`] = `ETA Re-working 2`
            dd[`reworking_pod_arrival`] = `ATA Re-working 2`
            dd[`reworking_pod_departure_estimate`] = `ETD Re-working 2`
            dd[`reworking_pod_departure`] = `ATD Re-working 2`
            dd[`reworking_pod_vessel_name`] = `Re-working 2 Leg Vessel Name`
            dd[`reworking_pod_vessel_number`] = `Re-working 2 Leg Voyage #`
            dd[`reworking_pod_destuffing`] = `De-stuffing @ Re-working 2 Port`
            dd[`reworking_pod_restuffing`] = `Re-stuffing @ Re-working 2 Port`
            dd[`reworking_pod_container_number`] = `Re-working 2 Container #`
        }
        console.log(dd)
        return dd
    },
    addairts: (dd, ret) => {
        let l = ["ts1_airport",
            "ts1_arrival_estimate",
            "ts1_arrival",
            "flight_number_ts1",
            "ts1_departure_estimate",
            "ts1_departure",
            "ts2_airport",
            "ts2_arrival_estimate",
            "ts2_arrival",
            "flight_number_ts2",
            "ts2_departure_estimate",
            "ts2_departure"]
        let lv = {}
        let lk = Object.keys(dd)
        for (let i = 0; i < lk.length; i++) {
            if (l.indexOf(lk[i]) == -1) {
                lv[lk[i]] = dd[lk[i]]
            }
        }
        dd = lv
        for (let i = 0; i < 2; i++) {

            if (ret[`ts${i + 1}_airport`] != undefined && ret[`ts${i + 1}_airport`] != null && ret[`ts${i + 1}_airport`].trim().length > 0) {
                console.log(ret[`ts${i + 1}_airport`] != undefined, ret[`ts${i + 1}_airport`] != null, ret[`ts${i + 1}_airport`].trim().length > 0, ret[`ts${i + 1}_airport`])
                dd[`ts${i + 1}_airport`] = `Transhipment ${i + 1} Airport (if any)`
                dd[`ts${i + 1}_arrival_estimate`] = `ETA Transhipment ${i + 1}`
                dd[`ts${i + 1}_arrival`] = `ATA Transhipment ${i + 1}`
                dd[`flight_number_ts${i + 1}`] = `Flight Number from Transhipment ${i + 1} Airport`
                dd[`ts${i + 1}_departure_estimate`] = `ETD Transhipment ${i + 1}`
                dd[`ts${i + 1}_departure`] = `ATD Transhipment ${i + 1}`
            }
        }

        console.log(dd)
        return dd
    },
    modaldd: {},
    setmodaldd: (data) => {
        myactionsstore.modaldd = Object.assign({}, data)
    },



    lcldatastore: {
        'customer_name': 'Customer Name',
        'shipmentScope': 'Shipment Scope',
        'cust_ref_number': 'Customer Reference #',
        'agraga_booking_#': 'Agraga Booking Reference #',
        'origin': 'Origin',
        'destination': 'Destination',
        'por': 'Place of Receipt',
        'pol': 'Port of Loading',
        'pod': 'Port of Discharge',
        'fpod': 'Final Place of Delivery',
        "etd_at_pol": "ETD @ POL",
        'eta_at_pod': 'ETA at POD',
        'carrier': 'Carrier',
        'arrival_at_por': 'Arrival at CFS',
        'si_submission': 'SI Submission',
        'stuffing_confirmation': 'Stuffing Confirmation',
        'hbl_draft_approval': 'HBL Draft Approval',
        'gatein_pol': 'Gate-in @ POL',
        'sob_pol': 'Shipped-on-Board (ATD)',
        'pol_container_number': 'POL Container #',
        'consolidator_bl_number': 'Consolidator BL Number',
        'bl_release': 'BL Released to Customer',
        'mbl_number': 'Carrier MBL Number',
        'hbl_number': 'HBL Number',
        'arrival_notice': 'Arrival Notice to Consignee',
        'gatein_fpod': 'Gate-in at FPOD',
        'destuffing_fpod': 'De-stuffing Status @ fpod',
        'delivery_order_release': 'Delivery Order Release to Consignee',
        'cargo_pickup_fpod': 'Cargo Picked-up for Delivery',
        'remarks': 'Special Remarks',
        'status_closed': 'Is the Booking Closed?'
    },

    lcldatastoreorder: [
        'customer_name',
        'shipmentScope',
        'cust_ref_number',
        'agraga_booking_#',
        'origin',
        'destination',
        'por',
        'pol',
        'pod',
        'fpod',
        'etd_at_pol',
        'eta_at_pod',
        'carrier',
        'cargo_pickup_date',
        'arrival_at_por',
        'si_submission',
        'stuffing_confirmation',
        'hbl_draft_approval',
        'container_handover',
        'por_to_pol_movement',
        'pol_arrival',
        'rail_yard_to_cfs',
        'destuffing_at_pol',
        'restuffing_at_pol',
        'gatein_pol',
        'sob_pol',
        'pol_container_number',
        'consolidator_bl_number',
        'bl_release',
        'mbl_number',
        'hbl_number',
        'ts1_port',
        'ts1_arrival_estimate',
        'ts1_arrival',
        'ts1_departure_estimate',
        'ts1_departure',
        'ts2_port',
        'ts2_arrival_estimate',
        'ts2_arrival',
        'ts2_departure_estimate',
        'ts2_departure',
        'ts3_port',
        'ts3_arrival_estimate',
        'ts3_arrival',
        'ts3_departure_estimate',
        'ts3_departure',
        'reworking_port',
        'reworking_departure_estimate',
        'reworking_arrival_estimate',
        'reworking_departure',
        'reworking_arrival',
        'destuffing_reworking',
        'restuffing_reworking',
        'gatein_reworking',
        'sob_reworking',
        'new_carrier_name',
        'new_carrier_mbl_number',
        'reworking_container_number',
        'arrival_notice',
        'pod_arrival',
        'gateout_to_fpod',
        'gatein_fpod',
        'destuffing_fpod',
        'delivery_order_release',
        'cargo_pickup_fpod',
        'cargo_delivery',
        'remarks',
        'status_closed'
    ],

    setlcldatastore: (data) => {
        var newObject = {};
        let order = myactionsstore.lcldatastoreorder
        for (var i = 0; i < order.length; i++) {
            if (data.hasOwnProperty(order[i])) {
                if (data[order[i]] != undefined) {
                    newObject[order[i]] = data[order[i]];
                }
            }
        }
        Object.assign(myactionsstore.lcldatastore, newObject);
    },

    removelcldata: (data) => {
        myactionsstore.lcldatastore = Object.assign({}, data)
        myactionsstore.setlcldatastore(data)
    }
})
// devtools(proxy([{myactionsstore}]),{ name: 'Store2', enabled: true })
export { myactionsstore }