import homeIcon from "../assets/images/home.svg";
import { breadCrumbStore } from "../state/CommonState";

function Point(){
    return <div className="min-h-[5px] min-w-[5px] rounded-[50%] bg-blue-dark"></div>
}

export function BreadCrumb2(){
    return <div className="flex flex-row gap-[5px]">
        <div className="flex flex-row gap-[5px] h-[14px] items-center">
            <img src={homeIcon} />
            <p className="text-xs text-blue-dark font-bold">Home</p>
            <Point/>
        </div>
         {breadCrumbStore.breadCrumbs.map((breadCrumb,i)=><div onClick={()=>{
             breadCrumbStore.setCurrentCrumb(i);
         }} className={`flex flex-row gap-[5px] h-[14px] items-center cursor-pointer`}>
         <p className={`text-xs ${ !(i===breadCrumbStore.breadCrumbs.length-1) ? "text-blue-dark" : "text-[black]"} font-bold`}>{breadCrumb}</p>
         { !(i===breadCrumbStore.breadCrumbs.length-1) && <Point/>}
         </div>)}
    </div>
}