import { Box, Typography } from "@mui/material";
import { ChooseFile } from "./ChooseFile";
import { useState } from "react";
import Api from "../state/Api";
import { configStore } from "../state/CommonState";

export function UploadDocuments({task}){
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/filestore/storefile`
    const [filesToBeUploaded,setFilesToBeUploaded] = useState([...task.files])
    return <Box sx={{display:"flex",flexDirection:"column",gap:2,height:"100%"}}>
         <Typography sx={{fontFamily:"Figtree",color:"black",fontWeight:"bold",fontSize:"18px"}}>Documents</Typography>
         <Box sx={{display:"flex",width:"100%",flexDirection:"column",gap:1,justifyContent:"start"}}>
         {filesToBeUploaded.map((fileToBeUploaded)=>
         <ChooseFile 
         onFileSelected={(file,updateFileState,updateFailureState)=>{
            const formData = new FormData()
            
            formData.append('myFile', file)
            formData.append('label', fileToBeUploaded.label)
            formData.append('key', file.name)
            fetch(url, {
                method: 'POST',
                headers: Api.H1(),
                body: formData
            })
                .then(async response => await response.json())
                .then(data=>{
                    if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') {
                        //  configStore.setModalMessage(data.error) 
                         if(updateFailureState){
                              updateFailureState();
                         }
                        }}
                    else{
                        if(updateFileState){
                            updateFileState();
                        }
                    }
                }).catch((error)=>{
                    if(updateFailureState){
                        updateFailureState();
                   }
                })
         }} onFileDeleted={(succes,failure)=>{
            if(succes){
                succes();
            }
         }} direction="row" title={fileToBeUploaded.label} />)}
         </Box>
    </Box>
}