import { useSnapshot } from "valtio"
import {
  AgmodalStore,
  ApprovalTabStore,
  EntityV2Store,
  branchStore,
  configStore,
  loginStore,
  routerStore,
} from "../state/CommonState"
import { ApprovalTab, EntityTypeV2 } from "../state/Types"
import { useEffect, useRef, useState } from "react"

//import { Col12, Col1,Col2, Col3, Col4, Col5, Col6, Columns } from "../Controls";
import {
  Col12,
  Col1,
  Col2,
  Col3,
  Col4,
  Col8,
  Col7,
  Col5,
  Cols,
  Col6,
  Columns,
  Col10,
  Col11,
} from "../Controls"

import { loader } from "../util/loader"
import RoleUtils from "../util/RoleUtils"
import Approval_Apis from "../state/approval-api"
import MainBranch from "../entity-v2/Branches/main-branch"
import BranchApi from "../state/branch-api"
import { BsFillEyeFill } from "react-icons/bs"
import EntityUtils from "../util/entityUtils"
import { CiFileOff } from "react-icons/ci";
import { SCOPE_OF_ACTIVITY } from "../util/constants"

export default function EditBranchApproval(props) {
  const Branch = branchStore.BranchRec
  const Entity = EntityV2Store.EntityRec
  const APP = ApprovalTabStore.ApprovalRec
  console.log(APP);
  const [FormUserStatus, setFormUserStatus] = useState("")
  const [Remarks, setRemarks] = useState("")
  const [errors, setErrors] = useState({})
  const [pendingErrors, setPendingErrors] = useState({})
  const [approveCheckbox, setApproveCheckbox] = useState(false)
  const [approvalStatus, setApprovalStatus] = useState(null)
  const [message, setMessage] = useState("")
  const [downloadFormData, setDownloadFormData] = useState({})
  const [cursor, setCursor] = useState(0)
  const [subbtnstatus, setsubbtnstatus] = useState(false)
  const [RDIR, setRDIR] = useState(false)
  const [ROLE, setROLE] = useState("")
  const ref = useRef(null)
  const hiddenFileInput1 = useRef()
  let title = ""
  switch (props.mode) {
    case "edit":
      title = `Modify (${Branch?._id}) ${Branch?.status}`
      break
    case "view":
      title = `View (${Branch?._id}) ${Branch?.status}`
      break
    case "new":
      title = "Create Branch"
      break
    default:
      title = "Branch"
      break
  }
  // useSnapshot(EntityV2Store)
  useSnapshot(ApprovalTabStore)
  useSnapshot(AgmodalStore)
  useSnapshot(loader)
  useEffect(() => {
    const input = ref.current
  }, [ref, cursor])
console.log("hhhhhhhhhhhhhhh");
  useEffect(() => {

    setROLE(ApprovalTabStore.ApprovalRec.userRole)
    setFormUserStatus(ApprovalTabStore.ApprovalRec.action)
  }, [])
  const handleVerifyRadioChange = (event) => {
    // Branch.remarks = ""
    setRemarks("")
    setApprovalStatus(event.target.value)
    setApproveCheckbox(true)
    setMessage("")
  }

  const handleMessageChange = (e) => {
    // Branch.remarks = e.target.value
    setRemarks(e.target.value)
  }

  const timelinepushfn = (r) => {
    let timeline = {
      date: Date.now(),
      event: r,
      user: loginStore.email,
      role: RoleUtils.getUserRole()
    }
    Branch.timeline.push(timeline)
  }
  useEffect(() => {
    setRemarks(Branch?.remarks)
    if(Branch.timeline === null || !Branch.timeline) {
      Branch.timeline = []
    }
  }, [])
  
  const handleSubmit = async (e) => {
    // e.preventDefault()
    setsubbtnstatus(true)
    console.log(ROLE);
    // if (Branch.entityType === "Customer") {
      if (approvalStatus === "approve" ) {
        const chkBox = document.getElementById("approveChkbox")?.checked
        if (chkBox) {
          await timelinepushfn("Verified")
          let dataUpdate = {
            formdata: Branch,
            userTypeObj:{
            role: ROLE,
            status:"approve",
            updatedBy: loginStore.email,
            },
          }
          await Approval_Apis.BRANCHUpdateStatus(dataUpdate, setsubbtnstatus,(d)=>{
            setsubbtnstatus(false)
          })
        } else {
          setsubbtnstatus(false)
        }

      } else if (approvalStatus === "reject") {
        if (Remarks.length < 1) {
          setsubbtnstatus(false)
          return configStore.setModalMessage("Please enter remarks , it should contain atleast 3 characters")
        }
        if (Remarks.trim().length < 3) {
          setsubbtnstatus(false)
          return configStore.setModalMessage("Remarks should contain atleast 3 characters")
        }
        await timelinepushfn("Rejected")
        let dataUpdate = {
          formdata: Branch,
          userTypeObj:{
            role: ROLE,
            status:"reject",
            rejectedrole: Branch.role,
            updatedBy: loginStore.email,
          },
          Remarks
        }
        await Approval_Apis.BRANCHUpdateStatus(dataUpdate, setsubbtnstatus,(d)=>{
          setsubbtnstatus(false)
        })
      } else{
        setsubbtnstatus(false)
      }
  } 
  const onRedirect =async (event)=>{
    setsubbtnstatus(true)
    await timelinepushfn("Rejection-Acknowledged")
    let dataUpdate = {
      formdata: Branch,
      userTypeObj:{
      role: ROLE,
      status:"approve",
      updatedBy: loginStore.email,
      },
    }
    await Approval_Apis.BRANCHUpdateStatus(dataUpdate, setsubbtnstatus,async(d)=>{
      if (d.status === "REJECTED") {
        await BranchApi.viewBranch(Branch.branchId,async(d)=>{
          if (d?.data.status === "REJECTED") {
            setRDIR(true)
            setsubbtnstatus(false)
          }
        })    
        }
    })
  }
  const checkScopeBs = (r) => {
    return Branch?.scopeOfBusiness?.includes(r);
  };
  return (
    <>
    
    { RDIR ? <MainBranch ch={RDIR}/>: <>
      <div class="main-content" style={{ zoom: "0.9" }}>
        <div className="header-wrapper">
          <div className="left-header">
            <div className="page-heading" >

              <span className="heading">
                {Branch.branchId}/{FormUserStatus}{' '}
              </span>

            </div>
          </div>
        </div>
        <section
          class="section section-padding"
          style={{ paddingTop: "0px" }}
        >
          <Cols>
          <Col7 style={{ zoom: "0.9",height:"100vh",overflowX:"scroll",display:"flex",flexWrap:"wrap" }}>
            <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>State</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Branch.state?.split("/")[0]}</Col12>
            </div>
            
            {Branch.country==="India"?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
           <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>GST Number</label>

                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>
                    <Cols  >
                    <Col10>
                    {Branch.gst || "NA"}
                     </Col10>
                <Col2 style={{margin:"auto",padding:"0"}}>
                      {Branch.documents[1]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_API_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Branch.documents[1]?.pkey +
                          "/" +
                          Branch.documents[1]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                </Col2>
                    </Cols>
                    </Col12>
             </div>:null}
                {Branch.scopeOfBusiness.includes("Cross Border") || Branch.scopeOfBusiness.includes("Domestic Transport") ?
                  <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Advance Received for Domestic Transport</label>
                    <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{Branch.advanceReceivableForDT || "NA"}</Col12>
                  </div> :
                  <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Advance Payable for Domestic Transport Bookings</label>
                    <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{Branch.advancePayable || "NA"}</Col12>
                  </div>
                }
                      {/* {Branch.scopeOfBusiness.includes("Cross Border") || Branch.scopeOfBusiness.includes("Domestic Transport")?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                  <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Scope Of Business</label>
                  <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Branch.scopeOfBusiness.map((d)=> <span style={{display:"block"}}>{d}</span>)}</Col12>
                </div>:null} */}
                {Branch.scopeOfBusiness.includes("Cross Border") || Branch.scopeOfBusiness.includes("Domestic Transport") ? <>
                  <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Scope Of Business</label>
                    <Col12 style={{ margin: "0 0.75rem", borderRadius: "4px" }}>{Object.values(SCOPE_OF_ACTIVITY).map((r, i) => {
                      return <>
                        <p className="is-flex is-flex-direction-row">
                          <input
                            id="scopeOfActivityFreitForward"
                            type="checkbox"
                            checked={checkScopeBs(r)}
                            disabled></input>
                          <span className="is-size-7 ml-2">{r}</span>
                        </p>
                      </>
                    })}</Col12>
                  </div>

                </> : null}
                {Branch.scopeOfBusiness.includes("Cross Border") ? <>
                  <div style={{ width: "100%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <hr style={{ border: "4px solid grey", width: "100%" }} />
                    <h3 style={{ width: "100%", fontSize: "30px" }}>Agraga Point of Contact- Cross-Border</h3>
                  </div>
                  <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Sales Vertical</label>
                    <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{Branch.crossBorder.salesVertical || "NA"}</Col12>
                  </div>
                  <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Agraga Branch</label>
                    <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{Branch.crossBorder.agragaBranch || "NA"}</Col12>
                  </div>
                  <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Customer Service</label>
                    <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{Branch.crossBorder.customerService || "NA"}</Col12>
                  </div>
                  <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Relationship Manager</label>
                    <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{Branch.crossBorder.relationshipManager || "NA"}</Col12>
                  </div>
                </> : null}
                {Branch.scopeOfBusiness.includes("Domestic Transport") ? <>
                  <div style={{ width: "100%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <hr style={{ border: "4px solid grey", width: "100%" }} />
                    <h3 style={{ width: "100%", fontSize: "30px" }}>Agraga Point of Contact- Domestic Transport</h3>
                  </div>
                  <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Agraga Branch</label>
                    <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{Branch.domesticTransport.agragaBranch || "NA"}</Col12>
                  </div>
                  <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Customer Service</label>
                    <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{Branch.domesticTransport.customerService || "NA"}</Col12>
                  </div>
                  <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Relationship Manager</label>
                    <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{Branch.domesticTransport.relationshipManager || "NA"}</Col12>
                  </div>
                  <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                    <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Branch Operation Manager</label>
                    <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{Branch.domesticTransport.branchOperationManager || "NA"}</Col12>
                  </div>
                                  {/* <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Customer Billing Cycle</label>
                            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Branch.domesticTransport.billingCycle}</Col12>
                      </div>
                      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>POD Type Of Accepting Invoice</label>
                            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Branch.domesticTransport.podInvoiceAcceptance}</Col12>
                      </div> */}

                </> : null}
                {!Branch.scopeOfBusiness.includes("Cross Border") && !Branch.scopeOfBusiness.includes("Domestic Transport") ?
                  <>
                    <div style={{ width: "100%", padding: "0.75rem", margin: "0.5rem 0" }}>
                      <hr style={{ border: "4px solid grey", width: "100%" }} />
                      <h3 style={{ width: "100%", fontSize: "30px" }}>Point of Contact</h3>
                    </div>

                    <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                      <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Agraga Branch</label>
                      <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{Branch.agragaBranch || "NA"}</Col12>
                    </div>
                    <div style={{ width: "50%", padding: "0.75rem", margin: "0.5rem 0" }}>
                      <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>RelationShip Manager Supply</label>
                      <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{Branch.relationShipManagerSupply || "NA"}</Col12>
                    </div>
                  </> :
                  null}
                <div style={{ width: "100%", padding: "0.75rem", margin: "0.5rem 0" }}>
                  <hr style={{ border: "4px solid grey", width: "100%" }} />
                  <h3 style={{ width: "100%", fontSize: "30px" }}>Branch Details</h3>
                </div>
                          {/* <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Country</label>
                      <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Branch.country || "NA"}</Col12>
                </div> */}
                          {/* <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>State</label>
                      <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Branch.state || "NA"}</Col12>
                </div> */}
                {Branch.branchAddresses.map((d) => {
                  return <>
                    <div style={{ width: "30%", padding: "0.75rem", margin: "0.5rem 0" }}>
                      <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Address</label>
                      <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{d.address || "NA"}</Col12>
                    </div>
                    <div style={{ width: "30%", padding: "0.75rem", margin: "0.5rem 0" }}>
                      <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>City</label>
                      <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{d.city || "NA"}</Col12>
                    </div>
                    {Branch.country === "India" ? <div style={{ width: "30%", padding: "0.75rem", margin: "0.5rem 0" }}>
                      <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Pincode</label>
                      <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{d.pincode || "NA"}</Col12>
                    </div> : null}
                    {Branch.country !== "India" ? <div style={{ width: "30%", padding: "0.75rem", margin: "0.5rem 0" }}>
                      <label style={{ color: "#000", padding: "0 0.75rem", fontWeight: 600 }}>Zipcode</label>
                      <Col12 style={{ border: "1px solid rgb(180 178 178)", margin: "0 0.75rem", borderRadius: "4px" }}>{d.zipcode || "NA"}</Col12>
                    </div> : null}
                  </>
                })
                }
            </Col7>
            <Col5 style={{position:"relative",height:"95vh"}}>
              <div
                style={{
                  fontWeight: "bold",
                  color: "purple",
                  fontSize: "1.1rem",
                }}
                className=""
              >
                TimeLine
              </div>
              <table
                className="table is-fullwidth is-narrow timeline_table"
                style={{ zoom: "0.8" }}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Event</th>
                    <th>User</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {Branch?.timeline?.map((r, i) => {
                    return <>
                      <tr key={i}>
                        <td>{new Date(Branch.timeline[i].date).toLocaleString()}</td>
                        <td>{Branch.timeline[i].event}</td>
                        <td>{Branch?.timeline[i]?.user}</td>
                        <td>{Branch.timeline[i].role}</td>
                      </tr>
                    </>
                  })}
                </tbody>
              </table>
              <div className="approve-container">
              {(
            <>
              {(ROLE === "FINANCE" || ROLE === "Operations") && (APP.status ==="Pending") && (
                <p className="is-size-5 has-text-weight-bold">
                  Document Verification
                </p>

              )
              }
              {/* {true && (
                <p className="is-size-5 has-text-weight-bold">
                  Finance Approvals for this Credit Facility
                </p>
              )
              } */}
              {((ROLE === "FINANCE" || ROLE === "Operations") && (APP.status ==="Pending")) && (
                <div className="is-flex is-justify-content-space-between">
                  <span className="is-size-6" style={{ marginTop: "1%" }}>
                    <div>
                      <label>
                        <input
                          type="radio"
                          className="is-size-2"
                          name="approvalStatus"
                          value="approve"
                          checked={approvalStatus === "approve"}
                          onChange={handleVerifyRadioChange}
                        />
                        Approve
                      </label>
                      <label style={{ marginLeft: "10px" }}>
                        <input
                          type="radio"
                          className="is-size-2"
                          name="approvalStatus"
                          value="reject"
                          checked={approvalStatus === "reject"}
                          onChange={handleVerifyRadioChange}
                        />
                        Reject
                      </label>
                    </div>
                  </span>
                </div>
              )}

              {approvalStatus === "reject" && (
                <div>
                  <textarea
                    //  id="rejectremarksid"
                    className="px-2"
                    rows="4"
                    cols="50"
                    placeholder="Enter your Remarks"
                    value={Remarks}
                    onChange={handleMessageChange}
                    //onChange={approvebtnnfn}
                    style={{ marginTop: "10px", border: "1px solid #d1d1e0" }}
                  />
                </div>
              )}
              {(approvalStatus === "approve") && (
                <div>
                  Do you want to approve? <span></span>
                  <input
                    type="checkbox"
                    className="is-size-2"
                    name="approvalStatus"
                    // value="approve"
                    id="approveChkbox"
                  // onChange={approvebtnnfn}
                  />
                </div>
              )}
            </>
          )}
            {(ROLE === "FINANCE" || ROLE === "Operations") && (APP.status ==="Pending") &&
            (
              <div>
                <span>
                  <button
                    className="button_cancel btn_height"
                    style={{ marginLeft: "3px", paddingLeft: "10px" }}
                    onClick={() => {
                      EntityV2Store.EntityRec.set(new EntityTypeV2())
                      AgmodalStore.pageVisible = false;
                      AgmodalStore.apiCall = true;
                    }}
                  >
                    CANCEL
                  </button>
                  <button
                    className="button_submit btn_height"
                    id="approvebtnid"
                    onClick={(e) => handleSubmit(e)}
                    disabled={subbtnstatus}
                  >
                    SUBMIT
                  </button>
                </span>
              </div>
            )
          }
 
              </div>
              {(ROLE === "FINANCE" || ROLE === "Operations"|| ROLE === "SALES") && (ROLE === "SALES" || Branch?.timeline?.find((d)=>d.event === "Rejection-Acknowledged")) &&  (
              <>
            <div style={{border:"1px solid #f5f5f5"}}>
              <p style={{padding:"1rem",backgroundColor:"#f2f2f2"}}>Rejection Remarks</p>
             <p style={{padding:"1rem"}}>{Branch?.remarks}</p> 
            </div>  
            {(APP.status ==="Pending") && ROLE === "SALES" && <button className="button_submit"   disabled={subbtnstatus} style={{padding:".7rem",}} onClick={(e)=>onRedirect(e)}>Acknowlegement</button>}
            </>
          )

          }

            </Col5>
          </Cols>
        </section>




        <hr />
      </div>
      </>
} </>
  )

  function clearErrorMessages(r) {
    pendingErrors[r] = ""
    errors[r] = ""
    setPendingErrors(pendingErrors)
  }

}
