import { useSnapshot } from "valtio"
import { enquiryStore } from "./enquiry-data"
import ListEnquiry from "./list-enquiry"
import ViewEnquiry from "./view-enquiry"
import HomeScreen from "./home-screen"
import HomeScreenLCL from "./home-screen-lcl"
import AgEnquiry from "./AgEnquiry"

export default function ListEnquiryMain(props) {
    useSnapshot(enquiryStore)
    console.log('VALUE OF LIST ENQUIRY =', enquiryStore.currentEnquiryListComponent)
    switch(enquiryStore.currentEnquiryListComponent) {
        case 'list': return <ListEnquiry />
        case 'view': return <ViewEnquiry viewonly='1' />
        case 'new' : return <AgEnquiry/>
        default: return <h1>Not Found</h1>
    }
}