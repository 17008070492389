import { useState } from "react";
import { useSnapshot } from "valtio";
import { Agmongofile } from "../components/AgFile";
import TaskDatetime from "./task-datetime";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
let server = process.env.REACT_APP_API_SERVER
export default function Taskfiletable(props) {
    useSnapshot(taskStore)
    const [newLabel, setNewLabel] = useState('')
    const [newButtonEnabled, setNewButtonEnabled] = useState(false)
    let task = taskStore.current
    let docs = task.files
    let removeadd = ["Carrier Release Order","AWB Upload","DO Release (Air)","DO Release (Ocean)","BL Draft Approval","BL Upload"]
    if (!docs) docs = []
    return (
        <div>
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                <tr>
                    {
                        (removeadd.indexOf(task.actionName)==-1)?<th></th>:<></>
                    }
                    <th>Document</th>
                    <th>Link</th>
                    <th>Upload</th>
                    {
                        (props.showRejectReason) ?
                            <th>Reject Reason</th>
                            : <></>
                    }
                </tr>
                {
                    docs.map((e, index) => <tr style={{ padding: '0.5rem' }} id={e.label.replaceAll(" ","")+"___"+index}>
                        {
                        (removeadd.indexOf(task.actionName)==-1)?<td>
                        <p className="control" style={{ padding: '0px' }}>
                            <button className="button is-success  is-inverted"
                                onClick={() => {
                                    taskStore.current.files.splice(index, 0, {
                                        label: e.label,
                                        filename: '',
                                        approved: '',
                                        rejectReason: ''
                                    })
                                }}>
                                <span className="icon is-small">
                                    <i className="fas fa-plus"></i>
                                </span>
                            </button>
                        </p>
                    </td>:<></>
                    }
                        

                        <td >{e.label}</td>
                        <td >
                            {
                                (e.filename) ?<>
                                    <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + e.filename}>
                                        {e.filename}
                                    </a>{
                                        (e.approved != 'Approve')?<>&nbsp;<button class="button is-danger" onClick={()=>e.filename=""}><i class="fa fa-trash" aria-hidden="true"></i></button></>:<></>
                                    } </> : <></>
                            }
                        </td>
                        <td >
                            {
                                (e.approved !== 'Approve') ?
                                    <Agmongofile disabled={(e.approved === 'Approve') ? true : false} parent={e.label.replaceAll(" ","")+"___"+index}
                                        label={e.label} filekey={task.bookingNum}
                                        callback={(data,id) => {
                                            console.log(id)
                                            docs[id].filename = data.key.filename
                                           // e.filename = data.key.filename
                                            //Taskapi.updateTask(taskStore.current)
                                        }} />
                                    : <></>
                            }
                        </td>
                        {
                            (props.showRejectReason) ?
                                <td>
                                    {
                                        (e.approved === 'Reject') ? e.rejectReason : ''
                                    }
                                </td>
                                : <></>
                        }
                    </tr>)
                }
                {
                        (removeadd.indexOf(task.actionName)==-1)?<tr>
                        <td></td>
                        <td>
                            <input placeholder="Name for new Document" className="input is-small"
                                value={newLabel} onChange={(e) => {
                                    if (e.target.value.length > 0) setNewButtonEnabled(true)
                                    else setNewButtonEnabled(false)
                                    setNewLabel(e.target.value)
                                }} />
                        </td>
                        <td>
                            <button disabled={!newButtonEnabled} className="button is-small islink"
                                onClick={() => {
                                    taskStore.current.files.push({
                                        label: newLabel,
                                        filename: '',
                                        approved: '',
                                        rejectReason: ''
                                    })
                                    setNewLabel('')
                                    setNewButtonEnabled(false)
                                }}>Add Document Type to Table</button>
                        </td>
                    </tr>:<></>
                }
                
            </table>
            <hr />
        </div>
    )
}