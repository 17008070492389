import { Box, Typography } from "@mui/material";
import { AttributeDetails } from "../mui-components/AttributeValueBox";
import { PricingDetailsCard } from "../mui-components/PricingDetailsCard";
import { useSnapshot } from "valtio";
import { viewBookingStore } from "../state/CommonState";
import { LCLBookingPricingDetail } from "./LCLBookingPricingDetail";
import { AIRBookingPricingDetail } from "./AIRBookingPricingDetail";
import { FCLBookingPricingDetail } from "./FCLBookingPricingDetail";
import Utils from "../util/Utils";
import { useEffect, useState } from "react";
import { ServerLists } from "../StaticData";


const bpookingDetails = (isImport=false)=>{
  return [
    { label: "First Name", key: "firstName", type : "string" },
    { label: "Last Name", key: "lastName", type: "string" },
    { label: "Contact Email", key: "emailid", type: "string" },
    { label: "Contact Number", key: "phone" , type: "string"},
    { label: isImport ? "Consignee Name" : "Shipper Name", key: isImport ? "consignee" : "shipper" , type: "string"},
    { label: "Address", key: "address" , type: "string"},
    { label: "PIN / ZIP Code", key: "pin" , type: "string"},
    { label: "Country", key: "country" , type: "string"}, 
]

}
const commonDetails = [
    { label: "Special Instructions", key: "specialInstructions", type: "string" },
    { label: "Commodity Type", key: "commodityType", type: "string" },
    { label: "Incoterms", key: "incoTerms" , type: "string"},
]

const consigneeDetails = (isExport)=>{
  return [
    { label:  isExport ? "Consignee Name" : "Shipper Name", key: "companyName", type : "string" },
    { label: "Address", key: "address" , type: "string"},
    { label: "State", key: "state", type: "string" },
    { label: "Country", key: "country" , type: "string"},
    { label: "PIN / ZIP Code", key: "pincode" , type: "string"},
    { label: "First Name", key: "firstName", type : "string" },
    { label: "Last Name", key: "lastName", type: "string" },
    { label: "Contact Email", key: "emailId", type: "string" },
    { label: "Contact Number", key: "mobileNumber" , type: "string"}, 
]
}


export function BookingDetailsComponent() {
   const booking = viewBookingStore.current
const [showConsignee,setShowConsignee] = useState(false)
const theMatch = Utils.extractCountry(booking?.booking?.contract?.origin);
const theDestinationMatch = Utils.extractCountry(booking?.booking?.contract?.destination);
const isExport = booking?.booking?.country === theMatch
const isImport = booking?.booking?.country === theDestinationMatch
const shipperDetailsKeys = bpookingDetails(isImport);
const consigneeDetailsKeys = consigneeDetails(isExport);
  
    return <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "start",paddingX:"10px" }}>
      <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
        <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"16px",color:"black"}}>Shipper Details</Typography>
        {isImport || !isExport ?  booking.Consignee ? 
         <AttributeDetails details={consigneeDetailsKeys.map((bookingDetail)=>{
            const objectToBeSearched =  booking.Consignee;
        return  {title:bookingDetail.label,value:objectToBeSearched[bookingDetail.key]}
      })} /> : <></> :
         <AttributeDetails details={shipperDetailsKeys.map((bookingDetail)=>{
              const objectToBeSearched = booking.booking;
          return  {title:bookingDetail.label,value:objectToBeSearched[bookingDetail.key]}
        })} />
}
     
      
      </Box>
    
       {  isExport && booking.Consignee  && <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
      <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"16px",color:"black"}}>Consignee Details</Typography>
        <AttributeDetails details={consigneeDetailsKeys.map((bookingDetail)=>{
              const objectToBeSearched =  booking.Consignee;
          return  {title:bookingDetail.label,value:objectToBeSearched[bookingDetail.key]}
        })} /> 
      </Box>
}

{  isImport && <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
      <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"16px",color:"black"}}>Consignee Details</Typography>
        <AttributeDetails details={shipperDetailsKeys.map((bookingDetail)=>{
              const objectToBeSearched =  booking.booking;
          return  {title:bookingDetail.label,value:objectToBeSearched[bookingDetail.key]}
        })} /> 
      </Box>
}
      <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
        <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"16px",color:"black"}}>Shipment Details</Typography>
         <AttributeDetails details={commonDetails.map((bookingDetail)=>{
              const objectToBeSearched = bookingDetail.nested ? booking.booking.contract : booking.booking;
          return  {title:bookingDetail.label,value:objectToBeSearched[bookingDetail.key]}
        })} />
        </Box>
    {(!viewBookingStore.commonView &&  Utils.isMyBooking(booking?.booking?.contract?.entityId)) && <Box sx={{ width: "100%" }}>
        { booking.booking.contract?.shipmentType.indexOf("LCL")>=0 && <LCLBookingPricingDetail disableInclusion={true} contract={booking.booking.contract} />}
        { booking.booking.contract?.shipmentType.indexOf("Air")>=0 && <AIRBookingPricingDetail disableInclusion={true} contract={booking.booking.contract} />}
        { booking.booking.contract?.shipmentType.indexOf("FCL")>=0 && <FCLBookingPricingDetail contract={booking.booking.contract} />}
    </Box>}
    </Box>
}