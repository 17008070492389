import { useSnapshot } from "valtio";
import { DtStore } from "../dt/DtStore";
import { dtactionStore } from "./dtaction-store";
import Dtnewinput from "../dt/cps/Dtnewinput";
import { Agmongofilenew } from "../components/AgFile";
import { configStore, loginStore } from "../state/CommonState";
import MonApi from "../state/mongo-api";
import Utils from "../util/Utils";
import { Col2, Col3, Col4, Col6, Col8, Cols } from "../Controls";
import { Col } from "reactstrap";
import { useEffect } from "react";

let finaldocverifyStages = {
    INITIAL: 0,
    ALL_APPROVED: 1,
    REJECTED: 2,
    COMPLETED: 9
}
export {
    finaldocverifyStages
}
function CommonSection(props) {
    useSnapshot(dtactionStore)
    let action = dtactionStore.current
    let booking = action.booking
    let adc = 0
    if(booking.vendor_additional_cost && booking.vendor_additional_cost.length>0){
        adc = Number(booking.vendor_additional_cost)
      }
    return (
        <>
            <section class="section section-padding" >
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>Vendor Name</Col3>
                    <Col4>{action.vendor}</Col4>
                </Cols>
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>Vendor Agreed Cost</Col3>
                    <Col4>{Utils.N(action.vendorCost)}</Col4>
                </Cols>
                {
                          (booking.bookingamount!=undefined && booking.vendor_agreed_cost!=undefined)  ?
                          <>
                          <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Gross Profit Amount</Col3>
                                            <Col4>{Number(booking.total_cost)-Number(booking.vendor_agreed_cost)-adc} (INR)</Col4>
                                            </Cols><Cols>
                                            <Col3 style={{ color: 'darkblue' }}>GP %</Col3>
                                            <Col4>{(((Number(booking.total_cost)-Number(booking.vendor_agreed_cost)-adc)/(Number(booking.vendor_agreed_cost)+adc))*100).toFixed(2)}</Col4>
                                        </Cols>
                          </>
                          :""
                        }
            </section>
        </>
    )
}
export default function DtactionsFinaldocverify(props) {
    const { setSubmitCallback } = props
    useSnapshot(DtStore)
    useSnapshot(dtactionStore)
    useSnapshot(loginStore)
    useSnapshot(configStore)
    let dtStore = DtStore.DTBooking
    let action = dtactionStore.current
    let booking = action.booking
    let server = process.env.REACT_APP_API_SERVER
    let client = loginStore.isClient()
    
    const callback = () => {
        let t = 0.0;
        let k = {};
        let ton = 0;
        
        if (booking.details[0].delivery[0]["pod_date"] == undefined) {
            for (let lz = 0; lz < booking.details[0].delivery.length; lz++) {
                booking.details[0].delivery[lz]["pod_date"] = "";
            }
          }

          if (booking.pricing_type == "vehicle") {
            let t = booking.details[0].vehicle.map((v) => {
              if (v.vehicle_type == booking.vehicle) {
                return v.vehicle_amount;
              }
            });
            if (booking.bookingamount != undefined || booking.bookingamount != t) {
                booking.bookingamount = t;
            }
          }
          if (booking.pricing_type != "vehicle") {
            for (let i = 0; i < booking.details[0].vehicle.length; i++) {
              if (booking.details[0].vehicle[i].vehicle_type == booking.vehicle) {
                k = booking.details[0].vehicle[i];
              }
            }
            t = k["minimum"];
            if (booking.totel_weight.length > 0) {
              ton = Number(k["minimum"]) / Number(k["amt_per_ton"]);
              if (booking.weight_type == "tons") {
                let l = Number(booking.totel_weight);
                if (l > ton) {
                  t = (l * Number(k["amt_per_ton"])).toFixed(2);
                }
              }
              if (booking.weight_type == "kgs") {
                let l = Number(booking.totel_weight) / 1000;
                if (l > ton) {
                  t = (l * Number(k["amt_per_ton"])).toFixed(2);
                }
              }
            }
            if (booking.bookingamount != t) {
              booking.bookingamount = t;
            }
          }
        let delivery = booking.details[0].delivery
        let allapproved = true
        if (dtactionStore.current.stage == finaldocverifyStages.ALL_APPROVED) {
            dtactionStore.current.stage = finaldocverifyStages.COMPLETED
            dtactionStore.current.status = 'COMPLETED'
            dtactionStore.view = 'list'
            return 1
        }
        if (dtactionStore.current.stage == finaldocverifyStages.INITIAL) {
            const checkstatus = (d,key)=>{
                if((d[key]!=undefined && d[key].length == 0) || d[key]==undefined){
                    return true
                }else{
                    return false
                }
            }
            const checklength = (d,key)=>{
                allapproved = false
                if((d[key]!=undefined && d[key].length < 3) || d[key]==undefined){
                    return true
                }else{
                    return false
                }
            }
            if(checkstatus(booking,"invoiceApproved") ||  
                checkstatus(booking,"vendorinvoiceApproved") ||
                checkstatus(booking,"tvcApproved") ||
                checkstatus(booking,"ciaApproved")
                ){
                    configStore.setModalMessage('Select Status Mandatory')
                    return 0
                }
            if ((booking.invoiceApproved == 'Reject' && checklength(booking,"invoiceRejectReason")) || 
            (booking.vendorinvoiceApproved == 'Reject' && checklength(booking,"vendorinvoiceRejectReason")) ||
            (booking.tvcApproved == 'Reject' && checklength(booking,"tvcRejectReason")) ||
            (booking.ciaApproved == 'Reject' && checklength(booking,"ciaRejectReason"))) {
                    configStore.setModalMessage('Reject Reason Mandatory (minimum 3 characters)')
                    return 0
            }
           
            for (let i = 0; i < delivery.length; i++) {
                let d = delivery[i]
                if(checkstatus(d,"podApproved") ||
                checkstatus(d,"poddApproved")){
                configStore.setModalMessage('Select Status Mandatory')
                return 0
            }
                if ((d.podApproved == 'Reject' && checklength(d,"podRejectReason")) ||
                (d.poddApproved == 'Reject' && checklength(d,"poddRejectReason"))) {
                        configStore.setModalMessage('Reject Reason Mandatory (minimum 3 characters)')
                        return 0
                }
            }
  
            if (allapproved) {
                dtactionStore.current.stage = finaldocverifyStages.COMPLETED
                dtactionStore.current.status = 'COMPLETED'
            } else {
                dtactionStore.current.stage = finaldocverifyStages.REJECTED
                dtactionStore.current.pendingWithRole = 'SALES'
            }
            dtactionStore.view = 'list'
            return 1
        }
        if (dtactionStore.current.stage == finaldocverifyStages.REJECTED) {
            if(booking.ciaApproved=="Reject" && ((booking.total_cost != undefined && booking.total_cost.trim().length==0)|| booking.total_cost == undefined)){
                configStore.setModalMessage('Customer Invoice Amount Mandatory')
                return 0
            }
            for (let i = 0; i < delivery.length; i++) {
                let d = delivery[i]
                if(d.poddApproved=="Reject" && ((d.pod_date != undefined && d.pod_date.trim().length==0)|| d.pod_date == undefined)){
                    configStore.setModalMessage('Pod Date Mandatory')
                    return 0
                }
            }
            dtactionStore.current.stage = finaldocverifyStages.INITIAL
            dtactionStore.current.pendingWithRole = 'OPERATIONS'
            dtactionStore.view = 'list'
            return 1
        }
    }
    useEffect(() => {
        console.log('\n\n\n$$$$$$$$$ setsubmitcallback calling')
        dtactionStore.submitCallback = callback
    }, [])

    if (action.stage == finaldocverifyStages.INITIAL) {
        return (
            <>
                <CommonSection />
                <section class="section section-padding" >
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>Additional Cost if any</Col3>
                    <Col4>{Utils.N(booking.vendor_additional_cost)}</Col4>
                </Cols>
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>Additional Cost Reason</Col3>
                    <Col4>{action.additionalCostReason}</Col4>
                </Cols>
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>Total Vendor Cost</Col3>
                    <Col4>{Utils.N(Utils.toNum(action.vendorCost) + Utils.toNum(booking.vendor_additional_cost))}</Col4>
                    <Col2>
                            <div className="select">
                                <select value={booking.tvcApproved} onChange={(ev) => {
                                    booking.tvcApproved = ev.target.value
                                }}>
                                    <option value=''>Select Status</option>
                                    <option value='Approve'>Approve</option>
                                    <option value='Reject'>Reject</option>
                                </select>
                            </div>
                        </Col2>
                        <Col2>
                            {
                                (booking.tvcApproved == 'Reject') ?
                                    <input className="input" placeholder='Reject Reason'
                                        value={booking.tvcRejectReason} onChange={(ev) => {
                                            booking.tvcRejectReason = ev.target.value
                                        }}></input>
                                    : <></>
                            }
                        </Col2>
                </Cols>
                    <Cols>
                        <Col3 style={{ color: 'darkblue' }}>Customer Invoice Amount</Col3>
                        <Col4>{Utils.N(booking.total_cost)}</Col4>
                        <Col2>
                            <div className="select">
                                <select value={booking.ciaApproved} onChange={(ev) => {
                                    booking.ciaApproved = ev.target.value
                                }}>
                                    <option value=''>Select Status</option>
                                    <option value='Approve'>Approve</option>
                                    <option value='Reject'>Reject</option>
                                </select>
                            </div>
                        </Col2>
                        <Col2>
                            {
                                (booking.ciaApproved == 'Reject') ?
                                    <input className="input" placeholder='Reject Reason'
                                        value={booking.ciaRejectReason} onChange={(ev) => {
                                            booking.ciaRejectReason = ev.target.value
                                        }}></input>
                                    : <></>
                            }
                        </Col2>
                    </Cols>
                    <Cols>
                        <Col3 style={{ color: 'darkblue' }}>Customer Invoice</Col3>
                        <Col4>
                            <a target="_blank"
                                href={server +
                                    "/api/v1/filestore/getfile/" +
                                    booking.details[0].contract_id +
                                    "/" +
                                    booking.invoice
                                }
                            >{booking.invoice}</a>
                        </Col4>
                        <Col2>
                            <div className="select">
                                <select value={booking.invoiceApproved} onChange={(ev) => {
                                    booking.invoiceApproved = ev.target.value
                                }}>
                                    <option value=''>Select Status</option>
                                    <option value='Approve'>Approve</option>
                                    <option value='Reject'>Reject</option>
                                </select>
                            </div>
                        </Col2>
                        <Col2>
                            {
                                (booking.invoiceApproved == 'Reject') ?
                                    <input className="input" placeholder='Reject Reason'
                                        value={booking.invoiceRejectReason} onChange={(ev) => {
                                            booking.invoiceRejectReason = ev.target.value
                                        }}></input>
                                    : <></>
                            }
                        </Col2>
                    </Cols>
                    <Cols>
                        <Col3 style={{ color: 'darkblue' }}>Vendor Invoice</Col3>
                        <Col4>
                            <a target="_blank"
                                href={server +
                                    "/api/v1/filestore/getfile/" +
                                    booking.details[0].contract_id +
                                    "/" +
                                    booking.vender_invoice
                                }
                            >{booking.vender_invoice}</a>
                        </Col4>
                        <Col2>
                            <div className="select">
                                <select value={booking.vendorinvoiceApproved} onChange={(ev) => {
                                    booking.vendorinvoiceApproved = ev.target.value
                                }}>
                                    <option value=''>Select Status</option>
                                    <option value='Approve'>Approve</option>
                                    <option value='Reject'>Reject</option>
                                </select>
                            </div>
                        </Col2>
                        <Col2>
                            {
                                (booking.vendorinvoiceApproved == 'Reject') ?
                                    <input className="input" placeholder='Reject Reason'
                                        value={booking.vendorinvoiceRejectReason} onChange={(ev) => {
                                            booking.vendorinvoiceRejectReason = ev.target.value
                                        }}></input>
                                    : <></>
                            }
                        </Col2>
                    </Cols>
                </section>
                <section class="section section-padding">
                    <div >
                        {
                            booking.details[0].delivery.map((e, i) => {
                                return (
                                    <div style={{ border: '1px solid #ccc' }}>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Delivery Address {i + 1}</Col3>
                                            <Col4>{e.address}</Col4>
                                        </Cols>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Proof of Delivery</Col3>
                                            <Col4>
                                                <a target="_blank"
                                                    href={server +
                                                        "/api/v1/filestore/getfile/" +
                                                        booking.details[0].contract_id +
                                                        "/" + e.proofofdelivery
                                                    }
                                                >{e.proofofdelivery}</a>
                                            </Col4>
                                            <Col2>
                                                <div className="select">
                                                    <select value={e.podApproved} onChange={(ev) => {
                                                        e.podApproved = ev.target.value
                                                        console.log('****\n', e, '*******')
                                                    }}>
                                                        <option value=''>Select Status</option>
                                                        <option value='Approve'>Approve</option>
                                                        <option value='Reject'>Reject</option>
                                                    </select>
                                                </div>
                                            </Col2>
                                            <Col2>
                                                {
                                                    (e.podApproved == 'Reject') ?
                                                        <input className="input" placeholder='Reject Reason'
                                                            value={e.podRejectReason} onChange={(ev) => {
                                                                e.podRejectReason = ev.target.value
                                                            }}></input>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>
                                        {
                                            (e.pod_date != undefined)?<Cols>
                                            <Col3 style={{ color: 'darkblue' }}>POD Dated?</Col3>
                                            <Col4>
                                               {e.pod_date}
                                            </Col4>
                                            <Col2>
                                                <div className="select">
                                                    <select value={e.poddApproved} onChange={(ev) => {
                                                        e.poddApproved = ev.target.value
                                                        console.log('****\n', e, '*******')
                                                    }}>
                                                        <option value=''>Select Status</option>
                                                        <option value='Approve'>Approve</option>
                                                        <option value='Reject'>Reject</option>
                                                    </select>
                                                </div>
                                            </Col2>
                                            <Col2>
                                                {
                                                    (e.poddApproved == 'Reject') ?
                                                        <input className="input" placeholder='Reject Reason'
                                                            value={e.poddRejectReason} onChange={(ev) => {
                                                                e.poddRejectReason = ev.target.value
                                                            }}></input>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>:""
                                        }
                                        <div style={{ height: '5px', backgroundColor: 'white' }}></div>
                                    </div>
                                )

                            })
                        }
                    </div>
                </section>
            </>
        )
    }
    if (action.stage == finaldocverifyStages.REJECTED) {
        return (
            <>
                <CommonSection />

                <section class="section section-padding" >
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>Additional Cost if any</Col3>
                    <Col4>{Utils.N(booking.vendor_additional_cost)}</Col4>
                    <Col2>
                                                {
                                                    (booking.tvcApproved == 'Reject') ?
                                                        <div style={{ fontSize: '1.2rem' }}>Reject Reason: {booking.tvcRejectReason}</div>
                                                        :
                                                        <div style={{ fontSize: '1.2rem' }}>Approved</div>
                                                }
                                            </Col2>
                                            <Col2>
                                                {
                                                    booking.tvcApproved == 'Reject' ?
<div className="field">
<div className="control is-fullwidth">
                                                    <Dtnewinput
                                                    type="number"
                                                    record={booking}
                                                    name="vendor_additional_cost"
                                                    classnames="input is-small"
                                                  />
                                                  </div></div>
                                                        : <></>
                                                }
                                            </Col2>
                </Cols>
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>Additional Cost Reason</Col3>
                    <Col4>{action.additionalCostReason}</Col4>
                </Cols>
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>Total Vendor Cost</Col3>
                    <Col4>{Utils.N(Utils.toNum(action.vendorCost) + Utils.toNum(booking.vendor_additional_cost))}</Col4>
                    <Col2>
                                                {
                                                    (booking.tvcApproved != 'Reject') ?
                                                    <div style={{ fontSize: '1.2rem' }}>Approved</div>
                                                        :
                                                        ""
                                                }
                                            </Col2>
                </Cols>
                    <Cols>
                        <Col3 style={{ color: 'darkblue' }}>Customer Invoice Amount</Col3>
                        <Col4>{Utils.N(booking.total_cost)}</Col4>
                        <Col2>
                                                {
                                                    (booking.ciaApproved == 'Reject') ?
                                                    <div style={{ fontSize: '1.2rem' }}>Reject Reason: {booking.ciaRejectReason}</div>
                                                        :
                                                        <div style={{ fontSize: '1.2rem' }}>Approved</div>
                                                }
                                            </Col2>
                                            <Col2>
                                                {
                                                    booking.ciaApproved == 'Reject' ?
<div className="field">
<div className="control is-fullwidth">
                                                    <Dtnewinput
                                                    type="number"
                                                    record={booking}
                                                    name="total_cost"
                                                    classnames="input is-small"
                                                  />
                                                  </div></div>
                                                        : <></>
                                                }
                                            </Col2>
                    </Cols>
                    <Cols id='DTEB__invoice'>
                        <Col3 style={{ color: 'darkblue' }}>Customer Invoice</Col3>
                        <Col4>
                            <a target="_blank"
                                href={server +
                                    "/api/v1/filestore/getfile/" +
                                    booking.details[0].contract_id +
                                    "/" +
                                    booking.invoice
                                }
                            >{booking.invoice}</a>
                        </Col4>
                        <Col2>
                            {
                                (booking.invoiceApproved == 'Reject') ?
                                    <div style={{ fontSize: '1.2rem' }}>Reject Reason: {booking.invoiceRejectReason}</div>
                                    :
                                    <div style={{ fontSize: '1.2rem' }}>Approved</div>
                            }
                        </Col2>
                        <Col2>
                            {
                                booking.invoiceApproved == 'Reject' ?
                                    <Agmongofilenew
                                        parent={"DTEB__invoice"}
                                        label={"invoice"}
                                        filekey={booking.details[0].contract_id}
                                        callback={(data, id) => {
                                            console.log(id);
                                            booking.invoice = data.key.filename;
                                        }}
                                    />
                                    : <></>
                            }
                        </Col2>
                    </Cols>
                    <Cols id='DTEB__vender_invoice'>
                        <Col3 style={{ color: 'darkblue' }}>Vendor Invoice</Col3>
                        <Col4>
                            <a target="_blank"
                                href={server +
                                    "/api/v1/filestore/getfile/" +
                                    booking.details[0].contract_id +
                                    "/" +
                                    booking.vender_invoice
                                }
                            >{booking.vender_invoice}</a>
                        </Col4>
                        <Col2>
                            {
                                (booking.vendorinvoiceApproved == 'Reject') ?
                                    <div style={{ fontSize: '1.2rem' }}>Reject Reason: {booking.vendorinvoiceRejectReason}</div>
                                    :
                                    <div style={{ fontSize: '1.2rem' }}>Approved</div>
                            }
                        </Col2>
                        <Col2>
                            {

                                booking.vendorinvoiceApproved == 'Reject' ?
                                    <Agmongofilenew
                                        parent={"DTEB__vender_invoice"}
                                        label={"vender_invoice"}
                                        filekey={booking.details[0].contract_id}
                                        callback={(data, id) => {
                                            console.log(id);
                                            //console.log('\n****************\n', booking.invoice, booking.vender_invoice)
                                            booking.vender_invoice = data.key.filename;
                                            //console.log(booking.invoice, booking.vender_invoice,'\n*******************')
                                        }}
                                    />
                                    : <></>
                            }
                        </Col2>
                    </Cols>
                </section>
                <section class="section section-padding">
                    <div >
                        {
                            booking.details[0].delivery.map((e, i) => {
                                return (
                                    <div style={{ border: '1px solid #ccc' }}>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Delivery Address {i + 1}</Col3>
                                            <Col4>{e.address}</Col4>
                                        </Cols>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Proof of Delivery</Col3>
                                            <Col4>
                                                <a target="_blank"
                                                    href={server +
                                                        "/api/v1/filestore/getfile/" +
                                                        booking.details[0].contract_id +
                                                        "/" + e.proofofdelivery
                                                    }
                                                >{e.proofofdelivery}</a>
                                            </Col4>
                                            <Col2>
                                                {
                                                    (e.podApproved == 'Reject') ?
                                                        <div style={{ fontSize: '1.2rem' }}>Reject Reason: {e.podRejectReason}</div>
                                                        :
                                                        <div style={{ fontSize: '1.2rem' }}>Approved</div>
                                                }
                                            </Col2>
                                            <Col2>
                                                {
                                                    e.podApproved == 'Reject' ?
                                                        <Agmongofilenew
                                                            parent={"DTEB___" + i}
                                                            label={"proofofdelivery" + i}
                                                            filekey={booking.details[0].contract_id}
                                                            callback={(data, id) => {
                                                                console.log(id);
                                                                e.proofofdelivery = data.key.filename;
                                                            }}
                                                        />
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>
                                        {
                                            (e.pod_date != undefined)?<Cols>
                                            <Col3 style={{ color: 'darkblue' }}>POD Dated?</Col3>
                                            <Col4>
                                               {e.pod_date}
                                            </Col4>
                                            <Col2>
                                            {
                                                    (e.poddApproved == 'Reject') ?
                                                        <div style={{ fontSize: '1.2rem' }}>Reject Reason: {e.poddRejectReason}</div>
                                                        :
                                                        <div style={{ fontSize: '1.2rem' }}>Approved</div>
                                                }
                                            </Col2>
                                            <Col2>
                                            {
                                                    e.poddApproved == 'Reject' ?
<div className="field">
<div className="control is-fullwidth">
                                                    <Dtnewinput
                                                    record={e}
                                                    type="date"
                                        name="pod_date"
                                        mindate={booking.bookingdate}
                                                    classnames="input is-small"
                                                  />
                                                  </div></div>
                                                        : <></>
                                                }
                                            </Col2>
                                        </Cols>:""
                                        }
                                        <div style={{ height: '5px', backgroundColor: 'white' }}></div>
                                    </div>
                                )

                            })
                        }
                    </div>
                </section>
            </>
        )
    }
    if (action.stage >= finaldocverifyStages.ALL_APPROVED) {
        return (
            <>
                <CommonSection />
                <section class="section section-padding" >
                    <Cols>
                        <Col3 style={{ color: 'darkblue' }}>Customer Invoice Amount</Col3>
                        <Col4>{Utils.N(booking.total_cost)}</Col4>
                        <Col2>
                            APPROVED
                        </Col2>
                    </Cols>
                    <Cols>
                        <Col3 style={{ color: 'darkblue' }}>Customer Invoice</Col3>
                        <Col4>
                            <a target="_blank"
                                href={server +
                                    "/api/v1/filestore/getfile/" +
                                    booking.details[0].contract_id +
                                    "/" +
                                    booking.invoice
                                }
                            >{booking.invoice}</a>
                        </Col4>
                        <Col2>
                            APPROVED
                        </Col2>
                    </Cols>
                    <Cols>
                        <Col3 style={{ color: 'darkblue' }}>Vendor Invoice</Col3>
                        <Col4>
                            <a target="_blank"
                                href={server +
                                    "/api/v1/filestore/getfile/" +
                                    booking.details[0].contract_id +
                                    "/" +
                                    booking.vender_invoice
                                }
                            >{booking.vender_invoice}</a>
                        </Col4>
                        <Col2>
                            APPROVED
                        </Col2>
                    </Cols>
                </section>
                <section class="section section-padding">
                    <div >
                        {
                            booking.details[0].delivery.map((e, i) => {
                                return (
                                    <div style={{ border: '1px solid #ccc' }}>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Delivery Address {i + 1}</Col3>
                                            <Col4>{e.address}</Col4>
                                        </Cols>
                                        <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Proof of Delivery</Col3>
                                            <Col4>
                                                <a target="_blank"
                                                    href={server +
                                                        "/api/v1/filestore/getfile/" +
                                                        booking.details[0].contract_id +
                                                        "/" + e.proofofdelivery
                                                    }
                                                >{e.proofofdelivery}</a>
                                            </Col4>
                                            <Col2>
                                                APPROVED
                                            </Col2>
                                        </Cols>
                                        {
                                            (e.pod_date != undefined)?<Cols>
                                            <Col3 style={{ color: 'darkblue' }}>POD Dated?</Col3>
                                            <Col4>
                                               {e.pod_date}
                                            </Col4>
                                            <Col2>
                                            Approved
                                            </Col2>
                                            
                                        </Cols>:""
                                        }
                                        <div style={{ height: '5px', backgroundColor: 'white' }}></div>
                                    </div>
                                )

                            })
                        }
                    </div>
                </section>
            </>
        )
    }
}