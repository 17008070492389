import { useSnapshot } from "valtio"
import {
  configStore,
  loginStore,
  DriverStore,
  routerStore,
  AgmodalStore,
} from "../state/CommonState"
import { $c, $cg, $cr } from "../components/AgComponents"
import DriverApi from "../state/driver_api"
import { useEffect, useState, React } from "react"
import Api from "../state/Api"

import { MdDelete } from "react-icons/md"
import { State, City } from "country-state-city"
import { Columns, Col, Col2, Col3, Col4, Col5, Col6, Col12 } from "../Controls"
import { useRef } from "react"
//import { MdDelete } from "react-icons/md";
import { fileStore } from "../state/fileStore"
import { CgMoreVertical } from "react-icons/cg"
import { BsFillEyeFill } from "react-icons/bs"
import { ImCancelCircle } from "react-icons/im"
import { AiOutlineConsoleSql, AiOutlineLeft, AiOutlineRight } from "react-icons/ai"
import Utils from "../util/Utils"
import { ReactComponent as Loader } from "../assets/loader.svg"
import { DriverType } from "../state/Types"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS, ROLES, VERTICALS } from "../util/constants"
import InputField from "./components/input/InputField"
import SelectField from "./components/select/SelectField"
import { loader } from "../util/loader"


export default function EditDrivers(props) {
  useSnapshot(fileStore)
  useSnapshot(AgmodalStore)
  const domain = loginStore.email.split("@")[1]
  const [ulippopup, setulippopup] = useState(true)
  // const [title, settitle] = useState("");
  const [state, setstate] = useState("")
  const [states, setstates] = useState([])
  const [uploadedfilename, setuploadedfilename] = useState("")
  const [selecttag, setselecttag] = useState(false)
  const [cities, setcities] = useState([])
  const [approvepopup, setapprovepopup] = useState(false)
  const [rejectpopup, setrejectpopup] = useState(false)
  const [updateDripopup, setupdateDripopup] = useState(false)
  const [changeDriNumpopup, setchangeDriNumpopup] = useState(false)
  const [newdlno, setnewdlno] = useState("")
  const [updateDridata, setupdateDridata] = useState({})
  const [appbtnstatus, setappbtnstatus] = useState(true)
  const [rejbtnstatus, setrejbtnstatus] = useState(true)
  const [newDlchanged, setNewDlchanged] = useState(false)
  const [alertcomment, setalertcomment] = useState("")
  const [loading, setloading] = useState(false)
  const [nonTranExpiryMsg, setNonTranExpiryMsg] = useState("")
  let [cursor, setCursor] = useState(0)
  const [DriverNumChanged, setDriverNumChanged] = useState(false)
  const [isDriverRefreshed, setIsDriverRefreshed] = useState(false)
  const [formObject, setformObject] = useState({});
  const ref = useRef(null)
  let tomorrowdate = Utils.getTomorrowDateString()
  let todaydate = Utils.getCurrentDateString()
  // let todaydate = Utils.ddmmyyyy(new Date())
  let tomorrowDate = Utils.getCurrentDateStringFuture()
  const hiddenFileInput = useRef()
  const hiddenFileInput1 = useRef()
  const ref1 = useRef(0)
  const ref2 = useRef(0)
  const ref3 = useRef(0)
  const ref4 = useRef(0)
  const ref5 = useRef(0)
  let Drivers = DriverStore.DriversRec
  const attachmentNameMapping = {
    "Upload Driving License": "driving_license",
  }
  useSnapshot(DriverStore)

  let title = ""
  switch (props.mode) {
    case "edit": //(${Branch?._id}) ${Branch?.status}
      title = `Modify (${Drivers?.DlNumber}) ${Drivers?.Status}`
      break
    case "view":
      title = `View (${Drivers?.DlNumber}) ${Drivers?.Status}`
      break
    case "new":
      title = "Add New Driver"
      break
    default:
      title = "Driver"
      break
  }

  const delDocs = (r) => {
    Drivers.documents[r].fileLink = ""
    Drivers.documents[r].fileKey = ""
  }
  const delDocs1 = (r) => {
    let Documents = [...updateDridata.documents]
    updateDridata.documents = null
    // Documents[r].fileLink = "";
    // Documents[r].fileKey = "";
    // updateDridata.documents=Documents
    // setupdateDridata(updateDridata)
    Documents[0].fileLink = ""
    Documents[0].pkey = ""
    updateDridata.documents = [...Documents]
    setupdateDridata(updateDridata)
    setCursor(cursor + 1)
    console.log("running")
  }
  function change(files, label, filekey, props) {
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/filestore/storefile`
    const formData = new FormData()
    formData.append("myFile", files[0])
    formData.append("label", label)
    formData.append("key", Drivers.DlNumber)

    fetch(url, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) configStore.setModalMessage(data.error)
        else {
          if (!updateDripopup) {
            Drivers.documents[0].fileLink = data.key.filename
            Drivers.documents[0].pkey = data.key.pkey
          }
          if (updateDripopup || changeDriNumpopup) {
            setNewDlchanged(true)
            updateDridata.documents[0].fileLink = data.key.filename
            updateDridata.documents[0].pkey = data.key.pkey
          }
          setuploadedfilename(data.key.filename)
          setalertcomment("")
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    titlefn()
  })

  function trimStr(str) {
    if (!str) return str
    return str.replace(/^\s+/g, "")
  }
  useEffect(() => {
    const input = ref5.current
    if (input) {
      input.type = 'text'
      input.setSelectionRange(cursor, cursor)
      input.type = 'number'
    }
  }, [ref5, cursor])
  useEffect(() => {
    const input = ref1.current
    if (input) {
      input.setSelectionRange(cursor, cursor)
    }
  }, [ref1, cursor])
  useEffect(() => {
    const input = ref2.current
    if (input) {
      input.setSelectionRange(cursor, cursor)
    }
  }, [ref2, cursor])
  useEffect(() => {
    const input = ref3.current
    if (input) {
      input.setSelectionRange(cursor, cursor)
    }
  }, [ref3, cursor])
  useEffect(() => {
    const input = ref4.current
    if (input) {
      input.setSelectionRange(cursor, cursor)
    }
  }, [ref4, cursor])

  const handleChange = (e, r) => {
    // e.target.type = 'text'
    setCursor(e.target.selectionStart)
    if (r === "DlNumber" || r === "Name") {
      if (r === "DlNumber") {
        let re = new RegExp("^([A-Za-z0-9- ]{0,20})$")
        if (!re.test(e.target.value)) {
          return null
        }
        return Drivers[r] = trimStr(e.target.value.toUpperCase())
      } else if (r === "Name") {
        let re = new RegExp("^([A-Za-z0-9 ]{0,45})$")
        if (!re.test(e.target.value)) {
          return null
        }
      }
      Drivers[r] = trimStr(e.target.value)
    } else if (r === "MobileNumber") {
      if (e.target.value.length > 10) {
        return null
      }
      e.target.type = 'number'
      Drivers[r] = e.target.value.trim()
    } else if (r === "newdlno") {
      let re = new RegExp("^([A-Za-z0-9- ]{0,20})$")
      if (!re.test(e.target.value)) {
        return null
      }
      setnewdlno(e.target.value.toUpperCase())
      updateDridata.documents[0].fileName = ""
      updateDridata.documents[0].pkey = ""
      updateDridata.documents[0].fileLink = ""
    } else {
      Drivers[r] = e.target.value.trim()
    }
  }

  function titlefn() {
    if (props.mode === "new") {
      // settitle("Add New Driver");
      setselecttag(true)
    } else if (props.mode === "edit") {
      // settitle(`#${Drivers.DlNumber}--->${Drivers.Status}`);
    }
  }

  const selectState = (e) => {
    // const statee = State.getStateByCodeAndCountry(e.target.value, "IN");
    const Statee = State.getStatesOfCountry("IN").filter(
      (r) => r.name === e.target.value
    )
    // setstate(statee);
    Drivers.StateCode = Statee[0]?.isoCode
    Drivers.State = e.target.value
    const cities = City.getCitiesOfState("IN", Statee[0]?.isoCode)
    setcities(cities)
    Drivers.City = ""
  }
  const selectcity = (e) => {
    Drivers.City = e.target.value
    Drivers.Country = "IN"
  }

  const renderLink = (fileName) => {
    if (fileName && fileName.length > 3) {
      return (
        <a
          href={process.env.REACT_APP_API_SERVER + "/clientfiles/" + fileName}
          target="_blank">
          {fileName}
        </a>
      )
    } else return <></>
  }

  const StatesforBranch = (code) => {
    const states = State.getStatesOfCountry(code)
    setstates(states)
  }
  const getStateandCityValue = async () => {
    const Statess = State.getStatesOfCountry("IN")
    const Statecode = Statess.filter(
      (r) => r.name === Drivers?.State
    )
    const cities = City.getCitiesOfState(
      "IN",
      Statecode[0]?.isoCode
    )
    setcities(cities)
  }

  useEffect(() => {
    let form = JSON.parse(JSON.stringify(DriverStore.DriversRec));
    setformObject(form);
    async function setStateCity() {
      getStateandCityValue()
    }
    setStateCity()
    if (Drivers?.DlNTExpiryDateFlag) {
      setNonTranExpiryMsg("NOTE: This is Non-Transport validity")
    }
    if(Drivers.timeline === null || !Drivers.timeline) {
      Drivers.timeline = []
    }
  }, [])
  const DriversStateCode = () => {
    console.log(Drivers.State)
  }

  const handleClick = (event) => {
    console.log(Drivers.DlNumber.length)
    if (Drivers.DlNumber.length < 10) {
      return configStore.setModalMessage(
        "Please enter the Driving License Number , it should be 10-20 characters length"
      )
    }
    hiddenFileInput.current.click()
  }

  const handleClick1 = (event) => {
    if (newdlno.length < 10) {
      return configStore.setModalMessage(
        "Please enter the Driving License Number , it should be 10-20 characters length"
      )
    }
    hiddenFileInput1.current.click()
  }

  const showselecttag = () => {
    const StateCode = State.getStatesOfCountry("IN").filter(
      (r, i) => r.name === Drivers.State
    )
    const statee = State.getStateByCodeAndCountry(StateCode[0].isoCode, "IN")
    setstate(statee)
    const cities = City.getCitiesOfState("IN", StateCode[0].isoCode)
    setcities(cities)
    setselecttag(!selecttag)
  }
  const statusdropdown = () => {
    let element = document.getElementById("drivstatusdp")
    if (element.style.display === "block") {
      return (document.getElementById("drivstatusdp").style.display = "none")
    }
    document.getElementById("drivstatusdp").style.display = "block"
  }
  const statusdropdownexit = () => {
    document.getElementById("drivstatusdp").style.display = "none"
  }

  const approvefn = () => {
    setapprovepopup(true)
    document.getElementById("editdriversmaincon").style.filter = "blur(15px)"
    document.getElementById("editdriversmaincon").style.pointerEvents = "none"
  }
  const updatedrifn = (x) => {
    if (x === "UpdateDl") {
      setnewdlno(Drivers.DlNumber)
    } else {
      setnewdlno("")
    }
    let updateDridata = { ...Drivers }
    updateDridata.documents = []
    let docs = [...Drivers.documents]
    docs = JSON.parse(JSON.stringify(docs))
    let documents = [...docs]
    updateDridata.documents = documents
    setupdateDridata(updateDridata)
    if (x === "UpdateDl") {
      setupdateDripopup(true)
    } else if (x === "ChangeDl") {
      updateDridata.documents[0].fileLink = ""
      updateDridata.documents[0].fileKey = ""
      setupdateDridata(updateDridata)
      setchangeDriNumpopup(true)
    }
    document.getElementById("editdriversmaincon").style.filter = "blur(15px)"
    document.getElementById("editdriversmaincon").style.pointerEvents = "none"
  }
  const rejectfn = () => {
    setrejectpopup(true)
    document.getElementById("editdriversmaincon").style.filter = "blur(15px)"
    document.getElementById("editdriversmaincon").style.pointerEvents = "none"
  }
  const cancelpopup = () => {
    setrejectpopup(false)
    setapprovepopup(false)
    setupdateDripopup(false)
    setchangeDriNumpopup(false)
    setappbtnstatus(true)
    setalertcomment("")
    setNewDlchanged(false)
    setloading(false)
    document.getElementById("editdriversmaincon").style.filter = "none"
    document.getElementById("editdriversmaincon").style.pointerEvents = "auto"
  }
  const approvedrifn = () => {
    if (appbtnstatus === true) {
      return null
    }
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/drivers/updatestatus`

    const formData = new FormData()
    formData.append("Id", Drivers.DlNumber)
    formData.append("Status", "Verified")
    formData.append("Remarks", "")
    formData.append("updatedBy", loginStore.email)
    formData.append("verifiedBy", loginStore.email)

    fetch(url, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) configStore.setModalMessage(data.error)
        else {
          configStore.setModalMessage(data.msg)
          // Drivers.Status = "Verified";
          cancelpopup()
          setappbtnstatus(true)
          routerStore.driverCurrentView = "list"
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const rejectdrifn = () => {
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/drivers/updatestatus`
    let Remarks = document.getElementById("rejectremarksid").value

    const formData = new FormData()
    formData.append("Id", Drivers.DlNumber)
    formData.append("Status", "Rejected")
    formData.append("Remarks", Remarks)
    formData.append("updatedBy", loginStore.email)
    formData.append("updatedBy", Drivers.verifiedBy)
    formData.append("rejectedBy", loginStore.email)

    fetch(url, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) configStore.setModalMessage(data.error)
        else {
          configStore.setModalMessage(data.msg)
          Drivers.Status = "Rejected"
          Drivers.Remarks = Remarks
          cancelpopup()
          routerStore.driverCurrentView = "list"
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  //  const ChangeDlnumfn = () => {

  //  }
  const handleChange3 = (e, r) => {
    // setCursor(e.target.selectionStart)
    let data = updateDridata
    if (r === "newdlno") {
      let re = new RegExp("^([A-Za-z0-9- ]{0,20})$")
      if (!re.test(e.target.value)) {
        return null
      }
      setnewdlno(e.target.value.toUpperCase())
      data.documents[0].fileName = ""
      data.documents[0].pkey = ""
      data.documents[0].fileLink = ""
    }
    data[r] = e.target.value
    setCursor(e.target.selectionStart)
    // if (e.target.selectionStart === null) {
    //   setCursor(cursor + 1)
    // }
    setupdateDridata(data)
  }
  const handleChange4 = (e, r) => {
    let data = updateDridata
    data[r] = e.target.value
    setCursor(e.target.selectionStart)
    if (e.target.selectionStart === null) {
      setCursor(cursor + 1)
    }
    setupdateDridata(data)
  }
  const UpdateDlnumfn = async (d) => {
    setloading(true)
 
    if (d === "update") {
      Drivers = { ...updateDridata }
      Drivers.Name = updateDridata.Name
    }
    if (JSON.stringify(Drivers) === JSON.stringify(formObject)) {
      setloading(false);
      return configStore.setModalMessage(
        "Please Check the values, we got same value before we Stored"
      );
    }
    if (Drivers.Name.length < 1) {
      configStore.setModalMessage("Please Enter the Driver Name")
      setloading(false)
      return
    }
    if (newdlno.length < 1) {
      configStore.setModalMessage(
        "Please enter the Driving License number , it should contain  atleast 10-20 characters in length"
      )
      setloading(false)
      return
    }
    if (newdlno.length < 10) {
      configStore.setModalMessage(
        "Driving License number should contain  atleast 10-20 characters in length"
      )
      setloading(false)
      return
    }
    if (Drivers.DlExpiryDate === "") {
      configStore.setModalMessage("Please select the expiry Date")
      setloading(false)
      return
    }
    if (updateDridata.documents[0].fileLink.length === 0) {
      setloading(false)
      return setalertcomment("Please upload new driving license copy")
    }
    if (Drivers.DlNumber === trimStr(newdlno)) {
      if (updateDripopup) {
        Drivers = { ...updateDridata }
          if (Drivers.timeline.length === 0) {
            Drivers.timeline = [{
              date: Date.now(),
              event: "Created",
              user: loginStore.email,
              role: RoleUtils.getUserRole()
            }]
          }
          else {
            Drivers.timeline.push({
              date: Date.now(),
              event: "Resubmited",
              user: loginStore.email,
              role: RoleUtils.getUserRole()
            })
          }

        // }
        return await DriverApi.saveDriver(setloading, Drivers)
      } else if (changeDriNumpopup) {
        return setalertcomment("Please change the Dl number ")
      }
    }
    if (!newDlchanged) {
      setloading(false)
      return setalertcomment("Please upload new driving license copy")
    }
    let url = ""
    if (d === "update") {
      url = `${process.env.REACT_APP_API_SERVER}/api/v1/drivers/updatedlnum`
    } else if (d !== "update") {
      url = `${process.env.REACT_APP_API_SERVER}/api/v1/drivers/changedlnum`
    }

    let Comments = `Driving license number changed from ${Drivers.DlNumber} to ${newdlno}`

    const formData = new FormData()
    formData.append("Name", Drivers.Name)
    formData.append("DlNumber", Drivers.DlNumber)
    formData.append("NewDlno", newdlno)
    formData.append("DlExpiryDate", Drivers.DlExpiryDate)
    formData.append("Comments", Comments)
    formData.append("Updatedfilelink", Drivers.documents[0].fileLink)
    formData.append("filepkey", Drivers.documents[0].pkey)
    formData.append("updatedBy", loginStore.email)
    if (updateDripopup) {
      if (Drivers.timeline.length === 0) {
        Drivers.timeline = [{
          date: Date.now(),
          event: "Created",
          user: loginStore.email,
          role: RoleUtils.getUserRole()
        }]
      }
      else {
        Drivers.timeline.push({
          date: Date.now(),
          event: "Resubmited",
          user: loginStore.email,
          role: RoleUtils.getUserRole()
        })
      }
    }
    fetch(url, {
      method: "PUT",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) { configStore.setModalMessage(data.error); setloading(false) }
        else {
          configStore.setModalMessage(data.msg)
          Drivers.DlNumber = newdlno
          Drivers.Comments = Comments
          cancelpopup()
          setloading(false)
          setDriverNumChanged(true)
          if (d === "update") {
            routerStore.driverCurrentView = "list"
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  const submitRemarks = (e) => {
    if (e.target.value.trim().length > 3) {
      setrejbtnstatus(false)
      return (document.getElementById("rejectbtnid").style.opacity = "1")
    } else if (e.target.value.trim().length < 3) {
      setrejbtnstatus(true)
      return (document.getElementById("rejectbtnid").style.opacity = "0.4")
    }
  }
  const approvebtnnfn = () => {
    if (document.getElementById("approvebtnid").disabled == true) {
      document.getElementById("approvebtnid").style.opacity = "1"
      setappbtnstatus(false)
      return (document.getElementById("approvebtnid").disabled = false)
    } else if (document.getElementById("approvebtnid").disabled == false) {
      document.getElementById("approvebtnid").style.opacity = "0.4"
      setappbtnstatus(true)
      return (document.getElementById("approvebtnid").disabled = true)
    }
  }
  function isEditMode() {
    return props.mode === "edit"
  }

  function isViewMode() {
    return props.mode === "view"
  }
  function isNewMode() {
    return props.mode === "new"
  }

  function isPendingStatus() {
    return Drivers?.Status === "Pending"
  }

  function isVerifiedStatus() {
    return Drivers?.Status === "Verified"
  }
  function isRejectedStatus() {
    return Drivers?.Status === "Rejected"
  }
  function isDlExpired() {
    return Drivers?.Status === "DlExpired"
  }

  function edit_file_icon(isDriverRefreshed) {
    return (
      <>
        <span class="icon mt-1 ml-5 is-size-4">
          {(Drivers?.documents[0]?.fileLink?.length > 0 && (!isDriverRefreshed) ) ? (
            <>
              <span className="ml-2 mr-1">
                <a
                  href={
                    process.env.REACT_APP_API_SERVER +
                    "/api/v1/filestore/getfile/" +
                    Drivers?.documents[0]?.pkey +
                    "/" +
                    Drivers?.documents[0]?.fileLink
                  }
                  target="_blank"
                  rel="noreferrer"
                  alt="">
                  <BsFillEyeFill />
                </a>
              </span>
              {isNewMode() ||
                (isEditMode() &&
                  !isPendingStatus() &&
                  !isVerifiedStatus()) ? (
                <>
                  <span>
                    <MdDelete className="is-clickable"
                      onClick={() => (
                        delDocs(0), (hiddenFileInput.current.value = "")
                      )}
                    />
                  </span>
                </>
              ) : null}
            </>
          ) : null}
          {(isRejectedStatus() && isEditMode()) ? (
            <>
              <span class="icon is-clickable mb-1 ml-1 is-size-6">
                <i
                  class="fa-solid fa-pencil is-clickable"
                  id="editpencilicon"
                  onClick={() => updatedrifn("ChangeDl")}
                ></i>
              </span>
            </>
          ) : null}
          {isNewMode() || (isEditMode() && (isDriverRefreshed && Drivers?.DlExpiryDate =="") && Drivers.ulip) || (isEditMode() && !Drivers.ulip && !Drivers.Status.includes('Expire')) || (isEditMode() && !isDriverRefreshed && !Drivers.Status.includes('Expire')) || Drivers.DlExpiryDateFlag ? (
            <i
              class="fa-solid ml-2 fa-file-arrow-up is-clickable"
              onClick={handleClick}
              {...(isViewMode()
                ? { disabled: true }
                : {
                  ...(isEditMode() &&
                    (isPendingStatus() || isVerifiedStatus())
                    ? { disabled: true }
                    : {}),
                })}
              style={{
                ...(Drivers?.documents[0]?.fileLink?.length > 0
                  ? { display: "none" }
                  : null),
              }}>
             
              <input
                type="file"
                onChange={(e1) => {
                  const pattern = /^[a-zA-Z0-9_ .()-]+$/
                  if (pattern.test(e1.target.files[0]["name"])) {
                    change(
                      e1.target.files,
                      "Driving License",
                      Drivers.DlNumber
                    )
                  } else {
                    configStore.setModalMessage(
                      "Please Check File Name and Format"
                    )
                    return (e1.target.value = "")
                  }
                }}
                name="image"
                accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                multiple=""
                ref={hiddenFileInput}
                data-original-title="upload photos"
                style={{ display: "none" }}
                {...(isViewMode()
                  ? { disabled: true }
                  : {
                    ...(isEditMode() &&
                      (isPendingStatus() || isVerifiedStatus())  
                      ? { disabled: true }
                      : {}),
                  })}
              /> 
            </i>
          ) : null}
        </span>

      </>
    )
  }

  const getDriverDetails = (isRefreshed) => {

    if (Drivers.DlNumber === "") {
      configStore.setModalMessage(
        `Please enter the DlNumber`
      )
      return
    }
    if (Drivers.DOB === "") {
      configStore.setModalMessage(
        `Please enter the Date of Birth`
      )
      return
    }
    loader.show()
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/drivers/driverDetails?dlnumber=${Drivers.DlNumber}&dob=${Drivers.DOB}`
    console.log(Drivers.DlNumber, '&&***', Drivers.DOB)
    const fetchData = async () => {
      try {
        const abortController = new AbortController()

        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(() => {
            abortController.abort()
            loader.hide()
          }, 10000)
        )



        const response = await Promise.race([
          fetch(url, {
            method: "GET",
            headers: Api.H1(),
            signal: abortController.signal,
          }),
          timeoutPromise,
        ])


        const data = await response.json()

        if (data.error && isRefreshed) {
          loader.hide()
          configStore.setModalMessage(data.error)
          return
        }
        if (data) {
          loader.hide()
          Drivers['DriverDataFlag'] = true
        }

        if (!data.error) {
          setIsDriverRefreshed(isRefreshed)
          Drivers['ulip'] = true
          Drivers['State'] = data.state
          if(data.state_cd == "CG"){
            Drivers['StateCode'] = "CT"
          }else if(data.state_cd == "DN"){
            Drivers['StateCode'] = "DH"
          }else if(data.state_cd == "OD"){
            Drivers['StateCode'] = "OR"
          }else if(data.state_cd == "UK"){
            Drivers['StateCode'] = "UT"
          }else{
            Drivers['StateCode'] = data.state_cd
          }
          Drivers['Name'] = data.bioFullName
          if (data.dlTrValdtoDt) {
            Drivers['DlExpiryDate'] = data.dlTrValdtoDt
            Drivers['DlNTExpiryDateFlag'] = false
          } else {
            Drivers['DlExpiryDate'] = data.dlNtValdtoDt
            Drivers['DlNTExpiryDateFlag'] = true
            if (data.dlNtValdtoDt) {
              setNonTranExpiryMsg("NOTE: This is Non-Transport validity")
            }
          }

          // Drivers['DlTrValdtoDt'] = data.dlTrValdtoDt
          // Drivers['MobileNumber'] = data.bioMobileNo
        }
      } catch (error) {
        loader.hide()
        configStore.setModalMessage(`ULIP server is not reachable. Try again later or add manually`)
        Drivers['DriverDataFlag'] = true
      }

    }

    fetchData()
    setulippopup(false)

  }

  //disabled property for name field
  const isViewOrNewModeForName = isViewMode() || (Drivers?.ulip && Drivers?.Name && isNewMode())
  const isEditModeAndPendingOrVerifiedForNameField = isEditMode() && (isPendingStatus() || isVerifiedStatus() || (isDriverRefreshed && Drivers?.Name) || (Drivers?.ulip && isRejectedStatus()))
  const isDlExpiredForNameField = isDlExpired()
  const isDisabledForName = isViewOrNewModeForName || isEditModeAndPendingOrVerifiedForNameField || isDlExpiredForNameField
  //disabled property for Dl Expiry field
  const isViewOrNewModeWithValidDlExpiry = isViewMode() || (Drivers?.ulip && Drivers?.DlExpiryDate && isNewMode() && !Drivers.DlExpiryDateFlag)
  const isEditModeAndValidDlExpiry = isEditMode() && (isPendingStatus() || isVerifiedStatus() || (isDriverRefreshed && Drivers?.DlExpiryDate > todaydate) || (Drivers?.ulip && isRejectedStatus()))
  // const isDlExpiredForDLDate = !(isDlExpired() && isDriverRefreshed) 
  const isDisabledForDLExpiry = isViewOrNewModeWithValidDlExpiry || isEditModeAndValidDlExpiry 



  return (
    <>
      <div id="editdriversmaincon" style={(ulippopup && ((Drivers?.Status?.includes('Expired') && Drivers?.ulip)|| (Drivers.Status =='Rejected' && Drivers?.ulip)) && props.mode != 'view') ?{filter:"blur(15px)",pointerEvents:"none"}:{}}>
        <h1 className="title is-flex is-flex-direction-row is-justify-content-space-between">
          <span>{title}</span>
        
          {(((Drivers.Status === "Verified" && !Drivers.ulip) || (Drivers.Status.includes('Expired') && !Drivers.ulip)) && !isViewMode()) ? (
            <>
              <button
                className="is-clickable p-1"
                style={{
                  border: "1px solid #0099cc",
                  borderRadius: "5px",
                  height: "35px",
                  color: "#0099cc",
                }}
                onClick={() => updatedrifn("UpdateDl")}>
                Update Driver Details
              </button>
            </>
          ) : null}
          {props.mode === "edit" ? (
            <>
              {Drivers.Status !== "Verified" &&
                Drivers.Status !== "Rejected" &&
                Drivers.Status !== "DlExpired" ? (
                <>
                  <span className="is-clickable is-flex is-flex-direction-column is-align-item-center">
                    <span>
                      <CgMoreVertical
                        onClick={statusdropdown}
                        style={{ float: "right" }}
                      />
                    </span>
                    <span
                      className=""
                      id="drivstatusdp"
                      style={{ display: "none", width: "5rem" }}>
                      {
                        (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.DRIVERS_APPROVE_REJECT)) &&

                        <div className="is-size-6 ">
                          <p
                            className=""
                            style={{
                              border: "1px solid black",
                              padding: "3% 2%",
                              borderRadius: "0.4rem",
                            }}
                            onClick={approvefn}>
                            Approve
                          </p>
                          <p
                            style={{
                              border: "1px solid black",
                              padding: "3% 2%",
                              borderRadius: "0.4rem",
                            }}
                            onClick={rejectfn}>
                            Reject
                          </p>
                        </div>
                      }
                    </span>
                  </span>
                </>
              ) : null}
            </>
          ) : null}
        </h1>

        <div style={{ padding: "0rem 5rem" }}>
          <Columns>
            <Col5>


              <InputField
                label_class={"label is-small"}
                label_style={{ marginBottom: "0px" }}
                type={"text"}
                container={"is-flex"}
                placeholder={"DL.No"}
                classname={"input is-small inp_wd_80"}
                value={Drivers?.DlNumber}
                ref={ref1} pattern={"[A-Z0-9]"}
                // onkeydown={(e) =>
                //   ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                // }
                callback={(e) => handleChange(e, "DlNumber")}
                children={{
                  ...(isViewMode() || isEditMode() || (Drivers?.ulip && Drivers?.DlNumber && isNewMode())
                    ? { disabled: true }
                    : {
                      ...(isEditMode() &&
                        (isPendingStatus() || isVerifiedStatus() || isRejectedStatus() || isDlExpired())
                        ? { disabled: true }
                        : {}),
                    })
                }}

                icon_childer={
                  (
                  (((!Drivers?.ulip && isNewMode()) || Drivers.DlExpiryDateFlag)
                     && Drivers['DriverDataFlag']) 
                  || (isDriverRefreshed && Drivers.DlExpiryDate < todaydate) 
                  || (Drivers?.documents[0]?.fileLink?.length > 0 && (isPendingStatus || isVerifiedStatus())) 
                  || ((isRejectedStatus() || Drivers.Status.includes('Expire') ) && Drivers?.documents[0]?.fileLink?.length === 0 && !Drivers.ulip)
                  ) 
                  && edit_file_icon(isDriverRefreshed)}
              >
              </InputField>
            </Col5>
            <Col5>

              <InputField
                type={"date"}
                placeholder={"Date of Birth"}
                classname={"input is-small "}
                label_class={"label is-small"}
                label_style={{ marginBottom: "0px" }}
                value={Drivers?.DOB}
                max={todaydate}
                callback={(e) => handleChange(e, "DOB")}
                children={{
                  ...(isViewMode() || (isEditMode() && !isRejectedStatus()) || (Drivers?.ulip && Drivers?.DlNumber && isNewMode()) || (isEditMode() && Drivers.ulip)
                    ? { disabled: true }
                    : {
                      ...(isEditMode() &&
                        (isPendingStatus() || isVerifiedStatus())
                        ? { disabled: true }
                        : {}),
                    })
                }}

              ></InputField>

              {/* <DatePicker
      selected={selectedDate}
      onChange={(e) => handleChange(e, "DOB")}
      dateFormat="dd-MM-yyyy" 
      placeholderText="Select Date of Birth"
      className="input is-small"
      // ...other props
    /> */}
            </Col5>

          </Columns>
          {((Drivers['DriverDataFlag'] && isNewMode()) || isEditMode() || isViewMode()) &&
            <Columns>
              <Col5>

                <InputField
                  type={"text"}
                  label_class={"label is-small"}
                  label_style={{ marginBottom: "0px" }}
                  placeholder={"Name"}
                  classname={"input is-small"}
                  value={Drivers?.Name}
                  ref={ref3} pattern={"[A-Z0-9]"}
                  callback={(e) => handleChange(e, "Name")}
                  // children={{...((isViewMode() ||(Drivers?.ulip && Drivers?.Name && isNewMode()))
                  //   ? { disabled: true }
                  //   : {
                  //       ...(isEditMode() &&
                  //       (isPendingStatus() || isVerifiedStatus() || (isDriverRefreshed && Drivers?.Name))
                  //         ? { disabled: true }
                  //         : {}),
                  //     })}}
                  children={isDisabledForName
                    ? { disabled: true }
                    : {}
                  }

                ></InputField>

              </Col5>
              <Col5>
                <InputField
                  label_class={"label is-small"}
                  label_style={{ marginBottom: "0px" }}
                  type={"text"}
                  placeholder={"Mobile Number"}
                  classname={"input is-small"}
                  value={Drivers?.MobileNumber}
                  ref={ref5} pattern={"[A-Z0-9]"}
                  onkeydown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  callback={(e) => handleChange(e, "MobileNumber")}
                  children={{
                    ...((isViewMode() || (isDriverRefreshed && Drivers?.MobileNumber && isEditMode()))
                      ? { disabled: true }
                      : {
                        ...(isEditMode() &&
                          (isPendingStatus() || isVerifiedStatus() || (isDriverRefreshed && Drivers?.MobileNumber))
                          ? { disabled: true }
                          : {}),
                      })
                  }}

                ></InputField>


              </Col5>
            </Columns>
          }


          {((Drivers['DriverDataFlag'] && isNewMode()) || isEditMode() || isViewMode()) &&
            <Columns>
              <Col5>

                <InputField
                  type={"date"}
                  placeholder={"DL Expiry Date"}
                  classname={"input is-small "}
                  label_class={"label is-small"}
                  label_style={{ marginBottom: "0px" }}
                  id={"colordate"}
                  value={Drivers?.DlExpiryDate}
                  min={tomorrowdate}
                  callback={(e) => {
                    Drivers.DlExpiryDateFlag = true
                    Drivers['DriverDataFlag'] = true
                    handleChange(e, "DlExpiryDate")
                  }}
                  // children={{
                  //   ...((isViewMode() || (Drivers?.ulip && Drivers?.DlExpiryDate && isNewMode() && !Drivers.DlExpiryDateFlag))
                  //     ? { disabled: true }
                  //     : {
                  //       ...(isEditMode() &&
                  //         (isPendingStatus() || isVerifiedStatus() || (isDriverRefreshed && Drivers?.DlExpiryDate > todaydate))
                  //         ? { disabled: true }
                  //         : {}),
                  //     })
                  // }}
                  children={ (isDisabledForDLExpiry || (isDriverRefreshed && Drivers?.DlExpiryDate && isEditMode()) || (Drivers.Status.includes('Expired') && !Drivers.ulip)) ? { disabled: true } : {}}
                  input_style={
                    {
                      border:
                        ((isEditMode() || isViewMode()) && Drivers?.DlExpiryDate < todaydate)
                          ? "1px solid red"
                          : null,
                    }
                  }
                ></InputField>
                {(nonTranExpiryMsg.length > 0) &&
                  <p className="help is-input-danger-message">{nonTranExpiryMsg}</p>}
              </Col5>
              <Col5>

                <SelectField
                  placeholder={"State"}
                  classname="select is-small "
                  data={State.getStatesOfCountry("IN").map((r, i) => {
                    return <option value={r.name}>{r.name}</option>
                  })}
                  label_class={"label is-small"}
                  label_style={{ marginBottom: "0px" }}
                  ref={ref}
                  input_style={{ width: "100%", border: "1px solid #d1d1e0" }}
                  value={Drivers.State}
                  callback={(e) => selectState(e)}
                  children={{ ...((isViewMode() || (Drivers?.ulip && isNewMode()) || (isDriverRefreshed && Drivers.State) || isPendingStatus()) ? { disabled: true } : {}) }}
                  first_value={""}
                  default_label={Drivers?.State ? Drivers?.State : 'Select State'}

                >
                </SelectField>


              </Col5>
            </Columns>}

          <Columns>
            {((Drivers['DriverDataFlag'] && isNewMode()) || isEditMode() || isViewMode()) &&
              <Col5>
                <SelectField
                  placeholder={"City"}
                  classname="select is-small "
                  data={City.getCitiesOfState("IN", Drivers.StateCode).map((r, i) => {
                    return <option value={r.name}>{r.name}</option>
                  })}
                  label_class={"label is-small"}
                  label_style={{ marginBottom: "0px" }}
                  ref={ref}
                  input_style={{ width: "100%", border: "1px solid #d1d1e0" }}
                  value={Drivers?.City}
                  callback={(e) => selectcity(e)}
                  children={{ ...(isViewMode() ? { disabled: true } : {}) }}
                  first_value={""}
                  default_label={Drivers?.City ? Drivers?.City : "Select City"}
                >
                </SelectField>


              </Col5>}
            {(!Drivers['DriverDataFlag'] && isNewMode()) &&
              <Col3>
                <button
                  className="is-clickable"
                  onClick={(e) => getDriverDetails(false)}
                  style={{ margin: '20px', backgroundColor: "#2a75de", padding: "1%", fontWeight: '600', color: 'white' }}
                >Get Driver Details</button>
              </Col3>}
          </Columns>
        </div>
        {Drivers.Status == "Rejected" ? (
          <div className="columns remarks-badge">
            <span className=" has-text-weight-bold ">Remarks :</span>
            <span className="ml-3">{Drivers.Remarks}</span>
          </div>
        ) : null}

        {Drivers.Comments.length > 0 ? (
          <div className="columns remarks-badge">
            <span className=" has-text-weight-bold ">Comments:</span>
            <span className="ml-3">{Drivers.Comments}</span>
          </div>
        ) : null}

        <div className="columns">
          <$c n="1"></$c>
          <$c n="9"></$c>
        </div>
        {((Drivers['DriverDataFlag'] && isNewMode()) || isEditMode() || isViewMode()) &&
          <div className="columns">
            <$cr n="1"></$cr>
            <$c n="3">
              <div className="is-flex">
                <>
                  <button
                    className="button is-primary"
                    onClick={() =>{
                      // if (props.fn) {
                      //   return routerStore.creditApplicationView ="list"
                      // }
                      AgmodalStore.pageVisible = false
                      AgmodalStore.apiCall = true
                      DriverStore.DriversRec.set(new DriverType())
                    }}>
                    Cancel
                  </button>
                </>
                {isViewMode() ? null : (
                  <button
                    className="button is-primary"
                    style={{ marginLeft: "20px", width: "80px" }}
                    onClick={async(e) => {
                      console.log("Save clicked")
                      if (Drivers.Name.length < 1) {
                        configStore.setModalMessage(`Please Enter the name`)
                        return
                      }
                      if (!Drivers.Name.match(/[A-Za-z0-9]/)) {
                        configStore.setModalMessage(
                          `Name should be only alphanumeric`
                        )
                        return
                      }
                      if(Drivers.DOB === "") {
                        configStore.setModalMessage(
                          `Select the DOB`
                        )
                        return
                      }
                      if (Drivers.MobileNumber.length < 1) {
                        configStore.setModalMessage(
                          `Please Enter the mobile number`
                        )
                        return
                      }
                      if (Drivers.MobileNumber.length < 10) {
                        configStore.setModalMessage(
                          `Mobile Number Must be atleast 10 digits`
                        )
                        return
                      }
                      if (Drivers.DlNumber.length === 0) {
                        configStore.setModalMessage(
                          "Please Enter the Driving license number , it should contain atleast 10 characters"
                        )
                        return
                      }
                      if (Drivers.DlNumber.length < 10) {
                        configStore.setModalMessage(
                          "Driving License number should contain  atleast 10 characters "
                        )
                        return
                      }
                      if (Drivers.documents[0].fileLink.length === 0 && !Drivers.ulip) {
                        configStore.setModalMessage(
                          "Upload Driving License copy"
                        )
                        return
                      }
                      if (Drivers.DlExpiryDate === "") {
                        configStore.setModalMessage("Select Expiry Date")
                        return
                      }

                      if (Drivers.State.length < 1) {
                        configStore.setModalMessage("Please Select State")
                        return
                      }
                      if (Drivers.City.length < 1) {
                        configStore.setModalMessage("Please Select City")
                        return
                      }
                      if (DriverNumChanged) {
                        Drivers.Status = "Pending"
                      }

                      if (props.mode === "new") {
                        setloading(true)
                          if (Drivers.timeline.length === 0) {
                            Drivers.timeline.push({
                              date: Date.now(),
                              event: "Created",
                              user: loginStore.email,
                              role: RoleUtils.getUserRole()
                            })
                          }                       
                        Drivers.createdBy = loginStore.email
                        return DriverApi.createDriver(setloading)
                      } else {
                        console.log(JSON.stringify(Drivers) === JSON.stringify(formObject));
                        console.log(JSON.stringify(Drivers),JSON.stringify(formObject));
                        if (JSON.stringify(Drivers) === JSON.stringify(formObject)) {
                          setloading(false);
                          return configStore.setModalMessage(
                            "Please Check the values, we got same value before we Stored"
                          );
                        }
                        setloading(true)
                     const resub =    Drivers.timeline[Drivers.timeline.length-1]?.event == "Resubmited"
                        if (Drivers.Status !=="Verified" && !resub) {
                          Drivers.timeline.push({
                            date: Date.now(),
                            event: "Resubmited",
                            user: loginStore.email,
                            role: RoleUtils.getUserRole()
                          })
                        }
                        Drivers.updatedBy = loginStore.email
                      await  DriverApi.saveDriver(setloading,false,async(d)=>{
                        console.log(d);
                        if (props.fn) {
                          AgmodalStore.apiCall = true;
                          return routerStore.creditApplicationView ="list"
                        }
                      })

                      }
                    }}>
                    {!loading ? (
                      props.mode === "edit" ? (
                        "UPDATE"
                      ) : (
                        "SUBMIT"
                      )
                    ) : (
                      <Loader className="spinner" />
                    )}
                  </button>
                )}
              </div>
            </$c>
          </div>}
      </div>

      {approvepopup ? (
        <div
          style={{
            width: "450px",
            // height: "150px",
            backgroundColor: "#f2f2f2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "40%",
            left: "43%",
            padding: "1% 1% 1% 1%",
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span> Approve </span>
            <span>
              <ImCancelCircle onClick={cancelpopup} className="is-clickable" />
            </span>
          </div>

          <div className="is-flex is-justify-content-space-between">
            <span
              className="is-size-6 is-flex is-align-items-center"
              style={{ marginTop: "0%", width: "85%" }}>
              <span className="is-flex is-align-items-center is-size-6 ml-2">
                {Drivers.Name}
                <AiOutlineLeft />{" "}
                <span className="has-text-weight-bold">{Drivers.DlNumber}</span>
                <AiOutlineRight />
              </span>
              <span>
                <input
                  type="checkbox"
                  className="is-size-2"
                  style={{ width: "20px", height: "18px" }}
                  onChange={approvebtnnfn}
                />
              </span>
            </span>
            {/* <span>Approve</span> */}
          </div>
          <div>
            <span>
              <button
                className="is-clickable"
                style={{
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "2%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "4%",
                  marginLeft: "80%",
                  opacity: 0.4,
                }}
                id="approvebtnid"
                disabled={appbtnstatus}
                onClick={approvedrifn}>
                SUBMIT
              </button>
            </span>
          </div>
        </div>
      ) : null}
      {rejectpopup ? (
        <div
          style={{
            width: "450px",
            backgroundColor: "#f2f2f2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "40%",
            left: "43%",
            padding: "1%",
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span
              className="is-size-6 has-text-weight-bold"
              style={{ marginTop: "0%" }}>
              Reject <span className="is-size-6 ">{Drivers.DlNumber}</span>
              <br />
              <span>Remarks</span>
            </span>

            <span>
              <ImCancelCircle onClick={cancelpopup} className="is-clickable" />
            </span>
          </div>

          <textarea
            id="rejectremarksid"
            placeholder="Remarks*"
            style={{
              height: "60px",
              width: "300px",
              border: "1px solid grey",
              borderRadius: "5px",
              outline: "none",
              padding: "1%",
            }}
            onChange={(e) => submitRemarks(e)}></textarea>
          <div>
            <span>
              <button
                className="is-clickable"
                disabled={rejbtnstatus}
                style={{
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "2%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "0%",
                  marginLeft: "80%",
                  opacity: "0.4",
                }}
                onClick={rejectdrifn}
                id="rejectbtnid">
                SUBMIT
              </button>
            </span>
          </div>
        </div>
      ) : null}
      {updateDripopup ? (
        <div
          style={{
            width: "450px",
            backgroundColor: "#f2f2f2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "35%",
            left: "43%",
            padding: "1% 1% 1% 1%",
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span className="is-size-6">Update </span>
            <span>
              <ImCancelCircle onClick={cancelpopup} className="is-clickable" />
            </span>
          </div>
          <p className="mt-4" style={{ width: "90%" }}>
            Name
          </p>
          <p>
            <input
              placeholder="Enter Driver Name"
              value={updateDridata.Name}
              style={{
                width: "85%",
                padding: "1.5%",
                borderRadius: "5px",
                border: "1px solid black",
                outline: "none",
              }}
              onChange={(e) => handleChange4(e, "Name")}></input>
          </p>
          <Columns>
            <Col12>
              <p className="label is-small" style={{ marginTop: "10px" }}>
                DL.No
              </p>

              <div className="is-flex">
                <input
                  placeholder="Enter Dl.No"
                  value={newdlno}
                  pattern="[a-zA-Z0-9]"
                  style={{
                    width: "85%",
                    padding: "1.5%",
                    borderRadius: "5px",
                    border: "1px solid black",
                    outline: "none",
                  }}
                  ref={ref3}
                  onChange={(e) => handleChange3(e, "newdlno")}
                />
                <span class="icon mt-1 ml-3 is-size-4">
                  {updateDridata?.documents[0]?.fileLink?.length > 0 ? (
                    <>
                      <span className="ml-2 mr-1">
                        <a
                          href={
                            process.env.REACT_APP_API_SERVER +
                            "/api/v1/filestore/getfile/" +
                            updateDridata?.documents[0]?.pkey +
                            "/" +
                            updateDridata?.documents[0]?.fileLink
                          }
                          target="_blank"
                          rel="noreferrer"
                          alt="">
                          <BsFillEyeFill />
                        </a>
                      </span>

                      {isNewMode() || (isEditMode() && (isVerifiedStatus() || isDlExpired())) ? (
                        <>
                          <span className="is-clikable">
                            <MdDelete
                              onClick={() => (
                                delDocs1(0),
                                (hiddenFileInput1.current.value = "")
                              )}
                            />
                          </span>
                        </>
                      ) : null}
                    </>
                  ) : null}

                  {isNewMode() || isEditMode() || isVerifiedStatus() ? (
                    <i
                      class="fa-solid fa-file-arrow-up is-clickable"
                      onClick={handleClick1}
                      style={{
                        ...(updateDridata?.documents[0]?.fileLink?.length > 0
                          ? { display: "none" }
                          : null),
                      }}>
                      <input
                        type="file"
                        onChange={(e1) => {
                          const pattern = /^[a-zA-Z0-9_ .()-]+$/
                          if (pattern.test(e1.target.files[0]["name"])) {
                            // "driving_license"
                            change(e1.target.files, "Driving License", newdlno)
                          } else {
                            configStore.setModalMessage(
                              "Please Check File Name and Format"
                            )
                            return (e1.target.value = "")
                          }
                        }}
                        name="image"
                        accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                        multiple=""
                        ref={hiddenFileInput1}
                        data-original-title="upload photos"
                        style={{ display: "none" }}
                      />
                    </i>
                  ) : null}
                </span>
              </div>
            </Col12>
          </Columns>
          <p className="mt-4 has-text-danger">
            <span>{alertcomment}</span>
          </p>
          <p className="mt-4">DL Expire Date</p>
          <input
            type="date"
            placeholder="Select date"
            value={updateDridata?.DlExpiryDate}
            style={{
              width: "85%",
              padding: "1.5%",
              borderRadius: "5px",
              border: "1px solid black",
              outline: "none",
            }}
            min={tomorrowdate}
            onChange={(e) => handleChange3(e, "DlExpiryDate")}></input>
          <div>
            <span>
              <button
                className="is-clickable"
                style={{
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "2%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "7%",
                  marginLeft: "80%",
                  width: "75px",
                }}
                onClick={() => UpdateDlnumfn("update")}>
                {!loading ? "SUBMIT" : <Loader className="spinner" />}
              </button>
            </span>
          </div>
        </div>
      ) : null}
      {changeDriNumpopup ? (
        <div
          style={{
            width: "450px",
            backgroundColor: "#f2f2f2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "35%",
            left: "43%",
            padding: "1% 1% 1% 1%",
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span className="is-size-6">Change Driver Number </span>
            <span>
              <ImCancelCircle onClick={cancelpopup} className="is-clickable" />
            </span>
          </div>
          {/* <p className="mt-4" style={{ width: "90%" }}>
            Name
          </p>
          <p>
            <input
              placeholder="Enter Dl.No"
              value={Drivers.Name}
              style={{
                width: "85%",
                padding: "1.5%",
                borderRadius: "5px",
                border: "1px solid black",
                outline: "none",
              }}
              onChange={(e) => handleChange(e, "Name")}
            ></input>
          </p> */}

          <p className="mt-4" style={{ width: "90%" }}>
            DL.No
          </p>
          <p>
            <input
              placeholder="Enter Dl.No"
              value={newdlno}
              pattern="[a-zA-Z0-9]"
              className="mb-4"
              ref={ref4}
              style={{
                width: "85%",
                padding: "1.5%",
                borderRadius: "5px",
                border: "1px solid black",
                outline: "none",
              }}
              onChange={(e) => handleChange(e, "newdlno")}></input>
            {updateDridata?.documents[0]?.fileLink?.length > 0 ? (
              <>
                <span className="ml-1 mr-1 is-size-5">
                  <a
                    href={
                      process.env.REACT_APP_API_SERVER +
                      "/api/v1/filestore/getfile/" +
                      updateDridata?.documents[0]?.pkey +
                      "/" +
                      updateDridata?.documents[0]?.fileLink
                    }
                    target="_blank"
                    rel="noreferrer"
                    alt="">
                    <BsFillEyeFill />
                  </a>
                </span>
                <span className="icon is-clickable is-size-5">
                  <MdDelete
                    onClick={() => (
                      delDocs1(0), (hiddenFileInput.current.value = "")
                    )}
                  />
                </span>
              </>
            ) : <span class=" icon  ml-1 is-size-4 is-clickable">
              <i class="fa-solid fa-file-arrow-up" onClick={handleClick1}>
                <input
                  type="file"
                  onChange={(e1) => {
                    const pattern = /^[a-zA-Z0-9_ .()-]+$/
                    if (pattern.test(e1.target.files[0]["name"])) {
                      change(e1.target.files, "Driving License", newdlno)
                    } else {
                      configStore.setModalMessage(
                        "Please Check File Name and Format"
                      )
                      return (e1.target.value = "")
                    }
                  }}
                  name="image"
                  accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                  multiple=""
                  ref={hiddenFileInput1}
                  data-original-title="upload photos"
                  style={{ display: "none" }}
                />
              </i>
            </span>}

          </p>
          {/* <p>
            <input
              placeholder="Enter Dl.No"
              value={newdlno}
              pattern="[a-zA-Z0-9]"
              ref={ref4}
              style={{
                width: "85%",
                padding: "1.5%",
                borderRadius: "5px",
                border: "1px solid black",
                outline: "none",
              }}
              onChange={(e) => handleChange(e, "newdlno")}></input>
            {updateDridata?.documents[0]?.fileLink?.length > 0 ? (
              <>
                <span className="ml-1 mr-1 is-size-5">
                  <a
                    href={
                      process.env.REACT_APP_API_SERVER +
                      "/api/v1/filestore/getfile/" +
                      updateDridata?.documents[0]?.pkey +
                      "/" +
                      updateDridata?.documents[0]?.fileLink
                    }
                    target="_blank"
                    rel="noreferrer"
                    alt="">
                    <BsFillEyeFill />
                  </a>
                </span>
                <span className="icon is-clickable is-size-5">
                  <MdDelete
                    onClick={() => (
                      delDocs1(0), (hiddenFileInput.current.value = "")
                    )}
                  />
                </span>
              </>
            ) : <span class=" icon  ml-1 is-size-4 is-clickable">
              <i class="fa-solid fa-file-arrow-up" onClick={handleClick1}>
                <input
                  type="file"
                  onChange={(e1) => {
                    const pattern = /^[a-zA-Z0-9_ .()-]+$/
                    if (pattern.test(e1.target.files[0]["name"])) {
                      change(e1.target.files, "Driving License", newdlno)
                    } else {
                      configStore.setModalMessage(
                        "Please Check File Name and Format"
                      )
                      return (e1.target.value = "")
                    }
                  }}
                  name="image"
                  accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                  multiple=""
                  ref={hiddenFileInput1}
                  data-original-title="upload photos"
                  style={{ display: "none" }}
                />
              </i>
            </span>}

          </p> */}
          <InputField
                  type={"date"}
                  placeholder={"DL Expiry Date"}
                  classname={"input is-small "}
                  label_class={"label is-small"}
                  label_style={{ marginBottom: "0px" }}
                  id={"colordate"}
                  value={Drivers?.DlExpiryDate}
                  min={tomorrowdate}
                  callback={(e) => {
                    Drivers.DlExpiryDateFlag = true
                    Drivers['DriverDataFlag'] = true
                    handleChange(e, "DlExpiryDate")
                  }}
                  // children={{
                  //   ...((isViewMode() || (Drivers?.ulip && Drivers?.DlExpiryDate && isNewMode() && !Drivers.DlExpiryDateFlag))
                  //     ? { disabled: true }
                  //     : {
                  //       ...(isEditMode() &&
                  //         (isPendingStatus() || isVerifiedStatus() || (isDriverRefreshed && Drivers?.DlExpiryDate > todaydate))
                  //         ? { disabled: true }
                  //         : {}),
                  //     })
                  // }}
                  children={ (isDisabledForDLExpiry || (isDriverRefreshed && Drivers?.DlExpiryDate && isEditMode()) || (Drivers.Status.includes('Expired') && !Drivers.ulip)) ? { disabled: true } : {}}
                  input_style={
                    {
                      border:
                        ((isEditMode() || isViewMode()) && Drivers?.DlExpiryDate < todaydate)
                          ? "1px solid red"
                          : null,
                    }
                  }
                ></InputField>
          <p className="mt-4 has-text-danger">
            <span>{alertcomment}</span>
          </p>
          {/* <p className="mt-4">DL Expire Date</p>
          <input
            type="date"
            placeholder="Enter Dl.No"
            value={Drivers?.DlExpiryDate}
            style={{
              width: "85%",
              padding: "1.5%",
              borderRadius: "5px",
              border: "1px solid black",
              outline: "none",
            }}
            min={tomorrowdate}
            onChange={(e) => handleChange(e, "DlExpiryDate")}
          ></input> */}
          <div>
            <span>
              <button
                className="is-clickable"
                style={{
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "2%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "7%",
                  marginLeft: "80%",
                }}
                onClick={UpdateDlnumfn}>
                SUBMIT
              </button>
            </span>
          </div>
        </div>
      ) : null}

{(ulippopup && ((Drivers.Status.includes('Expired') && Drivers?.ulip)|| (Drivers.Status =='Rejected' && Drivers?.ulip)) && props.mode != 'view') ? (
        <div
          style={{
            width: "450px",
            // height: "150px",
            backgroundColor: "#f2f2f2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "40%",
            left: "43%",
            padding: "1% 1% 1% 1%",
          }}>
          <div className="is-flex is-justify-content-space-between">
          <span
              className="is-size-6 has-text-weight-bold"
              style={{ marginTop: "5%" }}>
              Kindly refresh the Driver Details
            </span>
            <span>
              <ImCancelCircle onClick={() => (routerStore.driverCurrentView = "list")} className="is-clickable" />
            </span>
          </div>

          <div>
            <span>
            <button
              className="is-clickable"
              style={{
                    border: "1px solid #0099CC",
                    borderRadius: "5px",
                    height: "28px",
                    width:'70px',
                    color: "white",
                    marginTop: "10%",
                    marginLeft: "84%",
                    backgroundColor: "#00CCFF",
                    // opacity: 0.4,
          }}
              // onClick={updatevehfn}>
              onClick={(e) => getDriverDetails(true)}>
              Refresh
            </button>
            </span>
          </div>
        </div>
      ) : null}
    </>
  )
}




