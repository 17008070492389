import { Box, Typography } from "@mui/material";
import { enquiryStore } from "../enquiry/enquiry-data";
import Utils from "../util/Utils";



const dividerStyle = { border: '1px solid',borderImageSource: 'linear-gradient(90deg, #2C358A 0%, #4BA7DD 100%)',borderImageSlice: 1,background: "linear-gradient(90deg, #2C358A 0%, #4BA7DD 100%)"}

function FlexibleDivider() {
    return <Box sx={{ display: "flex", flex: 1, ...dividerStyle }}></Box>
}

function Vesssel({graphNode}){
    const showVessel = graphNode.r1?.split(" ");
    let vessel = <></>;
    if(showVessel.length===3){
        vessel = <Typography sx={{fontSize:"10px",textTransform:"capitalize"}}><span style={{fontWeight:"bold",fontSize:"10px",paddingRight:"3px"}}>{showVessel[0]}</span>
             {showVessel[1]} - {showVessel[2]}</Typography>
    }else if(showVessel){
        vessel =  <Typography sx={{fontSize:"10px"}}>{graphNode.r1}</Typography>
    }
    return <Box sx={{display:"flex",flexDirection:"column",flex:1,alignItems:"center",height:"54px",justifyContent:"space-around"}}>
        {vessel}
        {/* <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",minHeight:"16px",width:"100%"}}><FlexibleDivider/></Box> */}
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",minHeight:"30px"}}>
        {graphNode.r2?.length >0 && <Typography sx={{fontFamily:"Figtree",color:"white",textTransform:"capitalize",fontSize:"10px",backgroundColor:"#2B6ED4",borderRadius:"35px",padding:"5px 6px"}}>{graphNode.r2}</Typography>}
        </Box>
    </Box>
}

function TimelineWidget({ value1, value2}) {
    const val1 = value1?.split(" ");
    const val2 = value2?.split(" ");
    return <Box sx={{ display: "flex", flexDirection: "column", minHeight: "30px" }}>
        <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "10px", textAlign:"center" }}>{val1?.length>1 ? val1[0]+": " : ""} <span style={{ fontWeight: "normal", display: "inline",color:"#555555",paddingLeft:"3px" }}>{val1?.length>0 ? val1[1]: ""}</span></Typography>
        <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "10px", textAlign:"center" }}>{val2?.length>1 ? val2[0]+": " : ""} <span style={{ fontWeight: "normal", display: "inline", color:"#555555",paddingLeft:"3px" }}>{val2?.length>0 ? val2[1]: ""}</span></Typography>
    </Box>
}

function DestinationPoint(){
   return <Box sx={{ display: "flex", height: "16px", width: "16px", borderRadius: "50%", backgroundColor: "#2B6ED44D", padding: "4px" }}>
   <Box sx={{ height: "8px", width: "8px", borderRadius: "50%", backgroundColor: "#2B6ED4",border:"1px solid white" }}></Box>
</Box>
}

function OriginPoint() {
    return <Box sx={{ display: "flex", height: "16px", width: "16px", borderRadius: "50%", backgroundColor: "#F4B14A", padding: "4px" }}>
        <Box sx={{ height: "8px", width: "8px", borderRadius: "50%", backgroundColor: "white" }}></Box>
    </Box>
}

function Destination({graphNode}){
    return <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start",flex:1,alignItems:"end"}}>
        <OriginPoint/>
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "30px"}}></Box>
</Box>
}


function Origin({ graphNode }) {
    return <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start",flex:1 }}>
            <OriginPoint />
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "30px"}}>
            <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "10px" }}>{graphNode.r3?.split(" ")[0]}</Typography>
            <Typography sx={{ fontFamily: "Figtree", color: "#555555", fontSize: "10px" }}>{graphNode.r3?.split(" ")[1]}</Typography>
        </Box>
    </Box>
}

function Point({ color = "#F4B14A", graphNode }) {
    return <Box sx={{ display: "flex", flexDirection: "column", minHeight: "16px", minWidth: "8px", flex:1,alignItems:"center" }}>
        <Typography sx={{ fontFamily: "Figtree", color : "black", fontSize: "10px", fontWeight: "bold",textAlign:"center" }}>{graphNode.r2}</Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center",justifyContent:"center" }}>
                {/* <FlexibleDivider /> */}
                <Box sx={{ height: "16px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{ height: "8px", width: "8px", borderRadius: "50%", backgroundColor: color }}></Box>
                </Box>
                {/* <FlexibleDivider /> */}
            </Box>
            <TimelineWidget value1={graphNode.r1} value2={graphNode.r3} />
        </Box>
    </Box>
}

export function ScheduleGraph2({
    graphNodes= []
}) {
    const isOriginDoor = enquiryStore.current.shipmentScope.split("-")[0]?.toLowerCase() === "door";
    const isDestinationDoor = enquiryStore.current.shipmentScope.split("-")[2]?.toLowerCase() === "door";
  
    return graphNodes && graphNodes.length && <Box sx={{ width: "100%", display: "flex", flexDirection: "row", backgroundColor: "white",alignItems:"center",gap:2 }}>
        {(()=>{
  const originCode = Utils.extractPostalCode(graphNodes[0].r2);
  const destinationCode= Utils.extractPostalCode(graphNodes[graphNodes.length-1].r2);
  return <>
      <Box sx={{maxHeight:"70px",fontSize:"14px",color:"black",width:"136px",display:"flex",flexDirection:"column",justifyContent:"center",overflow:"hidden",textOverflow:"ellipsis"}}>
        <p className="text-sm">{
            Utils.getCity(graphNodes[0].r2, isOriginDoor)}{originCode && ", " + originCode}</p>
        <p className="text-[black] text-xs min-h-[14px] font-bold">{graphNodes[0].r1?.split(" ")[0]||""} <span className="font-normal">{graphNodes[0].r1?.split(" ")[1]||""}</span></p>
        </Box>
        <Box sx={{height:"62px",position:"relative",width:"100%"}}>
            <Box sx={{width:"100%",bottom:0,position:"absolute"}}>
            <FlexibleDivider/>
            <Box sx={{minHeight:"37px"}}></Box>
            </Box>
        <Box sx={{display:"flex",flex:1,flexDirection:"row", alignItems: "end",justifyContent:"space-between",bottom:0,position:"absolute",width:"100%"}}>
      {graphNodes.map((graphNode,i)=>{
        if(graphNode.transparent){
            return <Vesssel graphNode={graphNode} />
        }
         if(i==0){
            return <Origin graphNode={graphNode}/>
         }

         if(i==graphNodes.length-1){
             return <Destination graphNode={graphNode} />
         }

         return <Point graphNode={graphNode} />
       })}
      </Box>
        </Box>
        <Box sx={{maxHeight:"70px",fontSize:"14px",color:"black",width:"136px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"start"}}>
            <p className="text-sm">{Utils.getCity(graphNodes[graphNodes.length-1].r2,isDestinationDoor)}{destinationCode && ", " + destinationCode}</p>
            <p className="text-[black] text-xs font-bold min-h-[14px]">{graphNodes[graphNodes.length-1].r1?.split(" ")[0]||""} <span className="font-normal">{graphNodes[graphNodes.length-1].r1?.split(" ")[1]||""}</span></p>
            </Box>
  </>
        }
        )()}
    
    </Box>
}