import { useSnapshot } from "valtio";
import { DtStore } from "./DtStore";
import DtApi from "../state/dt-api";
import { useGlobalState } from "../GlobalState";
let server = process.env.REACT_APP_API_SERVER;
export function DtInitiateBalancePaymentmodal() {
  const { changeCurrentMenu } = useGlobalState()
  useSnapshot(DtStore);
  let dtStore = DtStore.DTBooking;
  const close = () => {
    DtStore.initiateBalancePaymentmodal = false;
    changeCurrentMenu('Domestic Transport Actions')
  };
  let adc = 0
  if(dtStore.vendor_additional_cost && dtStore.vendor_additional_cost.length>0){
    adc = Number(dtStore.vendor_additional_cost)
  }
  const Update = async () => {
    dtStore.initiateBalancePayment = "Yes";
    DtApi.postEditDtBooking(dtStore, close);
  };
  let modalClass = "modal";
  if (DtStore.initiateBalancePaymentmodal) modalClass += " is-active";
  return (
    <>
      {DtStore.initiateBalancePaymentmodal ? (
        <div
          className={modalClass}
          onClick={() => {
            DtStore.initiateBalancePaymentmodal = false;
          }}
        >
          <div className="modal-background"></div>
          <div
            className="modal-card"
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ borderRadius: "10px" }}
          >
            <section className="modal-card-body">
              <div class="columns m-1 is-vcentered is-multiline">
                <div class="column is-12 pb-0 mb-0">
                  <b>
                    <h3 className="title is-5">Initiate Balance Payment</h3>
                  </b>
                </div>
                <div class="column is-5">Vendor Name</div>
                <div class="column is-1">:</div>
                <div class="column is-6">{dtStore.vendor_assigned}</div>
                <div class="column is-5">Final Cost</div>
                <div class="column is-1">:</div>
                <div class="column is-6">{Number(dtStore.vendor_agreed_cost)+adc} (INR)</div>
                <div class="column is-12">
                  <table className="table is-bordered">
                    <thead>
                      <th>Destination</th>
                      <th>Proof Of Delivery</th>
                      <th>POD Dated?</th>
                    </thead>
                    <tbody>
                      {dtStore.details[0].delivery.map((e, i) => {
                        return (
                          <tr>
                            <td>{e.address}</td>
                            <td>
                              {e.proofofdelivery.length > 0 ? (
                                <>
                                  <a
                                    target="_blank"
                                    href={
                                      server +
                                      "/api/v1/filestore/getfile/" +
                                      dtStore.details[0].contract_id +
                                      "/" +
                                      e.proofofdelivery
                                    }
                                  >
                                    Proof Of Delivery - {i + 1}
                                  </a>
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>{e.pod_date}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div class="column is-5">Agraga Freight Invoice</div>
                <div class="column is-1">:</div>
                <div class="column is-6">
                {dtStore.invoice.length > 0 ? (
                                <>
                                  <a
                                    target="_blank"
                                    href={
                                      server +
                                      "/api/v1/filestore/getfile/" +
                                      dtStore.details[0].contract_id +
                                      "/" +
                                      dtStore.invoice
                                    }
                                  >
                                   {dtStore.invoice}
                                  </a>
                                </>
                              ) : (
                                ""
                              )}
                  </div>
                <div class="column is-5">Gross Profit Amount</div>
                <div class="column is-1">:</div>
                <div class="column is-6">{Number(dtStore.total_cost)-Number(dtStore.vendor_agreed_cost)-adc} (INR)</div>
                <div class="column is-5">GP %</div>
                <div class="column is-1">:</div>
                <div class="column is-6">{(((Number(dtStore.total_cost)-Number(dtStore.vendor_agreed_cost)-adc)/(Number(dtStore.vendor_agreed_cost)+adc))*100).toFixed(2)}</div>
              </div>
              <button
                class="button is-warning"
                onClick={() => (DtStore.initiateBalancePaymentmodal = false)}
              >
                cancel
              </button>
              <button
                class="button is-danger"
                style={{ float: "right" }}
                onClick={Update}
              >
                Proceed
              </button>
            </section>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
