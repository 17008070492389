import { useSnapshot } from "valtio";
import AguserApi from "../../state/AguserApi";
import {
  EntityV2Store,
  configStore,
  creditformStore,
  loginStore,
  routerStore,
  branchStore,
  AgmodalStore
} from "../../state/CommonState";
import EntityApi from "../../state/entities-api";
import { City, Country, State } from "country-state-city";
import { useEffect, useRef, useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { HiMiniDocumentCheck } from "react-icons/hi2";
import { ImCancelCircle } from "react-icons/im";
import { BiPlus } from "react-icons/bi";
import { Col12, Col2, Col3, Col4, Col5, Col6, Columns } from "../../Controls";
import { Roles, currencyList } from "../../StaticData";
import off from "../../components/off.png";
import on from "../../components/on2.png";
import { $c, $cg, $cr, AgInput, AgSelect } from '../../components/AgComponents'
import Api from "../../state/Api";
import { CreditFormType, Customer, EntityTypeV2 } from "../../state/Types";
import {
  CUSTOMER_CATEGORY,
  ENTITY_STATUS,
  ENTITY_TYPE,
  OPERATOR_TYPE,
  ROLES,
  SCOPE_CUSTOMS_BROKER,
  SCOPE_ROAD_TRANSPORT,
  FREGIHT_FORWARD_TYPE,
  VENDOR_CATEGORY,
  VENDOR_CATEGORY_NONIN,
  PERMISSIONS, VERTICALS, SCOPE_OF_ACTIVITY, CREDIT_STATUS,CUSTOMER_BILLING_CYCLE,
  POD_TYPE_INVOICE,
  SALESVERTICAL,
  DIVISION
} from "../../util/constants"
import EntityUtils from "../../util/entityUtils"
import MainBranch from "../Branches/main-branch"
import MainShippers from "../ShipperConsignee/main-shipper-consignee"
import { Agyesno } from "../../components/Agyesno"
import Utils from "../../util/Utils"
import { ReactComponent as Loader } from "../../assets/loader.svg"
import { loader } from "../../util/loader"
import { debounce } from "lodash"
import EntityV2Api from "../../state/entities-api"
import RoleUtils from "../../util/RoleUtils"
import MainCredit from "../CreditFacility/main-credit"
import CreditsApis from "../../state/credit-api"
import MainUsers from "../Users/main-user"
import uam_roles_data from "../../static-data/users_verticals"
import GSTVerify from "../../dtactions/gst-verify";
import { dtactionStore } from "../../dtactions/dtaction-store";
import CustomsKycRequirements from "../../mytasks/act-customsKycRequirements";
import { Modal } from "reactstrap";
import { IoSettingsOutline } from "react-icons/io5";
import DSRCustomization from "../Dsr/dsrCustomization";
import DSRScheduler from "../Dsr/dsrScheduler";
import EntityDSRApi from "../../state/entitiesDsr-api";

export default function EditEntity(props) {
  function change(files, filetype, i) {
    const formData = new FormData();
    formData.append("myFile", files[0]);
    formData.append("label", filetype);
    formData.append("key", i);
    fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/filestore/storefile`, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) configStore.setModalMessage(data.error);
        else {
          let documentObj1 = Entity.documents.filter(
            (r) => r.name === filetype
          );
          if(documentObj1?.length <= 0){
            let entityV2 = new EntityTypeV2();

            for (let docV2 of entityV2.documents) {
              const foundDoc = Entity.documents.find(doc => doc.name === docV2.name);

              if (!foundDoc) {
                Entity.documents.push(docV2);
                // documentObj.push(docV2)
              }
            }
          }

          const documentObj = Entity.documents.filter(
            (r) => r.name === filetype
          );

          documentObj[0].fileName = files[0]?.name;
          documentObj[0].fileLink = data?.key?.filename;
          documentObj[0].pkey = data?.key?.pkey;

          if (filetype === "gstCertificate") {
            Entity.branch[0].documents[1].fileName = files[0]?.name;
            Entity.branch[0].documents[1].fileLink = data?.key?.filename;
            Entity.branch[0].documents[1].pkey = data?.key?.pkey;
          } else if (filetype === "Identifer Reference Number") {
            Entity.branch[0].documents[0].fileName = files[0]?.name;
            Entity.branch[0].documents[0].fileLink = data?.key?.filename;
            Entity.branch[0].documents[0].pkey = data?.key?.pkey;
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const [branchesForTypes, setbranchesForTypes] = useState([]);
  const [branchesForTypesCB, setbranchesForTypesCB] = useState([]);
  const [branchesForTypesDT, setbranchesForTypesDT] = useState([]);
  const [formObject, setformObject] = useState({});
  const [gstData, setGstData] = useState();
  const [gstData1, setGstData1] = useState(false);
  const [gstVerify, setGstVerify] = useState();
  const [gstCancel, setGstCancel] = useState(false);
  const [city,setCity] =  useState(false)
  const [count,setCount] =  useState(false)
  const [showKycRequirements, setShowKycRequirements] = useState(false); 
  const [dsrCustomization, setDsrCustomization] = useState(false);
  const [dsrScheduler, setDsrScheduler] = useState(false);
  const [dsrData, setDsrData] = useState({});



  let activeStyle = { backgroundColor: '#2c358f', color: 'white', border: '1px solid #2c358f' }
  let inactiveStyle = { backgroundColor: '#888', color: 'white', border: '1px solid #bbbbbb', margin: '1px' }

  let pendingStyle = inactiveStyle
  let completedStyle = inactiveStyle

  // let pendingStyle1 = inactiveStyle
  // let completedStyle1 = inactiveStyle

  const bin2hex = (d) =>
  {
    const randomBytes = (count) => {
      const result = Array(count)
    for (let i = 0;i < count;++i) {
        result[i] = Math.floor(256 * Math.random())
      }
      return result
    }
    let bin = randomBytes(d)
    var i = 0, l = bin.length, chr, hex = ''
    for (i; i < l; ++i)
    {
      chr = bin[i].toString(16)
      hex += chr.length < 2 ? '0' + chr : chr
    }
    return hex
  }

  const token= ()=> {
    let str1 = bin2hex(32)
    let str2 = ""
    if(EntityV2Store.EntityRec.entityId){
        str2 = btoa(Entity.entityId+Date.now())
    }else{
        str2 = btoa(bin2hex(15)+Date.now())
    }


    let result = '';
    const maxLength = Math.max(str1.length, str2.length);
    for (let i = 0; i < maxLength; i++) {
      if (i < str1.length) {
        result += str1.charAt(i);
      }

      if (i < str2.length) {
        result += str2.charAt(i);
      }
    }
    Entity.api = {
        "key" : result,
        "active":"Yes"
    }
  }




  const getAllBranchesList = async () => {
    let v = {
      vertical: "all",
      division: "all",
    };
    let cbFilter = {
      vertical: "Cross-Border",
      division: "all",
    };
    let dtFilter = {
      vertical: "Domestic Transport",
      division: "all",
    };
    AguserApi.getbranchesForTypes(v, setbranchesForTypes);
    AguserApi.getbranchesForTypes(cbFilter, setbranchesForTypesCB);
    AguserApi.getbranchesForTypes(dtFilter, setbranchesForTypesDT);
  };

  let sortedbranches = branchesForTypes.sort();
  let sortedbranchesForCB = branchesForTypesCB.sort();
  let sortedbranchesForDT = branchesForTypesDT.sort();

  useEffect(() => {
    getAllBranchesList();
    EntityV2Store.EntityRec.kycDetails = []
    let form = JSON.parse(JSON.stringify(EntityV2Store.EntityRec));
    setformObject(form);
  }, []);

  function isEditMode() {
    return props.mode === "edit";
  }
  function isNewMode() {
    return props.mode === "new";
  }

  function isViewMode() {
    return props.mode === "view";
  }

  function isPendingStatus() {
    return Entity?.status.toLowerCase() === ENTITY_STATUS.PENDING.toLowerCase();
  }

  function isVerifiedStatus() {
    return (
      Entity?.status.toLowerCase() === ENTITY_STATUS.VERIFIED.toLowerCase()
    );
  }

  function isDeactivatedStatus() {
    return (
      Entity?.status.toLowerCase() === ENTITY_STATUS.DEACTIVATED.toLowerCase()
    );
  }

  function isIncompleteStatus() {
    const isAuthorizedRoadTransport = RoleUtils.isUserAuthorized(
      PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT
    );
    if (
      isAuthorizedRoadTransport &&
      !isNewMode() &&
      !isAgragaAdmin &&
      !isAdminOrBranchHead
    ) {
      return (
        Entity?.status.toLowerCase() === ENTITY_STATUS.INCOMPLETE.toLowerCase()
      );
    }
  }

  function isRejectStatus() {
    const isAuthorizedRoadTransport = RoleUtils.isUserAuthorized(
      PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT
    );
    if (
      isAuthorizedRoadTransport &&
      !isNewMode() &&
      !isAgragaAdmin &&
      !isAdminOrBranchHead
    ) {
      return (
        Entity?.status.toLowerCase() === ENTITY_STATUS.REJECTED.toLowerCase()
      );
    }
  }

  const tradeLine = [
    {
      fromState: "",
      fromCity: "",
      toState: "",
      toCity: "",
    },
  ];
  const agragabranchlist = {
    Ahmedabad: "Ahmedabad",
    Indore: "Indore",
    Varanasi: "Varanasi",
    Bellary: "Bellary",
    Jaipur: "Jaipur",
    Vijayawada: "Vijayawada",
    Bengaluru: "Bengaluru",
    Jammu: "Jammu",
    Visakhapatnam: "Visakhapatnam",
    Bhilwara: "Bhilwara",
    Karnal: "Karnal",
    Baroda: "Baroda",
    Bhubaneshwar: "Bhubaneshwar",
    Kolkata: "Kolkata",
    Cochin: "Cochin",
    Chandigarh: "Chandigarh",
    Lucknow: "Lucknow",
    Ludhiana: "Ludhiana",
    Chennai: "Chennai",
    Mumbai: "Mumbai",
    Tuticorin: "Tuticorin",
    Coimbatore: "Coimbatore",
    Nagpur: "Nagpur",
    Guwahati: "Guwahati",
    Delhi: "Delhi",
    Nashik: "Nashik",
    Hyderabad: "Hyderabad",
    Ghandidham: "Ghandidham",
    Patna_Ranchi: "Patna_Ranchi",
    Raipur: "Raipur",
    Gurgaon: "Gurgaon",
    Pune: "Pune",
    Rudrapur: "Rudrapur",
  };
  const tradeLineCities = [
    {
      fromCities: [],
      toCities: [],
    },
  ];

  const domain = loginStore.email.split("@")[1];
  const [errors, setErrors] = useState({});
  const [pendingErrors, setPendingErrors] = useState({});

  const [salesList, setSalesList] = useState([]);
  const [agUsersList, setAGUsersList] = useState([]);
  const [salesListForCB, setSalesListForCB] = useState([]);
  const [branchesListForCBByVertical, setBranchesListForCBByVertical] = useState([]);
  const [salesListForDT, setSalesListForDT] = useState([]);
  const [Branches, setBranches] = useState(false);
  const [CreditAvail, setCreditAvail] = useState(false);
  const [manageUser, setMangeuser] = useState(false);
  const [manageShipper, setManageShipper] = useState(false);
  const [CAFAvailMode, setCAFAvailMode] = useState("");
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);
  const [serviceabilityCities, setserviceabilityCities] = useState([]);
  const [csList, setCsList] = useState([]);
  const [csListForCB, setCsListForCB] = useState([]);
  const [csListForDT, setCsListForDT] = useState([]);
  const [branchOpsDTList, setBranchOpsDTList] = useState([]);
  const [relMgrSupplyList, setRelMgrSupplyList] = useState([]);
  const [cadminList, setCadminList] = useState([]);
  const [verifyPopup, setVerifyPopup] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [stateCode, setstateCode] = useState("");
  const [cursor, setCursor] = useState(0);
  const [panAvailable, setPanAvailable] = useState({});
  const [gstAvailable, setGstAvailable] = useState({});
  const [tinAvailable, setTinAvailable] = useState({});
  const [approveCheckbox, setApproveCheckbox] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [serviceability, setserviceability] = useState(false);
  const [gstType, setgstType] = useState("");
  const [appbtnstatus, setappbtnstatus] = useState(false);
  const [loading, setloading] = useState(false);
  const [Countries, setCountries] = useState([]);
  const [panummm, setpanummm] = useState("");
  const [tinNum, setTinnum] = useState("");
  const [validema, setValidema] = useState(false);
  const [errors1, setErrors1] = useState({});

  const [CB_Checked, setCB_Checked] = useState(false);
  const [DT_Checked, setDT_Checked] = useState(false);

  // const [formData, setFormData] = useState({
  //   entityName: "",
  //   entityType: "",
  //   // ... other form fields
  // });
  const refEntityName = useRef(null);
  const refErpNumber = useRef(null);
  const ref1 = useRef(0);
  const ref2 = useRef(0);
  const ref3 = useRef(0);
  const refAdvanceReceived = useRef(null);
  const refAdvancePayable = useRef(null);
  const [advanceError, setAdvanceError] = useState(null);
  const [advancePayableError, setAdvancePayableError] = useState(null);
  const [creditDaysError, setCreditDaysError] = useState(null);
  const refEntityType = useRef(null);
  const refEntityCategory = useRef(null);
  const refEntityCountry = useRef(null);

  // const ref = useRef(null);
  const ref = useRef(null);
  const store = useSnapshot(routerStore);
  const hiddenFileInput = useRef();
  const hiddenFileInput1 = useRef();
  const hiddenFileInput2 = useRef();
  const hiddenFileInput3 = useRef();
  const hiddenFileInput4 = useRef();
  const hiddenFileInput5 = useRef();
  const hiddenFileInput6 = useRef();
  const hiddenFileInput7 = useRef();
  const hiddenFileInput8 = useRef();
  const hiddenFileInput9 = useRef();
  const hiddenFileInput10 = useRef();
  const hiddenFileInput11 = useRef();
  const hiddenFileInput12 = useRef();
  // const panAvailabilityck = useRef();
  // const {,hiddenFileInput8} = useRef();
  const Entity = EntityV2Store.EntityRec;
  const Branch = branchStore.BranchRec;
  let CreditForm = creditformStore.CreditformRec
  const [serviceArray, setserviceArray] = useState([]);

  const finArray = [
    {
      customercontact: "",
      department: "",
      contactnum: "",
      email: "",
    },
  ];
  const finEsaArray = [
    {
      CustomerContactPerson: "",
      Department: "",
      ContactNumber: "",
      Email: "",
    },
  ];
  const [financeArray, setfinanceArray] = useState(finArray);
  const [financeEsaArray, setfinanceEsaArray] = useState(finEsaArray);
  const [editMode, setEditMode] = useState(false);
  const [displayStatus, setDisplayStatus] = useState(!Entity?.paymentEnable?"ON":Entity.paymentEnable)
  const [secureNowStatus, setSecureNowStatus] = useState(!Entity?.SecureNowEnable?"ON":Entity.SecureNowEnable)

  if (displayStatus === "ON") {
    pendingStyle = activeStyle;
  } else {
    completedStyle = activeStyle;
  }



  // if (secureNowStatus === "ON") {
  //   pendingStyle1 = activeStyle;
  // } else {
  //   completedStyle1 = activeStyle;
  // }

  let title = "";
  const emptyFields = () => {
    if (EntityUtils.isCustomer(Entity)) {
      Entity.vendor.vendorType = "";
      Entity.advancePayable = "";
      Entity.vendor.isRoadTransportApplied = false;
      Entity.vendor.isCustomBrokerApplied = false;
      Entity.vendor.operatorType = "";
      Entity.vendor.isSoleProp = false;
      Entity.vendor.isMsme = false;
      Entity.vendor.scopeOfActivityRT = [];
      Entity.vendor.scopeOfActivityCB = [];
      Entity.vendor.creditDays = "";
      Entity.vendor.currency = null;
      Entity.vendor.creditLimit = "";
      Entity.vendor.loginUrl = "";
      Entity.vendor.userId = "";
      Entity.vendor.password = "";
      Entity.vendor.remarks = "";
      Entity.vendor.relationShipManagerSupply = "";
      Entity.vendor.handlingCharges = {
        perCbm: { currency: null, value: 0 },
        perBL: { currency: null, value: 0 },
        perContainer: { currency: null, value: 0 },
        perAWB: { currency: null, value: 0 },
      };
      Entity.vendor.isVendorAgreementApproved = false;
      Entity.vendor.isMsmeAgreementApproved = false;

      Entity.vendor.accountHolderName = "";
      Entity.vendor.bankName = "";
      Entity.vendor.accountNo = "";
      Entity.vendor.IFSCcode = "";
      Entity.vendor.swiftCode = "";
      Entity.vendor.isCreditFacilitated = false;
      Entity.vendor.fileCancelledChequeOrEmail = "";
      let documentObj = Entity?.documents?.filter(
        (r) => r.name === "fileVendorAgreement"
      );
      documentObj[0].fileName = "";
      documentObj[0].fileLink = "";
      documentObj[0].pkey = "";

      const documentObj1 = Entity?.documents?.filter(
        (r) => r.name === "CkLeafOrBankStat"
      );
      documentObj1[0].fileName = "";
      documentObj1[0].fileLink = "";
      documentObj1[0].pkey = "";

      const documentObj2 = Entity?.documents?.filter(
        (r) => r.name === "MsmeAgreement"
      );
      if (documentObj2[0]?.fileName) {
        documentObj2[0].fileName = "";
        documentObj2[0].fileLink = "";
        documentObj2[0].pkey = "";
      }
      // Entity.logisticInformation?.map((e)=>{
      //   e.customercontact = null
      //   e.department = null
      //   e.contactnum = null
      //   e.email = null
      // });
    }
    if (EntityUtils.isVendor(Entity)) {
      Entity.advanceReceivableForDT = "";
      Entity.customer.customerType = "";
      Entity.customer.relationshipManager = "";
      Entity.customer.customerService = "";
      Entity.customer.branchOperationManager = "";
      // Entity.customer.domesticTransport.billCopy = false;
      // Entity.customer.domesticTransport.billingCycle =  "";
      // Entity.customer.domesticTransport.podInvoiceAcceptance =  "";

      const documentObj2 = Entity?.documents?.filter(
        (r) => r.name === "InvoiceFormat"
      );
      if (documentObj2[0]?.fileName) {
        documentObj2[0].fileName = "";
        documentObj2[0].fileLink = "";
        documentObj2[0].pkey = "";
      }
      if (!EntityUtils.isFreightForwarder(Entity)) {
        Entity.vendor.isRoadTransportApplied = false;
        Entity.vendor.isCustomBrokerApplied = false;
        Entity.vendor.handlingCharges = {
          perCbm: { currency: null, value: 0 },
          perBL: { currency: null, value: 0 },
          perContainer: { currency: null, value: 0 },
          perAWB: { currency: null, value: 0 },
        };
        Entity.vendor.scopeOfActivityFreitForward = [];
      }
      // Entity.logisticInformation?.map((e)=>{
      //   e.customercontact = null
      //   e.department = ""
      //   e.contactnum = ""
      //   e.email = ""
      // });
      if (EntityUtils.isFreightForwarder(Entity)) {
        if (Entity.vendor.isRoadTransportApplied === false) {
          Entity.vendor.scopeOfActivityRT = [];
        }
        if (Entity.vendor.isCustomBrokerApplied === false) {
          Entity.vendor.scopeOfActivityCB = [];
        }
        // if (Entity.vendor.scopeOfActivityFreitForward === false) {
        //   Entity.vendor.scopeOfActivityFreitForward = [];
        // }
      }
      if (Entity.vendor.isCreditFacilitated === false) {
        Entity.vendor.creditDays = "";
        Entity.vendor.currency = null;
        Entity.vendor.creditLimit = "";
      }
      if (!EntityUtils.isSoleProp(Entity)) {
        Entity.aadharNum = "";
        addMissingDocs()
        const documentObj = Entity?.documents?.filter(
          (r) => r.name === "aadharCert"
        );
        documentObj[0].fileName = "";
        documentObj[0].fileLink = "";
        documentObj[0].pkey = "";
      }

      if (!EntityUtils.isMsme(Entity)) {
        addMissingDocs()
        const documentObj2 = Entity?.documents?.filter(
          (r) => r.name === "MsmeAgreement"
        );
        if (documentObj2[0]?.fileName) {
          documentObj2[0].fileName = "";
          documentObj2[0].fileLink = "";
          documentObj2[0].pkey = "";
        }
      }
      if (!EntityUtils.isRoadTransport(Entity)) {
        Entity.vendor.scopeOfActivityRT = [];
      }
      if (!EntityUtils.isCustomsBroker(Entity)) {
        Entity.vendor.scopeOfActivityCB = [];
      }
    }
    if (!EntityUtils.isIndia(Entity)) {
      Entity.panNum = "";
      const documentObj = Entity?.documents?.filter(
        (r) => r.name === "panCard"
      );
      documentObj[0].fileName = "";
      documentObj[0].fileLink = "";
      documentObj[0].pkey = "";
      Entity.IECCode = "";
      const documentObjj = Entity?.documents?.filter(
        (r) => r.name === "IEC CODE"
      );
      documentObjj[0].fileName = "";
      documentObjj[0].fileLink = "";
      documentObjj[0].pkey = "";
      Entity.vendor.IFSCcode = "";
      Entity.vendor.isSoleProp = false;
      Entity.vendor.isMsme = false;
      Entity.branch[0].gst = "";
      const documentObj2 = Entity?.documents?.filter(
        (r) => r.name === "gstCertificate"
      );
      documentObj2[0].fileName = "";
      documentObj2[0].fileLink = "";
      documentObj2[0].pkey = "";
      if (EntityUtils?.isSoleProp(Entity) && EntityUtils.isIndia(Entity)) {
        const documentObj3 = Entity?.documents?.filter(
          (r) => r.name === "gstDeclaration"
        );
        documentObj3[0].fileName = "";
        documentObj3[0].fileLink = "";
        documentObj3[0].pkey = "";
      }
      if (EntityUtils.isIndia(Entity)) {
        Entity.tinNum = "";
        const documentObj = Entity?.documents?.filter(
          (r) => r.name === "tinCert"
        );
        documentObj[0].fileName = "";
        documentObj[0].fileLink = "";
        documentObj[0].pkey = "";
        const documentObj2 = Entity?.documents?.filter(
          (r) => r.name === "taxresCert"
        );
        documentObj2[0].fileName = "";
        documentObj2[0].fileLink = "";
        documentObj2[0].pkey = "";
        Entity.vendor.swiftCode = "";
        Entity.vendor.bankName = "";
        Entity.vendor.accountHolderName = "";
        Entity.vendor.accountNo = "";
        Entity.branch[0].uidName = "";
        Entity.branch[0].uidRefNo = "";
        const documentObj3 = Entity?.documents?.filter((r) => r.name === "UID");
        documentObj3[0].fileName = "";
        documentObj3[0].fileLink = "";
        documentObj3[0].pkey = "";
        if (EntityUtils.isSoleProp(Entity)) {
          Entity.aadharNum = "";
          const documentObj = Entity?.documents?.filter(
            (r) => r.name === "aadharCert"
          );
          documentObj[0].fileName = "";
          documentObj[0].fileLink = "";
          documentObj[0].pkey = "";
        }

        if (EntityUtils.isMsme(Entity)) {
          const documentObj2 = Entity?.documents?.filter(
            (r) => r.name === "MsmeAgreement"
          );
          if (documentObj2[0]?.fileName) {
            documentObj2[0].fileName = "";
            documentObj2[0].fileLink = "";
            documentObj2[0].pkey = "";
          }
        }
        if (Entity.vendor.scopeOfActivityFreitForward === false) {
          Entity.vendor.scopeOfActivityFreitForward = [];
        }
      }
    }
    if (EntityUtils.isIndia(Entity)) {
      if (
        Entity?.branch[0]?.gst.length === 0 &&
        Entity?.documents[9]?.fileLink?.length === 0
      ) {
        Entity.branch[0].state = "";
        Entity.branch[0].branchAddresses[0].address = "";
        Entity.branch[0].branchAddresses[0].city = "";
        Entity.branch[0].branchAddresses[0].pincode = "";
        Entity.branch[0].branchAddresses[0].zipcode = "";
      }
      if (!Object.values(VENDOR_CATEGORY).includes(Entity.vendor.vendorType)) {
        Entity.vendor.vendorType = "";
      }
    }
    if (EntityUtils.isVendor(Entity) && !EntityUtils.isIndia(Entity)) {
      Entity.agragaBranch = "All";
    }
  };

  emptyFields();

  // console.log("mmmmm",Entity.vendor.vendorType);
  const getStateandCityValue = async () => {
    const Entityy = await EntityV2Store.EntityRec;
    // console.log("RTYTY" , Entityy);
    const EntityCountryy = await Entityy?.entityCountry;
    // console.log(EntityCountryy);
    const EntityCountry = Country.getAllCountries().filter(
      (r) => r.name === Entityy?.entityCountry
    );
    const Statess = State.getStatesOfCountry(EntityCountry[0]?.isoCode);
    setstates(Statess);
    const Statecode = Statess.filter(
      (r) => r.name === Entityy?.branch[0]?.state
    );
    setstateCode(Statecode[0]?.isoCode);
    const cities = City.getCitiesOfState(
      EntityCountry[0]?.isoCode,
      Statecode[0]?.isoCode
    );
    setcities(cities);
  };

  useEffect(() => {
    const input = ref.current;
  }, [ref, cursor]);




  useEffect(()=>{
    setgstType("gstnumber")
  },[EntityUtils.isCustomer(Entity)])

  useEffect(() => {
    let field = "gstVerify";
    if (gstVerify) {
      pendingErrors[field] = gstVerify;
      setPendingErrors(pendingErrors);
    } else {
      clearErrorMessages(field);
    }
  }, [gstVerify]);

  useEffect(() => {
    optimizeProperties(
      salesListForDT,
      customerDTProperties,
      Entity.customer?.domesticTransport?.relationshipManager,
      "DT_relationshipManager"
    );
    // optimizeProperties(
    //   CUSTOMER_BILLING_CYCLE,
    //   customerDTProperties,
    //   Entity.customer?.domesticTransport?.billingCycle,
    //   "DT_relationshipManager"
    // );
    optimizeProperties(
      salesListForCB,
      customerCBProperties,
      Entity.customer?.crossBorder?.relationshipManager,
      "CB_relationshipManager"
    );
    optimizeProperties(
      csListForDT,
      customerDTProperties,
      Entity.customer?.domesticTransport?.customerService,
      "DT_customerService"
    );
    optimizeProperties(
      csListForCB,
      customerCBProperties,
      Entity.customer?.crossBorder?.customerService,
      "Customer_Service"
    );
    optimizeProperties(
      branchOpsDTList,
      customerDTProperties,
      Entity.customer?.domesticTransport?.branchOperationManager,
      "DT_branchOperationManager"
    );
    optimizeProperties(
      relMgrSupplyList,
      vendorProperties,
      Entity.vendor?.relationShipManagerSupply,
      "relationShipManagerSupply"
    );
  }, [salesList]);

  useEffect(() => {
    let field = "gst";
    if (gstAvailable.status) {
      pendingErrors[field] = `GST Exists for ${gstAvailable?.data?.entityId}`;
      setPendingErrors(pendingErrors);
    } else {
      clearErrorMessages(field);
    }
  }, [gstAvailable]);

  

  useEffect(() => {
    async function setStateCity() {
      getStateandCityValue();
    }
    filterCountries();
    setStateCity();
    setTinnum(Entity?.tinNum);
    setgstType(
      EntityUtils.isVendor(Entity)
        ? Entity.entityId
          ? Entity?.documents[9]?.fileLink?.length > 0
            ? "gstdeclaration"
            : "gstnumber"
          : ""
        : "gstnumber"
    );
    setserviceArray(Entity?.vendor?.tradeLine);
    let array = [];
    let length = Entity.vendor.tradeLine?.length;
    for (let i = 0; i < length; i++) {
      array.push(tradeLineCities[0]);
      if (i === length - 1) {
        setserviceabilityCities(array);
      }
    }
  }, []);

  function addMissingDocs() {
    let entityV2 = new EntityTypeV2();

    entityV2.documents.forEach((docV2, index) => {
      const foundIndex = Entity.documents.findIndex(doc => doc.name === docV2.name);

      if (foundIndex === -1) {

          if(Entity.documents[index]){
          Entity.documents[index].name = docV2.name;
        } else {
          Entity.documents[index] = { ...docV2 };
        }
      } else {
        const foundDoc = Entity.documents.find(doc => doc.name === docV2.name);

        if (!foundDoc) {
          Entity.documents[foundIndex] = (docV2);
          // documentObj.push(docV2)
        }
        // Entity.documents[foundIndex] = docV2;
      }
    })
  }

  const filterCountries = () => {
    const array = Country.getAllCountries().filter(
      (x, i) => State.getStatesOfCountry(x.isoCode).length !== 0
    );
    setCountries(array);
  };

  switch (props.mode) {
    case "edit":
      title = `Modify (${Entity?._id}) ${Entity?.status}`;
      break;
    case "view":
      title = `View (${Entity?._id}) ${Entity?.status}`;
      break;
    case "new":
      title = "Create Entity";
      break;
    default:
      title = "Entity";
      break;
  }
  useSnapshot(EntityV2Store);
  useSnapshot(AgmodalStore);
  useSnapshot(loader);

  const handleVerifyRadioChange = (event) => {
    setApprovalStatus(event.target.value);
    setappbtnstatus(true);
    setApproveCheckbox(false);
    setRemarks("");
  };
  const handleGstRadioChange = (event) => {
    Entity.branch[0].gst = "";
    const documentObj2 = Entity?.documents?.filter(
      (r) => r.name === "gstCertificate"
    );
    documentObj2[0].fileName = "";
    documentObj2[0].fileLink = "";
    documentObj2[0].pkey = "";
    const documentObj3 = Entity?.documents?.filter(
      (r) => r.name === "gstDeclaration"
    );
    documentObj3[0].fileName = "";
    documentObj3[0].fileLink = "";
    documentObj3[0].pkey = "";
    Entity.vendor.gstType = event.target.value;
    setgstType(event.target.value);
  };

  const filterUsers1 = (users, criteria) => {
    const filteredUsers = users.filter(criteria);

    return filteredUsers;
  };
  const getZonalByBranchAndVertical = (branch, vertical) => {
    const { originalDTData, originalCBData } = uam_roles_data;

    const findZonal = (data, branch) => {
      const result = data.find(entry => entry.Branch.toLowerCase() === branch.toLowerCase());
      return result ? result.Zonal : 'Zonal information not found';
    };

    if (vertical === VERTICALS.CROSS_BORDER) {
      return findZonal(originalCBData, branch);
    } else if(vertical ===  VERTICALS.DOMESTIC_TRANSPORT) {
      return findZonal(originalDTData, branch);
    } else {
      return 'Invalid vertical';
    }
  }

  const getRegionalByBranchAndVertical = (branch, vertical) => {
    const { originalDTData, originalCBData, originalCBDTData } = uam_roles_data;

    const findRegional = (data, branch) => {
      const result = data.find(entry => entry.Branch.toLowerCase() === branch.toLowerCase());
      return result ? result.Regional : 'Regional information not found';
    };

    if (vertical === VERTICALS.CB_DT) {
      return findRegional(originalCBDTData, branch);
    } else if(vertical ===  VERTICALS.DOMESTIC_TRANSPORT) {
      return findRegional(originalDTData, branch);
    } else {
      return 'Invalid vertical';
    }
  }

  const setLists = (users) => {
    
    let agusers = users.filter(
      (e) => e?.email?.trim()?.endsWith("agraga.com") && e?.status === "ACTIVE"
    );
    setAGUsersList(agusers)
    agusers.sort((a, b) => a.firstName?.localeCompare(b.firstName));

    const filterByRoleAndVerticalAndBranchForVendors = (vertical, role, role2, role3) => (e) => {
      let branchCondition = true
 
      if(vertical === VERTICALS.DOMESTIC_TRANSPORT){
      if (role?.includes(ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY) || role2?.includes(ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING)) {
        if (Entity?.agragaBranch?.length > 0) {
          branchCondition = e?.branch?.trim() === Entity?.agragaBranch.trim()
        }
 
      }}else if (vertical === VERTICALS.CENTRAL) {
        branchCondition = e.department?.includes(role)
      }
      return (e.vertical === vertical && (e.department?.includes(role) || e.department?.includes(role2)) && branchCondition) || e.department?.includes(role3)
    }
    
    const filterByRoleAndVerticalForVendors =(vertical1,vertical2) => (e) =>{
      return (e.vertical === vertical1 || e.vertical === vertical2)
    }

    const filterByRoleAndVertical = (role, vertical) => (e) =>
      e.vertical === vertical && e.role === role;

    if (EntityUtils.isCustomer(Entity)) {
      const baseRolesCB= [
        {role: ROLES.CB.BRANCH_HEAD,vertical: VERTICALS.CROSS_BORDER},
        {role: ROLES.CB.ADMIN,vertical: VERTICALS.CROSS_BORDER},
        {role: ROLES.CB_DT.BRANCH_HEAD,vertical: VERTICALS.CB_DT},
        {role: ROLES.CB_DT.ADMIN,vertical: VERTICALS.CB_DT},
        {role: ROLES.CENTRAL.PRODUCT_ADOPTION,vertical: VERTICALS.CENTRAL},
        {role: ROLES.CENTRAL.AGRAGA_ADMIN,vertical: VERTICALS.CENTRAL},
      ]
      const baseRolesDT= [
        {role: ROLES.CB.BRANCH_HEAD,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.CB.ADMIN,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.CB_DT.BRANCH_HEAD,vertical: VERTICALS.CB_DT},
        {role: ROLES.CB_DT.ADMIN,vertical: VERTICALS.CB_DT},
        {role: ROLES.CENTRAL.PRODUCT_ADOPTION,vertical: VERTICALS.CENTRAL},
        {role: ROLES.CENTRAL.AGRAGA_ADMIN,vertical: VERTICALS.CENTRAL},
      ]
      const rolesRMCB = [
        {role: ROLES.CB.RELATIONSHIP_MANAGER,vertical: VERTICALS.CROSS_BORDER},
        {role: ROLES.CB_DT.RELATIONSHIP_MANAGER,vertical: VERTICALS.CB_DT}, 
        ...baseRolesCB
      ]
      const rolesRMDT = [
        {role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.CB_DT.RELATIONSHIP_MANAGER,vertical: VERTICALS.CB_DT},
        ...baseRolesDT
      ]
      const rolesCSCB = [
        {role: ROLES.CB.CUSTOMER_SUCCESS,vertical: VERTICALS.CROSS_BORDER},
        {role: ROLES.CB_DT.CUSTOMER_SUCCESS,vertical: VERTICALS.CB_DT},
        ...baseRolesCB
      ]
      const rolesCSDT = [
        {role: ROLES.DT.CUSTOMER_SUCCESS,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.CB_DT.CUSTOMER_SUCCESS,vertical: VERTICALS.CB_DT},
        ...baseRolesDT
      ]
      const rolesBOpsDT = [
        {role: ROLES.DT.DT_OPERATIONS,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.DT.BRANCH_HEAD,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.DT.ADMIN,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.CENTRAL.PRODUCT_ADOPTION,vertical: VERTICALS.CENTRAL},
        {role: ROLES.CENTRAL.AGRAGA_ADMIN,vertical: VERTICALS.CENTRAL},
      ]
      setSalesList(
        filterUsers1(
          agusers,
          filterByRoleAndVertical(
            ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
            VERTICALS.DOMESTIC_TRANSPORT
          )
        )
      );

      const filterUsers = (user, roleCondition, branchCondition) => {
        if (!user.role || !user.vertical) return false;
    
        const hasCrossBorder = Entity?.scopeOfBusiness.includes("Cross Border");
        const crossBorderBranch = Entity?.customer?.crossBorder?.agragaBranch?.trim();
        const crossBorderZone = getZonalByBranchAndVertical(crossBorderBranch, user.vertical);
        const crossBorderRegion = getRegionalByBranchAndVertical(crossBorderBranch, user.vertical);
    
        const selectedBranch = crossBorderBranch === user?.branch?.trim();
        const selectedBranchZone = crossBorderZone === user?.zonal?.trim();
        const selectedBranchRegion = crossBorderRegion === user?.regional?.trim();
    
        // console.log("User Details:", user);
        // console.log("Cross Border Details:", { crossBorderBranch, crossBorderZone, crossBorderRegion });
        // console.log("Selected Branch Conditions:", { selectedBranch, selectedBranchZone, selectedBranchRegion });
        // console.log("Has Cross Border:", hasCrossBorder);
    
        if (!hasCrossBorder || !crossBorderBranch?.length) return false;
    
        const isCrossBorderVertical = [VERTICALS.CROSS_BORDER, VERTICALS.CB_DT, VERTICALS.CENTRAL].includes(user.vertical);
        if (!isCrossBorderVertical) return false;
    
        // console.log("Cross Border and Vertical Check Passed");
    
        const branchConditionResult = 
            (isCrossBorderVertical && selectedBranch) || 
            (isCrossBorderVertical && crossBorderZone === user?.zonal?.trim());
    
        // console.log("Branch Condition Result:", branchConditionResult);
    
        const usersConditionResult = roleCondition(user, selectedBranch, selectedBranchZone, selectedBranchRegion);
        // console.log("Users Condition Result:", usersConditionResult);
    
        const finalResult = rolesRMCB.some((role) => {
            const roleMatch = role.role.includes(user.role) && role.vertical === user.vertical;
            // console.log("Role Match Check:", roleMatch);
            return usersConditionResult;
        });
    
        console.log("Final Result for user:", user?.email, finalResult);
        return finalResult;
    };

      const filteredUsersForCBRM = agusers.filter((user) => {
        return filterUsers(user, (u, branch, bzonal, bregion) => {
          // console.log(" args: ", u, branch, bzonal, bregion);
          
            const userEmail = user?.email;
            const userRMSalesVertical = user?.RMSalesVertical;
            const userAdminSalesVertical = user?.AdminSalesVertical;
            const entitySalesVertical = Entity?.customer?.crossBorder?.salesVertical;
            const userBranch = user?.branch;
            const entityAgragaBranch = Entity?.customer?.crossBorder?.agragaBranch;
            const userDepartment = u?.department;
        
            // console.log("Logs sales:", userEmail, userRMSalesVertical, userAdminSalesVertical, entitySalesVertical, userBranch, entityAgragaBranch);
        
            const verticalCBDT = u?.vertical === VERTICALS.CROSS_BORDER || u?.vertical === VERTICALS.CB_DT;
            // console.log("Vertical CBDT:", verticalCBDT, user);
        
            const isAdmin = ROLES.CB.ADMIN === (userDepartment);
            const isNational = user?.division === DIVISION.NATIONAL;
            const isZonal = user?.division === DIVISION.ZONAL;
            const isBranchHeadOrRM = ROLES.CB.BRANCH_HEAD.includes(userDepartment) || ROLES.CB.RELATIONSHIP_MANAGER === (userDepartment);
            const isRM = ROLES.CB.RELATIONSHIP_MANAGER === (userDepartment);
            const isAAGRAAdmin = ROLES.CENTRAL.AGRAGA_ADMIN === userDepartment;
            const isProductAdoption = ROLES.CENTRAL.PRODUCT_ADOPTION === userDepartment;
        
            const adminSalesVerticalMatch = userAdminSalesVertical?.some(vertical => entitySalesVertical === (vertical));
            const rmsalesVerticalMatch = userRMSalesVertical?.some(vertical => entitySalesVertical === (vertical));
        
            const adminCondition = verticalCBDT && isAdmin && (isNational ? adminSalesVerticalMatch : (isZonal ? (adminSalesVerticalMatch && bzonal) : false));
            const branchHeadOrRMCondition = verticalCBDT && isBranchHeadOrRM && branch;
            const rmCondition = verticalCBDT && isRM && (bzonal || bregion) && rmsalesVerticalMatch;
            const centralCondition = isAAGRAAdmin || isProductAdoption;
    
            // console.log("Conditions Admin:", adminCondition, 
            //   "\n  - Vertical CBDT:", verticalCBDT, 
            //   "\n  - Is Admin:", isAdmin, 
            //   "\n  - Is National:", isNational, 
            //   "\n  - Admin Sales Vertical Match:", adminSalesVerticalMatch, 
            //   "\n  - Is Zonal:", isZonal, 
            //   "\n  - Bzonal:", bzonal);
            
            // console.log("Conditions Branch Head or RM:", branchHeadOrRMCondition, 
            //   "\n  - Vertical CBDT:", verticalCBDT, 
            //   "\n  - Is Branch Head or RM:", isBranchHeadOrRM, 
            //   "\n  - Branch:", branch);
            
            // console.log("Conditions RM:", rmCondition, 
            //   "\n  - Vertical CBDT:", verticalCBDT, 
            //   "\n  - Is RM:", isRM, 
            //   "\n  - Bz or Br:", bzonal || bregion, 
            //   "\n  - RM Sales Vertical Match:", rmsalesVerticalMatch, 
            // );
            
            // console.log("Conditions Central:", centralCondition, 
            //   "\n  - Is AGRAGA Admin:", isAAGRAAdmin, 
            //   "\n  - Is Product Adoption:", isProductAdoption);
        
            return (
                adminCondition ||
                branchHeadOrRMCondition ||
                rmCondition ||
                centralCondition
            );
        }, (u) => true);
    });
    
      setSalesListForCB(filteredUsersForCBRM);

      const filteredUsersForCBCS = agusers.filter((user) => {
        return filterUsers(user, (u, b, bz, br) => {
            const userEmail = user?.email;
            const userRMSalesVertical = user?.RMSalesVertical;
            const userAdminSalesVertical = user?.AdminSalesVertical;
            const entitySalesVertical = Entity?.customer?.crossBorder?.salesVertical;
            const userBranch = user?.branch;
            const entityAgragaBranch = Entity?.customer?.crossBorder?.agragaBranch;
            const userDepartment = u?.department;
    
            // console.log("Logs sales:", userEmail, userRMSalesVertical, userAdminSalesVertical, entitySalesVertical, userBranch, entityAgragaBranch);
    
            const verticalCBDT = u?.vertical === VERTICALS.CROSS_BORDER || u?.vertical === VERTICALS.CB_DT;
            // console.log("Vertical CBDT:", verticalCBDT, user);
    
            const isAdmin = ROLES.CB.ADMIN === (userDepartment);
            const isNational = user?.division === DIVISION.NATIONAL;
            const isZonal = user?.division === DIVISION.ZONAL;
            const isBranchHeadOrCS = ROLES.CB.BRANCH_HEAD.includes(userDepartment) || ROLES.CB.CUSTOMER_SUCCESS.includes(userDepartment);
            const isBranchHeadOrRM = ROLES.CB.BRANCH_HEAD.includes(userDepartment) || ROLES.CB.RELATIONSHIP_MANAGER === (userDepartment);
            const isCS = ROLES.CB.CUSTOMER_SUCCESS.includes(userDepartment);
            const isRM = ROLES.CB.RELATIONSHIP_MANAGER.includes(userDepartment);
            const isAAGRAAdmin = ROLES.CENTRAL.AGRAGA_ADMIN === userDepartment;
            const isProductAdoption = ROLES.CENTRAL.PRODUCT_ADOPTION === userDepartment;
    
            const adminSalesVerticalMatch = userAdminSalesVertical?.some(vertical => entitySalesVertical === (vertical));
            const rmsalesVerticalMatch = userRMSalesVertical?.some(vertical => entitySalesVertical === (vertical));
    
            const adminCondition = verticalCBDT && isAdmin && (isNational ? adminSalesVerticalMatch : (isZonal ? (adminSalesVerticalMatch && bz) : false));
            const branchHeadOrCSCondition = verticalCBDT && (isBranchHeadOrCS || isBranchHeadOrRM) && b;
            const csCondition = verticalCBDT && isCS && (bz || br);
            const rmCondition = verticalCBDT && isRM && (bz || br) && rmsalesVerticalMatch;
            const centralCondition = isAAGRAAdmin || isProductAdoption;
    
            // console.log("Conditions Admin:", adminCondition, 
            //     "\n  - Vertical CBDT:", verticalCBDT, 
            //     "\n  - Is Admin:", isAdmin, 
            //     "\n  - Is National:", isNational, 
            //     "\n  - Admin Sales Vertical Match:", adminSalesVerticalMatch, 
            //     "\n  - Is Zonal:", isZonal, 
            //     "\n  - Bz:", bz);
            
            // console.log("Conditions Branch Head or CS:", branchHeadOrCSCondition, 
            //     "\n  - Vertical CBDT:", verticalCBDT, 
            //     "\n  - Is Branch Head or CS:", isBranchHeadOrCS, 
            //     "\n  - B:", b);
            
            // console.log("Conditions CS:", csCondition, 
            //     "\n  - Vertical CBDT:", verticalCBDT, 
            //     "\n  - Is CS:", isCS, 
            //     "\n  - Bz or Br:", bz || br);

            //     console.log("Conditions RM:", rmCondition, 
            //       "\n  - Vertical CBDT:", verticalCBDT, 
            //       "\n  - Is RM:", isRM, 
            //       "\n  - RM Sales Vertical Match:", rmsalesVerticalMatch, 
            //       "\n  - Bz or Br:", bz || br);
            
            // console.log("Conditions Central:", centralCondition, 
            //     "\n  - Is AGRAGA Admin:", isAAGRAAdmin, 
            //     "\n  - Is Product Adoption:", isProductAdoption);
    
            return (
                adminCondition ||
                branchHeadOrCSCondition ||
                csCondition ||
                rmCondition ||
                centralCondition
            );
        }, (u) => true);
    });
    

      setCsListForCB(filteredUsersForCBCS);

      // DT

      const filterUsersDT = (user, roleCondition, branchCondition) => {
        if (user.role && user.vertical) {
          const hasDomesticTransport = Entity?.scopeOfBusiness.includes("Domestic Transport");
          const domesticTransportBranch = Entity?.customer?.domesticTransport?.agragaBranch?.trim();
          const domesticTransportZone = getZonalByBranchAndVertical(domesticTransportBranch, user.vertical);
          const domesticTransportRegion = getRegionalByBranchAndVertical(domesticTransportBranch, user.vertical);

          const selectedBranch = domesticTransportBranch === user?.branch?.trim();
          const selectedBranchZone = domesticTransportZone === user?.zonal?.trim();
          const selectedBranchRegion = domesticTransportRegion === user?.regional?.trim();

          let branchConditionResult = false;
          let usersConditionResult = false;

          if (
            hasDomesticTransport &&
            (user.vertical === VERTICALS.DOMESTIC_TRANSPORT || user.vertical === VERTICALS.CB_DT || user.vertical === VERTICALS.CENTRAL) &&
            domesticTransportBranch?.length > 0
          ) {
            branchConditionResult =
              ((user.vertical === VERTICALS.DOMESTIC_TRANSPORT || user.vertical === VERTICALS.CB_DT) && selectedBranch) ||
              ((user.vertical === VERTICALS.DOMESTIC_TRANSPORT || user.vertical === VERTICALS.CB_DT) && domesticTransportZone === user?.zonal?.trim());

            usersConditionResult = roleCondition(user, selectedBranch, selectedBranchZone, selectedBranchRegion);
          }

          return rolesRMCB.some((role) => {
            return (role.role.includes(user?.department) && role.vertical === user.vertical && branchConditionResult) || usersConditionResult;
          });
        }
        return false;
      };

      const filteredUsersForDTRM = agusers.filter((user) => {
        return filterUsersDT(user, (u, b, bz, br) => {
          const verticalCBDT = u?.vertical === VERTICALS.DOMESTIC_TRANSPORT || u?.vertical === VERTICALS.CB_DT;
          return (
            (verticalCBDT && (ROLES.CB.ADMIN.includes(u?.role))) ||
            (verticalCBDT && (ROLES.CB.BRANCH_HEAD.includes(u?.role) || ROLES.CB.RELATIONSHIP_MANAGER.includes(u?.role)) && b) ||
            (verticalCBDT && (ROLES.CB.RELATIONSHIP_MANAGER.includes(u?.role)) && (bz || br)) ||
            (ROLES.CENTRAL.AGRAGA_ADMIN.includes(u?.role) || ROLES.CENTRAL.PRODUCT_ADOPTION.includes(u?.role))
          );
        }, (u) => true);
      });

      setSalesListForDT(filteredUsersForDTRM);

      const filteredUsersForDTCS = agusers.filter((user) => {
        return filterUsersDT(user, (u, b, bz, br) => {
          const verticalCBDT = u?.vertical === VERTICALS.DOMESTIC_TRANSPORT || u?.vertical === VERTICALS.CB_DT;
          return (
            (verticalCBDT && (ROLES.CB.ADMIN.includes(u?.role))) ||
            (verticalCBDT && (ROLES.CB.BRANCH_HEAD.includes(u?.role) || ROLES.CB.CUSTOMER_SUCCESS.includes(u?.role)) && b) ||
            (verticalCBDT && (ROLES.CB.CUSTOMER_SUCCESS.includes(u?.role)) && (bz || br)) ||
            (ROLES.CENTRAL.AGRAGA_ADMIN.includes(u?.role) || ROLES.CENTRAL.PRODUCT_ADOPTION.includes(u?.role))
          );
        }, (u) => true);
      });

      setCsListForDT(filteredUsersForDTCS);
      setCsList(
        filterUsers1(
          agusers,
          (e) =>
            e.vertical === VERTICALS.CROSS_BORDER &&
            [ROLES.CB.CUSTOMER_SUCCESS, ROLES.DT.CUSTOMER_SUCCESS].includes(
              e.role
            )
        )
      );

      const filteredUsersForDTBOps = agusers.filter((user) => {
        return filterUsersDT(user, (u, b, bz, br) => {
          const verticalDT = u?.vertical === VERTICALS.DOMESTIC_TRANSPORT
          return (
            (verticalDT && (ROLES.DT.ADMIN.includes(u?.role))) ||
            (verticalDT && (ROLES.DT.BRANCH_HEAD.includes(u?.role) || ROLES.DT.DT_OPERATIONS.includes(u?.role)) && b) ||
            (verticalDT && (ROLES.DT.DT_OPERATIONS.includes(u?.role)) && (bz || br)) ||
            (ROLES.CENTRAL.AGRAGA_ADMIN.includes(u?.role) || ROLES.CENTRAL.PRODUCT_ADOPTION.includes(u?.role))
          );
        }, (u) => true);
      });
      setBranchOpsDTList(filteredUsersForDTBOps)
    } else {
      // if (!EntityUtils.isIndia(Entity)) {
      //   setRelMgrSupplyList(filterUsers1(agusers, filterByRoleAndVerticalForVendors(VERTICALS.CROSS_BORDER,VERTICALS.CENTRAL)))
      // } else {
      //   setRelMgrSupplyList(filterUsers1(agusers, filterByRoleAndVerticalAndBranchForVendors(ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING , VERTICALS.DOMESTIC_TRANSPORT,ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, ROLES.CENTRAL.CENTRAL_OPERATIONS)))
      // }
      if (EntityUtils.isRoadTransport(Entity)) {
        setRelMgrSupplyList(filterUsers1(agusers, filterByRoleAndVerticalAndBranchForVendors(VERTICALS.DOMESTIC_TRANSPORT, ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING, ROLES.CENTRAL.CENTRAL_OPERATIONS)))
      } else {
        setRelMgrSupplyList(filterUsers1(agusers, filterByRoleAndVerticalAndBranchForVendors(VERTICALS.CENTRAL, ROLES.CENTRAL.CENTRAL_OPERATIONS)))
      }
    }
  };

  const customerDTProperties = [
    "DT_relationshipManager",
    "DT_customerService",
    "DT_branchOperationManager",
  ];
  const customerCBProperties = ["Customer_Service", "CB_relationshipManager"];

  const vendorProperties = ["relationShipManagerSupply"];

  function optimizeProperties(
    categoryValues,
    properties,
    entity,
    selectedDropdown
  ) {
    if (categoryValues.length > 0) {
      for (const property of properties) {
        if (property === selectedDropdown) {
          const selectedOption = categoryValues.find(
            (type) => type?._id === entity
          );

          if (!selectedOption) {
            entity = "";
            pendingErrors[property] = true;
          }
        }
      }
    }
  }

  function trimStr(str) {
    if (!str) return str;
    return str.replace(/^\s+/g, "");
  }
  const toggleSoleProp = () => {
    return (Entity.vendor.isSoleProp = !Entity.vendor.isSoleProp);
  };
  const toggleMsme = () => {
    return (Entity.vendor.isMsme = !Entity.vendor.isMsme);
  };

  const toggleBillCopy = () => {
    return (Entity.customer.domesticTransport.billCopy = !Entity.customer.domesticTransport.billCopy  );
  };

  const toggleCreditfacility = () => {
    return (Entity.vendor.isCreditFacilitated =
      !Entity.vendor.isCreditFacilitated);
  };

  const toggleScopeActivityRT = () => {
    return (Entity.vendor.scopeOfActivityRT = !Entity.vendor.scopeOfActivityRT);
  };
  const toggleScopeActivityCB = () => {
    return (Entity.vendor.scopeOfActivityCB = !Entity.vendor.scopeOfActivityCB);
  };
  const toggleRoadTransport = () => {
    return (Entity.vendor.isRoadTransportApplied =
      !Entity.vendor.isRoadTransportApplied);
  };
  const toggleCustomsBroker = () => {
    return (Entity.vendor.isCustomBrokerApplied =
      !Entity.vendor.isCustomBrokerApplied);
  };

  useEffect(() => {
    const input = ref1.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref1, cursor]);

  useEffect(() => {
    const input = ref2.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref2, cursor]);

  useEffect(() => {
    const input = ref3.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref3, cursor]);

  const cursorPos = (e) => {
    const selectionStart = e.target.selectionStart;
    setTimeout(() => {
      e.target.setSelectionRange(selectionStart, selectionStart);
    }, 0);
  };

  const handleChange = (e, r, x, z, a) => {
    setCursor(e.target.selectionStart);
    const value = e.target.value;
    const clearErrorsAndSetEntityValue = async (
      key,
      entityKey,
      errorMessage
    ) => {
      clearErrorMessages(key);

      if (key === "gst") {
        // setGstVerify()
        //  pendingErrors["gstVerify"] = "";
        //   setPendingErrors(pendingErrors);
        // Entity.branch[0].natureOfBusinessActivity =""
        // Entity.entityName = ""


      if(gstData){
          Entity.entityName = ""
          Entity.branch[0].branchAddresses[0].address = ""
          Entity.branch[0].branchAddresses[0].city = ""
        Entity.branch[0].state= ""
        Entity.branch[0].branchAddresses[0].pincode =""
        Entity.branch[0].natureOfBusinessActivity =""

        }
        // if(Object.keys(gstAvailable).length==0 &&  Entity.branch[0].natureOfBusinessActivity  ){
        //   Entity.entityName = ""
        //   Entity.branch[0].branchAddresses[0].address = ""
        //   Entity.branch[0].branchAddresses[0].city = ""
        //   Entity.branch[0].state= ""
        //   Entity.branch[0].branchAddresses[0].pincode =""
        //   Entity.branch[0].natureOfBusinessActivity =""
        // } {
        //   Entity.branch[0].branchAddresses[0].address = ""
        //   Entity.branch[0].branchAddresses[0].city = ""
        //   Entity.branch[0].state= ""
        //   Entity.branch[0].branchAddresses[0].pincode =""
        // }


        setGstData()
        setGstVerify()


        if (value.length === 15) {
          // let response;

          await EntityApi.checkGst(value.toUpperCase(), (response) => {
            if (response?.data?.entityId !== Entity?.entityId) {
              setGstAvailable(response);
            }
          });

          clearErrorMessages(x);
        } else if (value.length === 14) {
          Entity.branch[0].natureOfBusinessActivity=""
          setGstAvailable({});
          clearErrorMessages(x);
        }

      }
      if (entityKey) {
        Entity[entityKey] = trimStr(value);
      }
      if (errorMessage) {
        setErrors({ ...errors, [key]: errorMessage });
      }
    };

    const handleValidation = (key, regex) => {
      if (!regex.test(value)) {
        setErrors({ ...errors, [key]: "Invalid input" });
        return null;
      }
    };

    const handlePanValidation = async (e) => {
      if (value.length === 10) {
        await EntityApi.checkPan(value.toUpperCase(), setPanAvailable);
        clearErrorMessages("panNum");
      } else if (value.length === 9) {
        setPanAvailable({});
        clearErrorMessages("panNum");
      } else if (value.length > 10) {
        return null;
      }
      Entity.panNum = trimStr(value.toUpperCase());
      cursorPos(e);
    };

    const handleTinValidation = async () => {
      if (value.length >= 9 && value.length <= 20) {
        await EntityApi.checkTin(value.toUpperCase(), setTinAvailable);
      } else if (value.length < 9) {
        setTinAvailable({});
      }
      Entity.tinNum = trimStr(value.toUpperCase());
      cursorPos(e);
    };

    switch (r) {
      case "branch":
        // if (x === "gst") {
        clearErrorsAndSetEntityValue(x);
        // }

        if (x === "gst") {
          // setCursor(e.target.selectionStart)
          if (value.length > 15) {
            return null;
          }
          cursorPos(e);
          return (Entity.branch[0].gst = trimStr(value.toUpperCase()));
        }

        if (x === "pincode") {
          if (trimStr(value).length > 6) {
            return null;
          }

          handleValidation(x, /^([0-9]{0,6})$/);
          return (Entity.branch[0].branchAddresses[0][x] = trimStr(value));
        }
        if (x === "zipcode") {
          if (trimStr(value).length > 10) {
            return null;
          }

          handleValidation(x, /^([0-9]{0,10})$/);
          return (Entity.branch[0].branchAddresses[0][x] = trimStr(value));
        }

        if (Entity.branch.length === 0) {
          Entity.branch[0].gst = "";
        }
        if (x === "address") {
          return (Entity.branch[0].branchAddresses[0][x] = trimStr(value.replace(/[^\x00-\x7F]/g, '')));
        }
        if (x === "city") {
          return (Entity.branch[0].branchAddresses[0][x] = trimStr(value.replace(/[^\x00-\x7F]/g, '')));
        }

        Entity.branch[0][x] = trimStr(value.replace(/[^\x00-\x7F]/g, ''));
        break;

      case "panNum":
        clearErrorMessages(r);
        handlePanValidation(e);
        // cursorPos(e)
        break;

      case "IECCode":
        pendingErrors[r] = "";
        setPendingErrors(pendingErrors);
        cursorPos(e);
        Entity.IECCode = trimStr(value.toUpperCase());

        break;

      case "tinNum":
        handleValidation(r, /^([A-Za-z0-9-_ ]{0,11})$/);
        handleTinValidation(e);
        // cursorPos(e)
        break;
      case "erpnumber":
        // handleValidation(r, /^[A-Z]{3}\/[A-Z]{2}\/\d{2}\/\d{2}\/[A-Z]\/\d{4}$/);
        handleValidation(r, /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{}|;:'",.<>?/\\]+$/);
        // cursorPos(e)
        Entity.erpnumber = value;
        clearErrorMessages("erpnumber");
        break;
      case "customer":
        const customerValue = trimStr(value);
        errors.entityCategory =
          customerValue.length > 0 ? "" : "Please select a valid option.";
        setErrors(errors);

        Entity.customer[x] = customerValue;
        clearErrorMessages(x);
        break;
      case "crossBorder":
        const customerValue1 = trimStr(value);

        if(Entity?.customer?.crossBorder){
        } else {
          Entity.customer.crossBorder = new Customer().crossBorder;
          Entity.customer.domesticTransport = new Customer().domesticTransport;
        }
        if (x === "customerService") {
          pendingErrors.Customer_Service =
            customerValue1.length > 0 ? "" : "Please select a valid option.";
        }
        if (x === "relationshipManager") {
          pendingErrors.CB_relationshipManager =
            customerValue1.length > 0 ? "" : "Please select a valid option.";
        }
        if(x === "CB_agragaBranch") {
          pendingErrors.CB_agragaBranch =
            customerValue1.length > 0 ? "" : "Please select a valid option.";
        }

        setPendingErrors(pendingErrors);

        Entity.customer.crossBorder[x] = customerValue1;
        AguserApi.getList(setLists);
        clearErrorMessages(x);
        break;
      case "domesticTransport":
        const customerValue2 = trimStr(value);
        if(Entity?.customer?.domesticTransport){
        } else {
          Entity.customer.crossBorder = new Customer().crossBorder;
          Entity.customer.domesticTransport = new Customer().domesticTransport;
        }
        if (x === "DT_customerService") {
          pendingErrors.DT_customerService =
            customerValue2.length > 0 ? "" : "Please select a valid option.";
          Entity.customer.domesticTransport["customerService"] = customerValue2;
        }
        if (x === "DT_relationshipManager") {
          pendingErrors.DT_relationshipManager =
            customerValue2.length > 0 ? "" : "Please select a valid option.";
          Entity.customer.domesticTransport["relationshipManager"] =
            customerValue2;
        }
        if (x === "branchOperationManager") {
          pendingErrors.DT_branchOperationManager =
            customerValue2.length > 0 ? "" : "Please select a valid option.";
          Entity.customer.domesticTransport["branchOperationManager"] =
            customerValue2;
        }

        if (x === "billingCycle") {
          pendingErrors.billingCycle =
            customerValue2.length > 0 ? "" : "Please select a valid option.";
          Entity.customer.domesticTransport["billingCycle"] = customerValue2;
        }

        if (x === "podInvoiceAcceptance") {
          pendingErrors.podInvoiceAcceptance =
            customerValue2.length > 0 ? "" : "Please select a valid option.";
          Entity.customer.domesticTransport["podInvoiceAcceptance"] =
            customerValue2;
        }

        setPendingErrors(pendingErrors);

        clearErrorMessages(x);
        break;
      case "vendor":
        const vendorValue = trimStr(value);
        errors.entityCategory =
          vendorValue.length > 0 ? "" : "Please select a valid option.";
        setErrors(errors);
        if (x === "handlingCharges") {
          if (trimStr(value).length > 10) {
            return null;
          }
          Entity.vendor[x][z][a] = vendorValue;
        } else if (x === "relationShipManagerSupply") {
          Entity.vendor[x] = vendorValue;
          AguserApi.getList(setLists);
        } else if (x === "creditDays") {
          const newValueCreditDays = parseInt(value, 10);
          if (newValueCreditDays < 1 || newValueCreditDays > 90) {
            setCreditDaysError("Value must be between 1 and 90");
          } else {
            setCreditDaysError(null);
            Entity.vendor[x] = newValueCreditDays.toString();
          }
        } else if (x === "creditLimit") {
          if (trimStr(value).length > 10) {
            return null;
          }
          handleValidation(x, /^([0-9]{0,10})$/);
          Entity.vendor[x] = vendorValue;
        } else if (x === "vendorType") {
          Entity.vendor.isSoleProp = false;
          Entity.vendor.isMsme = false;
          Entity.vendor[x] = trimStr(value);
        } else if (x === "accountHolderName") {
          if (vendorValue.length > 100) {
            return null;
          }
          Entity.vendor[x] = vendorValue;
        } else if (x === "swiftCode") {
          if (vendorValue.length > 11) {
            return null;
          }
          Entity.vendor[x] = vendorValue.toUpperCase();
        } else if (x === "IFSCcode") {
          if (vendorValue.length > 11) {
            return null;
          }
          Entity.vendor[x] = vendorValue.toUpperCase();
        } else {
          Entity.vendor[x] = vendorValue;
        }
        clearErrorMessages(x);
        break;

      case "entityCountry":
        const countryValue = trimStr(
          e.target.value.substring(0, e.target.value.indexOf("/"))
        );
        const code = trimStr(
          e.target.value.substring(
            e.target.value.indexOf("/") + 1,
            e.target.value.length
          )
        );
        Entity.entityCountryCode = code;
        Entity.branch[0].branchcountryCode = code;
        StatesforBranch(code);
        Entity.vendor.accountNo = "";
        Entity.vendor.accountHolderName = "";
        Entity.vendor.bankName = "";
        errors[r] =
          countryValue.length > 0 ? "" : "Please select a valid option.";
        setErrors(errors);
        Entity[r] = countryValue;
        AguserApi.getList(setLists);
        break;

      case "advanceReceivableForDT":
        const newValueReceivable = parseInt(value, 10);
        if (newValueReceivable < 1 || newValueReceivable > 100) {
          setAdvanceError("Value must be between 1 and 100");
          return null;
        } else {
          setAdvanceError(null);
          Entity[r] = newValueReceivable.toString();
          Entity.branch[0][r] = newValueReceivable.toString();
        }

        break;

      case "advancePayable":
        const newValue = parseInt(value, 10);
        if (newValue < 1 || newValue > 100) {
          setAdvancePayableError("Value must be between 1 and 100");
          return null;
        } else {
          setAdvancePayableError(null);
          Entity[r] = newValue.toString();
          Entity.branch[0][r] = newValue.toString();
        }

        break;
      case "entityCategory":
        Entity.vendor.isSoleProp = false;
        Entity.vendor.isMsme = false;
        Entity[r] = trimStr(value);
        break;

      case "entityName":
      case "entityType":
        if (isAdminOrBranchHead && value === "Vendor") {
          Entity[r] = trimStr(value);
          Entity["entityCategory"] = VENDOR_CATEGORY.ROAD_TRANSPORT;
          Entity.vendor.vendorType = "Road Transport";
          Entity.scopeOfBusiness.pop("Cross Border");
        } else {
          Entity[r] = trimStr(value);
          Entity.scopeOfBusiness=["Cross Border"];
        }
        clearErrorMessages("entityType");
        break;

      case "agragaBranch":
        let re = new RegExp("^([A-Za-z0-9- ]{0,1})$");
        if (r === "entityName" && value.length < 2 && !re.test(value)) {
          return null;
        }
        if (isEditMode()) {
          Entity.vendor.relationShipManagerSupply = "";
        }
        const name = trimStr(value);
        errors[r] =
          name.length > 0
            ? ""
            : r === "entityName"
              ? "This field is required."
              : "Please select a valid option.";
        setErrors(errors);
        AguserApi.getList(setLists);
        Entity[r] = r === "aadharNum" ? (name.length > 12 ? null : name) : name;
        break;
      case "CB_salesVertical":
        // let ree = new RegExp("^([A-Za-z0-9- ]{0,1})$")
        // if (r === "entityName" && value.length < 2 && !ree.test(value)) {
        //   return null
        // }
        if (isEditMode() || isNewMode()) {
          Entity.customer.crossBorder.customerService = "";
          Entity.customer.crossBorder.relationshipManager = "";
          Entity.customer.crossBorder.agragaBranch = "";
        }
        const name3 = trimStr(value);
        if (r === "CB_salesVertical") {
          if(Entity?.customer?.crossBorder){
          } else {
            Entity.customer.crossBorder = new Customer().crossBorder;
            Entity.customer.domesticTransport = new Customer().domesticTransport;
          }
          Entity.customer.crossBorder.salesVertical = name3;
        }
        errors[r] =
          name3.length > 0
            ? ""
            : r === "entityName"
              ? "This field is required."
              : "Please select a valid option.";
        setErrors(errors);
        // AguserApi.getList(setLists);
        // Entity[r] = r === "aadharNum" ? (name1.length > 12 ? null : name1) : name1
        break;
      case "DT_agragaBranch":
        // let reee = new RegExp("^([A-Za-z0-9- ]{0,1})$")
        // if (r === "entityName" && value.length < 2 && !reee.test(value)) {
        //   return null
        // }
        if (isEditMode() || isNewMode()) {
          Entity.customer.domesticTransport.customerService = "";
          Entity.customer.domesticTransport.relationshipManager = "";
          Entity.customer.domesticTransport.branchOperationManager = "";
        }
        const name2 = trimStr(value);
        if (r === "DT_agragaBranch") {
          if(Entity?.customer?.domesticTransport){
          } else {
            Entity.customer.crossBorder = new Customer().crossBorder;
            Entity.customer.domesticTransport = new Customer().domesticTransport;
          }
          Entity.customer.domesticTransport.agragaBranch = name2;
        }
        errors[r] =
          name2.length > 0
            ? ""
            : r === "entityName"
              ? "This field is required."
              : "Please select a valid option.";
        setErrors(errors);
        AguserApi.getList(setLists);
        // Entity[r] = r === "aadharNum" ? (name1.length > 12 ? null : name1) : name1
        break;

      case "aadharNum":
        const aadhaarValue = trimStr(value);
        const maxLength = 12; // Set the maximum length

        if (aadhaarValue.length <= maxLength) {
          errors[r] = aadhaarValue.length > 0 ? "" : "This field is required.";
          Entity[r] = aadhaarValue; // Update the value if it's within the allowed length
        } else {
          errors[r] = `Maximum length is ${maxLength}.`;
        }

        setErrors(errors);
        clearErrorMessages(r);
        break;

      case "scopeOfActivityCB":
        clearErrorMessages(x);
        break;

      default:
        Entity[r] = trimStr(value);
        break;
    }
  };
  const logvalue = debounce(() => {
    // console.log("uuuuu", panummm);
    // handleChange(e , "panNum")
    Entity.panNum = panummm;
  }, 1000);
  const debouncedOnChange = (e) => {
    setpanummm(e.target.value);
    // console.log(e.target.value);
    logvalue();
    // debounce(handleChange, 100);}
  };

  const StatesforBranch = (code) => {
    const states = State.getStatesOfCountry(code);
    setstates(states);
  };
  const selectState = (e) => {
    clearErrorMessages("state");
    const statee = State.getStateByCodeAndCountry(
      e.target.value,
      Entity?.entityCountryCode
    );
    Entity.branch[0].state = statee?.name;
    setstateCode(statee?.isoCode);
    const cities = City?.getCitiesOfState(
      Entity?.entityCountryCode,
      statee?.isoCode
    );
    setcities(cities);
  };
  const selectStateServiceAbl = async (e, type, index) => {
    if (serviceArray.length !== 0) {
      const array = [...serviceArray];
      array[index][type] = e;
      setserviceArray(array);
    }
  };

  const selectCityServiceAbl = (e, type, index) => {
    const array = [...serviceArray];
    if (serviceArray.length !== 0) {
      array[index][type] = e;
      setserviceArray(array);
    }
  };

  const cancelpopup = () => {
    setVerifyPopup(false);
    setserviceability(false);
    setApprovalStatus("");
    setserviceArray(Entity?.vendor?.tradeLine);
    setRemarks("");
    document.getElementById("editEntity").style.filter = "none";
    document.getElementById("editEntity").style.pointerEvents = "auto";
  };

  const shouldSelectOption = (optionType, selectedValue) => {

    return (isEditMode() || isViewMode()) && optionType === selectedValue;
  };
  // const shouldSelectOption = (optionType, selectedValue) => {
  //   if (Entity && Entity.customer && Entity.customer.crossBorder    ) {
  //     Entity.customer.crossBorder.agragaBranch = "default";
  //     Entity.customer.crossBorder.relationshipManager = "default";
  //     Entity.customer.crossBorder.customerService = "default";
  //   } else {
  //     console.error("Entity or nested properties are not defined");
  //     return false;
  //   }
  
  //   if (typeof isEditMode !== 'function' || typeof isViewMode !== 'function') {
  //     console.error("isEditMode or isViewMode functions are not defined");
  //     return false;
  //   }
  
  //   return (isEditMode() || isViewMode()) && optionType === selectedValue;
  // };
  
  // console.log(">>>>11111111111",Entity.Country)
  // console.log(">>>>><<<<<",Entity.erpnumber)
  const onFormSubmit = async () => {
    setloading(true);
    if(displayStatus == "ON"){
      Entity.paymentEnable = displayStatus
    } else{
      Entity.paymentEnable = displayStatus
    }
    if (JSON.stringify(Entity) === JSON.stringify(formObject)) {
      setloading(false);
      return configStore.setModalMessage(
        "Please Check the values, we got same value before we Stored"
      );
    }

    if (!Entity.vendor.handlingCharges.perAWB.currency)Entity.vendor.handlingCharges.perAWB.currency = "INR"
    if (!Entity.vendor.handlingCharges.perBL.currency)Entity.vendor.handlingCharges.perBL.currency = "INR"
    if (!Entity.vendor.handlingCharges.perCbm.currency)Entity.vendor.handlingCharges.perCbm.currency = "INR"
    if (!Entity.vendor.handlingCharges.perContainer.currency)Entity.vendor.handlingCharges.perContainer.currency = "INR"
    if (!Entity.vendor.currency)Entity.vendor.currency = "INR"
    if (EntityUtils.isCustomer(Entity)) {
      if (!Entity.scopeOfBusiness.includes("Cross Border")) {
        Entity.customer.crossBorder.agragaBranch = ""
        Entity.customer.crossBorder.customerService = ""
        Entity.customer.crossBorder.relationshipManager = ""
      }
      if (!Entity.scopeOfBusiness.includes("Domestic Transport")) {
        Entity.customer.domesticTransport.agragaBranch = ""
        Entity.customer.domesticTransport.branchOperationManager = ""
        Entity.customer.domesticTransport.customerService = ""
        Entity.customer.domesticTransport.relationshipManager = ""
      }
    }
    const fieldsCheck = await doMandatoryCheck()
    let obj1 = Entity.logisticInformation
    let checkLogisticInformationError = ""
    // let checkLogisticInfoError = ""
    // if(isNewMode()){
    //   obj.forEach(obj => {
    //     Object.keys(obj).forEach(key => {
    //       if (obj[key] !== "") {
    //         console.log("ttututut",true);
    //         const fieldsCheck1 =  dovalidationcheck1();
    //         checkLogisticInformationError = fieldsCheck1

    //       }
    //     });
    //   });
    // }else{
    //   obj.forEach(obj => {
    //     Object.keys(obj).forEach(key => {
    //       if (obj[key] !== "") {
    //         console.log("ttututut",true);
    //         const fieldsCheck1 =  dovalidationcheck1();
    //         checkLogisticInfoError = fieldsCheck1

    //       }
    //     });
    //   });
    //   const fieldsCheck1 = await dovalidationcheck1();
    //   checkLogisticInfoError = fieldsCheck1

    // }
    // function test(objects){
    //   return objects.every(obj =>
    //     Object.values(obj).some(value => value !== "")
    //   );
    // }
    // function test1(objects){
    //   return objects.every(obj =>
    //     Object.values(obj).every(value => value === "")
    //   );
    // }
    //   let checkAllTrackmyBooking = false
    //   if (Entity.isTrackMyBooking == true) {
    //     if (!Entity.isAirChecked && !Entity.isOceanChecked && !Entity.isDTChecked) {
    //         configStore.setModalMessage(`Please select atleast one Track My Bookings.`);
    //         checkAllTrackmyBooking = true
    //           setloading(false);
    //           return
    //     }

    // }
    function test2(objects) {
      return objects.every((obj) =>
        Object.values(obj).every((value) => value === "")
      );
    }
    // let cin = await test(obj1)
    // let cio = await test1(obj1)
    let CheckEmptyValues = await test2(obj1);
    // console.log("?????>>>>>",CheckEmptyValues)
    if (!CheckEmptyValues) {
      const fieldsCheck1 = await dovalidationcheck1();
      checkLogisticInformationError = fieldsCheck1;
    }
    if(Entity.status===ENTITY_STATUS.REJECTED || !isNewMode()) {
      const fieldsCheck1 = await dovalidationcheck1();
      checkLogisticInformationError = fieldsCheck1;
    }

    // console.log(">>>>>>>>>>>>>>>>",checkLogisticInformationError)
    if (isNewMode()) {
      Entity.createdBy = loginStore.email;
    } else if (isEditMode()) {
      Entity.updatedBy = loginStore.email;
    }
    let isFormValid = fieldsCheck.mandatoryCheck;
    Entity.mandatoryCheck = isFormValid;
   const timelineCheck =  Entity.timeline[Entity.timeline.length-1]?.event == "Rejection-Acknowledged"
   const timelineCheckIsRejected =  Entity.timeline[Entity.timeline.length-2]?.event == "Rejected"
   const timelineCheckIsCreated =  Entity.timeline[Entity.timeline.length-3]?.event == "Created"
   const timelineCheckIsResubmited =  Entity.timeline[Entity.timeline.length-3]?.event == "Resubmited"
    //  const timelineCheckIsCReVerified =  Entity.timeline[Entity.timeline.length-2]?.event == "Created"
    //  const timelineCheckIsVerified =  Entity.timeline[Entity.timeline.length-1]?.event == "Verified"

    // if (!isVerifiedStatus()) {
    //   Entity.status = isFormValid
    //     ? ENTITY_STATUS.PENDING
    //     : ENTITY_STATUS.INCOMPLETE;
    // }
    // if (isFormValid && Entity.status === "PENDING" && timelineCheckIsCReVerified && timelineCheckIsVerified) {
    //   Entity.timeline.push({
    //     date: Date.now(),
    //     event: "Resubmited",
    //     user: loginStore.email,
    //     role: RoleUtils.getUserRole()
    //   })
    // }
    if (EntityUtils.isVendor(Entity)) {
      Entity.vendor.gstType = gstType;
      Entity.branch[0]["advancePayable"] = Entity["advancePayable"];
      Entity.branch[0]["agragaBranch"] = Entity.agragaBranch;
      Entity.branch[0]["relationShipManagerSupply"] =
      Entity.vendor.relationShipManagerSupply;
    }
    if (EntityUtils.isCustomer(Entity)) {
      if (Entity?.branch && Entity?.branch?.length > 0) {
        // Entity?.branch?.forEach((branchElement) => {
        const branchElement = Entity?.branch[0];
        branchElement["scopeOfBusiness"] = Entity.scopeOfBusiness;
        branchElement["crossBorder"] = Entity.customer.crossBorder;
        branchElement["domesticTransport"] = Entity.customer.domesticTransport;
        // });
      }
    }
    // console.log(isFormValid,!gstAvailable.status,!fieldsCheck.isError,!checkLogisticInformationError.isError);
    // console.log(!isVerifiedStatus() || (isVerifiedStatus() && isFormValid));
    // console.log("C",fieldsCheck.isError,checkLogisticInformationError.isError,gstAvailable.status,isFormValid)

    const branch = Entity?.branch[0]
    if (EntityUtils.isIndia(Entity) && branch?.gst && Entity.panNum && branch?.gst?.length === 15 && Entity?.panNum?.length === 10) {
      const extractedPan = EntityUtils.extractPanFromGST(branch?.gst)
      if (extractedPan !== Entity.panNum) {
        configStore.setModalMessage("PAN & GST Mismatch, please rectify it")
        setloading(false)
        return
      }
    }


    if (EntityUtils.isCustomer(Entity) && EntityV2Store.EntityRec.isTrackMyBooking === "Yes") {
      if (
        !EntityV2Store.EntityRec.isAirChecked &&
        !EntityV2Store.EntityRec.isOceanChecked &&
        !EntityV2Store.EntityRec.isDTChecked
      ) {
        configStore.setModalMessage(`Please select at least one Solution for Track My Booking`);
        setloading(false);
        return
      }
    }

if (
      !fieldsCheck.isError &&
      !checkLogisticInformationError.isError &&
      // !checkLogisticInfoError.isError &&
      // !checkAllTrackmyBooking &&
      // !fieldsCheck1.isError &&
      !gstAvailable.status &&
      (!isVerifiedStatus() || (isVerifiedStatus() && isFormValid))
    ) {
      if (isEditMode() || isNewMode()) {
        if (!fieldsCheck.isPendingErrors) {
          if (!isVerifiedStatus()) {
            Entity.status = isFormValid
              ? ENTITY_STATUS.PENDING
              : ENTITY_STATUS.INCOMPLETE;
          }
          if (isFormValid && Entity.timeline.length === 0 && Entity.status === "PENDING") {
            Entity.timeline.push({
              date: Date.now(),
              event: "Created",
              user: loginStore.email,
              role: RoleUtils.getUserRole()
            })
          }
          if (isFormValid && timelineCheck && Entity.status === "PENDING" && timelineCheckIsRejected && (timelineCheckIsCreated || timelineCheckIsResubmited)) {
            Entity.timeline.push({
              date: Date.now(),
              event: "Resubmited",
              user: loginStore.email,
              role: RoleUtils.getUserRole()
            })


          }
          if (isNewMode()) {
            loader.show();

            await EntityApi.paymentEnable({
                entityId:Entity?._id,
                displayStatus:displayStatus,
            })


            await EntityApi.createEntity(setloading);
            loader.hide();
            if(props.fn){
              AgmodalStore.pageVisible = false;
              AgmodalStore.apiCall = true;
              return routerStore.creditApplicationView ="list"
            }
          } else {
            loader.show();

            if(!Entity.funded_Id){
              await EntityApi.updateEntity(
                Entity?._id,
                Entity?.branch[0]?.gst,
                setloading
              );

              await EntityApi.paymentEnable({
                entityId:Entity?._id,
                displayStatus:"OFF",
              })
            } else {
              await EntityApi.paymentEnable({
                entityId:Entity?._id,
                displayStatus:displayStatus,
              })
              await EntityApi.updateEntity(
                Entity?._id,
                Entity?.branch[0]?.gst,
                setloading
              );
            }



            loader.hide();
            if(props.fn){
              AgmodalStore.pageVisible = false;
              AgmodalStore.apiCall = true;    
              return routerStore.creditApplicationView ="list"
            }
            // Entity.erpnumber = ""

          }
          AgmodalStore.pageVisible = false;
          AgmodalStore.apiCall = true;          
        } else {
          configStore.setModalMessage("Recheck the fields");
          setloading(false);
        }
      } else {
        if (!isVerifiedStatus()) {
          Entity.status = isFormValid
            ? ENTITY_STATUS.PENDING
            : ENTITY_STATUS.INCOMPLETE;
        }
        if (isFormValid && Entity.timeline.length === 0 && Entity.status === "PENDING") {
          Entity.timeline.push({
            date: Date.now(),
            event: "Created",
            user: loginStore.email,
            role: RoleUtils.getUserRole()
          })
        }
        if (isFormValid && timelineCheck && Entity.status === "PENDING" && timelineCheckIsRejected && (timelineCheckIsCreated || timelineCheckIsResubmited)) {
          Entity.timeline.push({
            date: Date.now(),
            event: "Resubmited",
            user: loginStore.email,
            role: RoleUtils.getUserRole()
          })
        }
        loader.show();
        await EntityApi.createEntity(setloading);
        // Entity.erpnumber = ""
        setloading(false);
        loader.hide();
      }
    } else {
      configStore.setModalMessage("Enter all required fields");
      setloading(false);
    }

    // Entity.erpnumber = ""
  };

  const validateForm = async (fieldsCheck) => {
    if (!fieldsCheck.isError && !gstAvailable.status) {
      if (isEditMode() || isNewMode()) {
        if (!fieldsCheck.isPendingErrors && !fieldsCheck.documentErrors) {
          if (isNewMode()) {
            await EntityApi.createEntity(setloading);
          } else {
            await EntityApi.updateEntity(
              Entity?._id,
              Entity?.branch[0]?.gst,
              setloading
            );
          }
        } else {
          if (fieldsCheck.isPendingErrors) {
            configStore.setModalMessage("Recheck the fields");
          } else if (fieldsCheck.documentErrors) {
            configStore.setModalMessage("Upload all documents");
          } else {
            configStore.setModalMessage("Recheck");
          }
        }
      } else {
        setloading(true);
        await EntityApi.createEntity(setloading);
      }
    } else if (fieldsCheck.documentErrors) {
      configStore.setModalMessage("Upload all documents");
    } else {
      configStore.setModalMessage("Enter all required fields");
    }
  };

  const updateStatusForEntity = () => {
    setloading(true);
    if (approvalStatus === "approve" && approveCheckbox) {
      Entity.status = ENTITY_STATUS.VERIFIED;
      Entity.verifiedBy = loginStore.email;
    } else if (approvalStatus === "reject" && remarks.trim() !== "") {
      Entity.status = ENTITY_STATUS.REJECTED;
      Entity.remarks = remarks;
      Entity.rejectedBy = loginStore.email;
    }
    Entity.branch[0].status = Entity.status;
    EntityApi.updateEntity(Entity?._id, Entity?.branch[0]?.gst, setloading);
    setloading(false);
  };

  useEffect(() => {
    AguserApi.getList(setLists);
    if (domain === "agraga.com")
      AguserApi.getUsers4role(Roles.CLADMIN, setCadminList);
    else {
      let list = [
        {
          email: loginStore.email,
          firstName: loginStore.userRec.aguserRec.firstName,
          lastName: loginStore.userRec.aguserRec.lastName,
        },
      ];
      setCadminList(list);
    }
    if(Entity.timeline === null || !Entity.timeline) {
      Entity.timeline = []
    }
  }, []);

  useEffect(() => {
    if (panAvailable.status) {
      pendingErrors.panNum = `PAN Exists - ${panAvailable?.data?.entityId}`;
      setPendingErrors(pendingErrors);
    } else {
      clearErrorMessages("panNum");
    }
  }, [panAvailable]);

  useEffect(() => {
    if (tinAvailable.status) {
      pendingErrors.tinNum = `TIN Exists - ${tinAvailable?.data?.entityId}`;
      setPendingErrors(pendingErrors);
    } else {
      clearErrorMessages("tinNum");
    }
  }, [tinAvailable]);

  useEffect(() => {
    const input = ref1.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref1, cursor]);

  const addNewTradeline = () => {
    const array = [...serviceArray];

    array.push(tradeLine[0]);
    setserviceArray(array);
    const cityArray = [...serviceabilityCities];
    cityArray.push(tradeLineCities[0]);
    setserviceabilityCities(cityArray);
  };
  const removeTradeline = (i) => {
    const array = [...serviceArray];
    if (array.length > 1) {
      array.splice(i, 1);
    }
    setserviceArray(array);
  };
  const doMandatoryCheck = async () => {
    function isValid(value) {
      return (
        (!Array.isArray(value) &&
          value &&
          value.trim().length > 0 &&
          value !== "default") ||
        (Array.isArray(value) &&
          value.some((item) => item.trim() !== "default"))
      );
    }

    function getSelectedDropDownValue(id) {
      let dropdownSelect = document.getElementById(`${id}`);
      return dropdownSelect ? dropdownSelect.value : null;
    }

    function getSelectedInputValue(id) {
      return document.getElementById(`${id}`)?.value;
    }

    function getSelectedDocumentValue(id) {
      if (document.getElementById(`${id}`) !== null) {
        let obj = Entity.documents.filter((r) => r.name === `${id}`);
        if (obj[0]?.fileLink.length > 0) {
          return obj[0].fileLink;
        }
      }
    }

    function getSelectedValuesFromMultipleSelectCheckbox(id) {
      const checkboxes = document.querySelectorAll(
        `input[type="checkbox"][id="${id}"]:checked`
      );
      const selectedValues = Array.from(checkboxes).map(
        (checkbox) => checkbox.value
      );

      return selectedValues.length > 0 ? selectedValues : null;
    }

    let fieldsWithValues = {
      entityName: getSelectedInputValue("entityName"),
      erpnumber: getSelectedInputValue("erpnumber"),
      entityType: getSelectedDropDownValue("entityType"),
      entityCategory: getSelectedDropDownValue("entityCategory"),
      entityCountry: getSelectedDropDownValue("entityCountry"),
      panNum: getSelectedInputValue("panNum"),
      agragaBranch: getSelectedDropDownValue("agragaBranch"),
      relationshipManager: getSelectedDropDownValue("relationshipManager"),
      customerService: getSelectedDropDownValue("customerService"),
      branchOperationManager: getSelectedDropDownValue(
        "branchOperationManager"
      ),
      relationShipManagerSupply: getSelectedDropDownValue(
        "relationShipManagerSupply"
      ),
      panCard: getSelectedDocumentValue("panCard"),
      iecDoc: getSelectedDocumentValue("IEC CODE"),
      gst: getSelectedInputValue("gst"),
      gstCertificate: getSelectedDocumentValue("gstCertificate"),
      gstDeclaration: getSelectedDocumentValue("gstDeclaration"),
      tinNum: getSelectedInputValue("tinNum"),
      tinCert: getSelectedDocumentValue("tinCert"),
      taxresCert: getSelectedDocumentValue("taxresCert"),
      address: getSelectedInputValue("address"),
      state: getSelectedDropDownValue("state"),
      city: getSelectedDropDownValue("city"),
      pincode: getSelectedInputValue("pincode"),
      zipcode: getSelectedInputValue("zipcode"),
      operatorType: getSelectedDropDownValue("operatorType"),
      fileVendorAgreement: getSelectedDocumentValue("fileVendorAgreement"),
      MsmeAgreement: getSelectedDocumentValue("MsmeAgreement"),
      InvoiceFormat: getSelectedDocumentValue("InvoiceFormat"),
      accountHolderName: getSelectedInputValue("accountHolderName"),
      bankName: getSelectedInputValue("bankName"),
      accountNo: getSelectedInputValue("accountNo"),
      IFSCcode: getSelectedInputValue("IFSCcode"),
      swiftCode: getSelectedInputValue("swiftCode"),
      CkLeafOrBankStat: getSelectedDocumentValue("CkLeafOrBankStat"),
      advanceReceivableForDT: getSelectedInputValue("advanceReceivableForDT"),
      scopeOfActivityRT:
        getSelectedValuesFromMultipleSelectCheckbox("scopeOfActivityRT"),
      scopeOfActivityCB:
        getSelectedValuesFromMultipleSelectCheckbox("scopeOfActivityCB"),
      IECCode: getSelectedInputValue("IECCode"),
      aadharNum: getSelectedInputValue("aadharNum"),
      aadharCert: getSelectedDocumentValue("aadharCert"),
      loginUrl: getSelectedInputValue("loginUrl"),
      userId: getSelectedInputValue("userId"),
      password: getSelectedInputValue("password"),
      CB_agragaBranch: getSelectedDropDownValue("CB_agragaBranch"),
      CB_salesVertical: getSelectedDropDownValue("CB_salesVertical"),
      DT_agragaBranch: getSelectedDropDownValue("DT_agragaBranch"),
      DT_customerService: getSelectedDropDownValue("DT_customerService"),
      DT_relationshipManager: getSelectedDropDownValue(
        "DT_relationshipManager"
      ),
      billingCycle: getSelectedDropDownValue("billingCycle"),
      podInvoiceAcceptance: getSelectedDropDownValue("podInvoiceAcceptance"),
      scopeOfBusiness:
        getSelectedValuesFromMultipleSelectCheckbox("scopeOfBusiness"),
    };

    let fieldsToSubmitCommon = [
      { title: "Entity Name", field: "entityName", required: true },
      {
        title: "Entity Type",
        field: "entityType",
        required: true,
        dropdown: true,
      },
      {
        title: "Entity Country",
        field: "entityCountry",
        required: true,
        dropdown: true,
      },
      {
        title: "Entity Category",
        field: "entityCategory",
        required: true,
        dropdown: true,
      },
    ];
    let fieldsToValidateCommon = [
      { title: "Entity Name", field: "entityName", required: true },
      {
        title: "Entity Type",
        field: "entityType",
        required: true,
        dropdown: true,
      },
      {
        title: "Entity Country",
        field: "entityCountry",
        required: true,
        dropdown: true,
      },
      {
        title: "Entity Category",
        field: "entityCategory",
        required: true,
        dropdown: true,
      },
      { field: "address", required: true },
      { field: "state", required: true, dropdown: true },
      { field: "city", required: true, dropdown: true },
      { field: "erpnumber", required: false },
    ];

    let fieldsToValidate = [...fieldsToValidateCommon];
    let fieldsToSubmit = [...fieldsToSubmitCommon];

    if (EntityUtils.isCustomer(Entity)) {
      fieldsToSubmit.push(
        {
          field: "scopeOfBusiness",
          title: "Scope Of Business",
          // required: true,
          multiSelect: true,
        },
        ...fieldsToSubmitCommon
      );

      fieldsToValidate.push(
        {
          field: "scopeOfBusiness",
          title: "scope Of Business",
          // required: true,
          multiSelect: true,
        },
        ...fieldsToValidateCommon
      );
    } else {
      if (EntityUtils.isVendor(Entity)) {
        if (EntityUtils.isRoadTransport(Entity) && EntityUtils.isIndia(Entity)) {
          fieldsToSubmit.push({
            title: "Agraga Branch",
            field: "agragaBranch",
            required: true,
            dropdown: true,
          },
            {
              title: "Relationship Manager",
              field: "relationShipManagerSupply",
              required: true,
              dropdown: true,
            },
            ...fieldsToSubmitCommon)

          fieldsToValidate.push({
            title: "Agraga Branch",
            field: "agragaBranch",
            required: true,
            dropdown: true,
          }, {
            title: "Relationship Manager",
            field: "relationShipManagerSupply",
            required: true,
            dropdown: true,
          }, ...fieldsToValidateCommon)
        } else if(EntityUtils.isRoadTransport(Entity) && !EntityUtils.isIndia(Entity)){
          fieldsToSubmit.push(
            {
              title: "Relationship Manager",
              field: "relationShipManagerSupply",
              required: true,
              dropdown: true,
            },
            ...fieldsToSubmitCommon)

          fieldsToValidate.push({
            title: "Relationship Manager",
            field: "relationShipManagerSupply",
            required: true,
            dropdown: true,
          }, ...fieldsToValidateCommon)
        }
        // else {
        //   fieldsToSubmit.push({
        //     // title: "Agraga Branch",
        //     // field: "agragaBranch",
        //     // required: true,
        //     // dropdown: true,
        //   }, ...fieldsToSubmitCommon)
        //   fieldsToValidate.push(
        //   //   {
        //   //   title: "Agraga Branch",
        //   //   field: "agragaBranch",
        //   //   required: true,
        //   //   dropdown: true,
        //   // },{
        //   // title: "Relationship Manager",
        //   // field: "relationShipManagerSupply",
        //   // required: true,
        //   // dropdown: true,},
        //    ...fieldsToValidateCommon)
        // }
      }
    }

    if (
      EntityUtils.isCustomer(Entity) &&
      Entity.scopeOfBusiness.includes("Cross Border")
    ) {
      let CB_agragaBranch = [
        {
          title: "CB Sales Vertical",
          field: "CB_salesVertical",
          required: true,
          dropdown: true,
        },
        {
          title: "CB Agraga Branch",
          field: "CB_agragaBranch",
          required: true,
          dropdown: true,
        },
        {
          title: "CB relationship Manager",
          field: "relationshipManager",
          required: true,
          dropdown: true,
        },
        {
          title: "CB Customer Service",
          field: "customerService",
          required: true,
          dropdown: true,
        },
      ];
      fieldsToSubmit.push(
        ...[
          {
            title: "CB Sales Vertical",
          field: "CB_salesVertical",
            required: true,
            dropdown: true,
          },
        ]
      );
      fieldsToValidate.push(...CB_agragaBranch);
    }

    if (
      EntityUtils.isCustomer(Entity) &&
      Entity.scopeOfBusiness.includes("Domestic Transport")
    ) {
      let DT_agragaBranch = [
        {
          title: "DT Agraga Branch",
          field: "DT_agragaBranch",
          required: true,
          dropdown: true,
        },
        {
          title: "DT relationship Manager",
          field: "DT_relationshipManager",
          required: true,
          dropdown: true,
        },
        {
          title: "DT Customer Service",
          field: "DT_customerService",
          required: true,
          dropdown: true,
        },
        {
          title: "branchOperationManager",
          field: "branchOperationManager",
          required: true,
          dropdown: true,
        },
      ];
      fieldsToSubmit.push(
        ...[
          {
            title: "DT Agraga Branch",
            field: "DT_agragaBranch",
            required: true,
            dropdown: true,
          },
        ]
      );
      fieldsToValidate.push(...DT_agragaBranch);
    }
    let errors = {};
    let fieldsCheck = {};
    let pendingErrors = {};
    let documentErrors = {};

    fieldsToSubmit.forEach(({ title, field, required, dropdown }) => {
      let value = fieldsWithValues[field];
      if (required && !isValid(value)) {
        errors[field] = `${title} is required.`;
        fieldsCheck.isError = true;
      }
      if (dropdown && value === "default") {
        if (field == "entityCategory") {
          errors[field] = `Please select a valid option`;
        } else {
          errors[field] = `Please select a valid ${title}.`;
        }
        // errors[field] = `Please select a valid option.`;

        fieldsCheck.isError = true;
      }
    });

    setErrors(errors);
    //india fields
    if (EntityUtils.isIndia(Entity)) {
      fieldsToValidate.push(
        { field: "panNum", required: true },
        {
          field: "panCard",
          required: true,
          document: true,
        },
        // { field: "IECCode", required: true },
        // {
        //   field: "iecDoc",
        //   required: true,
        //   document: true,
        // },
        { field: "pincode", required: true }
      );
    }

    //non-india fields
    else if (!EntityUtils.isIndia(Entity)) {
      fieldsToValidate.push(
        {
          field: "tinCert",
          required: true,
          document: true,
        },
        { field: "tinNum", required: true },
        { field: "taxresCert", required: true, document: true },
        { field: "zipcode", required: true }
      );
    }

    if (EntityUtils.isCustomer(Entity) && EntityUtils.isIndia(Entity)) {
      fieldsToValidate.push( { field: "IECCode", required: true } ,   {
        field: "iecDoc",
        required: true,
        document: true,
      },);
    }
    //vednor fields
    if (!EntityUtils.isCustomer(Entity)) {
      const commonFields = [
        { field: "accountHolderName", required: true },
        { field: "bankName", required: true },
        { field: "accountNo", required: true },
        { field: "CkLeafOrBankStat", required: true },
        { field: "loginUrl", required: false },
        { field: "userId", required: false },
        { field: "password", required: false },
        { field: "fileVendorAgreement", required: true, document: true },
        // { field: "relationShipManagerSupply", required: true, document: true },
      ];
      if (EntityUtils.isIndia(Entity)) {
        fieldsToValidate.push({ field: "IFSCcode", required: true });
      }

      if (!EntityUtils.isIndia(Entity)) {
        fieldsToValidate.push({ field: "swiftCode", required: true });
      }

      if (EntityUtils.isRoadTransport(Entity)) {
        fieldsToValidate.push(
          {
            field: "operatorType",
            required: true,
            dropdown: true,
          },
          {
            field: "scopeOfActivityRT",
            required: true,
            multiSelect: true,
          },
          ...commonFields
        );
      }
      if (EntityUtils.isCustomsBroker(Entity)) {
        fieldsToValidate.push(
          {
            field: "scopeOfActivityCB",
            required: true,
            multiSelect: true,
          },
          ...commonFields
        );
      }

      if (EntityUtils.isMsme(Entity)) {
        fieldsToValidate.push({
          field: "MsmeAgreement",
          required: true,
          document: true,
        });
      }

      if (EntityUtils.isSoleProp(Entity)) {
        fieldsToValidate.push(
          {
            field: "aadharNum",
            required: true,
          },
          {
            field: "aadharCert",
            required: true,
            document: true,
          }
        );
        if (EntityUtils.isIndia(Entity)) {
          if (gstType === "gstnumber") {
            fieldsToValidate.push(
              { field: "gst", required: true },
              { field: "gstCertificate", required: true, document: true },

              ...commonFields
            );
          } else {
            fieldsToValidate.push(
              { field: "gstDeclaration", required: true, document: true },

              ...commonFields
            );
          }
        }
      } else {
        fieldsToValidate.push(
          {
            field: "aadharNum",
            required: false,
          },
          {
            field: "aadharCert",
            required: false,
            document: true,
          },
          ...commonFields
        );

        if (EntityUtils.isIndia(Entity)) {
          if (gstType === "gstnumber") {
            fieldsToValidate.push(
              { field: "gst", required: true },
              { field: "gstCertificate", required: true, document: true },

              ...commonFields
            );
          } else {
            fieldsToValidate.push(
              { field: "gstDeclaration", required: true, document: true },

              ...commonFields
            );
          }
        }
      }
    }

    //customer fields
    else if (EntityUtils.isCustomer(Entity)) {
      fieldsToValidate
        .push
        // { field: "relationshipManager", required: true, dropdown: true },
        // { field: "customerService", required: true, dropdown: true },
        // { field: "branchOperationManager", required: true, dropdown: true }
        // { field: "gst", required: true },
        // { field: "gstCertificate", required: true, document: true }
        ();
      if (EntityUtils.isIndia(Entity)) {
        fieldsToValidate.push(
          { field: "gst", required: true },
          { field: "gstCertificate", required: true, document: true }
        );
      }
    }
    const regexPanPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    // const regexIECPattern = /^[A-Z]{5}[0-9]{5}$/;
    const regexIECPattern =  /^[A-Za-z0-9]{10}$/;
    const regexSwiftCodePattern = /^[A-Z]{6}[A-Z-0-9]{2,5}$/;
    const regexGSTPattern =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const regexTinPattern = /^[\w\s!@#$%^&*()-+=<>,.?/:;'[\]{}|~-]{1,20}$/;
    const regexAadhaarPattern = /^\d{12}$/;
    const regexAccNoPattern = /^[A-Za-z0-9\-\/]{1,20}$/;
    const regexIFSCCodePattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const regexUserIDPattern =
      /^[a-zA-Z0-9\s!@#$%^&*()_+{}\[\]:;"'<>,.?/~`|-]{3,30}$/;
    const regexPasswordPattern =
      /^[a-zA-Z0-9\s!@#$%^&*()_+{}\[\]:;"'<>,.?/~`|-]{3,20}$/;
    // const regexErpPattern = /^[A-Z]{3}\/[A-Z]{2}\/\d{2}\/\d{2}\/[A-Z]\/\d{4}$/;
    const regexErpPattern = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{}|;:'",.<>?/\\]+$/;
    const regexLoginUrlPattern =
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/;

    let hasErrors = false;
    // console.log("fields to validate ",fieldsToValidate);
    await fieldsToValidate.forEach(
      async ({ field, required, dropdown, multiSelect, document }) => {
        let value = fieldsWithValues[field];

        if (field === "panNum" && value?.length > 0) {
          if (!regexPanPattern.test(value.trim())) {
            panAvailable.status = false;
            pendingErrors[field] = "Invalid PAN format. Eg: ABCDE1234F";
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
          if (panAvailable?.status) {
            clearErrorMessages(field);
            pendingErrors[
              field
            ] = `PAN Exists - ${panAvailable?.data?.entityId}`;
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
        } else if (field === "panCard" && value === undefined && document) {
          documentErrors[field] = "Please upload";
          fieldsCheck.documentErrors = true;
          hasErrors = required;
        } else if (field === "iecDoc" && value === undefined && document) {
          documentErrors[field] = "Please upload";
          fieldsCheck.documentErrors = true;
          hasErrors = required;
        }else if (field === "gst" && value?.length > 0) {
          if (!regexGSTPattern.test(value.trim())) {
            gstAvailable.status = false;
            pendingErrors[field] =
              "Invalid GST format. Eg: 22AAAAA0000A1Z5 or 29ABCDE1234F1ZK";
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
          // await checkGSTAvailable(value);

          if (gstAvailable?.status) {
            clearErrorMessages(field);
            pendingErrors[
              field
            ] = `GST Exists - ${gstAvailable?.data?.entityId}`;
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
        } else if ((field === "IECCode" && value?.length > 0) || (field === "IECCode" && Entity?.documents[0]?.fileLink?.length > 0)) {
          if (!regexIECPattern.test(value?.trim()) || value?.length !== 10) {
            if(value?.trim().length === 0){
              pendingErrors[field] = "Must be 10 characters alphanumeric";
            } else {
              pendingErrors[field] = "Invalid IEC Code format. Must be 10 characters alphanumeric";
            }
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
        } else if (field === "tinNum" && value?.length > 0) {
          if (!regexTinPattern.test(value.trim())) {
            tinAvailable.status = false;
            pendingErrors[field] = "Invalid Tin Num format";
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
          if (tinAvailable?.status) {
            clearErrorMessages(field);
            pendingErrors[
              field
            ] = `TIN Exists - ${tinAvailable?.data?.entityId}`;
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
        } else if (field === "aadharNum" && value?.length > 0) {
          if (!regexAadhaarPattern.test(value.trim())) {
            pendingErrors[field] = "Invalid Aadhaar format. Eg: 123456781781";
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
        } else if (field === "accountNo" && value?.length > 0) {
          if (!regexAccNoPattern.test(value.trim())) {
            pendingErrors[field] =
              "Invalid Account Number format. Eg: 12345678912345678912";
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
        } else if (field === "IFSCcode" && value?.length > 0) {
          if (!regexIFSCCodePattern.test(value.trim())) {
            pendingErrors[field] = "Invalid IFSC code format. Eg: SBIN0005943";
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
        } else if (field === "swiftCode" && value?.length > 0) {
          if (!regexSwiftCodePattern.test(value.trim())) {
            pendingErrors[field] =
              "Invalid Swift Code format. Eg: ABCDEF23 Or ABCDEFT5G67";
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
        } else if (field === "loginUrl" && value?.length > 0) {
          if (!regexLoginUrlPattern.test(value.trim())) {
            pendingErrors[field] = "Invalid URL format.";
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
        } else if (field === "userId" && value?.length > 0) {
          if (!regexUserIDPattern.test(value.trim())) {
            pendingErrors[field] = "Min 3 max 30 alphanumeric characters";
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
        } else if (field === "password" && value?.length > 0) {
          if (!regexPasswordPattern.test(value.trim())) {
            pendingErrors[field] = "Invalid password. Max length is 20.";
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
        } else if (
          field === "erpnumber" &&
          value?.length > 0 &&
          value?.length < 20
        ) {
          if (!regexErpPattern.test(value.trim())) {
            pendingErrors[field] = "Invalid Erp Number Max length is 20.";
            fieldsCheck.isPendingErrors = true;
            hasErrors = required;
          }
        }

        if (required) {
          const isValueValid =
            (multiSelect && Array.isArray(value) && value.length > 0) ||
            (!multiSelect && isValid(value));

          const isDropdownValid =
            !dropdown ||
            (value !== "default" &&
              !(multiSelect && (!Array.isArray(value) || value.length === 0)));
          // console.log(field ,value ,"kkkk" , Array.isArray(value) , multiSelect );
          if (
            Entity.status === "VERIFIED" &&
            multiSelect &&
            !value?.length > 0
          ) {
            pendingErrors[field] = true;
          }

          const isDocumentValid = document ? value?.length > 0 : true;

          if (!isValueValid || !isDropdownValid || !isDocumentValid) {
            hasErrors = true;
          }
        }
      }
    );
    Entity.logisticInformation.forEach((e) => {
      if (e.contactnum === '' && e.customercontact === '' && e.department === '' && e.email === '') {
        hasErrors = true
      }
    })

    let mandatoryCheck = !hasErrors;

    setPendingErrors(pendingErrors);
    fieldsCheck.mandatoryCheck = mandatoryCheck;

    return fieldsCheck;
  };

  const checkGSTAvailable = async (value) => {
    try {
      await EntityApi.checkGst(value, (response) => {
        if (response?.data?.entityId !== Entity?.entityId) {
          setGstAvailable(response);
        }
      });
    } catch (error) {
    }
  };

  const checkCategoryDisabled = () => {
    const isView = isViewMode();
    const isEdit = isEditMode();
    const isNew = isNewMode();
    const isAdminBranch = isAdminOrBranchHead;
    let disabled = false;
    if (
      isView ||
      (isEdit &&
        (isPendingStatus() ||
          (isVerifiedStatus() && !EntityUtils.isCustomer(Entity)) ||
          isIncompleteStatus() ||
           RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT_ONLY)||
          isRejectStatus()))
    ) {
      disabled = true;
    } else if (isNew && !isAgragaAdmin) {
      if (isAdminBranch && Entity.entityType === "Vendor") {
        disabled = true;
      } else if (
        RoleUtils.isUserAuthorized(
          PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT
        )
      ) {
        disabled = true;
      }
    } else if (
      RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT)
    ) {
      disabled = true;
    }
    return disabled;
  };

  const renderOptions = (categoryValues, selectedValue, preSelectedOption) => {
    if (preSelectedOption === ENTITY_TYPE.CUSTOMER) {
      Entity.entityType = "Customer";
    } else if (preSelectedOption === ENTITY_TYPE.VENDOR) {
      Entity.entityType = "Vendor";
    } else if (preSelectedOption === VENDOR_CATEGORY.ROAD_TRANSPORT) {
      Entity.vendor.vendorType = "Road Transport";
    } else if (
      isAdminOrBranchHead &&
      Entity.entityType === ENTITY_TYPE.VENDOR
    ) {
      Entity.vendor.vendorType = "Road Transport";
      preSelectedOption = VENDOR_CATEGORY.ROAD_TRANSPORT;
    }
    return categoryValues?.map((type) => {
      const isSelected =
        shouldSelectOption(type, selectedValue) || type === preSelectedOption;
      return (
        <option key={type} selected={isSelected}>
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </option>
      );
    });
  };

  useEffect(() => {
    const fieldsToCheck = [
      { field: "state", type: "branch" },
      { field: "city", type: "branch" },
      { field: "gst", type: "branch" },
      { field: "gstDeclaration", type: "branch" },
      { field: "address", type: "branch" },
      { field: "pincode", type: "branch" },
      { field: "zipcode", type: "branch" },
      { field: "relationshipManager", type: "customer", subtype:"crossBorder" },
      { field: "agragaBranch", type: "customer", subtype:"crossBorder" },
      { field: "customerService", type: "customer", subtype:"crossBorder" },
      { field: "branchOperationManager", type: "customer", subtype:"domesticTransport" },
      { field: "relationShipManagerSupply", type: "vendor" },
      { field: "operatorType", type: "vendor" },
      { field: "bankName", type: "vendor" },
      { field: "accountHolderName", type: "vendor" },
      { field: "accountNo", type: "vendor" },
      { field: "IFSCcode", type: "vendor" },
      { field: "swiftCode", type: "vendor" },
      { field: "panNum" },
      { field: "IECCode" },
      { field: "tinNum" },
      { field: "aadharNum" },
      { field: "scopeOfActivityCB", type: "vendor", subtype: "scope" },
      { field: "scopeOfActivityRT", type: "vendor", subtype: "scope" },
    ];

    const checkField = (fieldData) => {
      const { field, type, subtype } = fieldData;

      switch (type) {
        case "branch": {
          if (field === "address") {
            return (
              isEditMode() &&
              Entity?.branch[0]?.branchAddresses[0]?.[field] === ""
            );
          } else if (field === "city") {
            return (
              isEditMode() &&
              Entity?.branch[0]?.branchAddresses[0]?.[field] === ""
            );
          } else if (field === "pincode") {
            return (
              isEditMode() &&
              Entity?.branch[0]?.branchAddresses[0]?.[field] === ""
            );
          } else if (field === "zipcode") {
            return (
              isEditMode() &&
              Entity?.branch[0]?.branchAddresses[0]?.[field] === ""
            );
          } else {
            return isEditMode() && Entity?.branch[0]?.[field] === "";
          }
        }
        case "customer":
          return isEditMode() && Entity?.customer?.[subtype][field] === "";
        case "vendor":
          if (subtype === "scope") {
            return isEditMode() && Entity?.vendor?.[field].length <= 0;
          }
          return isEditMode() && Entity?.vendor?.[field] === "";
        default:
          return isEditMode() && Entity?.[field] === "";
      }
    };

    fieldsToCheck.forEach((fieldData) => {
      pendingErrors[fieldData.field] = checkField(fieldData);
    });
  }, []);

  const setPanNumberValue = isEditMode()
    ? Entity?.panNum
    : isViewMode()
      ? Entity?.panNum
      : isNewMode()
        ? Entity?.panNum
        : "";

  const renderSalesOptions = (categoryValues, selectedValue) => {
    return categoryValues.map((type) => (
      <option
        key={type?._id}
        selected={shouldSelectOption(type?._id, selectedValue)}
        value={type._id}>
        {type?.firstName?.charAt(0).toUpperCase() +
          type?.firstName?.slice(1) +
          " " +
          type?.lastName +
          "  " +
          " ( " +
          type?._id +
          " ) "}
      </option>
    ));
  };

const renderBranchesCBOptions = (categoryValues, selectedValue, salesVertical) => {
  let branchesList =new Set();

  // console.log("Category Values:", categoryValues);
  // console.log("Selected Value:", selectedValue);
  // console.log("Sales Vertical:", salesVertical);

  sortedbranchesForCB.forEach(branch => {
      const users = agUsersList.filter(user => 
          user.branch === branch && 
          user?.vertical === VERTICALS.CROSS_BORDER && 
          user?.department === ROLES.CB.RELATIONSHIP_MANAGER &&
          user?.RMSalesVertical?.some(vertical => Entity?.customer?.crossBorder?.salesVertical === vertical)
      );

      // console.log("Checking branch:", branch);
      // console.log("Users found:", users);

      users.forEach(user => {
          // console.log("User RMSalesVertical:", user.RMSalesVertical);
          // console.log("Entity customer crossBorder salesVertical:", Entity?.customer?.crossBorder?.salesVertical);
          const verticalMatch = user?.RMSalesVertical?.some(vertical => Entity?.customer?.crossBorder?.salesVertical === (vertical));
          // console.log("Vertical Match:", verticalMatch);
          if (verticalMatch) {
              branchesList.add(user.branch);
              // console.log("Branch added:", user.branch);
          }
      });
  });

  // console.log("Branches List:", branchesList);

  if (branchesList.length === 0) {
      // console.log("No branches available");
      return (
          <option disabled value="">
              No branches available
          </option>
      );
  }

  return Array.from(branchesList).map((branch) => (
      <option
          key={branch}
          selected={shouldSelectOption(branch, selectedValue)}
          value={branch}
      >
          {branch}
      </option>
  ));
};

  

  const addRoadTransScope = (e, r) => {
    if (e.target.checked === true) {
      Entity.vendor.scopeOfActivityRT.push(r);
      if (isEditMode()) {
        pendingErrors.scopeOfActivityRT = false;
      }
      setPendingErrors(pendingErrors);
    } else if (
      e.target.checked === false &&
      (!isVerifiedStatus() ||
        (Entity.status === "VERIFIED" &&
          Entity.vendor.scopeOfActivityRT.length > 1))
    ) {
      Entity.vendor.scopeOfActivityRT = Entity.vendor.scopeOfActivityRT.filter(
        (e) => e !== r
      );
    }
  };
  const addscopeofbusiness = (e, r) => {
    // console.log(e, r);
    if (r === "Domestic Transport") {
      setDT_Checked(!DT_Checked);
    }
    if (r === "Cross Border") {
      setCB_Checked(!CB_Checked);
    }

    if (e.target.checked === true) {
      Entity.scopeOfBusiness.push(r);
      if (isEditMode()) {
        pendingErrors.scopeOfBusiness = false;
      }
      setPendingErrors(pendingErrors);
    } else if (
      e.target.checked === false &&
      (!isVerifiedStatus() ||
        (Entity.status === "VERIFIED" && Entity.scopeOfBusiness.length > 1))
    ) {
      Entity.scopeOfBusiness = Entity.scopeOfBusiness.filter((e) => e !== r);
    }

    if (Entity.scopeOfBusiness.length === 0) {
      errors.scopeOfBusiness = "Please select a valid option.";
    } else {
      errors.scopeOfBusiness = "";
    }
    setErrors(errors);
  };
  const approveBtnnfn = (e) => {
    if (e.target.checked == true) {
      document.getElementById("approvebtnid").style.opacity = "1";
      setappbtnstatus(false);
      // return (document.getElementById("approvebtnid").disabled = false);
    } else if (e.target.checked == false) {
      document.getElementById("approvebtnid").style.opacity = "0.4";
      setappbtnstatus(true);
      // return (document.getElementById("approvebtnid").disabled = true);
    }
  };
  const addCustomBrokerScope = (e, r) => {
    if (e.target.checked === true) {
      Entity.vendor.scopeOfActivityCB.push(r);
    } else if (
      e.target.checked === false &&
      (!isVerifiedStatus() ||
        (Entity.status === "VERIFIED" &&
          Entity.vendor.scopeOfActivityCB.length > 1))
    ) {
      Entity.vendor.scopeOfActivityCB = Entity.vendor.scopeOfActivityCB.filter(
        (e) => e !== r
      );
      // console.log(Entity.vendor.scopeOfActivityCB);
    }
  };


  function populateCategories() {
    //debugger
    let categories = [];
    const isIndia = EntityUtils.isIndia(Entity);
    if (EntityUtils.isCustomer(Entity)) {
      categories = Object.values(CUSTOMER_CATEGORY);
    } else if (EntityUtils.isVendor(Entity)) {
      const isAuthorizedRemoveVendor = RoleUtils.isUserAuthorized(
        PERMISSIONS.CREATE.NEW_ENTITYV2_REMVENDOR
      );
      const isAuthorizedRoadTransport = RoleUtils.isUserAuthorized(
        PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT
      );
      const isAuthorizedRoadTransportOnly = RoleUtils.isUserAuthorized(
        PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT_ONLY
      );

      if (isIndia) {
        if (isNewMode() && !isAgragaAdmin && !isAdminOrBranchHead) {
          categories = isAuthorizedRemoveVendor
            ? Object.values(VENDOR_CATEGORY)
            : isAuthorizedRoadTransport
              ? Object.values(VENDOR_CATEGORY).filter(
                (value) => value === "Road Transport"
              )
              : Object.values(VENDOR_CATEGORY);
        } else {
          categories = Object.values(VENDOR_CATEGORY);
        }
      } else {
        if (isNewMode() && !isAgragaAdmin && !isAdminOrBranchHead) {
          categories = isAuthorizedRemoveVendor
            ? Object.values(VENDOR_CATEGORY_NONIN)
            : isAuthorizedRoadTransport
              ? Object.values(VENDOR_CATEGORY_NONIN).filter(
                (value) => value === "Road Transport"
              )
              : Object.values(VENDOR_CATEGORY_NONIN);
        } else {
          categories = Object.values(VENDOR_CATEGORY_NONIN);
        }
      }
    } else {
      categories = null;
    }

    return categories;
  }

  const submitRemarks = (e) => {
    if (e.target.value.length > 3) {
      setappbtnstatus(false);
      return (document.getElementById("approvebtnid").style.opacity = "1");
    } else if (e.target.value.length < 3) {
      setappbtnstatus(true);
      return (document.getElementById("approvebtnid").style.opacity = "0.4");
    }
  };

  const openVerifyPopup = () => {
    setVerifyPopup(true);

    document.getElementById("editEntity").style.filter = "blur(15px)";
    document.getElementById("editEntity").style.pointerEvents = "none";
  };
  const checkScopeRt = (r) => {
    return Entity.vendor.scopeOfActivityRT.includes(r);
  };
  const checkScopeBs = (r) => {
    return Entity?.scopeOfBusiness?.includes(r);
  };
  const checkScopeCb = (r) => {
    return Entity.vendor.scopeOfActivityCB.includes(r);
  };

  const checkScopeFreitFowrd = (r) => {
    return Entity?.vendor?.scopeOfActivityFreitForward?.includes(r);
  };
  // const checkScopeFreitFowrd = (r) => {

  //   return Entity.vendor.scopeOfActivityFreitForward.includes(r);

  // };
  const getServiceability = () => {
    const data = Entity.vendor.tradeLine.map(async (r, i) => {
      await selectStateServiceAbl(
        r.fromState,
        "fromState",
        i,
        "serviceability"
      );
      await selectCityServiceAbl(r?.fromCity, "fromCity", i);
      await selectStateServiceAbl(r.toState, "toState", i, "serviceability");
      await selectCityServiceAbl(r?.toCity, "toCity", i);
    });

    setserviceability(true);
  };
  // const isDomesticTransport = loginStore.userRec.aguserRec?.vertical === 'Domestic Transport';
  // const isAdminOrBranchHead = isDomesticTransport &&
  //   (loginStore.userRec.aguserRec?.role === 'Admin' || loginStore.userRec.aguserRec?.role === 'Branch Head');
  //debugger
  const isAdminOrBranchHead = RoleUtils.isUserAuthorized(

    PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT_ONLY
  );
  const isAgragaAdmin = RoleUtils.isUserAuthorized(
    PERMISSIONS.CREATE.ALL_ENTITIES
  );

  const manageCredit = async () => {
    AgmodalStore.apiCall = false
    const data = await CreditsApis.checkCaf(Entity.entityId, (response) => {
      if (response.status === true) {
        routerStore.creditsCurrentView = "edit";
        creditformStore.CreditformRec.set(response.data);
        if(Entity?.financeInformation?.length>0){
          CreditForm.finance = Entity.financeInformation 
        }
        setCreditAvail(true);
      } else if (response.status === 204) {
        let formData = response.data
        routerStore.creditsCurrentView = "new"
        creditformStore.CreditformRec.set(new CreditFormType())
        if(Entity?.financeInformation?.length>0){
          CreditForm.finance = formData.financeInformation 
        }
        CreditForm.entityId = formData.entityId
        CreditForm.entityName = formData.entityName
        CreditForm.panNum = formData.panNum
        CreditForm.tinNum = formData.tinNum
        CreditForm.customer = formData.customer
        CreditForm.turnover.currency = "INR"
        CreditForm.status = CREDIT_STATUS.NOTAVAILED
        setCreditAvail(true)
      }
    });
  };

  const manageDsr = async() => {
    await EntityDSRApi.checkDsr(Entity.entityId, (data)=> {
      if(data.status === 200){
        setDsrData(data)
           
      }
        setDsrCustomization(true);
         routerStore.dsrCustomization = "view"
    })

  }
  const manageDsrSche = async() => {
    await EntityDSRApi.checkDsr(Entity.entityId, (data)=> {
      if(data.status === 200){
        setDsrData(data)
          //  console.log("Entity DSR Data:", data);
      }
        setDsrScheduler(true);
         routerStore.dsrScheduler = "view"
    })

  }
  const activateServiceability = () => {
    const array = Entity.vendor.tradeLine;
    setserviceArray(array);

    getServiceability();
    document.getElementById("editEntity").style.filter = "blur(15px)";
    document.getElementById("editEntity").style.pointerEvents = "none";
  };
  const debouncedOnChangeTin = debounce((e) => handleChange(e, "tinNum"), 500);
  const delDocs = (r) => {
    Entity.documents[r].fileLink = "";
    Entity.documents[r].fileKey = "";
  };

  const handleChange1 = (e, i, r) => {
    //   if (r === "customercontact") {
    //    // if (r === "customercontact") {

    //       let re = new RegExp("^([A-Za-z0-9]{0,10})$");
    //       if (!re.test(e.target.value)) {
    //         return null;
    //       }
    //    // }
    //    return  Entity.logisticInformation[i][r] =e.target.value.trim();
    //   }
    //  else

    // if (r === "contactnum") {
    //   let re = new RegExp("^([0-9]{0,10})$");
    //   if (!re.test(e.target.value)) {
    //     return null;
    //   }

    //   //const name = e.target.value;
    //   // errors[r] = name.length > 0 ? "" : "This field is required.";
    //   //setErrors(errors);
    //   return (Entity.logisticInformation[i][r] = e.target.value.trim());
    // }
    // Entity.logisticInformation[i][r] = e.target.value.trim();

    // setValidema(true)
    // alert("test")
    if (r === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (e.target.value.length > 0) {
        if (emailRegex.test(e.target.value) == true) {
          setValidema(false);
        } else {
          setValidema(true);
        }
      } else {
        setValidema(false);
      }

      Entity.logisticInformation[i][r] = e.target.value.trim();
    }
  };

  const addNewFinancerow = () => {
    const array = [...Entity.logisticInformation];
    array.push(finArray[0]);
    Entity.logisticInformation = array;
    // console.log(CreditForm);
    // setfinanceArray(array);
  };

  const removeFinancerow = (i) => {
    const array = [...Entity.logisticInformation];
    if (array.length > 1) {
      array.splice(i, 1);
    }
    Entity.logisticInformation = array;
    // setfinanceArray(array);
  };
  const removeFinanceEsarow = (i) => {
    const array = [...financeEsaArray];
    if (array.length > 1) {
      array.splice(i, 1);
    }
    setfinanceEsaArray(array);
  };

  const addFreightForwarder = (e, r) => {
    if (e.target.checked === true) {
      Entity.vendor.scopeOfActivityFreitForward.push(r);

      if (isEditMode()) {
        pendingErrors.scopeOfActivityFreitForward = false;
      }

      setPendingErrors(pendingErrors);
    } else if (
      e.target.checked === false &&
      (!isVerifiedStatus() ||
        (Entity.status === "VERIFIED" &&
          Entity.vendor.scopeOfActivityFreitForward.length > 1))
    ) {
      Entity.vendor.scopeOfActivityFreitForward =
        Entity.vendor.scopeOfActivityFreitForward.filter((e) => e !== r);
    }
  };
  const addNewLogisticrow1 = () => {
    const array = [...Entity.logisticInformation];
    array.push(finArray[0]);
    Entity.logisticInformation = array;
    if (errors1.logisticInformation) {
      errors1.logisticInformation = null;
    }
  };

  const addNewFinancerow1 = () => {
    const array = [...Entity.financeInformation];
    array.push(finArray[0]);
    Entity.financeInformation = array;
    if (errors1.financeInformation) {
      errors1.financeInformation = null;
    }
  };
  const removeFinancerow1 = (i) => {
    const array = [...Entity.financeInformation];
    if (array.length > 1) {
      array.splice(i, 1);
    }
    Entity.financeInformation = array;
    if (errors1.financeInformation) {
      errors.financeInformation = null;
    }
  };

  const removeLogisticrow1 = (i) => {
    const array = [...Entity.logisticInformation];
    if (array.length > 1) {
      array.splice(i, 1);
    }
    Entity.logisticInformation = array;
    if (errors1.logisticInformation) {
      errors.logisticInformation = null;
    }
  };
  const handleChange2 = (e, i, r) => {
    setCursor(e.target.selectionStart);
    let re = new RegExp("^([A-Za-z0-9- ]{0,100})$");
    if (r !== "email" && !re.test(e.target.value)) {
      return null;
    }
    if (r === "contactnum" && e.target.value.length > 15) {
      return null;
    }
    if (e.target.value.length > 100) {
      return null;
    }
    Entity.logisticInformation[i][r] = e.target.value;
    if (errors1.logisticInformation?.obj?.length > 0) {
      errors1.logisticInformation.obj[i][r] = "";
      errors1.logisticInformation.error = false;
      setErrors1(errors1);
    }
  };

  const handleChange3 = (e, i, r) => {
    setCursor(e.target.selectionStart);
    let re = new RegExp("^([A-Za-z0-9- ]{0,100})$");
    if (r !== "email" && !re.test(e.target.value)) {
      return null;
    }
    if (r === "contactnum" && e.target.value.length > 15) {
      return null;
    }
    if (e.target.value.length > 100) {
      return null;
    }
    Entity.financeInformation[i][r] = e.target.value;
  };

  const dovalidationcheck1 = async () => {
    function isValid(value) {
      return (
        (!Array.isArray(value) &&
          value &&
          value.trim().length > 0 &&
          value !== "default") ||
        (Array.isArray(value) &&
          value.some((item) => item.trim() !== "default"))
      );
    }
    let errors1 = {};
    let fieldsCheck1 = { isError: false };
    errors1.logisticInformation = {
      obj: [],
      error: false,
    };
    errors1.financeInformation = {
      obj: [],
      error: false,
    }
    const emailRegexPattern =
      // /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9-]+.[A-Za-z0-9-]+$/;
      /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)+$/
    Entity.logisticInformation.forEach((r, i) => {
      errors1.logisticInformation.obj.push({
        customercontact: "",
        department: "",
        contactnum: "",
        email: "",
      });
      if (!isValid(r.customercontact)) {
        errors1.logisticInformation.obj[
          i
        ].customercontact = `Please enter all values`;
        fieldsCheck1.isError = true;
        errors1.logisticInformation.error = true;
      }
      if (!isValid(r.department)) {
        errors1.logisticInformation.obj[
          i
        ].department = `Please enter all values`;
        fieldsCheck1.isError = true;
        errors1.logisticInformation.error = true;
      }
      if (!isValid(r.contactnum)) {
        errors1.logisticInformation.obj[
          i
        ].contactnum = `Please enter all values`;
        fieldsCheck1.isError = true;
        errors1.logisticInformation.error = true;
      }
      if (!isValid(r.email)) {
        errors1.logisticInformation.obj[i].email = `Please enter all values`;
        fieldsCheck1.isError = true;
        errors1.logisticInformation.error = true;
      }
      if (isValid(r.contactnum) &&  (r.contactnum.length > 15 || r.contactnum.length < 7)) {
        errors1.logisticInformation.obj[
          i
        ].contactnum = `Enter Valid Phone Number (7-15 Digits)`;
        fieldsCheck1.isError = true;
        errors1.logisticInformation.error = true;
        errors1.logisticInformation.contactNumber = `Enter Valid Phone Number (7-15 Digits)`;
      }
      if (isValid(r.email) && !emailRegexPattern.test(r.email.trim())) {
        errors1.logisticInformation.obj[i].email = `Invalid email pattern`;
        fieldsCheck1.isError = true;
        errors1.logisticInformation.error = true;
        errors1.logisticInformation.email = `Invalid email`;
      }
    });
    let valueEnterCheck = false
    Entity.financeInformation.forEach((r,i)=>{
      if (r.customercontact || r.department || r.contactnum || r.email) {
        valueEnterCheck = true
      }
    })
    if(valueEnterCheck===true){
      Entity.financeInformation.forEach((r, i) => {
        errors1.financeInformation.obj.push({
          customercontact: "",
          department: "",
          contactnum: "",
          email: "",
        });
        if (!isValid(r.customercontact)) {
          errors1.financeInformation.obj[
            i
          ].customercontact = `Please enter all values`;
          fieldsCheck1.isError = true;
          errors1.financeInformation.error = true;
        }
        if (!isValid(r.department)) {
          errors1.financeInformation.obj[
            i
          ].department = `Please enter all values`;
          fieldsCheck1.isError = true;
          errors1.financeInformation.error = true;
  
        }
        if (!isValid(r.contactnum)) {
          errors1.financeInformation.obj[
            i
          ].contactnum = `Please enter all values`;
          fieldsCheck1.isError = true;
          errors1.financeInformation.error = true;
        }
        if (!isValid(r.email)) {
          errors1.financeInformation.obj[i].email = `Please enter all values`;
          fieldsCheck1.isError = true;
          errors1.financeInformation.error = true;
        }
        if (isValid(r.contactnum) &&  (r.contactnum.length > 15 || r.contactnum.length < 7)) {
          errors1.financeInformation.obj[
            i
          ].contactnum = `Enter Valid Phone Number (7-15 Digits)`;
          fieldsCheck1.isError = true;
          errors1.financeInformation.error = true;
          errors1.financeInformation.contactNumber = `Enter Valid Phone Number (7-15 Digits)`;
        }
        if (isValid(r.email) && !emailRegexPattern.test(r.email.trim())) {
          errors1.financeInformation.obj[i].email = `Invalid email pattern`;
          fieldsCheck1.isError = true;
          errors1.financeInformation.error = true;
          errors1.financeInformation.email = `Invalid email`;
        }
      });
    }
    

    setErrors1(errors1);
    return fieldsCheck1;
  };
  let v = false;
  if (
    (Entity.status == "PENDING" && props.mode == "edit") ||
    props.mode == "view"
  ) {
    v = true;
  }
  let c = false;
  if (Entity.status == "INCOMPLETE" && props.mode == "new") {
    c = true;
  }
  let showPlusIcon = false;
  let showFinPlusIcon = false;
  if (
    Entity.logisticInformation[Entity.logisticInformation.length - 1]
      .customercontact != "" &&
    Entity.logisticInformation[Entity.logisticInformation.length - 1]
      .contactnum != "" &&
    Entity.logisticInformation[Entity.logisticInformation.length - 1]
      .department != "" &&
    Entity.logisticInformation[Entity.logisticInformation.length - 1].email !=
    ""
  ) {
    showPlusIcon = true;
  }

  if (
    Entity.financeInformation[Entity.financeInformation.length - 1]
      .customercontact != "" &&
    Entity.financeInformation[Entity.financeInformation.length - 1]
      .contactnum != "" &&
    Entity.financeInformation[Entity.financeInformation.length - 1]
      .department != "" &&
    Entity.financeInformation[Entity.financeInformation.length - 1].email !=
    ""
  ) {
    showFinPlusIcon = true;
  }


  // const calEntityOption = (mode) => {
  //   console.log("We are inside cal function")
  //   // console.log('props.mode', mode, 'isAgragaAdmin', !isAgragaAdmin)
  //   let result
  //   if(mode === "new" && !isAgragaAdmin) {
  //     if(isAdminOrBranchHead) {
  //       console.log('isAdminOrBranchHead', isAdminOrBranchHead)
  //       result = "Select Entity Type"
  //     } else {
  //       const value = RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_ENTITYV2_CUSTOMERS)
  //       console.log('value---', value);
  //       if(value) {
  //         result = "Customer"
  //       } else {
  //         result = "Vendor"
  //       }
  //     }
  //   } else {
  //     result = "Select Entity Type"
  //   }
  //   console.log('result--', result);
  //   return result
  //   // props.mode === "new" && !isAgragaAdmin ? 
  //   //                 (isAdminOrBranchHead ? "Select Entity Type" : 
  //   //                 (
  //   //                   RoleUtils.isUserAuthorized(
  //   //                   PERMISSIONS.CREATE.NEW_ENTITYV2_CUSTOMERS
  //   //                   ) 
  //   //                 ) ? "Customer" : "Vendor") : ("Select Entity Type")
  // }

  // console.log("dt-cbdt",Entity.entityType)
  // console.log("dt-cbdt",Entity.funded_Id,Entity.paymentEnable)

  // console.log("dt-cb category",Entity.customer.customerType)

  if(title=="Create Entity"){
    Entity.funded_Id=undefined
    Entity.paymentEnable=undefined
  }

  if(gstData1==true &&  EntityUtils.isIndia(Entity))
    {
    {State.getStatesOfCountry(Entity.entityCountryCode).map(
        (r, i) => {
          let f = r.name.includes(gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.stateName)
       if(f==true){
         Entity.branch[0].state=r.name + "/" + r.isoCode

          }
        }
    )}

    {City?.getCitiesOfState(
        Entity?.entityCountryCode,
        Entity.branch[0].state.split("/")[1]
      ).map((r, i) => {

        let f = gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.districtName.toLowerCase().includes(r.name.toLowerCase())
    // console.log("C",r.name.toLowerCase(),f,gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.districtName.toLowerCase())
    if(f==true){
          setCity(true)
      // console.log("DC",r.name)
          Entity.branch[0].branchAddresses[0].city = r.name

    } else{
          Entity.branch[0].branchAddresses[0].city = gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.districtName
        }
    })} 
    // console.log("ddf",Entity.branch[0].state,Entity.branch[0].branchAddresses[0].city)
      Entity.branch[0].branchAddresses[0].address = gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.floorNumber +" "+gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.buildingNumber +" " +
      gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.buildingName + " " +
      gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.streetName
    ///Entity.branch[0].branchAddresses[0].city = gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.location
    //Entity.branch[0].state= gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.stateName
    Entity.branch[0].branchAddresses[0].pincode =  gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.pincode
    if( gstData?.tradeName=="" || gstData.tradeName=="-"){
      // console.log("Test..") 
    } else{
      Entity.entityName =  gstData?.tradeName
    }

    Entity.branch[0].natureOfBusinessActivity= gstData?.natureOfBusinessActivity.join(", ")

    dtactionStore.modalVisible1=false


    //dtactionStore.modalVisible1=false


    // console.log( Entity.branch[0].state,Entity.branch[0].branchAddresses[0].city)
    setGstData1(false)
  }
  if(gstData&& !EntityUtils.isIndia(Entity)){
    Entity.entityName = ""
    Entity.branch[0].branchAddresses[0].address = ""
    Entity.branch[0].branchAddresses[0].city = ""
    Entity.branch[0].state= ""
    Entity.branch[0].branchAddresses[0].pincode =""
    Entity.branch[0].natureOfBusinessActivity =""
    pendingErrors.gst=""
    pendingErrors.gstVerify=""
    setGstData()
  }
  if(!gstData&& !EntityUtils.isIndia(Entity)){ pendingErrors.gst=""
  pendingErrors.gstVerify=""}
  function onmouseoutfn () {
    return document.getElementById("entitySettingsMenu").style.display = "none"
   }

  var navBar = document.querySelector('#entitySettingsMenu');

 if(navBar){
  navBar.onmouseleave = onmouseoutfn
 }

 

  return (
    <>

      <GSTVerify data={gstData} gstDataClick={()=>{setGstData1(!gstData1)}} gstDataCancel={()=>{
        dtactionStore.modalVisible1=false
        //Entity.entityName = ""
        Entity.branch[0].branchAddresses[0].address=""
        Entity.branch[0].branchAddresses[0].pincode =""
        Entity.branch[0].branchAddresses[0].city = ""
        Entity.branch[0].state= ""
        Entity.branch[0].natureOfBusinessActivity=""
        setGstVerify()
        setGstData()}}></GSTVerify>
      {Branches ? (
        <MainBranch fn={setBranches} />
      ) : CreditAvail ? (
        <MainCredit fn={setCreditAvail} />
      ) : showKycRequirements ? (
        <CustomsKycRequirements fn={setShowKycRequirements} entityView={true} entityId={Entity?.entityId} />
      ) : manageUser ? (
        <MainUsers fn={setMangeuser} />
      ) : manageShipper ? (
        <MainShippers fn={setManageShipper} />
      ) : dsrCustomization ? (
        <DSRCustomization fn={setDsrCustomization} dsrData={dsrData} />
      ): dsrScheduler ? (
        <DSRScheduler fn={setDsrScheduler} dsrData={dsrData} />
      ): (
        <>
          <div className="" id="editEntity">
            <h1 className="title" style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
              {title}
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginLeft: "auto", gap: "10px" }}>
              {Entity.status !== ""   ? (
                <>
                  {/* {(Entity?.vendor?.gstType === "gstnumber" ||
                    EntityUtils.isCustomer(Entity)) &&
                  !EntityUtils.isSoleProp(Entity) ? ( */}
                  { <div className="is-flex is-flex-direction-column">
                    <button  className="button is-link"
                    onClick={()=> {
                      if ( document.getElementById("entitySettingsMenu").style.display === "block"){
                        return document.getElementById("entitySettingsMenu").style.display= "none"
                      }
                      document.getElementById("entitySettingsMenu").style.display= "block"
                    }}
                        style={{
                          // border: "1px solid #ffffff",
                          borderRadius: "8px",
                          height: "35px",
                          color: "white",
                          position: "relative",
                          width: "auto",
                        }}>
                    <IoSettingsOutline size={25} />
                    </button>
                                <div className="is-size-7" id="entitySettingsMenu" style={{
                                  display: "none",
                                  position: "absolute",
                                  top: '13%',
                                  zIndex:1,
                                  backgroundColor: "#ffffff",
                                  width: "150px",
                                  right: "1%",
                                  borderRadius: "10px",
                                  padding:"0.5%",
                                  boxShadow: "2px 4px 12px 0px rgba(0,0,0,0.59)"
                                }} onmouseleave={onmouseoutfn} >
                                {
                                  <ul>
                                    <li>Entity</li>
                                    {((Entity?.vendor?.gstType === "gstnumber" && isVerifiedStatus() ) ||
                                      EntityUtils.isCustomer(Entity) && isVerifiedStatus())  && 
                                      <li onClick={() => { 
                                        routerStore.branchCurrentView = "list";
                                        setBranches(true); 
                                      }}>Branches</li>
                                    }
                                    {
                                     ( isVerifiedStatus() && EntityUtils.isCustomer(Entity))  && 
                                      <li onClick={() => manageCredit()}>Credit</li>
                                    }
                                    {
                                      (EntityUtils.isRoadTransport(Entity) && isVerifiedStatus() ) && 
                                      <li onClick={() => activateServiceability()}>Serviceability</li>
                                    }
                                    {(isEditMode() || (isViewMode() && !isDeactivatedStatus())) && EntityUtils.isCustomer(Entity) &&
                                      <li onClick={() => {
                                      setMangeuser(true);
                                      routerStore.entityUserManageView = "list"
                                    }}>Users</li>

                                    }
                                    {
                                      (isEditMode() || (isViewMode() && !isDeactivatedStatus())) && EntityUtils.isCustomer(Entity) && 
                                      <li onClick={() => {
                                      setManageShipper(true)
                                      routerStore.shipperManageView = "list"
                                    }}>Shipper/Consignee</li>
                                    }
                                   {
                                    (isEditMode() || (isViewMode() && !isDeactivatedStatus())) && EntityUtils.isCustomer(Entity) &&
                                    <li onClick={() => {
                                      setShowKycRequirements(true);
                                      routerStore.customsKequirementsView = "view"
                                    }}>Customs KYC Documents</li>

                                   }
                                   {
                                    (isVerifiedStatus() && EntityUtils.isCustomer(Entity))  &&
                                    <>
                                    <li onClick={() => {
                                      manageDsr()
                                    }}>DSR Customization.</li>
                                        <li onClick={() => {
                                          manageDsrSche()
                                    }}>DSR Scheduler.</li>
                                    </>
                                   }
                                  </ul>
                                }
                                </div>
                    </div>
                  }


                  {/* {Entity?.vendor?.gstType === "gstnumber" ||
                    EntityUtils.isCustomer(Entity) ? (
                    <>
                      <button
                        className="button is-link"
                        style={{
                          border: "1px solid #ffffff",
                          borderRadius: "8px",
                          height: "35px",
                          color: "white",
                          position: "relative",
                          width: "auto",
                        }}
                        // {...(isViewMode() ? { disabled: true } : {})}
                        onClick={() => {
                          routerStore.branchCurrentView = "list"
                          setBranches(true);
                        }}>
                        Branches
                      </button>
                    </>
                  ) : null} */}


                  {/* {
                    EntityUtils.isCustomer(Entity) ? (
                      
                      <>
                        <label style={{marginLeft:"5%"}}>EnableSecureNow:</label>
                        <button className="button is-small"
                                  onClick={async () => {
                                    await EntityApi.secureNowEnable({
                                      entityId:Entity?._id,
                                      displayStatus:"ON"
                                    })
                                    setSecureNowStatus('ON')
                                  
                                  }}
                                  style={{ borderRadius: '25px 0px 0px 25px',...pendingStyle1 }}>ON</button>
                              <button
                                  className="button is-small"
                                  onClick={async () => {
                                    await EntityApi.secureNowEnable({
                                      entityId: Entity?._id,
                                      displayStatus: "OFF",
                                    });
                                    setSecureNowStatus("OFF");
                                  }}
                                  style={{ borderRadius: "0px 25px 25px 0px", ...completedStyle1 }}
                                >
                                  OFF
                              </button>
                      </>
                  ) : null} */}

                  {/* {EntityUtils.isCustomer(Entity)  ? (
                    <>
                      <button
                        className="button is-link"
                        style={{
                          border: "1px solid #ffffff",
                          borderRadius: "8px",
                          height: "35px",
                          color: "white",
                          position: "relative",
                          width: "auto",
                        }}
                        // {...(isViewMode() ? { disabled: true } : {})}
                        onClick={() => manageCredit()}>
                        Credit
                      </button>
                    </>
                  ) : null} */}

                  {/* {EntityUtils.isRoadTransport(Entity) ? (
                    <button
                      className="button is-link "
                      style={{
                        border: "1px solid #ffffff",
                        borderRadius: "8px",
                        height: "35px",
                        color: "white",
                        position: "relative",
                        width: "auto",
                      }}
                      // {...(isViewMode() ? { disabled: true } : {})}
                      onClick={activateServiceability}>
                      Serviceability
                    </button>
                  ) : null} */}
                </>
              ) : null}
              {(isEditMode() || (isViewMode() && !isDeactivatedStatus())) && EntityUtils.isCustomer(Entity) && (
                <>
                  {/* <button
                    className="button is-link"
                    style={{
                      border: "1px solid #ffffff",
                      borderRadius: "8px",
                      height: "35px",
                      color: "white",
                      position: "relative",
                      width: "auto",
                    }}
                    // {...(isViewMode() ? { disabled: true } : {})}
                    onClick={() => {
                      setMangeuser(true);
                      routerStore.entityUserManageView = "list"
                    }}
                  >
                    Users
                  </button> */}
                </>
              )}

              {(isEditMode() || (isViewMode() && !isDeactivatedStatus())) && EntityUtils.isCustomer(Entity) && (
                <>
                  {/* <button
                    className="button is-link"
                    style={{
                      border: "1px solid #ffffff",
                      borderRadius: "8px",
                      height: "35px",
                      color: "white",
                      // left: "15em",
                      position: "relative",
                      width: "auto",
                      // marginLeft: "20em"
                    }}
                    // {...(isViewMode() ? { disabled: true } : {})}
                    onClick={() => {
                      setManageShipper(true)
                      routerStore.shipperManageView = "list"
                    }}
                  >
                    Shipper/Consignee
                  </button> */}
                </>
              )}

{(isEditMode() || (isViewMode() && !isDeactivatedStatus())) && EntityUtils.isCustomer(Entity) && (
        <>
          {/* <button
            className="button is-link"
            style={{
              border: "1px solid #ffffff",
              borderRadius: "8px",
              height: "35px",
              color: "white",
              position: "relative",
              width: "auto",
            }}
            onClick={() => {
              setShowKycRequirements(true); 
              routerStore.customsKequirementsView = "view"
            }}
          >
            Customs KYC
          </button> */}
        </>
                    )}
                    </div>

              <span style={{ position: "relative", left: "18em" }}>
                {isEditMode() &&
                  ((RoleUtils.isUserAuthorized(
                    PERMISSIONS.EDIT.PENDING_CUSENTITYV2_VR
                  ) &&
                    Entity.entityType == "Customer") ||
                    (RoleUtils.isUserAuthorized(
                      PERMISSIONS.EDIT.PENDING_VENENTITYV2_VR
                    ) &&
                      Entity.entityType == "Vendor") ||
                    (RoleUtils.isUserAuthorized(
                      PERMISSIONS.EDIT.PENDING_VENENTITYV2RT_VR
                    ) &&
                      Entity.entityType == "Vendor" &&
                      Entity.vendor.vendorType == "Road Transport")) &&
                  Entity?.status.toLowerCase() ===
                  ENTITY_STATUS.PENDING.toLowerCase() ? (
                  <a>
                    <HiMiniDocumentCheck
                      onClick={() => openVerifyPopup()}
                    // className="is-clickable"
                    //class="dropdown-item"
                    ></HiMiniDocumentCheck>
                  </a>
                ) : null}{" "}
                <span></span>
              </span>
            </h1>

            <div style={{ padding: "0rem 2rem" }}>
              <Columns>
                <Col4>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    Entity Name *

                  </p>
                  {gstData &&gstData?.tradeName != "" && gstData?.tradeName != "-" ? <input
                    type="text"
                    className="input is-small "
                    value={Entity?.entityName}
                    ref={refEntityName}
                    pattern="^[a-zA-Z0-9_-]{100}$"
                    maxLength={100}
                    onChange={(e) => handleChange(e, "entityName")}
                    disabled
                    {...(isViewMode()
                      ? { disabled: true }
                      : {
                        ...(isEditMode() &&
                          (isPendingStatus() || isVerifiedStatus())
                          ? { disabled: true }
                          : {}),
                      })}
                    id="entityName"
                  />: <input
                    type="text"
                    className="input is-small "
                    value={Entity?.entityName}
                    ref={refEntityName}
                    pattern="^[a-zA-Z0-9_-]{100}$"
                    maxLength={100}
                    onChange={(e) => handleChange(e, "entityName")}
                    {...(isViewMode()
                      ? { disabled: true }
                      : {
                        ...(isEditMode() &&
                          (isPendingStatus() || isVerifiedStatus())
                          ? { disabled: true }
                          : {}),
                      })}
                    id="entityName"
                  />}

                  {errors.entityName && (
                    <p className="help is-input-danger-message">
                      {errors.entityName}
                    </p>
                  )}
                  {/* {!refEntityName.current?.validity.valid && (
                    <p className="help is-danger">
                      Invalid input. Please use alphanumeric characters and the
                      length should be between 3 and 20 characters.
                    </p>
                  )} */}
                  {/* {refEntityName.current?.value.length <= 0 && (
                    <p className="help is-danger">Empty field.</p>
                  )} */}
                </Col4>

                <Col4>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    Entity Type *
                  </p>
                  <select
                    className="select is-small "
                    style={{ width: "100%", border: "1px solid #d1d1e0" }}
                    onChange={(e) => {
                      handleChange(e, "entityType");
                      Entity.isTrackMyBooking = "No"
                      setCB_Checked(false);
                      setDT_Checked(false);
                    }}
                    {...(isViewMode() || isEditMode()
                      ? { disabled: true }
                      : {
                        ...(isNewMode() && !isAgragaAdmin
                          ?
                          RoleUtils.isUserAuthorized(
                            PERMISSIONS.CREATE.NEW_ENTITYV2_CUSTOMERS
                          )
                            ||
                            // || RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT)
                            RoleUtils.isUserAuthorized(
                              PERMISSIONS.CREATE.NEW_ENTITYV2_REMVENDOR
                            ) ||
                            RoleUtils.isUserAuthorized(
                              PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT
                            )
                            ? { disabled: true }
                            : {}
                          : {}),
                      })}
                    id="entityType">

                    <option

                      {...(!isEditMode() ? { selected: true } : {})}
                      disabled
                      value="default">

                      {/* {calEntityOption(props.mode)}
                    ||(RoleUtils.isUserAuthorized(
                          PERMISSIONS.CREATE.NEW_ENTITYV2_CUSTOMERS_CB_DT
                        ) )
                        
                    */}


                      {



                        props.mode === "new" && !isAgragaAdmin
                          ? isAdminOrBranchHead
                            ? "Select Entity Type"
                          :(RoleUtils.isUserAuthorized(
                              PERMISSIONS.CREATE.NEW_ENTITYV2_CUSTOMERS
                            )

                            )
                              ? "Customer"
                              : "Vendor"
                          : "Select Entity Type"

                        // ((RoleUtils.isUserAuthorized(VERTICALS.DOMESTIC_TRANSPORT, PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT)) && props.mode == 'new') &&
                        // ((RoleUtils.isUserAuthorized(VERTICALS.CENTRAL, PERMISSIONS.CREATE.NEW_ENTITYV2_REMVENDOR)) && props.mode == 'new') ?
                      }

                    </option>


                    {props.mode == "new" && !isAgragaAdmin
                      ? isAdminOrBranchHead
                        ?
                        renderOptions(
                          Object.values(ENTITY_TYPE),

                          Entity.entityType
                        )
                        :
                        RoleUtils.isUserAuthorized(
                          PERMISSIONS.CREATE.NEW_ENTITYV2_CUSTOMERS
                        )

                          ? renderOptions(
                            Object.values(ENTITY_TYPE),
                            Entity.entityType,
                            ENTITY_TYPE.CUSTOMER
                          )
                          : renderOptions(
                            Object.values(ENTITY_TYPE),
                            Entity.entityType,
                            ENTITY_TYPE.VENDOR
                          )
                      : renderOptions(

                        Object.values(ENTITY_TYPE),

                        Entity.entityType
                      )

                    }
                  </select>

                  {errors.entityType && (
                    <p className="help is-input-danger-message">
                      {errors.entityType}
                    </p>
                  )}
                </Col4>

                <Col4>
                  {/* {Entity?.entityType !== "default" ? ( */}
                  <>
                    <p
                      className="label is-small"
                      style={{ marginBottom: "0px" }}>
                      Entity Category *
                    </p>
                    <select
                      className="select is-small "
                      style={{ width: "100%", border: "1px solid #d1d1e0" }}
                      onChange={(e) =>
                        handleChange(
                          e,
                          EntityUtils.isCustomer(Entity)
                            ? "customer"
                            : "vendor",
                          EntityUtils.isCustomer(Entity)
                            ? "customerType"
                            : "vendorType"
                        )
                      }
                      disabled={checkCategoryDisabled()}
                      id="entityCategory">
                      {/* {
                        (RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT) && Entity.entityType === ENTITY_TYPE.VENDOR && isNewMode()) &&
                        (Entity.vendor.vendorType = 'Road Transport')
                      } */}
                      <option
                        // {...(!isEditMode() ? { selected: true } : {})}
                        // disabled
                        value="default">
                        {EntityUtils.isCustomer(Entity)
                          ? "Select Customer Type"
                          : null || EntityUtils.isVendor(Entity)
                            ? isNewMode() && !isAgragaAdmin
                              ? RoleUtils.isUserAuthorized(
                                PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT
                              ) || isAdminOrBranchHead
                                ? "Road Transport"
                                : "Select Vendor Type"
                              : "Select Vendor Type"
                            : null || "Select Entity  Category"}
                      </option>

                      {/* {console.log(
                        Entity.entityName,
                        Entity.entityType,
                        Entity.vendor.vendorType,
                        "))))"
                      )} */}
                      {renderOptions(

                        populateCategories(),
                        EntityUtils.isCustomer(Entity)
                          ? Entity?.customer?.customerType
                          : Entity?.vendor?.vendorType,
                        isNewMode() && !isAgragaAdmin
                          ? RoleUtils.isUserAuthorized(
                            PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT
                          )
                            ? VENDOR_CATEGORY.ROAD_TRANSPORT
                            : null
                          : null
                      )}
                    </select>
                    {errors.entityCategory && (
                      <p className="help is-input-danger-message">
                        {errors.entityCategory}
                      </p>
                    )}
                  </>
                  {/* ) : null} */}
                </Col4>
              </Columns>
              <Columns>
                <Col4>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    Entity Country *
                  </p>
                  <select
                    className="select is-small "
                    style={{ width: "100%", border: "1px solid #d1d1e0" }}
                    value={
                      Entity.entityCountry + "/" + Entity.entityCountryCode
                    }
                    onChange={(e) => handleChange(e, "entityCountry")}
                    {...(isViewMode() || isEditMode()
                      ? { disabled: true }
                      : {})}
                    id="entityCountry">
                    <option selected disabled value="default">
                      Select Entity Country
                    </option>
                    {Countries.map((r, i) => {
                      return (
                        <option
                          selected={shouldSelectOption(
                            r.name,
                            Entity?.entityCountry
                          )}
                          // value={r.name + "/" + r.isoCode}
                          value={r.name + "/" + r.isoCode}>
                          {r.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.entityCountry && (
                    <p className="help is-input-danger-message">
                      {errors.entityCountry}
                    </p>
                  )}
                </Col4>
                {EntityUtils.isVendor(Entity) ? (
                  <Col4>
                    <p
                      className="label is-small"
                      style={{ marginBottom: "0px" }}>
                      Agraga Branch{" "}
                      {Entity.vendor.vendorType === "Road Transport"
                        ? "*"
                        : ""}
                    </p>
                    <select
                        className={`select is-small ${
                          // !c && pendingErrors.agragaBranch
                          !Entity?.agragaBranch
                          ? "is-select-danger"
                          : ""
                        }`}
                      style={{ width: "100%", border: "1px solid #d1d1e0" }}
                      onChange={(e) => handleChange(e, "agragaBranch")}
                      // {...(isViewMode() ? { disabled: true } : {})}
                      disabled={!EntityUtils.isIndia(Entity)}
                      // value={Entity.agragaBranch}
                          value={!EntityUtils.isIndia(Entity)?"All":null} 
                      // {...!EntityUtils.isIndia(Entity)?{value={!EntityUtils.isIndia(Entity)?"All":""}}
                      id="agragaBranch">

                  {!EntityUtils.isIndia(Entity)?( 
                        <option selected disabled value="All">
                          All
                        </option>):<>
                        <option selected disabled value="default">
                          Select Agraga Branch
                        </option>
                        {sortedbranches.map((r, i) => {
                          return <option
                            selected={shouldSelectOption(
                              r,
                              Entity?.agragaBranch
                            )}>
                            {r}
                          </option>

                        })}</>
                      }
                    </select>
                    {errors.agragaBranch && (
                      <p className="help is-input-danger-message">
                        {errors.agragaBranch}
                      </p>
                    )}
                  </Col4>
                  ) :null}

                {EntityUtils.isCustomer(Entity) ? (
                  <Col4>
                    <p
                      className="label is-small"
                      style={{ marginBottom: "0px" }}>
                      Scope Of Business *
                    </p>
                    <div className="is-flex is-flex-direction-column">
                      {Object.values(SCOPE_OF_ACTIVITY).map((r, i) => {
                        return (
                          <>
                            <p className="is-flex is-flex-direction-row">
                              <input
                                id="scopeOfBusiness"
                                type="checkbox"
                                onClick={(e) => addscopeofbusiness(e, r)}
                                checked={checkScopeBs(r)}
                                {...(isViewMode() || isPendingStatus() || i === 1
                                  ? { disabled: true }
                                  : {})}></input>
                              <span className="is-size-7 ml-2">{r}</span>
                            </p>
                          </>
                        );
                      })}
                    </div>
                    {errors.scopeOfBusiness && (
                      <p class="help is-input-danger-message">
                        Please select this scope
                      </p>
                    )}
                  </Col4>
                ) : null}

                <Col2>
                  {EntityUtils.isVendor(Entity) &&
                    EntityUtils.isIndia(Entity) &&
                    !EntityUtils.isFreightForwarder(Entity) &&
                    (EntityUtils.isRoadTransport(Entity) ||
                      EntityUtils.isCustomsBroker(Entity) ||
                      EntityUtils.isWarehouse(Entity) ||
                      EntityUtils.isContainerFreightStation(Entity) ||
                      EntityUtils.isInlandContainerDepot(Entity) ||
                      EntityUtils.isSurveyor(Entity) ||
                      EntityUtils.isLashingandClothing(Entity) ||
                      EntityUtils.isFumigation(Entity) ||
                      EntityUtils.isPackaging(Entity) ||
                      EntityUtils.isSecondhandContainerVendor(Entity)) ? (
                    <>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Is Sole Proprietorship?
                      </p>
                      <button
                        className="mt-1 is-clickable"
                        type="button"
                        style={{ border: "none", backgroundColor: "white" }}
                        onClick={toggleSoleProp}
                        // {...(isViewMode() || isEditMode()
                        //   ? !EntityUtils.isSoleProp(Entity) && Entity?.branch[0]?.gst.length > 0
                        //     ? { disabled: true }
                        //     : {}
                        //   : {})}
                        {...(isViewMode()
                          ? { disabled: true }
                          : {
                            ...(isEditMode() &&
                              (isPendingStatus() || isVerifiedStatus())
                              ? { disabled: true }
                              : {}),
                          })}
                      // disabled={true}
                      >
                        {Entity?.vendor?.isSoleProp ? (
                          <img height="20px" width="36px" src={on}></img>
                        ) : (
                          <img height="20px" width="36px" src={off}></img>
                        )}
                      </button>
                    </>
                  ) : null}
                </Col2>
                <Col2>
                  {EntityUtils.isVendor(Entity) &&
                    EntityUtils.isIndia(Entity) &&
                    !EntityUtils.isFreightForwarder(Entity) &&
                    (EntityUtils.isRoadTransport(Entity) ||
                      EntityUtils.isCustomsBroker(Entity) ||
                      EntityUtils.isWarehouse(Entity) ||
                      EntityUtils.isContainerFreightStation(Entity) ||
                      EntityUtils.isInlandContainerDepot(Entity) ||
                      EntityUtils.isSurveyor(Entity) ||
                      EntityUtils.isLashingandClothing(Entity) ||
                      EntityUtils.isFumigation(Entity) ||
                      EntityUtils.isPackaging(Entity) ||
                      EntityUtils.isSecondhandContainerVendor(Entity)) ? (
                    <>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Is MSME?
                      </p>
                      <button
                        className="mt-1 is-clickable"
                        type="button"
                        style={{ border: "none", backgroundColor: "white" }}
                        onClick={toggleMsme}
                        // {...(isViewMode() || isEditMode()
                        //   ? !EntityUtils.isSoleProp(Entity) && Entity?.branch[0]?.gst.length > 0
                        //     ? { disabled: true }
                        //     : {}
                        //   : {})}
                        {...(isViewMode()
                          ? { disabled: true }
                          : {
                            ...(isEditMode() &&
                              (isPendingStatus() || isVerifiedStatus())
                              ? { disabled: true }
                              : {}),
                          })}
                      // disabled={true}
                      >
                        {Entity?.vendor?.isMsme ? (
                          <img height="20px" width="36px" src={on}></img>
                        ) : (
                          <img height="20px" width="36px" src={off}></img>
                        )}
                      </button>
                    </>
                  ) : null}
                </Col2>
              </Columns>
              <Columns></Columns>

              <Columns>
                {EntityUtils.isVendor(Entity) ? (
                  <>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Relationship Manager Supply{" "}
                        {Entity.vendor.vendorType === "Road Transport"
                          ? "*"
                          : null}
                      </p>
                      <select
                        className={`select is-small ${
                          // pendingErrors.relationShipManagerSupply
                          !c &&
                            pendingErrors.relationShipManagerSupply &&
                            Entity.vendor.vendorType === "Road Transport"
                            ? // !isNewMode()

                            "is-select-danger"
                            : ""
                          }`}
                        style={{ width: "100%", border: "1px solid #d1d1e0" }}
                        onChange={(e) =>
                          handleChange(e, "vendor", "relationShipManagerSupply")
                        }
                        {...(isViewMode() ? { disabled: true } : {})}
                        id="relationShipManagerSupply"
                        value={
                          Entity?.vendor?.relationShipManagerSupply || "default"
                        }>
                        <option selected disabled value="default">
                          Select Relationship Manager Supply
                        </option>
                        {renderSalesOptions(
                          Object.values(relMgrSupplyList),
                          Entity?.vendor?.relationShipManagerSupply
                        )}
                      </select>
                      {errors.relationShipManagerSupply && (
                        <p className="help is-input-danger-message">
                          {errors.relationShipManagerSupply}
                        </p>
                      )}
                    </Col4>
                  </>
                ) : null}
              </Columns>

              <Columns>
                <Col4>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    ERP ID
                  </p>
                  <input
                    type="text"
                    className="input is-small "
                    value={Entity?.erpnumber}
                    ref={refErpNumber}
                    pattern="^[A-Z]{3}/[A-Z]{2}/\d{2}/\d{2}/[A-Z]/\d{4}$"
                    maxLength={20}
                    onChange={(e) => handleChange(e, "erpnumber")}
                    {...(isViewMode() ? { disabled: true } : {})}
                    id="erpnumber"
                  />
                  {pendingErrors.erpnumber && (
                    <p className="help is-input-danger-message">
                      {pendingErrors.erpnumber}
                    </p>
                  )}
                </Col4>
              </Columns>

              {/* ========================== Cross-Border for Customer ===========================  */}

              {EntityUtils.isCustomer(Entity) &&
                Entity.scopeOfBusiness.includes("Cross Border") ? (
                <>
                  <hr
                    style={{
                      border: "2px solid grey",
                      width: "100%",
                      display: "block",
                    }}
                  />
                  <p
                    className="label is-3 mb-3"
                    style={{ marginBottom: "0px" }}>
                    Agraga Point of Contact- Cross-Border *
                  </p>
                  <Columns>
                  <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Sales Vertical *
                      </p>
                      <select
                        className="select is-small "
                        style={{ width: "100%", border: "1px solid #d1d1e0" }}
                        onChange={(e) => handleChange(e,
                          "CB_salesVertical")}
                        {...(isViewMode() ? { disabled: true } : {})}
                        // value={Entity.agragaBranch}
                        id="CB_salesVertical">
                        <option selected disabled value="default">
                          Select Sales Vertical
                        </option>
                        {Object.values(SALESVERTICAL).map((r, i) => {
                          return (
                            <option
                              selected={shouldSelectOption(
                                r,
                                Entity?.customer?.crossBorder?.salesVertical
                              )}>
                              {r}
                            </option>
                          );
                        })}
                      </select>
                      {errors.CB_salesVertical && (
                        <p className="help is-input-danger-message">
                          {errors.CB_salesVertical}
                        </p>
                      )}
                    </Col4>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Agraga Branch *
                      </p>
                      <select
                        className={`select is-small ${
                          // pendingErrors.relationshipManager
                          // !isNewMode()
                          !c && pendingErrors.agragaBranch
                            ? "is-select-danger"
                            : ""
                          }`}
                        style={{ width: "100%", border: "1px solid #d1d1e0" }}
                        onChange={(e) => handleChange(e, "crossBorder", "agragaBranch")}
                        {...(isViewMode() ? { disabled: true } : {})}
                        value={
                          Entity?.customer?.crossBorder
                            ?.agragaBranch || "default"
                        }
                        id="CB_agragaBranch">
                        <option selected disabled value="default">
                          Select Agraga Branch
                        </option>
                        {/* {sortedbranchesForCB.map((r, i) => {
                          return (
                            <option
                              selected={shouldSelectOption(
                                r,
                                Entity?.customer?.crossBorder?.agragaBranch
                              )}>
                              {r}
                            </option>
                          );
                        })} */}
                        {renderBranchesCBOptions(
                              Object.values(branchesListForCBByVertical),
                              Entity?.customer?.crossBorder?.agragaBranch
                            )}
                      </select>
                      {errors.CB_agragaBranch && (
                        <p className="help is-input-danger-message">
                          {errors.CB_agragaBranch}
                        </p>
                      )}
                    </Col4>
                  </Columns>
                  <Columns>
                    {EntityUtils.isCustomer(Entity) ? (
                      <>
                        <Col4>
                          <p
                            className="label is-small"
                            style={{ marginBottom: "0px" }}>
                            Relationship Manager *
                          </p>
                          <select
                            className={`select is-small ${
                              // pendingErrors.relationshipManager
                              // !isNewMode()
                              !c && pendingErrors.relationshipManager
                                ? "is-select-danger"
                                : ""
                              }`}
                            style={{
                              width: "100%",
                              border: "1px solid #d1d1e0",
                            }}
                            onChange={(e) =>
                              handleChange(
                                e,
                                "crossBorder",
                                "relationshipManager"
                              )
                            }
                            {...(isViewMode() ? { disabled: true } : {})}
                            value={
                              Entity?.customer?.crossBorder
                                ?.relationshipManager || "default"
                            }
                            id="relationshipManager">
                            <option selected disabled value="default">
                              Select Relationship Manager
                            </option>

                            {renderSalesOptions(
                              Object.values(salesListForCB),
                              Entity?.customer?.crossBorder?.relationshipManager
                            )}
                          </select>

                          {errors.relationshipManager && (
                            <p className="help is-input-danger-message">
                              {errors.relationshipManager}
                            </p>
                          )}
                        </Col4>
                        <Col4>
                          <p
                            className="label is-small"
                            style={{ marginBottom: "0px" }}>
                            Customer Service *
                          </p>
                          <select
                            className={`select is-small ${
                              // pendingErrors.customerService
                              // !isNewMode()
                              !c && pendingErrors.customerService
                                ? "is-select-danger"
                                : ""
                              }`}
                            style={{
                              width: "100%",
                              border: "1px solid #d1d1e0",
                            }}
                            onChange={(e) =>
                              handleChange(e, "crossBorder", "customerService")
                            }
                            {...(isViewMode() ? { disabled: true } : {})}
                            id="customerService"
                            value={
                              Entity?.customer?.crossBorder?.customerService ||
                              "default"
                            }>
                            <option selected disabled value="default">
                              Select Customer Service
                            </option>
                            {renderSalesOptions(
                              Object.values(csListForCB),
                              Entity?.customer?.crossBorder?.customerService
                            )}
                          </select>
                          {errors.customerService && (
                            <p className="help is-input-danger-message">
                              {errors.customerService}
                            </p>
                          )}
                        </Col4>
                      </>
                    ) : null}
                  </Columns>
                </>
              ) : null}

              {EntityUtils.isCustomer(Entity) &&
                Entity.scopeOfBusiness.includes("Domestic Transport") ? (
                <>
                  <hr
                    style={{
                      border: "2px solid grey",
                      width: "100%",
                      display: "block",
                    }}
                  />
                  <p
                    className="label is-3 mb-3"
                    style={{ marginBottom: "0px" }}>
                    Agraga Point of Contact- Domestic Transport *
                  </p>
                  <Columns>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Agraga Branch *
                      </p>
                      <select
                        className="select is-small "
                        style={{ width: "100%", border: "1px solid #d1d1e0" }}
                        onChange={(e) => handleChange(e, "DT_agragaBranch")}
                        {...(isViewMode() ? { disabled: true } : {})}
                        // value={Entity.agragaBranch}
                        id="DT_agragaBranch">
                        <option selected disabled value="default">
                          Select Agraga Branch
                        </option>
                        {sortedbranchesForDT.map((r, i) => {
                          return (
                            <option
                              selected={shouldSelectOption(
                                r,
                                Entity?.customer?.domesticTransport
                                  ?.agragaBranch
                              )}>
                              {r}
                            </option>
                          );
                        })}
                      </select>
                      {errors.DT_agragaBranch && (
                        <p className="help is-input-danger-message">
                          {errors.DT_agragaBranch}
                        </p>
                      )}
                    </Col4>
                  </Columns>
                  <Columns>
                    {EntityUtils.isCustomer(Entity) ? (
                      <>
                        <Col4>
                          <p
                            className="label is-small"
                            style={{ marginBottom: "0px" }}>
                            Relationship Manager *
                          </p>
                          <select
                            className={`select is-small ${
                              // pendingErrors.relationshipManager
                              // !isNewMode()
                              !c && pendingErrors.DT_relationshipManager
                                ? "is-select-danger"
                                : ""
                              }`}
                            style={{
                              width: "100%",
                              border: "1px solid #d1d1e0",
                            }}
                            onChange={(e) =>
                              handleChange(
                                e,
                                "domesticTransport",
                                "DT_relationshipManager"
                              )
                            }
                            {...(isViewMode() ? { disabled: true } : {})}
                            value={
                              Entity?.customer?.domesticTransport
                                ?.relationshipManager || "default"
                            }
                            id="DT_relationshipManager">
                            <option selected disabled value="default">
                              Select Relationship Manager
                            </option>

                            {renderSalesOptions(
                              Object.values(salesListForDT),
                              Entity?.customer?.domesticTransport
                                ?.relationshipManager
                            )}
                          </select>
                          {errors.DT_relationshipManager && (
                            <p className="help is-input-danger-message">
                              {errors.DT_relationshipManager}
                            </p>
                          )}
                        </Col4>
                        <Col4>
                          <p
                            className="label is-small"
                            style={{ marginBottom: "0px" }}>
                            Customer Service *
                          </p>
                          <select
                            className={`select is-small ${
                              // pendingErrors.customerService
                              // !isNewMode()
                              !c && pendingErrors.DT_customerService
                                ? "is-select-danger"
                                : ""
                              }`}
                            style={{
                              width: "100%",
                              border: "1px solid #d1d1e0",
                            }}
                            onChange={(e) =>
                              handleChange(
                                e,
                                "domesticTransport",
                                "DT_customerService"
                              )
                            }
                            {...(isViewMode() ? { disabled: true } : {})}
                            id="DT_customerService"
                            value={
                              Entity?.customer?.domesticTransport
                                ?.customerService || "default"
                            }>
                            <option selected disabled value="default">
                              Select Customer Service
                            </option>
                            {renderSalesOptions(
                              Object.values(csListForDT),
                              Entity?.customer?.domesticTransport
                                ?.customerService
                            )}
                          </select>
                          {errors.DT_customerService && (
                            <p className="help is-input-danger-message">
                              {errors.DT_customerService}
                            </p>
                          )}
                        </Col4>
                        <Col4>
                          <p
                            className="label is-small"
                            style={{ marginBottom: "0px" }}>
                            Branch Operation Manager (Domestic Transport) *
                          </p>
                          <select
                            className={`select is-small ${
                              // pendingErrors.branchOperationManager
                              !c && pendingErrors.DT_branchOperationManager
                                ? // !isNewMode()
                                "is-select-danger"
                                : ""
                              }`}
                            style={{
                              width: "100%",
                              border: "1px solid #d1d1e0",
                            }}
                            onChange={(e) =>
                              handleChange(
                                e,
                                "domesticTransport",
                                "branchOperationManager"
                              )
                            }
                            {...(isViewMode() ? { disabled: true } : {})}
                            id="branchOperationManager"
                            value={
                              Entity?.customer?.domesticTransport
                                ?.branchOperationManager || "default"
                            }>
                            <option selected disabled value="default">
                              Select Branch Operation Manager
                            </option>
                            {renderSalesOptions(
                              Object.values(branchOpsDTList),
                              Entity?.customer?.domesticTransport
                                ?.branchOperationManager
                            )}
                          </select>
                          {errors.DT_branchOperationManager && (
                            <p className="help is-input-danger-message">
                              {errors.DT_branchOperationManager}
                            </p>
                          )}
                        </Col4>
                      </>
                    ) : null}
                  </Columns>
                  <Columns>
                    {EntityUtils.isCustomer(Entity) ? (
                      <>
                        <Col4>
                          <p
                            className="label is-small"
                            style={{ marginBottom: "0px" }}>
                            Customer billing cycle
                          </p>
                          <select
                            className={`select is-small ${
                              !c && pendingErrors.billingCycle
                                ? "is-select-danger"
                                : ""
                              }`}
                            style={{
                              width: "100%",
                              border: "1px solid #d1d1e0",
                            }}
                            onChange={(e) => handleChange(e,"domesticTransport", "billingCycle")}
                            {...(isViewMode() ? { disabled: true } : {})}
                            value={
                              Entity?.customer?.domesticTransport
                                ?.billingCycle || "default"
                            }
                            id="billingCycle">
                            <option selected disabled value="default">
                              Select Billing
                            </option>

                            {renderOptions(
                              Object.values(CUSTOMER_BILLING_CYCLE),
                              Entity?.customer?.domesticTransport?.billingCycle
                            )}
                          </select>
                          {errors.billingCycle && (
                            <p className="help is-input-danger-message">
                              {errors.billingCycle}
                            </p>
                          )}
                        </Col4>

                        <Col4>
                          <p
                            className="label is-small"
                            style={{ marginBottom: "0px" }}>
                            POD Type Of Accepting Invoice
                          </p>
                          <select
                            className={`select is-small ${
                              !c && pendingErrors.podInvoiceAcceptance
                                ? "is-select-danger"
                                : ""
                              }`}
                            style={{
                              width: "100%",
                              border: "1px solid #d1d1e0",
                            }}
                            onChange={(e) => handleChange(e,"domesticTransport", "podInvoiceAcceptance")}
                            {...(isViewMode() ? { disabled: true } : {})}
                            value={
                              Entity?.customer?.domesticTransport
                                ?.podInvoiceAcceptance || "default"
                            }
                            id="podInvoiceAcceptance">
                            <option selected disabled value="default">
                              POD Type Invoice
                            </option>

                            {renderOptions(
                              Object.values(POD_TYPE_INVOICE),
                              Entity?.customer?.domesticTransport?.podInvoiceAcceptance
                            )}
                          </select>
                          {errors.podInvoiceAcceptance && (
                            <p className="help is-input-danger-message">
                              {errors.podInvoiceAcceptance}
                            </p>
                          )}
                        </Col4>
                        {/* <Col2>
                          {EntityUtils.isCustomer(Entity) &&
                          EntityUtils.isIndia(Entity) ? (
                            <>
                              <p
                                className="label is-small"
                                style={{ marginBottom: "0px" }}>
                                Soft Copy
                              </p>
                              <button
                                className="mt-1 is-clickable"
                                type="button"
                                style={{
                                  border: "none",
                                  backgroundColor: "white",
                                }}
                                onClick={toggleBillCopy}
                                // {...(isViewMode() || isEditMode()
                                //   ? !EntityUtils.isSoleProp(Entity) && Entity?.branch[0]?.gst.length > 0
                                //     ? { disabled: true }
                                //     : {}
                                //   : {})}
                                {...(isViewMode()
                                  ? { disabled: true }
                                  : {
                                      ...(isEditMode() &&
                                      (isPendingStatus() || isVerifiedStatus())
                                        ? { disabled: true }
                                        : {}),
                                    })}
                                // disabled={true}
                              >
                                {Entity?.customer?.domesticTransport.billCopy ? (
                                  <img
                                    height="20px"
                                    width="36px"
                                    src={on}></img>
                                ) : (
                                  <img
                                    height="20px"
                                    width="36px"
                                    src={off}></img>
                                )}
                              </button>
                            </>
                          ) : null}
                        </Col2> */}
                        <Col4>
                          <p
                            className="label is-small"
                            style={{ marginBottom: "0px" }}>
                            Invoice Format
                          </p>
                          <div className="is-flex">
                            <span class="icon mt-1 ml-3 is-size-4 ">
                              {Entity?.documents[11]?.fileLink?.length > 0 ? (
                                <>
                                  <span className="ml-2 mr-1" >
                                    <a
                                      href={
                                        process.env.REACT_APP_API_SERVER +
                                        "/api/v1/filestore/getfile/" +
                                        Entity?.documents[11]?.pkey +
                                        "/" +
                                        Entity?.documents[11]?.fileLink
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                      alt="">
                                    <BsFillEyeFill  />
                                    </a>
                                  </span>
                                  {isNewMode() ||
                                (isEditMode()  ) ? (
                                    <>
                                    <span style={{ cursor: "pointer",}}>
                                        <MdDelete
                                          onClick={() => (
                                            delDocs(11),
                                            (hiddenFileInput12.current.value = "")
                                          )}
                                        />
                                      </span>
                                    </>
                                  ) : null}
                                </>
                              ) : null}
                            {isNewMode() || isEditMode() || isViewMode()? (
                                <>
                                  {" "}
                                  <i
                                    id="InvoiceFormat"
                                    class="fa-solid fa-file-arrow-up is-clickable"
                                    onClick={() =>
                                      hiddenFileInput12.current.click()
                                    }

                                    {...(isViewMode()
                                      ? { disabled: true }
                                      : {
                                      ...((isEditMode()  ) ||
                                          isNewMode()
                                          ? {}
                                          : { disabled: true }),
                                      })}
                                    style={{
                                      ...(Entity?.documents[11]?.fileLink
                                        ?.length > 0
                                        ? { display: "none" }
                                        : null),
                                    }}>
                                    <input
                                      type="file"
                                      onChange={(e1) => {
                                        const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                                        if (
                                          pattern.test(e1.target.files[0]["name"])
                                        ) {
                                          change(
                                            e1.target.files,
                                            "InvoiceFormat",
                                            "InvoiceFormat"
                                          );
                                        } else {
                                          configStore.setModalMessage(
                                            "Please Check File Name and Format"
                                          );
                                          return (e1.target.value = "");
                                        }
                                      }}
                                      name="image"
                                      accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                                      multiple=""
                                      ref={hiddenFileInput12}
                                      data-original-title="upload photos"
                                      style={{ display: "none" }}
                                      {...(isViewMode()
                                        ? { disabled: true }
                                        : {

                                        })}
                                    />
                                  </i>
                                </>
                              ) : null}
                            </span>
                          </div>
                        </Col4>
                      </>
                    ) : null}
                  </Columns>
                </>
              ) : null}

              {/* ========================== Cross-Border for Customer ===========================  */}

              <Columns>
                <Col4>
                  {EntityUtils.isVendor(Entity) &&
                    !EntityUtils.isIndia(Entity) &&
                    EntityUtils.isFreightForwarder(Entity) ? (
                    // Entity.vendor.vendorType == "Freight Forwarder"
                    <>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Scope of Activity Freight Forwarder?
                      </p>

                      <div className="is-flex is-flex-direction-column">
                        {Object.values(FREGIHT_FORWARD_TYPE).map((r, i) => {
                          return (
                            <>
                              <p className="is-flex is-flex-direction-row">
                                <input
                                  id="scopeOfActivityFreitForward"
                                  type="checkbox"
                                  onClick={(e) => addFreightForwarder(e, r)}
                                  checked={checkScopeFreitFowrd(r)}
                                  {...(isViewMode() || isPendingStatus()
                                    ? { disabled: true }
                                    : {})}></input>
                                <span className="is-size-7 ml-2">{r}</span>
                              </p>
                            </>
                          );
                        })}
                      </div>
                      {pendingErrors.scopeOfActivityFreitForward && (
                        <p class="help is-input-danger-message">
                          Please select this scope
                        </p>
                      )}
                    </>
                  ) : null}
                </Col4>

                {EntityUtils.isVendor(Entity) &&
                  EntityUtils.isFreightForwarder(Entity) ? (
                  <>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Provides Road Transport Service?
                      </p>
                      <button
                        className="mt-1 is-clickable"
                        type="button"
                        style={{ border: "none", backgroundColor: "white" }}
                        onClick={toggleRoadTransport}
                        // disabled={(isPendingStatus() && isViewMode() ? true : false)}
                        {...(isViewMode() || isPendingStatus()
                          ? { disabled: true }
                          : {})}
                        id="roadTransport">
                        {Entity?.vendor?.isRoadTransportApplied ? (
                          <img height="20px" width="36px" src={on}></img>
                        ) : (
                          <img height="20px" width="36px" src={off}></img>
                        )}
                      </button>
                    </Col4>
                  </>
                ) : null}
                {/* </Col4> */}

                {EntityUtils.isVendor(Entity) &&
                  EntityUtils.isFreightForwarder(Entity) ? (
                  <>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Customs Broking License Holder?
                      </p>
                      <button
                        className="mt-1 is-clickable"
                        type="button"
                        style={{ border: "none", backgroundColor: "white" }}
                        onClick={toggleCustomsBroker}
                        {...(isViewMode() || isPendingStatus()
                          ? { disabled: true }
                          : {})}
                        id="customsBroker">
                        {Entity?.vendor?.isCustomBrokerApplied ? (
                          <img height="20px" width="36px" src={on}></img>
                        ) : (
                          <img height="20px" width="36px" src={off}></img>
                        )}
                      </button>
                    </Col4>
                  </>
                ) : null}
              </Columns>
              <Columns>
                {EntityUtils.isVendor(Entity) &&
                  EntityUtils.isRoadTransport(Entity) ? (
                  <>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Operator Type *
                      </p>
                      <select
                        className={`select is-small ${
                          pendingErrors.operatorType ? "is-select-danger" : ""
                          }`}
                        style={{ width: "100%", border: "1px solid #d1d1e0" }}
                        onChange={(e) =>
                          handleChange(e, "vendor", "operatorType")
                        }
                        id="operatorType"
                        // {...(isViewMode() ? { disabled: true } : {})}
                        {...(isViewMode() || isPendingStatus()
                          ? { disabled: true }
                          : {})}>
                        <option selected disabled value="default">
                          Select Operator Type
                        </option>
                        {renderOptions(
                          Object.values(OPERATOR_TYPE),
                          Entity?.vendor?.operatorType
                        )}
                      </select>
                      {errors.operatorType && (
                        <p className="help is-danger">{errors.operatorType}</p>
                      )}
                    </Col4>
                  </>
                ) : null}

                <Col4>
                  {EntityUtils.isVendor(Entity) &&
                    EntityUtils.isRoadTransport(Entity) ? (
                    <>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Scope of Activity Road Transport? *
                      </p>

                      <div className="is-flex is-flex-direction-column">
                        {Object.values(SCOPE_ROAD_TRANSPORT).map((r, i) => {
                          return (
                            <>
                              <p className="is-flex is-flex-direction-row">
                                <input
                                  id="scopeOfActivityRT"
                                  type="checkbox"
                                  onClick={(e) => addRoadTransScope(e, r)}
                                  checked={checkScopeRt(r)}
                                  {...(isViewMode() || isPendingStatus()
                                    ? { disabled: true }
                                    : {})}></input>
                                <span className="is-size-7 ml-2">{r}</span>
                              </p>
                            </>
                          );
                        })}
                      </div>
                      {pendingErrors.scopeOfActivityRT && (
                        <p class="help is-input-danger-message">
                          Please select this scope
                        </p>
                      )}
                    </>
                  ) : null}
                </Col4>
                <Col4>
                  {EntityUtils.isVendor(Entity) &&
                    EntityUtils.isCustomsBroker(Entity) ? (
                    <>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Scope of Activity Customs Broker? *
                      </p>

                      <div className="is-flex is-flex-direction-column">
                        {Object.values(SCOPE_CUSTOMS_BROKER).map((r, i) => {
                          return (
                            <>
                              <p className="is-flex is-flex-direction-row">
                                <input
                                  id="scopeOfActivityCB"
                                  type="checkbox"
                                  onClick={(e) => addCustomBrokerScope(e, r)}
                                  checked={checkScopeCb(r)}
                                  {...(isViewMode() || isPendingStatus()
                                    ? { disabled: true }
                                    : {})}></input>
                                <span className="is-size-7 ml-2">{r}</span>
                              </p>
                            </>
                          );
                        })}
                      </div>
                      {pendingErrors.scopeOfActivityCB && (
                        <p class="help is-input-danger-message">
                          Please select this scope
                        </p>
                      )}
                    </>
                  ) : null}
                </Col4>
              </Columns>
              {/* <Columns></Columns> */}

              <hr style={{ border: "2px solid grey" }}></hr>
              <h3 className="subtitle">KYC Details</h3>
              {EntityUtils.isIndia(Entity) ? (
                <>
                  {" "}
                  <Columns>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        PAN Number *
                      </p>
                      <div className="is-flex">
                        <input
                          // maxLength={10}
                          type="text"
                          className={`input is-small ${
                            pendingErrors.panNum ? "is-input-danger" : ""
                            }`}
                          ref={ref1}
                          value={Entity.panNum}
                          onChange={(e) => handleChange(e, "panNum")}
                          // onChange={debouncedOnChange}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...(isEditMode() &&
                                (isPendingStatus() || isVerifiedStatus())
                                ? { disabled: true }
                                : {}),
                            })}
                          id="panNum"
                        />

                        <span class="icon mt-1 ml-3 is-size-4 ">
                          {Entity?.documents[1]?.fileLink?.length > 0 ? (
                            <>
                              <span className="ml-2 mr-1">
                                <a
                                  href={
                                    process.env.REACT_APP_API_SERVER +
                                    "/api/v1/filestore/getfile/" +
                                    Entity?.documents[1]?.pkey +
                                    "/" +
                                    Entity?.documents[1]?.fileLink
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  alt="">
                                  <BsFillEyeFill />
                                </a>
                              </span>
                              {isNewMode() ||
                                (isEditMode() &&
                                  !isPendingStatus() &&
                                  !isVerifiedStatus()) ? (
                                <>
                                  <span>
                                    <MdDelete
                                      onClick={() => (
                                        delDocs(1),
                                        (hiddenFileInput1.current.value = "")
                                      )}
                                    />
                                  </span>
                                </>
                              ) : null}
                            </>
                          ) : null}
                          {isNewMode() || isEditMode() ? (
                            <>
                              <i
                                class="fa-solid fa-file-arrow-up is-clickable"
                                onClick={() => hiddenFileInput1.current.click()}
                                id="panCard"
                                {...(isViewMode()
                                  ? { disabled: true }
                                  : {
                                    ...(isEditMode() &&
                                      (isPendingStatus() || isVerifiedStatus())
                                      ? { disabled: true }
                                      : {}),
                                  })}
                                style={{
                                  ...(Entity?.documents[1]?.fileLink?.length > 0
                                    ? { display: "none" }
                                    : null),
                                }}>
                                <input
                                  type="file"
                                  onChange={(e1) => {
                                    const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                                    if (
                                      pattern.test(e1.target.files[0]["name"])
                                    ) {
                                      change(
                                        e1.target.files,
                                        "panCard",
                                        Entity?.panNum
                                      );
                                    } else {
                                      configStore.setModalMessage(
                                        "Please Check File Name and Format"
                                      );
                                      return (e1.target.value = "");
                                    }
                                  }}
                                  name="image"
                                  accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf,/.docx"
                                  multiple=""
                                  ref={hiddenFileInput1}
                                  data-original-title="upload photos"
                                  style={{ display: "none" }}
                                  {...(isViewMode()
                                    ? { disabled: true }
                                    : {
                                      ...(isEditMode() &&
                                        (isPendingStatus() ||
                                          isVerifiedStatus())
                                        ? { disabled: true }
                                        : {}),
                                    })}
                                />
                              </i>
                            </>
                          ) : null}
                        </span>
                        {pendingErrors.panCard && (
                          <p className="help">{errors.panCard}</p>
                        )}
                      </div>
                      {/* {panAvailable.status === true ? (
                        <>
                          <p className="is-size-7" style={{ color: "red" }}>
                            Pan Exists - Pan Belongs to{" "}
                            <a href="">{panAvailable.data.entityId}</a>
                          </p>
                        </>
                      ) : null} */}
                      {pendingErrors.panNum && (
                        <p className="help is-input-danger-message">
                          {pendingErrors.panNum}
                        </p>
                      )}

                      {/* <p>{panAvailable.message}</p> */}
                    </Col4>
                    {EntityUtils.isVendor(Entity) &&
                      (EntityUtils.isRoadTransport(Entity) ||
                        EntityUtils.isCustomsBroker(Entity) ||
                        EntityUtils.isWarehouse(Entity) ||
                        EntityUtils.isContainerFreightStation(Entity) ||
                        EntityUtils.isInlandContainerDepot(Entity) ||
                        EntityUtils.isSurveyor(Entity) ||
                        EntityUtils.isLashingandClothing(Entity) ||
                        EntityUtils.isFumigation(Entity) ||
                        EntityUtils.isPackaging(Entity) ||
                        EntityUtils.isSecondhandContainerVendor(Entity)) &&
                      Entity?.vendor?.isSoleProp ? (
                      <>
                        <Col4>
                          <p
                            className="label is-small"
                            style={{ marginBottom: "0px" }}>
                            Aadhar Card No *
                          </p>

                          <div className="is-flex">
                            <input
                              maxLength={12}
                              type="number"
                              className={`input is-small ${
                                pendingErrors.aadharNum ? "is-input-danger" : ""
                                }`}
                              ref={ref}
                              value={Entity?.aadharNum}
                              pattern="^[0-9]{1,12}$"
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              id="aadharNum"
                              onChange={(e) => handleChange(e, "aadharNum")}
                              {...(isViewMode()
                                ? { disabled: true }
                                : {
                                  ...(isEditMode() &&
                                    (isPendingStatus() || isVerifiedStatus())
                                    ? { disabled: true }
                                    : {}),
                                })}
                            />
                            <span class="icon mt-1 ml-3 is-size-4 ">
                              {Entity?.documents[3]?.fileLink?.length > 0 ? (
                                <>
                                  <span className="ml-2 mr-1">
                                    <a
                                      href={
                                        process.env.REACT_APP_API_SERVER +
                                        "/api/v1/filestore/getfile/" +
                                        Entity?.documents[3]?.pkey +
                                        "/" +
                                        Entity?.documents[3]?.fileLink
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                      alt="">
                                      <BsFillEyeFill />
                                    </a>
                                  </span>
                                  {isNewMode() ||
                                    (isEditMode() &&
                                      !isPendingStatus() &&
                                      !isVerifiedStatus()) ? (
                                    <>
                                      <span>
                                        <MdDelete
                                          onClick={() => (
                                            delDocs(3),
                                            (hiddenFileInput2.current.value =
                                              "")
                                          )}
                                        />
                                      </span>
                                    </>
                                  ) : null}
                                </>
                              ) : null}
                              {isNewMode() || isEditMode() ? (
                                <>
                                  {" "}
                                  <i
                                    class="fa-solid fa-file-arrow-up is-clickable"
                                    onClick={() =>
                                      hiddenFileInput2.current.click()
                                    }
                                    style={{
                                      ...(Entity?.documents[3]?.fileLink
                                        ?.length > 0
                                        ? { display: "none" }
                                        : null),
                                    }}
                                    id="aadharCert">
                                    <input
                                      type="file"
                                      onChange={(e1) => {
                                        const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                                        if (
                                          pattern.test(
                                            e1.target.files[0]["name"]
                                          )
                                        ) {
                                          change(
                                            e1.target.files,
                                            "aadharCert",
                                            Entity?.aadharNum
                                          );
                                        } else {
                                          configStore.setModalMessage(
                                            "Please Check File Name and Format"
                                          );
                                          return (e1.target.value = "");
                                        }
                                      }}
                                      name="image"
                                      accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                                      multiple=""
                                      ref={hiddenFileInput2}
                                      data-original-title="upload photos"
                                      style={{ display: "none" }}
                                      {...(isViewMode()
                                        ? { disabled: true }
                                        : {
                                          ...(isEditMode() &&
                                            (isPendingStatus() ||
                                              isVerifiedStatus())
                                            ? { disabled: true }
                                            : {}),
                                        })}
                                    />
                                  </i>
                                </>
                              ) : null}
                            </span>
                          </div>
                          {pendingErrors.aadharNum && (
                            <p className="help is-input-danger-message">
                              {pendingErrors.aadharNum}
                            </p>
                          )}
                        </Col4>
                      </>
                    ) : null}
                  </Columns>
                </>
              ) : null}
              {EntityUtils.isIndia(Entity) && EntityUtils.isCustomer(Entity) ? (
                <>
                  <Columns>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        IEC Code*
                      </p>
                      <div className="is-flex">
                        <input
                          maxLength={10}
                          type="text"
                          className={`input is-small ${
                            pendingErrors.IECCode ? "is-input-danger" : ""
                            }`}
                          value={Entity?.IECCode}
                          ref={ref}
                          pattern="[a-zA-Z0-9]"
                          onChange={(e) => handleChange(e, "IECCode")}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                         ...(isEditMode() &&
                                (isPendingStatus())
                                  ? { disabled: true }
                                  : {}),
                              })}
                          id="IECCode"
                        />

                        <span class="icon mt-1 ml-3 is-size-4 ">
                          {Entity?.documents[0]?.fileLink?.length > 0 ? (
                            <>
                              <span className="ml-2 mr-1">
                                <a
                                  href={
                                    process.env.REACT_APP_API_SERVER +
                                    "/api/v1/filestore/getfile/" +
                                    Entity?.documents[0]?.pkey +
                                    "/" +
                                    Entity?.documents[0]?.fileLink
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  alt="">
                                  <BsFillEyeFill />
                                </a>
                              </span>
                              {isNewMode() ||
                              (isEditMode() &&
                                !isPendingStatus() 
                                ) ? (
                                <>
                                  <span>
                                    <MdDelete
                                      onClick={() => (
                                        delDocs(0),
                                        (hiddenFileInput3.current.value = "")
                                      )}
                                    />
                                  </span>
                                </>
                              ) : null}
                            </>
                          ) : null}
                          {isNewMode() || isEditMode() ? (
                            <>
                              {" "}
                              <i
                                class="fa-solid fa-file-arrow-up is-clickable"
                                onClick={() => hiddenFileInput3.current.click()}
                                id="IEC CODE"
                                style={{
                                  ...(Entity?.documents[0]?.fileLink?.length > 0
                                    ? { display: "none" }
                                    : null),
                                }}>
                                <input
                                  type="file"
                                  onChange={(e1) => {
                                    const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                                    if (
                                      pattern.test(e1.target.files[0]["name"])
                                    ) {
                                      change(
                                        e1.target.files,
                                        "IEC CODE",
                                        Entity.IECCode
                                      );
                                    } else {
                                      configStore.setModalMessage(
                                        "Please Check File Name and Format"
                                      );
                                      return (e1.target.value = "");
                                    }
                                  }}
                                  name="image"
                                  accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                                  multiple=""
                                  ref={hiddenFileInput3}
                                  data-original-title="upload photos"
                                  style={{ display: "none" }}
                                  {...(isViewMode()
                                    ? { disabled: true }
                                    : {
                                        ...(isEditMode() &&
                                        (isPendingStatus() 
                                         )
                                          ? { disabled: true }
                                          : {}),
                                      })}
                                />
                              </i>
                            </>
                          ) : null}
                        </span>
                      </div>
                      {pendingErrors.iecDoc && (
                        <p className="help is-danger">
                          {pendingErrors.iecDoc}
                        </p>
                      )}
                    </Col4>
                  </Columns>
                </>
              ) : null}
              {!EntityUtils.isIndia(Entity) ? (
                <>
                  {" "}
                  <Columns>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        TAX Identification Number *
                      </p>
                      <div className="is-flex">
                        <input
                          maxLength={20}
                          type="text"
                          className={`input is-small ${
                            pendingErrors.tinNum ? "is-input-danger" : ""
                            }`}
                          id="tinNum"
                          // value={Entity?.tinNum}
                          value={tinNum}
                          ref={ref3}
                          // pattern="^[a-zA-Z0-9.-_]{1,20}(?<!\.)$"
                          // onChange={(e) => handleChange(e, "tinNum")}
                          onChange={(e) => {
                            debouncedOnChangeTin(e);
                            setTinnum(e.target.value.toUpperCase());
                            setCursor(e.target.selectionStart);
                          }}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...(isEditMode() &&
                                (isPendingStatus() || isVerifiedStatus())
                                ? { disabled: true }
                                : {}),
                            })}
                        />

                        <span class="icon mt-1 ml-3 is-size-4 ">
                          {Entity?.documents[4]?.fileLink?.length > 0 ? (
                            <>
                              <span className="ml-2 mr-1 is-size-4">
                                <a
                                  href={
                                    process.env.REACT_APP_API_SERVER +
                                    "/api/v1/filestore/getfile/" +
                                    Entity?.documents[4]?.pkey +
                                    "/" +
                                    Entity?.documents[4]?.fileLink
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  alt="">
                                  <BsFillEyeFill />
                                </a>
                              </span>
                              {isNewMode() ||
                                (isEditMode() &&
                                  !isPendingStatus() &&
                                  !isVerifiedStatus()) ? (
                                <>
                                  <span>
                                    <MdDelete
                                      onClick={() => (
                                        delDocs(4),
                                        (hiddenFileInput4.current.value = "")
                                      )}
                                    />
                                  </span>
                                </>
                              ) : null}
                            </>
                          ) : null}
                          {isNewMode() || isEditMode() ? (
                            <>
                              <i
                                class="fa-solid fa-file-arrow-up is-clickable"
                                onClick={() => hiddenFileInput4.current.click()}
                                id="tinCert"
                                style={{
                                  ...(Entity?.documents[4]?.fileLink?.length > 0
                                    ? { display: "none" }
                                    : null),
                                }}>
                                <input
                                  type="file"
                                  onChange={(e1) => {
                                    const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                                    if (
                                      pattern.test(e1.target.files[0]["name"])
                                    ) {
                                      change(
                                        e1.target.files,
                                        "tinCert",
                                        Entity?.tinNum
                                      );
                                    } else {
                                      configStore.setModalMessage(
                                        "Please Check File Name and Format"
                                      );
                                      return (e1.target.value = "");
                                    }
                                  }}
                                  name="image"
                                  accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                                  multiple=""
                                  ref={hiddenFileInput4}
                                  data-original-title="upload photos"
                                  style={{ display: "none" }}
                                  {...(isViewMode()
                                    ? { disabled: true }
                                    : {
                                      ...(isEditMode() &&
                                        (isPendingStatus() ||
                                          isVerifiedStatus())
                                        ? { disabled: true }
                                        : {}),
                                    })}
                                />
                              </i>
                            </>
                          ) : null}
                        </span>
                        {errors.tinCert && (
                          <p className="help is-danger">{errors.tinCert}</p>
                        )}
                      </div>
                      {/* {tinAvailable?.status === true ? (
                        <>
                          <p className="is-size-7" style={{ color: "red" }}>
                            Tin Already Exist - Tin Belongs to{" "}
                            <a href="">{tinAvailable?.data?.entityId}</a>
                          </p>
                        </>
                      ) : null} */}
                      {pendingErrors.tinNum && (
                        <p className="help is-input-danger-message">
                          {pendingErrors.tinNum}
                        </p>
                      )}
                    </Col4>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Tax Residency Certificate *
                      </p>
                      <div className="is-flex">
                        <span class="icon mt-1 ml-3 is-size-4 ">
                          {Entity?.documents[5]?.fileLink?.length > 0 ? (
                            <>
                              <span className="ml-2 mr-1">
                                <a
                                  href={
                                    process.env.REACT_APP_API_SERVER +
                                    "/api/v1/filestore/getfile/" +
                                    Entity?.documents[5]?.pkey +
                                    "/" +
                                    Entity?.documents[5]?.fileLink
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  alt="">
                                  <BsFillEyeFill />
                                </a>
                              </span>
                              {isNewMode() ||
                                (isEditMode() &&
                                  !isPendingStatus() &&
                                  !isVerifiedStatus()) ? (
                                <>
                                  <span>
                                    <MdDelete
                                      onClick={() => (
                                        delDocs(5),
                                        (hiddenFileInput5.current.value = "")
                                      )}
                                    />
                                  </span>
                                </>
                              ) : null}
                            </>
                          ) : null}
                          {isNewMode() || isEditMode() ? (
                            <>
                              <i
                                class="fa-solid fa-file-arrow-up is-clickable"
                                onClick={() => hiddenFileInput5.current.click()}
                                id="taxresCert"
                                style={{
                                  ...(Entity?.documents[5]?.fileLink?.length > 0
                                    ? { display: "none" }
                                    : null),
                                }}>
                                <input
                                  type="file"
                                  onChange={(e1) => {
                                    const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                                    if (
                                      pattern.test(e1.target.files[0]["name"])
                                    ) {
                                      change(
                                        e1.target.files,
                                        "taxresCert",
                                        Entity.entityName
                                      );
                                    } else {
                                      configStore.setModalMessage(
                                        "Please Check File Name and Format"
                                      );
                                      return (e1.target.value = "");
                                    }
                                  }}
                                  name="image"
                                  accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                                  multiple=""
                                  ref={hiddenFileInput5}
                                  data-original-title="upload photos"
                                  style={{ display: "none" }}
                                  {...(isViewMode()
                                    ? { disabled: true }
                                    : {
                                      ...(isEditMode() &&
                                        (isPendingStatus() ||
                                          isVerifiedStatus())
                                        ? { disabled: true }
                                        : {}),
                                    })}
                                />
                              </i>
                            </>
                          ) : null}
                        </span>
                      </div>
                    </Col4>
                  </Columns>
                </>
              ) : null}
              <>
                {" "}
                <Columns>
                  <Col3>
                    {EntityUtils.isVendor(Entity) ? (
                      <>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          Vendor Agreement *
                        </p>
                        <div className="is-flex">
                          <span class="icon mt-1 ml-3 is-size-4 ">
                            {Entity?.documents[6]?.fileLink?.length > 0 ? (
                              <>
                                <span className="ml-2 mr-1">
                                  <a
                                    href={
                                      process.env.REACT_APP_API_SERVER +
                                      "/api/v1/filestore/getfile/" +
                                      Entity?.documents[6]?.pkey +
                                      "/" +
                                      Entity?.documents[6]?.fileLink
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    alt="">
                                    <BsFillEyeFill />
                                  </a>
                                </span>
                                {isNewMode() ||
                                  (isEditMode() &&
                                    !isPendingStatus() &&
                                    !isVerifiedStatus()) ? (
                                  <>
                                    <span>
                                      <MdDelete
                                        onClick={() => (
                                          delDocs(6),
                                          (hiddenFileInput6.current.value = "")
                                        )}
                                      />
                                    </span>
                                  </>
                                ) : null}
                              </>
                            ) : null}
                            {isNewMode() || isEditMode() ? (
                              <>
                                {" "}
                                <i
                                  id="fileVendorAgreement"
                                  class="fa-solid fa-file-arrow-up is-clickable"
                                  onClick={() =>
                                    hiddenFileInput6.current.click()
                                  }
                                  style={{
                                    ...(Entity?.documents[6]?.fileLink?.length >
                                      0
                                      ? { display: "none" }
                                      : null),
                                  }}>
                                  <input
                                    type="file"
                                    onChange={(e1) => {
                                      const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                                      if (
                                        pattern.test(e1.target.files[0]["name"])
                                      ) {
                                        change(
                                          e1.target.files,
                                          "fileVendorAgreement",
                                          "fileVendorAgreement"
                                        );
                                      } else {
                                        configStore.setModalMessage(
                                          "Please Check File Name and Format"
                                        );
                                        return (e1.target.value = "");
                                      }
                                    }}
                                    name="image"
                                    accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                                    multiple=""
                                    ref={hiddenFileInput6}
                                    data-original-title="upload photos"
                                    style={{ display: "none" }}
                                    {...(isViewMode()
                                      ? { disabled: true }
                                      : {
                                        ...(isEditMode() &&
                                          (isPendingStatus() ||
                                            isVerifiedStatus())
                                          ? { disabled: true }
                                          : {}),
                                      })}
                                  />
                                </i>
                              </>
                            ) : null}
                          </span>
                        </div>
                      </>
                    ) : null}
                  </Col3>
                  <Col3>
                    {EntityUtils.isVendor(Entity) &&
                      (EntityUtils.isRoadTransport(Entity) ||
                        EntityUtils.isCustomsBroker(Entity) ||
                        EntityUtils.isWarehouse(Entity) ||
                        EntityUtils.isContainerFreightStation(Entity) ||
                        EntityUtils.isInlandContainerDepot(Entity) ||
                        EntityUtils.isSurveyor(Entity) ||
                        EntityUtils.isLashingandClothing(Entity) ||
                        EntityUtils.isFumigation(Entity) ||
                        EntityUtils.isPackaging(Entity) ||
                        EntityUtils.isSecondhandContainerVendor(Entity)) &&
                      Entity?.vendor?.isMsme ? (
                      <>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          Udyam Certificate *
                        </p>
                        <div className="is-flex">
                          <span class="icon mt-1 ml-3 is-size-4 ">
                            {Entity?.documents[10]?.fileLink?.length > 0 ? (
                              <>
                                <span className="ml-2 mr-1">
                                  <a
                                    href={
                                      process.env.REACT_APP_API_SERVER +
                                      "/api/v1/filestore/getfile/" +
                                      Entity?.documents[10]?.pkey +
                                      "/" +
                                      Entity?.documents[10]?.fileLink
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    alt="">
                                    <BsFillEyeFill />
                                  </a>
                                </span>
                                {isNewMode() ||
                                  (isEditMode() &&
                                    !isPendingStatus() &&
                                    !isVerifiedStatus()) ? (
                                  <>
                                    <span>
                                      <MdDelete
                                        onClick={() => (
                                          delDocs(10),
                                          (hiddenFileInput11.current.value = "")
                                        )}
                                      />
                                    </span>
                                  </>
                                ) : null}
                              </>
                            ) : null}
                            {isNewMode() || isEditMode() ? (
                              <>
                                {" "}
                                <i
                                  id="MsmeAgreement"
                                  class="fa-solid fa-file-arrow-up is-clickable"
                                  onClick={() =>
                                    hiddenFileInput11.current.click()
                                  }
                                  style={{
                                    ...(Entity?.documents[10]?.fileLink
                                      ?.length > 0
                                      ? { display: "none" }
                                      : null),
                                  }}>
                                  <input
                                    type="file"
                                    onChange={(e1) => {
                                      const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                                      if (
                                        pattern.test(e1.target.files[0]["name"])
                                      ) {
                                        change(
                                          e1.target.files,
                                          "MsmeAgreement",
                                          "MsmeAgreement"
                                        );
                                      } else {
                                        configStore.setModalMessage(
                                          "Please Check File Name and Format"
                                        );
                                        return (e1.target.value = "");
                                      }
                                    }}
                                    name="image"
                                    accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                                    multiple=""
                                    ref={hiddenFileInput11}
                                    data-original-title="upload photos"
                                    style={{ display: "none" }}
                                    {...(isViewMode()
                                      ? { disabled: true }
                                      : {
                                        ...(isEditMode() &&
                                          (isPendingStatus() ||
                                            isVerifiedStatus())
                                          ? { disabled: true }
                                          : {}),
                                      })}
                                  />
                                </i>
                              </>
                            ) : null}
                          </span>
                        </div>
                      </>
                    ) : null}
                  </Col3>
                </Columns>
              </>
              {/* {EntityUtils.isCustomer(Entity) ? (
                <>
                  <hr style={{ border: "2px solid grey" }}></hr>
                  <h3 className="subtitle">Advance Details</h3>
                  <Col4>
                    <p
                      className="label is-small"
                      style={{ marginBottom: "0px" }}>
                      Advance Receivable for Domestic Transport Bookings ( % )
                    </p>
                    <div className="is-flex">
                      <input
                        maxLength={3}
                        type="number"
                        className="input is-small "
                        value={Entity?.advanceReceivableForDT}
                        // ref={refAdvanceReceived}
                        pattern="[0-9]{2}"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                        min="1"
                        onChange={(e) =>
                          handleChange(e, "advanceReceivableForDT")
                        }
                        {...(isViewMode() ? { disabled: true } : {})}
                      />
                    </div>
                    {advanceError && (
                      <p className="help is-danger">{advanceError}</p>
                    )}
                  </Col4>
                </>
              ) : null} */}
              {EntityUtils.isVendor(Entity) &&
                EntityUtils.isRoadTransport(Entity) ? (
                <>
                  <hr style={{ border: "2px solid grey" }}></hr>
                  <h3 className="subtitle">Advance Details</h3>
                  <Col4>
                    <p
                      className="label is-small"
                      style={{ marginBottom: "0px" }}>
                      Advance Payable for Domestic Transport Bookings ( % )
                    </p>
                    <div className="is-flex">
                      <input
                        maxLength={3}
                        type="number"
                        className="input is-small "
                        // {...(isNewMode()
                        //   ? {}
                        //   : { value: `${Entity?.advancePayable}` })}
                        value={Entity?.advancePayable}
                        //ref={refAdvancePayable}
                        min="1"
                        pattern="[0-9]{2}"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                        onChange={(e) => handleChange(e, "advancePayable")}
                        {...(isViewMode() ? { disabled: true } : {})}
                      />
                    </div>
                    {advancePayableError && (
                      <p className="help is-danger">{advancePayableError}</p>
                    )}
                  </Col4>
                </>
              ) : null}


              {EntityUtils.isCustomer(Entity) ? (
                <>
                  <hr style={{ border: "2px solid grey" }}></hr>
                  <div className="columns">
                    <$c n="2">Track My Booking</$c>
                    <$cg n="4">
                      <Agyesno
                        record={Entity}
                        name="isTrackMyBooking"
              isDisabled={isViewMode()? true: false}
                        callback={(val) => {
                          Entity.isTrackMyBooking = val;
                // console.log(val,  Entity.isOceanChecked,Entity.isAirChecked,'&&&&');
                          if (val === "No") {
                            Entity.isAirChecked = false;
                            Entity.isOceanChecked = false;
                            Entity.isDTChecked = false;
                          }
                        }}
                      >
                        {" "}
                      </Agyesno>
                    </$cg>
                    {EntityV2Store.EntityRec.isTrackMyBooking === "Yes" && (
                      <>
                        <div
                          class="column"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div>
                            <input type="checkbox" checked={Entity.isAirChecked} onChange={(e) => {
                              Entity.isAirChecked = e.target.checked;
                            }} />
                            <label > Air</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              checked={Entity.isOceanChecked}
                              onChange={(e) => {
                                Entity.isOceanChecked = e.target.checked;
                              }}
                            />
                            <label> Ocean</label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
      ): null}



              {
    (EntityUtils.isCustomer(Entity) && Entity && Entity?.entityId)?<>
                  <hr style={{ border: "2px solid grey" }}></hr>
    {/* {console.log(EntityV2Store?.EntityRec?.api,'9999')} */}
                  {
                    (EntityV2Store?.EntityRec?.api?.key == "") ?
                      <div className="columns">
                        <$c n="9">
                          <div className="table-container " style={{ textAlign: "center" }}>
                            <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "center" }}>
                                    <strong>Api Key</strong>
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                      <button class="button is-primary" disabled= {isViewMode()? true:false} onClick={()=>{
                                      token()
                                    }}>Generate</button>
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </$c>
          </div>:
                      <div className="columns">
                        <$c n="1"></$c>
                        <$c n="9">
                          <div className="table-container " style={{ textAlign: "center" }}>
                            <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "center" }}>
                                    <strong>Api Key</strong>
                                  </th>
                                  <th colspan="2" rowspan="1" style={{ textAlign: "center" }}>
                                    <strong>Action</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                    <td style={{overflowWrap:"anywhere"}}>{Entity?.api?.key}</td>
                                  <td>{
                        EntityV2Store?.EntityRec?.api?.active=='Yes'?<button class="button is-info" onClick={()=>{
                                      Entity.api.active = "No"
                        }}>Disable</button>:<button class="button is-info" onClick={()=>{
                                      Entity.api.active = "Yes"
                                    }}>Enable</button>
                                  }
                                  </td>
                                  <td>
                      <button class="button is-danger" onClick={()=>{
                        Entity.api.key="";
                        Entity.api.active="No"
                                    }}>Delete</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </$c>
                      </div>
                  }
    </>:""
              }


              <hr style={{ border: "2px solid grey" }}></hr>
              {EntityUtils.isRoadTransport(Entity) ? (
                <h3 className="subtitle">Address Details</h3>
              ) : (
                <h3 className="subtitle">Branch Details</h3>
              )}
              <Columns>
                {/* {(EntityUtils.isIndia(Entity) || Entity.entityCountry === "") &&
                !EntityUtils.isSoleProp(Entity) ? ( */}
                {EntityUtils.isIndia(Entity) || Entity.entityCountry === "" ? (
                  <>
                    <Col4>
                      <p
                        className="label is-small is-flex is-align-items-center"
                        style={{ marginBottom: "0px" }}>
                        {EntityUtils.isCustomer(Entity) ? (
                          <>
                            <span> GST Number *</span>
                          </>
                        ) : null}

                        {!EntityUtils.isCustomer(Entity) ? (
                          <>
                            {Entity?.documents[9]?.fileLink?.length <= 0 ? (
                              <>
                                <input
                                  type="radio"
                                  className="is-size-2 ml-2"
                                  name="approvalStatus"
                                  value="gstnumber"
                                  checked={gstType === "gstnumber"}
                                  onChange={handleGstRadioChange}
                                  id="gstNumber"
                                  {...(isViewMode()
                                    ? { disabled: true }
                                    : {
                                      ...(isEditMode() &&
                                        (isPendingStatus() ||
                                          isVerifiedStatus())
                                        ? { disabled: true }
                                        : {}),
                                    })}
                                />
                                <span className="ml-2"> GST Number *</span>
                              </>
                            ) : null}
                            {!Entity?.branch[0]?.gst ? (
                              <>
                                <input
                                  type="radio"
                                  className="is-size-2 ml-2"
                                  name="approvalStatus"
                                  value="gstdeclaration"
                                  checked={gstType === "gstdeclaration"}
                                  onChange={handleGstRadioChange}
                                  id="gstDeclaration"
                                  {...(isViewMode()
                                    ? { disabled: true }
                                    : {
                                      ...(isEditMode() &&
                                        (isPendingStatus() ||
                                          isVerifiedStatus())
                                        ? { disabled: true }
                                        : {}),
                                    })}
                                />
                                <span className="ml-2">
                                  {" "}
                                  GST Declaration * (upload)
                                </span>
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </p>
                      {EntityUtils.isCustomer(Entity) ||
                        gstType === "gstnumber" ? (
                        <>
                          <div className="is-flex">
                            <input
                              maxLength={15}
                              type="text"
                              className={`input is-small ${
                                pendingErrors.gst ? "is-input-danger" : ""
                                }`}
                              id="gst"
                              value={Entity?.branch[0]?.gst}
                              ref={ref2}
                              pattern="[A-Z0-9]{15}"
                              onChange={(e) => handleChange(e, "branch", "gst")}
                              {...(isViewMode()
                                ? { disabled: true }
                                : {
                                  ...(isEditMode() &&
                                    (isPendingStatus() || isVerifiedStatus())
                                    ? { disabled: true }
                                    : {}),
                                })}
                            />

                            <span class="icon mt-1 ml-3 is-size-4 ">
                              {Entity?.documents[2]?.fileLink?.length > 0 ? (
                                <>
                                  <span className="ml-2 mr-1">
                                    <a
                                      href={
                                        process.env.REACT_APP_API_SERVER +
                                        "/api/v1/filestore/getfile/" +
                                        Entity?.documents[2]?.pkey +
                                        "/" +
                                        Entity?.documents[2]?.fileLink
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                      alt="">
                                      <BsFillEyeFill />
                                    </a>
                                  </span>
                                  {isNewMode() ||
                                    (isEditMode() &&
                                      !isPendingStatus() &&
                                      !isVerifiedStatus()) ? (
                                    <>
                                      <span>
                                        <MdDelete
                                          onClick={() => (
                                            delDocs(2),
                                            (hiddenFileInput7.current.value =
                                              "")
                                          )}
                                        />
                                      </span>
                                    </>
                                  ) : null}
                                </>
                              ) : null}

                              {isNewMode() || isEditMode() ? (
                                <>
                                  {" "}
                                  <i
                                    class="fa-solid fa-file-arrow-up is-clickable"
                                    onClick={() =>
                                      hiddenFileInput7.current.click()
                                    }
                                    style={{
                                      ...(Entity?.documents[2]?.fileLink
                                        ?.length > 0
                                        ? { display: "none" }
                                        : null),
                                    }}
                                    id="gstCertificate">
                                    <input
                                      type="file"
                                      onChange={(e1) => {
                                        const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                                        if (
                                          pattern.test(
                                            e1.target.files[0]["name"]
                                          )
                                        ) {
                                          change(
                                            e1.target.files,
                                            "gstCertificate",
                                            Entity?.branch[0]?.gst
                                          );
                                        } else {
                                          configStore.setModalMessage(
                                            "Please Check File Name and Format"
                                          );
                                          return (e1.target.value = "");
                                        }
                                      }}
                                      name="image"
                                      accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                                      multiple=""
                                      ref={hiddenFileInput7}
                                      data-original-title="upload photos"
                                      style={{ display: "none" }}
                                      {...(isViewMode()
                                        ? { disabled: true }
                                        : {
                                          ...(isEditMode() &&
                                            (isPendingStatus() ||
                                              isVerifiedStatus())
                                            ? { disabled: true }
                                            : {}),
                                        })}
                                    />
                                  </i>
                                </>
                              ) : null}
                            </span>
                          </div>
                          {pendingErrors.gst && (
                            <p className="help is-input-danger-message">
                              {pendingErrors.gst}
                            </p>
                          )}
                          {gstData?.gstnStatus?( <p className="help is-input-success-message">
                              {pendingErrors.gstVerify }
                            </p> ):""}
                            {!gstData?.gstnStatus&& pendingErrors.gstVerify && (
                            <p className="help is-input-danger-message">
                              {pendingErrors.gstVerify}
                            </p>
                          )}

                        </>
                      ) : null}
                      {!EntityUtils.isCustomer(Entity) &&
                        gstType === "gstdeclaration" ? (
                        <>
                          {" "}
                          <div className="is-flex  is-align-items-center">
                            <p className=" is-size-7">
                              <>
                                <a
                                  style={{
                                    fontSize: "12px",
                                    textDecoration: "underline",
                                  }}
                                  className="is-link"
                                  href="./GST_Declaration_form.docx"
                                  download>
                                  Download gst declaration template
                                </a>
                              </>
                            </p>

                            <p>
                              <span className="icon mt-5 ml-5 is-size-4 ">
                                {Entity?.documents[9]?.fileLink?.length > 0 ? (
                                  <>
                                    <span className="ml-2 mr-1">
                                      <a
                                        href={
                                          process.env.REACT_APP_API_SERVER +
                                          "/api/v1/filestore/getfile/" +
                                          Entity?.documents[9]?.pkey +
                                          "/" +
                                          Entity?.documents[9]?.fileLink
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        alt="">
                                        <BsFillEyeFill />
                                      </a>
                                    </span>
                                    {isNewMode() ||
                                      (isEditMode() &&
                                        !isPendingStatus() &&
                                        !isVerifiedStatus()) ? (
                                      <>
                                        <span>
                                          <MdDelete
                                            onClick={() => (
                                              delDocs(9),
                                              (hiddenFileInput10.current.value =
                                                "")
                                            )}
                                          />
                                        </span>
                                      </>
                                    ) : null}
                                  </>
                                ) : null}
                                {isNewMode() || isEditMode() ? (
                                  <>
                                    {" "}
                                    <i
                                      class="fa-solid fa-file-arrow-up is-clickable"
                                      onClick={() =>
                                        hiddenFileInput10.current.click()
                                      }
                                      style={{
                                        ...(Entity?.documents[9]?.fileLink
                                          ?.length > 0
                                          ? { display: "none" }
                                          : null),
                                      }}
                                      id="gstDeclaration"></i>
                                  </>
                                ) : null}

                                <input
                                  type="file"
                                  onChange={(e1) => {
                                    const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                                    if (
                                      pattern.test(e1.target.files[0]["name"])
                                    ) {
                                      change(
                                        e1.target.files,
                                        "gstDeclaration",
                                        Entity?.entityName
                                      );
                                    } else {
                                      configStore.setModalMessage(
                                        "Please Check File Name and Format"
                                      );
                                      return (e1.target.value = "");
                                    }
                                  }}
                                  name="image"
                                  accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf,/.docx"
                                  multiple=""
                                  ref={hiddenFileInput10}
                                  data-original-title="upload photos"
                                  style={{ display: "none" }}
                                  {...(isViewMode()
                                    ? { disabled: true }
                                    : {
                                      ...(isEditMode() &&
                                        (isPendingStatus() ||
                                          isVerifiedStatus())
                                        ? { disabled: true }
                                        : {}),
                                    })}
                                />
                              </span>
                            </p>
                          </div>
                        </>
                      ) : null}
                    </Col4>
                  </>
                ) : null}
                {!EntityUtils.isIndia(Entity) && Entity.entityCountry !== "" ? (
                  <>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Unique Identifier Document Name
                      </p>
                      <input
                        type="text"
                        className="input is-small "
                        value={Entity?.branch[0]?.uidName}
                        ref={ref}
                        maxLength={20}
                        pattern="[a-zA-Z0-9]{0,20}"
                        onChange={(e) => handleChange(e, "branch", "uidName")}
                        // {...(isViewMode() ? { disabled: true } : {...(isEditMode() &&
                        //   (isPendingStatus() || isVerifiedStatus())
                        //     ? { disabled: true }
                        //     : {})})}
                        {...(isViewMode()
                          ? { disabled: true }
                          : {
                            ...(isEditMode() && isPendingStatus()
                              ? { disabled: true }
                              : {}),
                          })}
                      />
                    </Col4>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Unique Identifier Document Reference Number
                      </p>
                      <div className="is-flex">
                        <input
                          type="text"
                          className="input is-small "
                          value={Entity?.branch[0]?.uidRefNo}
                          ref={ref}
                          pattern="[a-zA-Z0-9]"
                          maxLength={20}
                          onChange={(e) =>
                            handleChange(e, "branch", "uidRefNo")
                          }
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...(isEditMode() && isPendingStatus()
                                ? { disabled: true }
                                : {}),
                            })}
                        />
                        <span class="icon mt-1 ml-3 is-size-4 ">
                          {Entity?.documents[7]?.fileLink?.length > 0 ? (
                            <>
                              <span className="ml-2 mr-1">
                                <a
                                  href={
                                    process.env.REACT_APP_API_SERVER +
                                    "/api/v1/filestore/getfile/" +
                                    Entity?.documents[7]?.pkey +
                                    "/" +
                                    Entity?.documents[7]?.fileLink
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  alt="">
                                  <BsFillEyeFill />
                                </a>
                              </span>
                              {isNewMode() ||
                                (isEditMode() &&
                                  !isPendingStatus() &&
                                  !isVerifiedStatus()) ? (
                                <>
                                  <span>
                                    <MdDelete
                                      onClick={() => (
                                        delDocs(7),
                                        (hiddenFileInput8.current.value = "")
                                      )}
                                    />
                                  </span>
                                </>
                              ) : null}
                            </>
                          ) : null}
                          {isNewMode() || isEditMode() ? (
                            <>
                              {" "}
                              <i
                                class="fa-solid fa-file-arrow-up is-clickable"
                                onClick={() => hiddenFileInput8.current.click()}
                                style={{
                                  ...(Entity?.documents[7]?.fileLink?.length > 0
                                    ? { display: "none" }
                                    : null),
                                }}>
                                <input
                                  type="file"
                                  onChange={(e1) => {
                                    const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                                    if (
                                      pattern.test(e1.target.files[0]["name"])
                                    ) {
                                      change(
                                        e1.target.files,
                                        "UID",
                                        Entity?.branch[0]?.uidRefNo
                                      );
                                    } else {
                                      configStore.setModalMessage(
                                        "Please Check File Name and Format"
                                      );
                                      return (e1.target.value = "");
                                    }
                                  }}
                                  name="image"
                                  accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                                  multiple=""
                                  ref={hiddenFileInput8}
                                  data-original-title="upload photos"
                                  style={{ display: "none" }}
                                />
                              </i>
                            </>
                          ) : null}
                        </span>
                      </div>
                    </Col4>
                  </>
                ) : null}
                {/* <Col2></Col2> */}
                { (isNewMode() || isEditMode()) && gstType != "gstdeclaration" && Entity.status != "VERIFIED" && Entity?.entityName && Entity?.entityType && Entity?.branch[0]?.gst.length==15 && EntityUtils.isIndia(Entity)   ?<> <Col2 >  <p className="label is-small" style={{ marginBottom: "0px" }}>
                  <br></br>
                </p>
                  <button
                    className="button is-link"
                    style={{
                      border: "1px solid #ffffff",
                      borderRadius: "8px",
                      height: "35px",
                      color: "white",
                      // left: "14em",
                      position: "relative",
                      width: "100px",
                      marginLeft: "2%",
                    }}
                    //{...(isViewMode() ? { disabled: true } : {})}
                    onClick={async (event) => {
                      loader.show()
                      await EntityApi.verifyGST({
                            gstin:Entity?.branch[0]?.gst
                      }, (response) => {
                          // console.log("D1",response)
                          if(response.results.message=="Success" && response.results.code=='200'){
                          loader.hide()
                            // console.log("Ds",response.results.response[0].responseStatus )
                            if(response.results.response[0].responseStatus == "ERROR"){
                            setGstVerify("Please enter a valid GST number")
                            }else{


                              if(response.results.response[0].response.gstnStatus=="Active"){
                              setGstVerify("GST number verified succesfully")
                              setGstData(response.results.response[0].response)
                              dtactionStore.modalVisible1 = true
                              } else{
                              setGstVerify("Please enter a valid GST number")
                            }

                          }
                          } else
                          {
                          loader.hide()
                          setGstVerify("Please enter a valid GST number")
                        }
                        // if (response?.data?.entityId !== Entity?.entityId) {
                        //   setGstAvailable(response);
                        // }
                      });


                      //routerStore.entityCurrentViewNew = "edit"
                      event.stopPropagation()
                    }}
                    {...(isViewMode() ? { disabled: true } : {})}
                  >
                    Verify
                  </button>  </Col2>
                  </>:null}

                <Col4>
                  <p className="label is-small" style={{  }}>
                    Name *
                  </p>
                  <input
                    type="text"
                    className="input is-small "
                    value={Entity?.entityName}
                    ref={ref}
                    pattern="[a-zA-Z0-9]"
                    onChange={(e) => handleChange(e, "branch", "branchName")}
                    disabled
                    {...(isViewMode()
                      ? { disabled: true }
                      : {
                        ...(isEditMode() &&
                          (isPendingStatus() || isVerifiedStatus())
                          ? { disabled: true }
                          : {}),
                      })}
                  />
                </Col4>
              </Columns>
              {((Entity?.vendor?.gstType === "gstnumber" ||
                gstType === "gstnumber") &&
                Entity?.branch[0]?.gst.length > 0) ||
                ((Entity?.vendor?.gstType === "gstdeclaration" ||
                  gstType === "gstdeclaration") &&
                  Entity?.documents[9]?.fileLink?.length > 0) ||
                // Entity?.vendor?.isSoleProp ||
                !EntityUtils.isIndia(Entity) ? (
                <>
                  <Columns>
                    <Col6>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Address *
                      </p>
                      {gstData && EntityUtils.isIndia(Entity)?  
                        <input
                          type="text"
                        className={`input is-small ${
                          pendingErrors.address ? "is-input-danger" : ""
                            }`}
                          value={
                            Entity?.branch &&
                              Entity?.branch[0]?.branchAddresses[0]?.address
                              ? Entity?.branch[0]?.branchAddresses[0]?.address
                              : ""
                          }
                          ref={ref}
                          pattern="[a-zA-Z0-9]"
                          onChange={(e) => handleChange(e, "branch", "address")}
                          disabled
                          id="address"
                      />: <input
                          type="text"
                      className={`input is-small ${
                        pendingErrors.address ? "is-input-danger" : ""
                            }`}
                          value={
                            Entity?.branch &&
                              Entity?.branch[0]?.branchAddresses[0]?.address
                              ? Entity?.branch[0]?.branchAddresses[0]?.address
                              : ""
                          }
                          ref={ref}
                          pattern="[a-zA-Z0-9]"
                          onChange={(e) => handleChange(e, "branch", "address")}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...(isEditMode() &&
                                (isPendingStatus() || isVerifiedStatus())
                                ? { disabled: true }
                                : {}),
                            })}
                          id="address"
                        />}

                      {pendingErrors.address && (
                        <p className="help is-input-danger-message">
                          {pendingErrors.address}
                        </p>
                      )}
                    </Col6>
                    <Col4 >

                    { Entity?.branch[0]?.natureOfBusinessActivity && EntityUtils.isIndia(Entity)? <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Nature Of Business
                    </p>:null}

                    { Entity?.branch[0]?.natureOfBusinessActivity&& EntityUtils.isIndia(Entity)?
                        <input
                          type="text"
                          id="natureOfBusinessActivity"
                      value ={
                            Entity?.branch[0]?.natureOfBusinessActivity

                          }
                      className={`input is-small ${
                        pendingErrors.natureOfBusinessActivity ? "is-input-danger" : ""
                            }`}
                          // ref={ref}
                          pattern="[a-zA-Z0-9]"
                          onChange={(e) => handleChange(e, "branch", "natureOfBusinessActivity")}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...(isEditMode() &&
                                (isPendingStatus() || isVerifiedStatus())
                                ? { disabled: true }
                                : {}),
                            })}
                          disabled
                    />: null}




                      {pendingErrors.natureOfBusinessActivity && (
                        <p className="help is-input-danger-message">
                          {pendingErrors.natureOfBusinessActivity}
                        </p>
                      )}
                    </Col4>
                  </Columns>
                  <Columns>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        State *
                      </p>
                      {gstData && EntityUtils.isIndia(Entity)?<input
                        type="text"
                        className={`input is-small ${
                          pendingErrors.state ? "is-input-danger" : ""
                          }`}
                        value={
                          Entity?.branch[0]?.state
                        }

                        onChange={(e) => handleChange(e, "branch", "state")}

                        disabled
                        id="state"
                      />: 
                        <>
                          <select
                        className={`select is-small ${
                          pendingErrors.state ? "is-select-danger" : ""
                              }`}
                            style={{ width: "100%", border: "1px solid #d1d1e0" }}
                            // ref={ref}
                            id="state"
                            value={Entity?.branch[0]?.state}
                            onChange={(e) => handleChange(e, "branch", "state")}
                            {...(isViewMode()
                              ? { disabled: true }
                              : {
                                ...(isEditMode() &&
                                  (isPendingStatus() || isVerifiedStatus())
                                  ? { disabled: true }
                                  : {}),
                              })}>
                            <option value="">Select state</option>
                            {State.getStatesOfCountry(Entity.entityCountryCode).map(
                              (r, i) => {
                                return (
                                  <option value={r.name + "/" + r.isoCode}>
                                    {r.name}
                                  </option>
                                );
                              }
                            )}
                          </select>


                        </>


                      }

                    </Col4>
                    {EntityUtils.isIndia(Entity) ? (
                      <Col4>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          City *
                        </p>
                        {gstData && city ?
                          <input
                            type="text"
                            id="city"
                            value={
                              Entity?.branch &&
                                Entity?.branch[0]?.branchAddresses[0]?.city
                                ? Entity?.branch[0]?.branchAddresses[0]?.city
                                : null
                            }
                      className={`input is-small ${
                        pendingErrors.city ? "is-input-danger" : ""
                              }`}
                            // ref={ref}
                            pattern="[a-zA-Z0-9]"
                            onChange={(e) => handleChange(e, "branch", "city")}
                            {...(isViewMode()
                              ? { disabled: true }
                              : {
                                ...(isEditMode() &&
                                  (isPendingStatus() || isVerifiedStatus())
                                  ? { disabled: true }
                                  : {}),
                              })}
                            disabled
                    />: 
                          <>
                    {/* {!city && (( gstData ||Entity?.branch[0]?.branchAddresses[0]?.city ) ) ?  <input */}
                    {!city && ( gstData ) ?  <input

                              type="text"
                              id="city"
                              value={
                                Entity?.branch &&
                                  Entity?.branch[0]?.branchAddresses[0]?.city
                                  ? Entity?.branch[0]?.branchAddresses[0]?.city
                                  : null
                              }
                      className={`input is-small ${
                        pendingErrors.city ? "is-input-danger" : ""
                                }`}
                              // ref={ref}
                              pattern="[a-zA-Z0-9]"
                              onChange={(e) => handleChange(e, "branch", "city")}
                              {...(isViewMode()
                                ? { disabled: true }
                                : {
                                  ...(isEditMode() &&
                                    (isPendingStatus() || isVerifiedStatus())
                                    ? { disabled: true }
                                    : {}),
                                })}

                    />:  <select
                    className={`select is-small ${
                      pendingErrors.city ? "is-input-danger" : ""
                                }`}
                              style={{ width: "100%", border: "1px solid #d1d1e0" }}
                              id="city"
                              // ref={ref}
                              value={Entity?.branch[0]?.branchAddresses[0]?.city}
                              onChange={(e) => handleChange(e, "branch", "city")}
                              {...(isViewMode()
                                ? { disabled: true }
                                : {
                                  ...(isEditMode() &&
                                    (isPendingStatus() || isVerifiedStatus())
                                    ? { disabled: true }
                                    : {}),
                                })}>
                              <option value="">Select city</option>
                              {City?.getCitiesOfState(
                                Entity?.entityCountryCode,
                                Entity.branch[0].state.split("/")[1]
                              ).map((r, i) => {
                                return <option value={r.name}>{r.name}</option>;
                              })}
                            </select>}

                          </>

                        }

                      </Col4>
                    ) : (
                      <Col4>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          City *
                        </p>
                        <input
                          type="text"
                          id="city"
                          value={
                            Entity?.branch &&
                              Entity?.branch[0]?.branchAddresses[0]?.city
                              ? Entity?.branch[0]?.branchAddresses[0]?.city
                              : ""
                          }
                      className={`input is-small ${
                        pendingErrors.city ? "is-input-danger" : ""
                            }`}
                          // ref={ref}
                          pattern="[a-zA-Z0-9]"
                          onChange={(e) => handleChange(e, "branch", "city")}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...(isEditMode() &&
                                (isPendingStatus() || isVerifiedStatus())
                                ? { disabled: true }
                                : {}),
                            })}
                        />
                        {pendingErrors.city && (
                          <p className="help is-input-danger-message">
                            {pendingErrors.city}
                          </p>
                        )}
                      </Col4>
                    )}
                    {EntityUtils.isIndia(Entity) ? (
                      <Col4>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          Pincode *
                        </p>
                        { gstData ?<input
                          type="number"
                          maxLength={6}
                          min="0"
                          id="pincode"
                          value={
                            Entity?.branch &&
                              Entity?.branch[0]?.branchAddresses[0]?.pincode
                              ? Entity?.branch[0]?.branchAddresses[0]?.pincode
                              : ""
                          }
                          className={`input is-small ${
                            pendingErrors.pincode ? "is-input-danger" : ""
                            }`}
                          // ref={ref}
                          pattern="[0-9]{6}"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-", "."].includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(e) => handleChange(e, "branch", "pincode")}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...(isEditMode() &&
                                (isPendingStatus() || isVerifiedStatus())
                                ? { disabled: true }
                                : {}),
                            })}
                          disabled
                        /> :<input
                          type="number"
                          maxLength={6}
                          min="0"
                          id="pincode"

                          value={
                            Entity?.branch &&
                              Entity?.branch[0]?.branchAddresses[0]?.pincode
                              ? Entity?.branch[0]?.branchAddresses[0]?.pincode
                              : ""
                          }
                        className={`input is-small ${
                          pendingErrors.pincode ? "is-input-danger" : ""
                            }`}
                          // ref={ref}
                          pattern="[0-9]{6}"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-", "."].includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(e) => handleChange(e, "branch", "pincode")}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...(isEditMode() &&
                                (isPendingStatus() || isVerifiedStatus())
                                ? { disabled: true }
                                : {}),
                            })}
                        />}

                        {pendingErrors.pincode && (
                          <p className="help is-input-danger-message">
                            {pendingErrors.pincode}
                          </p>
                        )}
                      </Col4>
                    ) : (
                      <Col4>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          Zipcode *
                        </p>
                        <input
                          maxLength={10}
                          type="text"
                          id="zipcode"
                          value={
                            Entity?.branch &&
                              Entity?.branch[0]?.branchAddresses[0]?.zipcode
                              ? Entity?.branch[0]?.branchAddresses[0]?.zipcode
                              : ""
                          }
                          className={`input is-small ${
                            pendingErrors.zipcode ? "is-input-danger" : ""
                            }`}
                          ref={ref}
                          pattern="[0-9]"
                          onChange={(e) => handleChange(e, "branch", "zipcode")}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...(isEditMode() &&
                                (isPendingStatus() || isVerifiedStatus())
                                ? { disabled: true }
                                : {}),
                            })}
                        />
                        {pendingErrors.zipcode && (
                          <p className="help is-input-danger-message">
                            {pendingErrors.zipcode}
                          </p>
                        )}
                      </Col4>
                    )}
                  </Columns>
                </>
              ) : null}
              {!EntityUtils.isCustomer(Entity) ? (
                <>
                  <div>
                    <hr style={{ border: "2px solid grey" }}></hr>
                    <h3 className="subtitle">Credit Details</h3>
                    <Columns>
                      <Col4>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          Is Credit Available?
                        </p>
                        <button
                          className="mt-1 is-clickable"
                          type="button"
                          style={{ border: "none", backgroundColor: "white" }}
                          onClick={toggleCreditfacility}
                          {...(isViewMode() ? { disabled: true } : {})}
                        // disabled={true}
                        >
                          {Entity?.vendor?.isCreditFacilitated ? (
                            <img height="20px" width="36px" src={on}></img>
                          ) : (
                            <img height="20px" width="36px" src={off}></img>
                          )}
                        </button>
                      </Col4>
                      {Entity?.vendor?.isCreditFacilitated ? (
                        <>
                          <Col4>
                            <p
                              className="label is-small"
                              style={{ marginBottom: "0px" }}>
                              Credit Days
                            </p>
                            <input
                              maxLength={3}
                              type="number"
                              className="input is-small "
                              // ref={ref}
                              placeholder="Credit Days"
                              pattern="[0-9]{2}"
                              value={Entity?.vendor?.creditDays}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              min="1"
                              // max="90"
                              onChange={(e) =>
                                handleChange(e, "vendor", "creditDays")
                              }
                              {...(isViewMode() ? { disabled: true } : {})}
                            />
                            {creditDaysError && (
                              <p className="help is-danger">
                                {creditDaysError}
                              </p>
                            )}
                          </Col4>{" "}
                        </>
                      ) : null}
                    </Columns>
                    <Columns>
                      {Entity?.vendor?.isCreditFacilitated ? (
                        <>
                          <Col4>
                            {" "}
                            <p
                              className="label is-small"
                              style={{ marginBottom: "0px" }}>
                              Currency
                            </p>
                            <select
                              value={Entity.vendor.currency}
                              onChange={(e) =>
                                handleChange(e, "vendor", "currency")
                              }
                              {...(isViewMode() ? { disabled: true } : {})}>
                              <option selected disabled>
                                Currency
                              </option>
                              {currencyList.map((r, i) => {
                                return (
                                  <option key={i} selected={r === "INR"}>
                                    {r}
                                  </option>
                                );
                              })}
                            </select>
                            {/* <input
            type="text"
            className="input is-small "
            ref={ref}
            placeholder="Currency"
            // onChange={(e) => handleChange(e, "entityName")}
            // {...(isViewMode() ? { disabled: true } : {})}
          /> */}
                          </Col4>
                          <Col4>
                            {" "}
                            <p
                              className="label is-small"
                              style={{ marginBottom: "0px" }}>
                              Credit Limit
                            </p>
                            <input
                              maxLength={10}
                              type="number"
                              className="input is-small "
                              ref={ref}
                              min="0"
                              pattern="[0-9]*"
                              placeholder="Credit Limit"
                              value={Entity.vendor.creditLimit}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              onChange={(e) =>
                                handleChange(e, "vendor", "creditLimit")
                              }
                              {...(isViewMode() ? { disabled: true } : {})}
                            />
                          </Col4>
                        </>
                      ) : null}
                    </Columns>
                    <hr style={{ border: "2px solid grey" }}></hr>
                    <h3 className="subtitle">Login Details</h3>
                    <Columns>
                      <Col4>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          Website URL
                        </p>
                        <input
                          type="text"
                          className="input is-small "
                          ref={ref}
                          pattern="[a-zA-Z0-9]"
                          placeholder="Website URL"
                          value={Entity.vendor.loginUrl}
                          id="loginUrl"
                          onChange={(e) =>
                            handleChange(e, "vendor", "loginUrl")
                          }
                          {...(isViewMode() ? { disabled: true } : {})}
                        />
                        {pendingErrors.loginUrl && (
                          <p className="help is-danger">
                            {pendingErrors.loginUrl}
                          </p>
                        )}
                      </Col4>
                      <Col4>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          User_Id
                        </p>
                        <input
                          maxLength={30}
                          type="text"
                          className="input is-small "
                          ref={ref}
                          pattern="[a-zA-Z0-9]"
                          placeholder="User_Id"
                          id="userId"
                          value={Entity.vendor.userId}
                          onChange={(e) => handleChange(e, "vendor", "userId")}
                          {...(isViewMode() ? { disabled: true } : {})}
                        />
                        {pendingErrors.userId && (
                          <p className="help is-danger">
                            {pendingErrors.userId}
                          </p>
                        )}
                      </Col4>
                      <Col4>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          Password
                        </p>
                        <input
                          maxLength={20}
                          type="text"
                          className="input is-small "
                          ref={ref}
                          pattern="[a-zA-Z0-9]"
                          placeholder="Password"
                          value={Entity.vendor.password}
                          id="password"
                          onChange={(e) =>
                            handleChange(e, "vendor", "password")
                          }
                          {...(isViewMode() ? { disabled: true } : {})}
                        />
                        {pendingErrors.password && (
                          <p className="help is-danger">
                            {pendingErrors.password}
                          </p>
                        )}
                      </Col4>
                    </Columns>
                    <Columns>
                      <Col5>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          Remarks
                        </p>
                        <textarea
                          placeholder="Remarks"
                          type="textarea"
                          style={{
                            width: "100%",
                            height: "4rem",
                            padding: "1%",
                          }}
                          value={Entity.vendor.remarks}
                          onChange={(e) => handleChange(e, "vendor", "remarks")}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {})}></textarea>
                      </Col5>
                    </Columns>
                    {EntityUtils.isFreightForwarder(Entity) ? (
                      <>
                        <hr style={{ border: "2px solid grey" }}></hr>
                        <h3 className="subtitle">Handling Charges</h3>
                        <Columns>
                          <Col3>
                            {" "}
                            <p
                              className="label is-small"
                              style={{ marginBottom: "0px" }}>
                              Handling Charges Per CBM
                            </p>
                          </Col3>
                          <Col6>
                            <select
                              value={
                                Entity?.vendor?.handlingCharges?.perCbm
                                  ?.currency
                              }
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  "vendor",
                                  "handlingCharges",
                                  "perCbm",
                                  "currency"
                                )
                              }
                              {...(isViewMode() ? { disabled: true } : {})}>
                              <option selected disabled>
                                Currency
                              </option>
                              {/* {currencyList.map((r, i) => {
                                return <option>{r}</option>;
                              })}
                               */}
                              {currencyList.map((r, i) => {
                                return (
                                  <option key={i} selected={r === "INR"}>
                                    {r}
                                  </option>
                                );
                              })}
                            </select>{" "}
                            <span></span>
                            <input
                              type="number"
                              value={
                                Entity?.vendor?.handlingCharges?.perCbm?.value
                              }
                              min="0"
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  "vendor",
                                  "handlingCharges",
                                  "perCbm",
                                  "value"
                                )
                              }
                              {...(isViewMode()
                                ? { disabled: true }
                                : {})}></input>
                          </Col6>
                        </Columns>
                        <Columns>
                          <Col3>
                            {" "}
                            <p
                              className="label is-small"
                              style={{ marginBottom: "0px" }}>
                              Handling Charges Per BL
                            </p>
                          </Col3>
                          <Col6>
                            <select
                              value={
                                Entity?.vendor?.handlingCharges?.perBL?.currency
                              }
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  "vendor",
                                  "handlingCharges",
                                  "perBL",
                                  "currency"
                                )
                              }
                              {...(isViewMode() ? { disabled: true } : {})}>
                              <option selected disabled>
                                Currency
                              </option>
                              {/* {currencyList.map((r, i) => {
                                return <option>{r}</option>;
                              })} */}
                              {currencyList.map((r, i) => {
                                return (
                                  <option key={i} selected={r === "INR"}>
                                    {r}
                                  </option>
                                );
                              })}
                            </select>{" "}
                            <span></span>
                            <input
                              type="number"
                              value={
                                Entity?.vendor?.handlingCharges?.perBL?.value
                              }
                              min="0"
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  "vendor",
                                  "handlingCharges",
                                  "perBL",
                                  "value"
                                )
                              }
                              {...(isViewMode()
                                ? { disabled: true }
                                : {})}></input>
                          </Col6>
                        </Columns>
                        <Columns>
                          <Col3>
                            {" "}
                            <p
                              className="label is-small"
                              style={{ marginBottom: "0px" }}>
                              Handling Charges Per Container
                            </p>
                          </Col3>
                          <Col6>
                            <select
                              value={
                                Entity?.vendor?.handlingCharges?.perContainer
                                  ?.currency
                              }
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  "vendor",
                                  "handlingCharges",
                                  "perContainer",
                                  "currency"
                                )
                              }
                              {...(isViewMode() ? { disabled: true } : {})}>
                              <option selected disabled>
                                Currency
                              </option>
                              {currencyList.map((r, i) => {
                                return (
                                  <option key={i} selected={r === "INR"}>
                                    {r}
                                  </option>
                                );
                              })}
                            </select>{" "}
                            <span></span>
                            <input
                              type="number"
                              value={
                                Entity?.vendor?.handlingCharges?.perContainer
                                  ?.value
                              }
                              min="0"
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  "vendor",
                                  "handlingCharges",
                                  "perContainer",
                                  "value"
                                )
                              }
                              {...(isViewMode()
                                ? { disabled: true }
                                : {})}></input>
                          </Col6>
                        </Columns>
                        <Columns>
                          <Col3>
                            {" "}
                            <p
                              className="label is-small"
                              style={{ marginBottom: "0px" }}>
                              Handling Charges Per AWB
                            </p>
                          </Col3>
                          <Col6>
                            <select
                              value={
                                Entity?.vendor?.handlingCharges?.perAWB
                                  ?.currency
                              }
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  "vendor",
                                  "handlingCharges",
                                  "perAWB",
                                  "currency"
                                )
                              }
                              {...(isViewMode() ? { disabled: true } : {})}>
                              <option selected disabled>
                                Currency
                              </option>
                              {currencyList.map((r, i) => {
                                return (
                                  <option key={i} selected={r === "INR"}>
                                    {r}
                                  </option>
                                );
                              })}
                            </select>{" "}
                            <span></span>
                            <input
                              type="number"
                              value={
                                Entity?.vendor?.handlingCharges?.perAWB?.value
                              }
                              min="0"
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  "vendor",
                                  "handlingCharges",
                                  "perAWB",
                                  "value"
                                )
                              }
                              {...(isViewMode()
                                ? { disabled: true }
                                : {})}></input>
                          </Col6>
                        </Columns>
                      </>
                    ) : null}
                    <hr style={{ border: "2px solid grey" }}></hr>
                    <h3 className="subtitle">Bank Account Details</h3>
                    <Columns>
                      <Col4>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          Bank Name *
                        </p>
                        <input
                          type="text"
                          className={`input is-small ${
                            pendingErrors.bankName ? "is-input-danger" : ""
                            }`}
                          ref={ref}
                          id="bankName"
                          pattern="[a-zA-Z0-9]"
                          placeholder="Input Text"
                          value={Entity?.vendor?.bankName}
                          onChange={(e) =>
                            handleChange(e, "vendor", "bankName")
                          }
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...(isEditMode() &&
                                (isPendingStatus() || isVerifiedStatus())
                                ? { disabled: true }
                                : {}),
                            })}
                        />
                      </Col4>
                      <Col4>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          Account Holder Name *
                        </p>
                        <input
                          type="text"
                          className={`input is-small ${
                            pendingErrors.accountHolderName
                              ? "is-input-danger"
                              : ""
                            }`}
                          ref={ref}
                          id="accountHolderName"
                          pattern="[a-zA-Z0-9]"
                          placeholder="Input Text"
                          value={Entity?.vendor?.accountHolderName}
                          onChange={(e) =>
                            handleChange(e, "vendor", "accountHolderName")
                          }
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...(isEditMode() &&
                                (isPendingStatus() || isVerifiedStatus())
                                ? { disabled: true }
                                : {}),
                            })}
                        />
                      </Col4>
                      <Col4>
                        <p
                          className="label is-small"
                          style={{ marginBottom: "0px" }}>
                          Account Number *
                        </p>
                        <input
                          maxLength={20}
                          type="text"
                          className={`input is-small ${
                            pendingErrors.accountNo ? "is-input-danger" : ""
                            }`}
                          ref={ref}
                          id="accountNo"
                          pattern="[a-zA-Z0-9]{1,20}"
                          placeholder="Input Text"
                          value={Entity?.vendor?.accountNo}
                          onChange={(e) =>
                            handleChange(e, "vendor", "accountNo")
                          }
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...(isEditMode() &&
                                (isPendingStatus() || isVerifiedStatus())
                                ? { disabled: true }
                                : {}),
                            })}
                        />
                        {pendingErrors.accountNo && (
                          <p className="help is-input-danger-message">
                            {pendingErrors.accountNo}
                          </p>
                        )}
                      </Col4>
                    </Columns>
                    <Columns>
                      {EntityUtils.isIndia(Entity) ? (
                        <>
                          <Col4>
                            <p
                              className="label is-small"
                              style={{ marginBottom: "0px" }}>
                              IFSC Code *
                            </p>
                            <input
                              maxLength={11}
                              type="text"
                              className={`input is-small ${
                                pendingErrors.IFSCcode ? "is-input-danger" : ""
                                }`}
                              ref={ref}
                              id="IFSCcode"
                              pattern="[A-Z0-9]"
                              placeholder="Input Text"
                              value={Entity?.vendor?.IFSCcode}
                              onChange={(e) =>
                                handleChange(e, "vendor", "IFSCcode")
                              }
                              {...(isViewMode()
                                ? { disabled: true }
                                : {
                                  ...(isEditMode() &&
                                    (isPendingStatus() || isVerifiedStatus())
                                    ? { disabled: true }
                                    : {}),
                                })}
                            />
                            {pendingErrors.IFSCcode && (
                              <p className="help is-input-danger-message">
                                {pendingErrors.IFSCcode}
                              </p>
                            )}
                          </Col4>
                        </>
                      ) : null}
                      {!EntityUtils.isIndia(Entity) ? (
                        <>
                          <Col4>
                            <p
                              className="label is-small"
                              style={{ marginBottom: "0px" }}>
                              Swift Code *
                            </p>
                            <input
                              type="text"
                              className={`input is-small ${
                                pendingErrors.swiftCode ? "is-input-danger" : ""
                                }`}
                              ref={ref}
                              id="swiftCode"
                              pattern="[A-Z0-9]{0,11}"
                              placeholder="Input Text"
                              value={Entity?.vendor?.swiftCode}
                              onChange={(e) =>
                                handleChange(e, "vendor", "swiftCode")
                              }
                              {...(isViewMode()
                                ? { disabled: true }
                                : {
                                  ...(isEditMode() &&
                                    (isPendingStatus() || isVerifiedStatus())
                                    ? { disabled: true }
                                    : {}),
                                })}
                            />
                            {pendingErrors.swiftCode && (
                              <p className="help is-input-danger-message">
                                {pendingErrors.swiftCode}
                              </p>
                            )}
                          </Col4>
                        </>
                      ) : null}
                      {Entity?.funded_Id && Entity.status == "VERIFIED" && Entity?.vendor?.vendorType=="Road Transport" 
                        && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.Razor_Pay) && Entity.entityCountry=="India" ? (
                        <>
                          <Col4>
                            <p
                              className="label is-small"
                              style={{ marginBottom: "0px" }}>
                              RazorpayX Fund Account ID *
                            </p>
                            <input
                              type="text"
                              className={`input is-small ${
                                pendingErrors.funded_Id ? "is-input-danger" : ""
                                }`}
                              ref={ref}
                              id="funded_Id"
                              pattern="[A-Z0-9]{0,11}"
                              placeholder="Input Text"
                              value={Entity?.funded_Id}
                              // onChange={(e) =>
                              //   handleChange(e, "vendor", "funded_Id")
                              // }
                              {...(isViewMode()
                                ? { disabled: true }
                                : {
                                  ...(isEditMode() &&
                                    (isPendingStatus() || isVerifiedStatus())
                                    ? { disabled: true }
                                    : {}),
                                })}
                            />
                            {pendingErrors.swiftCode && (
                              <p className="help is-input-danger-message">
                                {pendingErrors.funded_Id}
                              </p>
                            )}
                          </Col4>
                        </>
                      ) : null}
                      {Entity?.funded_Id && Entity.status == "VERIFIED" && Entity?.vendor?.vendorType=="Road Transport" 
                        && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.Razor_Pay) && Entity.entityCountry=="India" ? (
                        <>

                          <Col5>
                            <p
                              className="label is-small"
                              style={{ marginBottom: "0px" }}>
                              Make Payment *
                            </p>
                            <div className="right-header-2">
                              <span>
                                <button className="button is-small"
                                  onClick={async () => {
                                    //Entity.vendor.makePayment="ON"
                                    setDisplayStatus('ON')
                                    // await EntityApi.paymentEnable({
                                    //   entityId:Entity?._id,
                                    //   displayStatus:"ON"
                                    // })




                                  }}
                                  {...(isViewMode() ? { disabled: true } : {})}
                                  style={{ borderRadius: '25px 0px 0px 25px', ...pendingStyle }}>ON</button>
                                <button className="button is-small"
                                  onClick={async() => { 
                                    //Entity.vendor.makePayment="OFF"
                                    // await EntityApi.paymentEnable({
                                    //   entityId:Entity?._id,
                                    //   displayStatus:"OFF"
                                    // })
                                  setDisplayStatus('OFF')}}
                                  {...(isViewMode() ? { disabled: true } : {})}
                                  style={{ borderRadius: '0px 25px 25px 0px', ...completedStyle }}>OFF</button>
                              </span>
                            </div>
                          </Col5>

                        </>
                      ) : null}

                        {!Entity?.funded_Id && Entity.status == "VERIFIED" && Entity?.vendor?.vendorType=="Road Transport" 
                        && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.Razor_Pay) && Entity.entityCountry=="India" ? (
                        <>
                          <Col4>
                            <p
                              className="label is-small"
                              style={{ marginBottom: "0px" }}>
                              <br></br>
                            </p>
                            <button
                              className="button is-link"
                              style={{
                                border: "1px solid #ffffff",
                                borderRadius: "8px",
                                height: "35px",
                                color: "white",
                                // left: "14em",
                                position: "relative",
                                width: "300px",
                                marginLeft: "25%",
                              }}
                              //{...(isViewMode() ? { disabled: true } : {})}
                              onClick={async (event) => {
                                await EntityApi.viewEntity1({
                          reference_id:Entity?._id,
                          name:Entity?.entityName,
                          bank_account:{
                            name:Entity?.vendor?.accountHolderName,
                            account_number:Entity?.vendor?.accountNo,
                            ifsc:Entity?.vendor?.IFSCcode},setloading

                                })
                                await EntityApi.paymentEnable({
                                        entityId:Entity?._id,
                                        displayStatus:"ON"
                                })

                                routerStore.entityCurrentViewNew = "edit"
                                event.stopPropagation()
                              }}
                              {...(isViewMode() ? { disabled: true } : {})}
                            >
                              Generate RazorpayX Fund Account
                            </button>
                          </Col4>
                        </>
                      ) : null}

                    </Columns>
                    <Columns>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Upload Cheque Leaf/Bank Statement *{" "}
                      </p>
                      <span class="icon mt-1 ml-3 is-size-4 ">
                        {Entity?.documents[8]?.fileLink?.length > 0 ? (
                          <>
                            <span className="ml-2 mr-1">
                              <a
                                href={
                                  process.env.REACT_APP_API_SERVER +
                                  "/api/v1/filestore/getfile/" +
                                  Entity?.documents[8]?.pkey +
                                  "/" +
                                  Entity?.documents[8]?.fileLink
                                }
                                target="_blank"
                                rel="noreferrer"
                                alt="">
                                <BsFillEyeFill />
                              </a>
                            </span>
                            {isNewMode() ||
                              (isEditMode() &&
                                !isPendingStatus() &&
                                !isVerifiedStatus()) ? (
                              <>
                                <span>
                                  <MdDelete
                                    onClick={() => (
                                      delDocs(8),
                                      (hiddenFileInput9.current.value = "")
                                    )}
                                  />
                                </span>
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </span>
                      <span className="icon mt-1 ml-3 is-size-4 ">
                        {" "}
                        {isNewMode() || isEditMode() ? (
                          <>
                            {" "}
                            <i
                              id="CkLeafOrBankStat"
                              class="fa-solid fa-file-arrow-up is-clickable"
                              onClick={() => hiddenFileInput9.current.click()}
                              style={{
                                ...(Entity?.documents[8]?.fileLink?.length > 0
                                  ? { display: "none" }
                                  : null),
                              }}></i>
                          </>
                        ) : null}
                      </span>

                      <input
                        type="file"
                        onChange={(e1) => {
                          const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                          if (pattern.test(e1.target.files[0]["name"])) {
                            change(
                              e1.target.files,
                              "CkLeafOrBankStat",
                              "CkLeafOrBankStat"
                            );
                          } else {
                            configStore.setModalMessage(
                              "Please Check File Name and Format"
                            );
                            return (e1.target.value = "");
                          }
                        }}
                        name="image"
                        accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                        multiple=""
                        ref={hiddenFileInput9}
                        data-original-title="upload photos"
                        style={{ display: "none" }}
                        {...(isViewMode()
                          ? { disabled: true }
                          : {
                            ...(isEditMode() &&
                              (isPendingStatus() || isVerifiedStatus())
                              ? { disabled: true }
                              : {}),
                          })}
                      />
                    </Columns>
                  </div>
                </>
              ) : null}
            </div>
            {/* <div
              className="px-4"
              style={{ marginTop: "30px", marginLeft: "20px" }}>
              <p className="is-size-5 has-text-weight-bold mt-4">
                Logistic Contact Information
              </p>

              <div className="is-flex ">
                <table className="table_caf_fin wd_80">
                  <thead>
                    <td> Contact Person</td>
                    <td>Description/Department</td>
                    <td>Contact Number</td>
                    <td>Email</td>
                    {isViewMode() || isPendingStatus() ? null : <td></td>}
                  </thead>
                  <tbody>
                    {Entity.logisticInformation?.map((r, i) => {
                      return (
                        <tr>
                          <td>
                            <input
                              className="inp_table_caf"
                              type="text"
                              value={
                                Entity.logisticInformation[i].customercontact
                              }
                              maxLength={100}
                              pattern="[a-zA-Z0-9]"
                              onChange={(e) =>
                                handleChange1(e, i, "customercontact")
                              }
                              {...(isViewMode()
                                ? { disabled: true }
                                : {
                                    ...(isEditMode() && isPendingStatus()
                                      ? { disabled: true }
                                      : {}),
                                  })}></input>
                          </td>
                          <td>
                            <input
                              className="inp_table_caf"
                              value={Entity.logisticInformation[i].department}
                              onChange={(e) =>
                                handleChange1(e, i, "department")
                              }
                              {...(isViewMode()
                                ? { disabled: true }
                                : {
                                    ...(isEditMode() && isPendingStatus()
                                      ? { disabled: true }
                                      : {}),
                                  })}></input>
                          </td>
                          <td>
                            <input
                              type="number"
                              className="inp_table_caf"
                              maxLength={10}
                              value={Entity.logisticInformation[i].contactnum}
                              onChange={(e) =>
                                handleChange1(e, i, "contactnum")
                              }
                              {...(isViewMode()
                                ? { disabled: true }
                                : {
                                    ...(isEditMode() && isPendingStatus()
                                      ? { disabled: true }
                                      : {}),
                                  })}></input>
                          </td>
                          <td>
                            <input
                              className={`inp_table_caf ${validema ?"is-select-danger":""}`}
                              value={Entity.logisticInformation[i].email}
                              onChange={(e) => handleChange1(e, i, "email")}
                              {...(isViewMode()
                                ? { disabled: true }
                                : {
                                    ...(isEditMode() && isPendingStatus()
                                      ? { disabled: true }
                                      : {}),
                                  })}></input>
                          </td>
                          {isViewMode() || isPendingStatus() ? null : (
                            <td>
                              <span className=" icon is-medium">
                                <BiPlus
                                  className="is-clickable fas fa-lg"
                                  style={{ color: "red" }}
                                  onClick={addNewFinancerow}
                                  // { ...(isEditMode() && isPendingStatus()
                                  //   ? { disabled: true }
                                  //   : {})}
                                />
                              </span>
                              <span className=" icon is-medium">
                                <MdDelete
                                  className="is-clickable fas fa-lg"
                                  onClick={() => removeFinancerow(i)}
                                />
                              </span>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div> */}
            <div className="px-4">
              <div>
                <p className="is-size-5 has-text-weight-bold mt-4">
                  Logistic Contact Information*
                </p>
                <div className="is-flex pt-4 ">
                  <table className="table_caf_fin wd_80">
                    <thead>
                      <td>Contact Person</td>
                      <td>Description/Department</td>
                      <td>Contact Number</td>
                      <td>Email</td>
                      {isNewMode() && <td></td>}
                    </thead>
                    <tbody>
                      {/* {EntityUtils.isVendor(Entity) &&Entity.logisticInformation?.map((r, i) => {
                  return (
                    
                    <tr>
                      <td>
                        <input
                          className={`inp_table_caf input is-small ${
                            errors1.logisticInformation?.obj[i]?.customercontact
                              ? "input-danger"
                              : ""
                          }`}
                          value={Entity.logisticInformation[i].customercontact}
                          ref={ref}
                          onChange={(e) =>
                            handleChange2(e, i, "customercontact")
                          }
                          // disabled={!isNewMode() || !isIncompleteStatus() }
                          
                      //  {
                      //   ...((!isEditMode() && !isViewMode() && !isIncompleteStatus())  ? {disabled:true} : (isIncompleteStatus()) ? {disabled : false} : {disabled:true})
                      //  }
                    //  {
                    //   ...(Entity.status == "INCOMPLETE") ? {disabled:false} : {disabled:true}
                    //  }
                    disabled={v}
                        ></input>
                      </td>
                      <td>
                        <input
                          className={`inp_table_caf input is-small ${
                            errors1.logisticInformation?.obj[i]?.department
                              ? "input-danger"
                              : ""
                          }`}
                          ref={ref}
                          value={Entity.logisticInformation[i].department}
                          onChange={(e) => handleChange2(e, i, "department")}
                          // disabled={!isNewMode()}
                          disabled={v}
                        ></input>
                      </td>
                      <td style={{borderRight:"none"}}
                       className={`is-flex wd_101  ${
                            errors1.logisticInformation?.obj[i]?.contactnum
                              ? "input-danger"
                              : ""
                          }`}
                          >
                        <span className="contNumcodespn mt-1">+91</span>
                        <input
                          type="number"
                          className={`inp_table_caf input is-small out_none_inp`}
                          ref={ref}
                          value={Entity.logisticInformation[i].contactnum}
                          onChange={(e) => handleChange2(e, i, "contactnum")}
                          // disabled={!isNewMode()}
                          disabled={v}

                          onKeyDown={(e) =>
                            ["e", "E", "+", "-", "."].includes(e.key) &&
                            e.preventDefault()
                          }
                          min={0}
                        ></input>
                      </td>
                      <td style={{border:"1px solid black"}}>
                        <input
                          className={`inp_table_caf input is-small ${
                            errors1.logisticInformation?.obj[i]?.email ? "input-danger" : ""
                          }`}
                          value={Entity.logisticInformation[i].email}
                          ref={ref}
                          onChange={(e) => handleChange2(e, i, "email")}
                          // disabled={!isNewMode()}
                          disabled={v}
                        ></input>
                      </td>
                      {!v ? (
                        <>
                          <td>
                          { Entity.logisticInformation.length === (Math.abs(i+1)) ? ( 
                            <span className=" icon is-medium">
                              <BiPlus
                                className="is-clickable fas fa-lg"
                                style={{ color: "red" }}
                                onClick={addNewFinancerow1}
                                // disabled={!isNewMode()}
                                disabled={!v}
                              />
                            </span>
                               ) : <span className=" icon is-medium"></span>
                              }
                            <span className=" icon is-medium">
                              <MdDelete
                                className="is-clickable fas fa-lg"
                                onClick={() => removeFinancerow1(i)}
                                // disabled={!isNewMode()}
                                disabled={!v} 
                              />
                            </span>
                          </td>
                        </>
                      ) : null}
                    </tr>
                  );
                })}
                {EntityUtils.isCustomer(Entity) &&Entity.logisticInformation?.map((r, i) => {
                  return (
                    
                    <tr>
                      <td>
                        <input
                          className={`inp_table_caf input is-small ${
                            errors1.logisticInformation?.obj[i]?.customercontact
                              ? "input-danger"
                              : ""
                          }`}
                          value={Entity.logisticInformation[i].customercontact}
                          ref={ref}
                          onChange={(e) =>
                            handleChange2(e, i, "customercontact")
                          }
                          // disabled={!isNewMode() || !isIncompleteStatus() }
                          
                      //  {
                      //   ...((!isEditMode() && !isViewMode() && !isIncompleteStatus())  ? {disabled:true} : (isIncompleteStatus()) ? {disabled : false} : {disabled:true})
                      //  }
                    //  {
                    //   ...(Entity.status == "INCOMPLETE") ? {disabled:false} : {disabled:true}
                    //  }
                    disabled={v}
                        ></input>
                      </td>
                      <td>
                        <input
                          className={`inp_table_caf input is-small ${
                            errors1.logisticInformation?.obj[i]?.department
                              ? "input-danger"
                              : ""
                          }`}
                          ref={ref}
                          value={Entity.logisticInformation[i].department}
                          onChange={(e) => handleChange2(e, i, "department")}
                          // disabled={!isNewMode()}
                          disabled={v}
                        ></input>
                      </td>
                      <td style={{borderRight:"none"}}
                       className={`is-flex wd_101  ${
                            errors1.logisticInformation?.obj[i]?.contactnum
                              ? "input-danger"
                              : ""
                          }`}
                          >
                        <span className="contNumcodespn mt-1">+91</span>
                        <input
                          type="number"
                          className={`inp_table_caf input is-small out_none_inp`}
                          ref={ref}
                          value={Entity.logisticInformation[i].contactnum}
                          onChange={(e) => handleChange2(e, i, "contactnum")}
                          // disabled={!isNewMode()}
                          disabled={v}

                          onKeyDown={(e) =>
                            ["e", "E", "+", "-", "."].includes(e.key) &&
                            e.preventDefault()
                          }
                          min={0}
                        ></input>
                      </td>
                      <td style={{border:"1px solid black"}}>
                        <input
                          className={`inp_table_caf input is-small ${
                            errors1.logisticInformation?.obj[i]?.email ? "input-danger" : ""
                          }`}
                          value={Entity.logisticInformation[i].email}
                          ref={ref}
                          onChange={(e) => handleChange2(e, i, "email")}
                          // disabled={!isNewMode()}
                          disabled={v}
                        ></input>
                      </td>
                      {!v ? (
                        <>
                          <td>
                          { Entity.logisticInformation.length === (Math.abs(i+1)) ? ( 
                            <span className=" icon is-medium">
                              <BiPlus
                                className="is-clickable fas fa-lg"
                                style={{ color: "red" }}
                                onClick={addNewFinancerow1}
                                // disabled={!isNewMode()}
                                disabled={!v}
                              />
                            </span>
                               ) : <span className=" icon is-medium"></span>
                              }
                            <span className=" icon is-medium">
                              <MdDelete
                                className="is-clickable fas fa-lg"
                                onClick={() => removeFinancerow1(i)}
                                // disabled={!isNewMode()}
                                disabled={!v} 
                              />
                            </span>
                          </td>
                        </>
                      ) : null}
                    </tr>
                  );
                })} */}
                      {/* {!EntityUtils.isCustomer(Entity) && !EntityUtils.isVendor(Entity) && Entity.logisticInformation?.map((r, i) => {
                  return (
                    
                    <tr>
                      <td>
                        <input
                          className={`inp_table_caf input is-small ${
                            errors1.logisticInformation?.obj[i]?.customercontact
                              ? "input-danger"
                              : ""
                          }`}
                          value={Entity.logisticInformation[i].customercontact}
                          ref={ref}
                          onChange={(e) =>
                            handleChange2(e, i, "customercontact")
                          }
                          // disabled={!isNewMode() || !isIncompleteStatus() }
                          
                      //  {
                      //   ...((!isEditMode() && !isViewMode() && !isIncompleteStatus())  ? {disabled:true} : (isIncompleteStatus()) ? {disabled : false} : {disabled:true})
                      //  }
                    //  {
                    //   ...(Entity.status == "INCOMPLETE") ? {disabled:false} : {disabled:true}
                    //  }
                    disabled={v}
                        ></input>
                      </td>
                      <td>
                        <input
                          className={`inp_table_caf input is-small ${
                            errors1.logisticInformation?.obj[i]?.department
                              ? "input-danger"
                              : ""
                          }`}
                          ref={ref}
                          value={Entity.logisticInformation[i].department}
                          onChange={(e) => handleChange2(e, i, "department")}
                          // disabled={!isNewMode()}
                          disabled={v}
                        ></input>
                      </td>
                      <td style={{borderRight:"none"}}
                       className={`is-flex wd_101  ${
                            errors1.logisticInformation?.obj[i]?.contactnum
                              ? "input-danger"
                              : ""
                          }`}
                          >
                        <span className="contNumcodespn mt-1">+91</span>
                        <input
                          type="number"
                          className={`inp_table_caf input is-small out_none_inp`}
                          ref={ref}
                          value={Entity.logisticInformation[i].contactnum}
                          onChange={(e) => handleChange2(e, i, "contactnum")}
                          // disabled={!isNewMode()}
                          disabled={v}

                          onKeyDown={(e) =>
                            ["e", "E", "+", "-", "."].includes(e.key) &&
                            e.preventDefault()
                          }
                          min={0}
                        ></input>
                      </td>
                      <td style={{border:"1px solid black"}}>
                        <input
                          className={`inp_table_caf input is-small ${
                            errors1.logisticInformation?.obj[i]?.email ? "input-danger" : ""
                          }`}
                          value={Entity.logisticInformation[i].email}
                          ref={ref}
                          onChange={(e) => handleChange2(e, i, "email")}
                          // disabled={!isNewMode()}
                          disabled={v}
                        ></input>
                      </td>
                      {!v ? (
                        <>
                          <td>
                          { Entity.logisticInformation.length === (Math.abs(i+1)) ? ( 
                            <span className=" icon is-medium">
                              <BiPlus
                                className="is-clickable fas fa-lg"
                                style={{ color: "red" }}
                                onClick={addNewFinancerow1}
                                // disabled={!isNewMode()}
                                disabled={!v}
                              />
                            </span>
                               ) : <span className=" icon is-medium"></span>
                              }
                            <span className=" icon is-medium">
                              <MdDelete
                                className="is-clickable fas fa-lg"
                                onClick={() => removeFinancerow1(i)}
                                // disabled={!isNewMode()}
                                disabled={!v} 
                              />
                            </span>
                          </td>
                        </>
                      ) : null}
                    </tr>
                  );
                })} */}
                      {Entity.logisticInformation?.map((r, i) => {
                        return (
                          <tr>
                            <td>
                              <input
                                className={`inp_table_caf is-size-6 ${
                                  errors1.logisticInformation?.obj[i]
                                    ?.customercontact
                                    ? "input-danger"
                                    : ""
                                  }`}
                                value={
                                  Entity.logisticInformation[i].customercontact
                                }
                                ref={ref}
                                onChange={(e) =>
                                  handleChange2(e, i, "customercontact")
                                }
                                // disabled={!isNewMode() || !isIncompleteStatus() }

                                //  {
                                //   ...((!isEditMode() && !isViewMode() && !isIncompleteStatus())  ? {disabled:true} : (isIncompleteStatus()) ? {disabled : false} : {disabled:true})
                                //  }
                                //  {
                                //   ...(Entity.status == "INCOMPLETE") ? {disabled:false} : {disabled:true}
                                //  }
                                disabled={v}></input>
                            </td>
                            <td>
                              <input
                                className={`inp_table_caf input is-size-6 ${
                                  errors1.logisticInformation?.obj[i]
                                    ?.department
                                    ? "input-danger"
                                    : ""
                                  }`}
                                ref={ref}
                                value={Entity.logisticInformation[i].department}
                                onChange={(e) =>
                                  handleChange2(e, i, "department")
                                }
                                // disabled={!isNewMode()}
                                disabled={v}></input>
                            </td>
                            <td
                              style={{ borderRight: "none" }}
                              className={`is-flex wd_101  ${
                                errors1.logisticInformation?.obj[i]?.contactnum
                                  ? "input-danger"
                                  : ""
                                }`}>
                              <span className="contNumcodespn mt-1"></span>
                              <input
                                type="number"
                                className={`inp_table_caf input is-small out_none_inp`}
                                ref={ref}
                                value={Entity.logisticInformation[i].contactnum}
                                onChange={(e) =>
                                  handleChange2(e, i, "contactnum")
                                }
                                // disabled={!isNewMode()}
                                disabled={v}
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-", "."].includes(e.key) &&
                                  e.preventDefault()
                                }
                                min={0}></input>
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                className={`inp_table_caf is-size-6 ${
                                  errors1.logisticInformation?.obj[i]?.email
                                    ? "input-danger"
                                    : ""
                                  }`}
                                value={Entity.logisticInformation[i].email}
                                ref={ref}
                                onChange={(e) => handleChange2(e, i, "email")}
                                // disabled={!isNewMode()}
                                disabled={v}></input>
                            </td>
                            {!v ? (
                              <>
                                <td>
                                  {showPlusIcon &&
                                    Entity.logisticInformation.length ==
                                    Math.abs(i + 1) ? (
                                    <span className=" icon is-medium">
                                      <BiPlus
                                        className="is-clickable fas fa-lg"
                                        style={{ color: "red" }}
                                        onClick={addNewLogisticrow1}
                                        // disabled={!isNewMode()}
                                        disabled={!v}
                                      />
                                    </span>
                                  ) : (
                                    <span className=" icon is-medium"></span>
                                  )}
                                  <span className=" icon is-medium">
                                    <MdDelete
                                      className="is-clickable fas fa-lg"
                                      onClick={() => removeLogisticrow1(i)}
                                      // disabled={!isNewMode()}
                                      disabled={!v}
                                    />
                                  </span>
                                </td>
                              </>
                            ) : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {errors1.logisticInformation?.error && (
                <p className="help is-input-danger-message">
                  {errors1.logisticInformation.contactNumber ||
                    errors1.logisticInformation.email
                    ? errors1.logisticInformation.contactNumber ||
                    errors1.logisticInformation.email
                    : "Please enter all details"}
                </p>
              )}
            </div>
            {/* finance contact information */}
            {Entity.entityType === 'Customer'?<div className="px-4">
              <div>
                <p className="is-size-5 has-text-weight-bold mt-4">
                  Finance Contact Information
                </p>
                <div className="is-flex pt-4 ">
                  <table className="table_caf_fin wd_80">
                    <thead>
                      <td>Contact Person</td>
                      <td>Description/Department</td>
                      <td>Contact Number</td>
                      <td>Email</td>
                      {isNewMode() && <td></td>}
                    </thead>
                    <tbody>
                      {Entity?.financeInformation?.map((r, i) => {
                        return (
                          <tr>
                            <td>
                              <input
                                className={`inp_table_caf is-size-6 ${errors1?.financeInformation?.obj[i]?.customercontact
                                  ? "input-danger"
                                  : ""
                                }`}
                                value={
                                  Entity.financeInformation[i].customercontact
                                }
                                ref={ref}
                                onChange={(e) =>
                                  handleChange3(e, i, "customercontact")
                                }
                                // disabled={!isNewMode() || !isIncompleteStatus() }

                                //  {
                                //   ...((!isEditMode() && !isViewMode() && !isIncompleteStatus())  ? {disabled:true} : (isIncompleteStatus()) ? {disabled : false} : {disabled:true})
                                //  }
                                //  {
                                //   ...(Entity.status == "INCOMPLETE") ? {disabled:false} : {disabled:true}
                                //  }
                                disabled={v}></input>
                            </td>
                            <td>
                              <input
                                className={`inp_table_caf is-size-6 ${errors1?.financeInformation?.obj[i]?.department
                                  ? "input-danger"
                                  : ""
                                }`}
                                ref={ref}
                                value={Entity.financeInformation[i].department}
                                onChange={(e) =>
                                  handleChange3(e, i, "department")
                                }
                                // disabled={!isNewMode()}
                                disabled={v}></input>
                            </td>
                            <td
                              style={{ borderRight: "none" }}
                              className={`is-flex wd_101`}>
                              <span className="contNumcodespn mt-1"></span>
                              <input
                                type="number"
                                className={`inp_table_caf input is-small out_none_inp ${errors1?.financeInformation?.obj[i]?.contactnum
                                  ? "input-danger"
                                  : ""
                                }`}
                                ref={ref}
                                value={Entity.financeInformation[i].contactnum}
                                onChange={(e) =>
                                  handleChange3(e, i, "contactnum")
                                }
                                // disabled={!isNewMode()}
                                disabled={v}
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-", "."].includes(e.key) &&
                                  e.preventDefault()
                                }
                                min={0}></input>
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                className={`inp_table_caf is-size-6 ${errors1?.financeInformation?.obj[i]?.email
                                  ? "input-danger"
                                  : ""
                                }`}
                                value={Entity.financeInformation[i].email}
                                ref={ref}
                                onChange={(e) => handleChange3(e, i, "email")}
                                // disabled={!isNewMode()}
                                disabled={v}></input>
                            </td>
                            {!v ? (
                              <>
                                <td>
                                  {showFinPlusIcon &&
                                    Entity.financeInformation.length ==
                                    Math.abs(i + 1) ? (
                                    <span className=" icon is-medium">
                                      <BiPlus
                                        className="is-clickable fas fa-lg"
                                        style={{ color: "red" }}
                                        onClick={addNewFinancerow1}
                                        // disabled={!isNewMode()}
                                        disabled={!v}
                                      />
                                    </span>
                                  ) : (
                                    <span className=" icon is-medium"></span>
                                  )}
                                  <span className=" icon is-medium">
                                    <MdDelete
                                      className="is-clickable fas fa-lg"
                                      onClick={() => removeFinancerow1(i)}
                                      // disabled={!isNewMode()}
                                      disabled={!v}
                                    />
                                  </span>
                                </td>
                              </>
                            ) : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {errors1.financeInformation?.error && (
                <p className="help is-input-danger-message">
                  {errors1.financeInformation.contactNumber ||
                    errors1.financeInformation.email
                    ? errors1.financeInformation.contactNumber ||
                    errors1.financeInformation.email
                    : "Please enter all details"}
                </p>
              )}
            </div>:<></>}
            <Columns>
              <div className="mt-4 ml-6 is-flex is-align-items-center">
                {EntityUtils.isRejected(Entity) ? (
                  <>
                    <span className="is-size-4 has-text-weight-semibold">
                      Remarks :
                    </span>{" "}
                    <span className="ml-2">{Entity.remarks}</span>
                  </>
                ) : null}
              </div>
            </Columns>

            <div className="is-flex">
              {isViewMode() ? null : (
                <>
                  <button
                    //className="is-clickable"
                    className="button is-primary"
                    onClick={() => {
                      AgmodalStore.pageVisible = false;
                      AgmodalStore.apiCall = true;
                    }}
                    style={{
                      backgroundColor: "#32a893",
                      border: "none",
                      padding: "1%",
                      borderRadius: "8px",
                      color: "white",
                      fontWeight: "600",
                      marginTop: "7%",
                      marginLeft: "80%",
                      width: "70px",
                      cursor: "pointer",
                    }}>
                    Cancel
                  </button>
                </>
              )}
              {isViewMode() ? null : (
                <>
                  <button
                    //className="is-clickable"
                    className="button is-primary"
                    onClick={onFormSubmit}
                    style={{
                      backgroundColor: "#32a893",
                      border: "none",
                      padding: "1%",
                      borderRadius: "8px",
                      color: "white",
                      fontWeight: "600",
                      marginTop: "7%",
                      marginLeft: isViewMode() ? "auto" : "2%",
                      width: "70px",
                      cursor: "pointer",
                    }}
                    disabled={loading}>
                    {!loading ? "SUBMIT" : <Loader className="spinner" />}
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      )}{" "}
      {verifyPopup ? (
        <div
          style={{
            width: "450px",
            // height: "150px",
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: `45%`,
            left: `35%`,
            padding: "1% 1% 1% 1%",
            zIndex: 1,
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span className="is-size-6" style={{ marginTop: "5%" }}>
              Document verification for{" "}
              <span className="is-size-5">{Entity?.entityName}</span>
              <div>
                <input
                  type="radio"
                  className="is-size-2"
                  name="approvalStatuss"
                  value="approve"
                  checked={approvalStatus === "approve"}
                  onChange={handleVerifyRadioChange}
                />
                <span>Approve</span>
                <input
                  type="radio"
                  className="is-size-2 ml-2"
                  name="approvalStatuss"
                  value="reject"
                  checked={approvalStatus === "reject"}
                  onChange={handleVerifyRadioChange}
                />
                <span>Reject</span>
              </div>
            </span>
            <span>
              <ImCancelCircle onClick={cancelpopup} className="is-clickable" />
            </span>
          </div>

          {approvalStatus === "reject" && (
            <div>
              <textarea
                rows="4"
                cols="50"
                placeholder="Remarks"
                style={{ marginTop: "10px", border: "1px solid #d1d1e0" }}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          )}
          {approvalStatus === "approve" && (
            <div>
              Do you want to approve?
              <input
                type="checkbox"
                className="is-size-2"
                name="approvalStatus"
                value="approve"
                checked={approveCheckbox}
                onChange={() => setApproveCheckbox(!approveCheckbox)}
              />
            </div>
          )}

          <div>
            <span>
              <button
                style={{
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "2%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "4%",
                  marginLeft: "80%",
                  opacity:
                    approvalStatus === "approve" && approveCheckbox
                      ? 1
                      : approvalStatus === "reject" && remarks.trim().length > 3
                        ? 1
                        : 0.4,
                }}
                id="approvebtnid"
                onClick={updateStatusForEntity}
                disabled={
                  (approvalStatus === "approve" && approveCheckbox === false) ||
                  (approvalStatus === "reject" && remarks.trim() === "") ||
                  approvalStatus === "" ||
                  approvalStatus == null ||
                  loading ||
                  (approvalStatus === "reject" && remarks?.length <= 3)
                }>
                {!loading ? "SUBMIT" : <Loader className="spinner" />}
                {/* SUBMIT */}
              </button>
            </span>
          </div>
        </div>
      ) : null}
      {serviceability ? (
        <div
          style={{
            width: "775px",
            backgroundColor: "#f2f2f2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "30%",
            left: "33%",
            padding: "1% 1% 1% 1%",
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span className="is-size-5 has-text-weight-bold">
              Serviceability{" "}
            </span>
            <span>
              <ImCancelCircle onClick={cancelpopup} className="is-clickable" />
            </span>
          </div>
          {serviceArray.map((r, index) => {
            return (
              <p key={index} className="mt-2 mb-2">
                {/* <span>{index}</span> */}
                <span>From Trade Line</span>
                <select
                  className="ml-3"
                  style={{ width: "7em" }}
                  value={r?.fromState}
                  onChange={(e) =>
                    selectStateServiceAbl(e.target.value, "fromState", index)
                  }>
                  <option>Select state</option>
                  {states.map((r, i) => {
                    return (
                      <option value={r.name + "/" + r.isoCode}>{r.name}</option>
                    );
                  })}
                </select>
                <select
                  className="ml-2"
                  style={{ width: "7em" }}
                  value={r.fromCity}
                  onChange={(e) =>
                    selectCityServiceAbl(e.target.value, "fromCity", index)
                  }>
                  <option>Select city</option>
                  {City?.getCitiesOfState(
                    Entity?.entityCountryCode,
                    r.fromState.split("/")[1]
                  ).map((x, i) => {
                    return <option value={x.name}>{x.name}</option>;
                  })}
                </select>

                <span className="ml-6">To Trade Line</span>
                <span>
                  <select
                    className="ml-3"
                    style={{ width: "7em" }}
                    value={r?.toState}
                    onChange={(e) =>
                      selectStateServiceAbl(e.target.value, "toState", index)
                    }>
                    <option value="">Select state</option>
                    {states.map((r, i) => {
                      return (
                        <option value={r.name + "/" + r.isoCode}>
                          {r.name}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    className="ml-3"
                    style={{ width: "7em" }}
                    value={r.toCity}
                    onChange={(e) =>
                      selectCityServiceAbl(e.target.value, "toCity", index)
                    }>
                    <option value="">Select city</option>
                    {City?.getCitiesOfState(
                      Entity?.entityCountryCode,
                      r.toState.split("/")[1]
                    ).map((r, i) => {
                      return <option value={r.name}>{r.name}</option>;
                    })}
                  </select>
                </span>
                <span className="ml-3 mt-2 ">
                  <BiPlus className="is-clickable" onClick={addNewTradeline} />
                </span>
                <span className="mt-3 ">
                  <MdDelete
                    className="is-clickable"
                    onClick={() => removeTradeline(index)}
                  />
                </span>
              </p>
            );
          })}

          <div>
            <span>
              {(RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.SERVICEABILITY)) && (
                <button
                  className="is-clickable"
                  style={{
                    backgroundColor: "#00ccff",
                    border: "none",
                    padding: "1%",
                    borderRadius: "8px",
                    color: "white",
                    fontWeight: "600",
                    marginTop: "3%",
                    marginLeft: "80%",
                  }}
                  onClick={async () => {
                    const data = await serviceArray.map((r, i) => {
                      if (r.fromState === "" || r.toState === "") {
                        configStore.setModalMessage(`Please Select the state`);
                        return false;
                      } else if (r.fromCity === "" || r.toCity === "") {
                        configStore.setModalMessage(`Please Select the city`);
                        return false;
                      } else {
                        return true;
                      }
                    });
                    if (!data.includes(false)) {
                      EntityApi.createTradeLine(Entity.entityId, {
                        tradeLine: serviceArray,
                        updatedBy: loginStore.email,
                      });
                      AgmodalStore.pageVisible = false;
                      AgmodalStore.apiCall = true;
                    }
                  }}>
                  SUBMIT
                </button>
              )}
            </span>
          </div>
        </div>
      ) : null}
    </>
  );

  function clearErrorMessages(r) {
    pendingErrors[r] = "";
    errors[r] = "";
    setPendingErrors(pendingErrors);
  }
}
