import Utils from "./Utils"

export default class CargoHelper {
    static convertCargoline2stdUnits(cargoLine, p2p = true) {
        let e = { ...cargoLine }
        //console.log('\n\n***BEFORE CARGO=', e)
        let wt = parseInt(e.weight.value)
        wt = Utils.stdUnits(wt, e.weight.unit)
        e.weight.value = wt
        e.weight.unit = 'Kgs'
        if (!p2p) {
            let l = parseInt(e.dimensions.length)
            let w = parseInt(e.dimensions.width)
            let h = parseInt(e.dimensions.height)
            l = Utils.stdUnits(l, e.dimensions.unit)
            w = Utils.stdUnits(w, e.dimensions.unit)
            h = Utils.stdUnits(h, e.dimensions.unit)
            e.dimensions.length = l
            e.dimensions.width = w
            e.dimensions.height = h
            e.dimensions.unit = 'Cms'
            let volPerPackage = (l * w * h) / 1000000.0
            let weight = Utils.stdUnits(Number(e.weight.value), e.weight.unit)
            e.totalWeight.value = parseFloat(parseInt(e.quantity) * parseFloat(weight))
            e.totalVolume.value = parseFloat(volPerPackage * e.quantity)
            e.totalVolume.unit = 'CBM'
            e.totalWeight.unit = 'Kgs'
            let volWeight = (l * w * h) / 6000
            volWeight = parseInt(e.quantity) * volWeight
            e.totalVolumeWeight = {
                value: Number(volWeight.toFixed(3)),
                unit: 'Kgs'
            }
        }
        let cbm = Utils.stdUnits(Number(e.totalVolume.value), e.totalVolume.unit)
        let kgs = Utils.stdUnits(Number(e.totalWeight.value), e.totalWeight.unit)
        e.totalVolume.value = cbm
        e.totalVolume.unit = 'CBM'
        e.totalWeight.value = kgs
        e.totalWeight.unit = 'Kgs'
        e.chargeableWeight.value = Math.ceil(Math.max(e.totalVolume.value, e.totalWeight.value))
        e.chargeableWeight.unit = 'Kgs'
        e.p2p = p2p
        //console.log('*** AFTER=', e, '\n')
        return e
    }
    static convertCargoDetails2stdUnits(cargoDetails, p2p = true) {
        let cargo = JSON.parse(JSON.stringify(cargoDetails))
        return cargo.map(e => CargoHelper.convertCargoline2stdUnits(e, p2p))
    }
}