import { useEffect, useRef, useState } from "react"
import { useSnapshot } from "valtio"
import Utils from "../util/Utils"
import { taskStore } from "./task-store"
import { myactionsstore } from "../my-actions/store-myactions"

export function OneInput(props) {

    const { label, name, ...otherProps } = props
    useSnapshot(taskStore)
    let task = taskStore.current
    if (props.record) task = props.record
    return (
        <>
            <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>{label}</div>
            </td>
            <td {...otherProps}>
                <input {...otherProps} className={`input is-small ${(!taskStore.current.isValid && taskStore.current.isValidfield.indexOf(name) != -1) ? "is-danger" : ""} `} value={task[name]}
                    onChange={(e) => {
                        task[name] = e.target.value
                        console.log('changed value =', task[name])
                    }}
                    onKeyDown={(e) => {
                        if (label == "Contact Number" && props.message) {
                            ["e", "E", "+"].includes(e.key) && e.preventDefault()
                        }
                    }
                    }
                    onInput={(e) => {
                        if (label == "Contact Number" && props.message) {
                            e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                        }
                    }}
                />




            </td>
        </>
    )
}

export function ShipConInput(props) {
    const { label, name, val, ...otherProps } = props;
    const [value, setValue] = useState(val); 
    useSnapshot(taskStore);

    let task = taskStore.current;
    if (props.record) task = props.record;
    useEffect(() => {
        setValue(val);
        task[name] = val
    }, [val]); 

    return (
        <>
            <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>{label}</div>
            </td>
            <td {...otherProps}>
                <input
                    {...otherProps}
                    className={`input is-small ${(!taskStore.current.isValid && taskStore.current.isValidfield.indexOf(name) !== -1) ? "is-danger" : ""}`}
                    value={value} 
                    onChange={(e) => {
                        setValue(e.target.value); 
                        task[name] = e.target.value;
                        console.log('changed value =', task[name]);
                    }}
                    onKeyDown={(e) => {
                        if (label === "Contact Number" && props.message) {
                            ["e", "E", "+"].includes(e.key) && e.preventDefault();
                        }
                    }}
                    onInput={(e) => {
                        if (label === "Contact Number" && props.message) {
                            e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                        }
                    }}
                />
            </td>
        </>
    );
}




export function OneInput5(props) {
    const { label, name, record, ...otherProps } = props
    const [cursor, setCursor] = useState(0)
    const ref = useRef(null)
    useSnapshot(myactionsstore)
    useEffect(() => {
        const input = ref.current
        if (input) {
            input.setSelectionRange(cursor, cursor)
        }
    }, [ref, cursor])
    if (record && record[name] == undefined) {
        record[name] = ""
    }

    const handleChange = (e) => {
        setCursor(e.target.selectionStart)
        record[name] = e.target.value
    }
    return (
        <>
            <div className="columns">
                <div className="column is-6 py-2">
                    <div style={{ marginRight: '5px', marginTop: '3px', fontWeight: "bold" }}>{label}</div>
                </div>
                <div className="column is-6 py-2">
                    {(record) ? <input {...otherProps} style={{ width: "100%" }} ref={ref}
                        value={record[name]} className={myactionsstore.error.indexOf(name) == -1 ? "input is-small" : "input is-small is-danger"}
                        onChange={(e) => {
                            handleChange(e)
                            console.log('changed value =', record[name], record)
                        }}
                    /> : <></>
                    }
                </div>
            </div>
        </>
    )
}

export function OneInput5table(props) {
    const { label, name, ind, record, ...otherProps } = props
    const [cursor, setCursor] = useState(0)
    const ref = useRef(null)
    useEffect(() => {
        if(name!="total_package"){
            const input = ref.current
            if (input) {
                input.setSelectionRange(cursor, cursor)
            }
        }
    }, [ref, cursor])
    if (record && record[name] == undefined) {
        record[name] = ""
    }
    useSnapshot(myactionsstore)
    const handleChange = (e) => {
        if(name != "number_of_package" && name!="total_package"){
            setCursor(e.target.selectionStart)
        }
        if(name == "number_of_package"){
            if(e.target.value.match(/\d/g)){
                let l = e.target.value.match(/\d/g).join('')
                setCursor(l.length)
                record[name] = l
            }else{
                setCursor(0)
                record[name] = ""
            }
        }else{
            record[name] = e.target.value
        }
    }
    let a = ""
    switch (name) {
        case "container_number":
            a = 'container_number' + ind
            break;
        case "container_number_vehicle":
            a = 'container_number_vehicle' + ind
            break;
        default:
            a = name
            break;
    }
    let type = "text"
    if(name=="total_package"){
        type  = "number"
    }
    return (
        <>

            {(record) ? <div className="control"><input type={type} {...otherProps} style={{ width: "100%" }} ref={ref} className={myactionsstore.error.indexOf(a) == -1 ? "input is-small" : "input is-small is-danger"}
                value={record[name]}
                onChange={(e) => {
                    handleChange(e)
                    console.log('changed value =', record[name], record,e.target.value)
                }}
            /></div> : <></>
            }

        </>
    )
}

export function OneSelect4(props) {
    const { label, name, record, ...otherProps } = props
    if (record && record[name] == undefined) {
        record[name] = "N"
    }

    if (record[name] == "" || record[name].toLowerCase() == 'no') {
        record[name] = "N"
    }

    if (['yes', 'y', 'n'].indexOf(record[name].toLowerCase()) == -1) {
        record[name] = "N"
    }
    return (
        <>
            <div className="columns">
                <div className="column is-6 py-2">
                    <div style={{ marginRight: '5px', marginTop: '3px', fontWeight: "bold" }}>{label}</div>
                </div>
                <div className="column is-6 py-2">
                    {(record) ?
                        <div class="control">
                            <label class="radio">
                                <input type="radio" name="answer" onChange={(e) => {
                                    record[name] = "Y"
                                    console.log('changed value =', record[name])
                                }} checked={record[name] == 'Y'} />
                                Yes
                            </label>
                            <label class="radio">
                                <input type="radio" name="answer" onChange={(e) => {
                                    record[name] = "N"
                                    console.log('changed value =', record[name])
                                }} checked={record[name] == 'N'} />
                                No
                            </label>
                        </div>

                        : <></>
                    }
                </div>
            </div>
        </>
    )
}



export function OneDate(props) {
    const { label, name, record, ...otherProps } = props
    const [change, setChange] = useState(false)
    if (record && record[name] == undefined) {
        record[name] = ""
    }
    let value = ""
    if (record && record[name].length == 10) {
        // if(new Date(record[name]) == 'Invalid Date'){
        let l = record[name].split('-')
        value = record[name]
        console.log(record[name], l[0].length)
        if (l.length == 3 && l[0].length <= 2) {
            record[name] = `${l[2]}-${l[1]}-${l[0]}`
        }
        // }else{
        //     value = Utils.yyyymmdd(new Date(record[name]))
        //     console.log("2###########"+value)
        // }
    }

    return (
        <>
            <div className="columns">
                <div className="column is-6 py-2">
                    <div style={{ marginRight: '5px', marginTop: '3px', fontWeight: "bold" }}>{label}</div>
                </div>
                <div className="column is-6 py-2">
                    {(record) ? <input {...otherProps} type={"date"} style={{ width: "100%" }}
                        value={record[name]}
                        onChange={(e) => {
                            record[name] = e.target.value
                            if (change) {
                                setChange(false)
                            } else {
                                setChange(true)
                            }
                            console.log('changed value =', record[name])
                        }}
                    /> : <></>
                    }
                </div>
            </div>

        </>

    )
}

export function OneDatetable(props) {
    const { label, name, record, ...otherProps } = props
    const [change, setChange] = useState(false)
    if (record && record[name] == undefined) {
        record[name] = ""
    }
    let value = ""
    if (record && record[name].length == 10) {
        // if(new Date(record[name]) == 'Invalid Date'){
        let l = record[name].split('-')
        value = record[name]
        console.log(record[name], l[0].length)
        if (l.length == 3 && l[0].length <= 2) {
            record[name] = `${l[2]}-${l[1]}-${l[0]}`
        }
        // }else{
        //     value = Utils.yyyymmdd(new Date(record[name]))
        //     console.log("2###########"+value)
        // }
    }

    return (
        <>

            {(record) ? <input {...otherProps} type={"date"} style={{ width: "100%" }}
                value={record[name]}
                onChange={(e) => {
                    record[name] = e.target.value
                    if (change) {
                        setChange(false)
                    } else {
                        setChange(true)
                    }
                    console.log('changed value =', record[name])
                }}
            /> : <></>
            }

        </>

    )
}

export function OneInput2(props) {
    const { label, name1, name2, ...otherProps } = props
    useSnapshot(taskStore)
    let task = taskStore.current
    if (props.record) task = props.record
    let placeholder1 = ''
    if (name1 === 'setValueMin') placeholder1 = 'Minimum'
    let placeholder2 = ''
    if (name2 === 'setValueMax') placeholder2 = 'Maximum'
    return (
        <>
            <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>{label}</div>
            </td>
            <td {...otherProps}>
                <tr>
                    <td>
                        <input placeholder={placeholder1} {...otherProps} className="input is-small" value={task[name1]}
                            onChange={(e) => {
                                task[name1] = e.target.value
                                console.log('changed value =', task[name1])
                            }} />
                    </td>
                    <td>
                        <input placeholder={placeholder2} {...otherProps} className="input is-small" value={task[name2]}
                            onChange={(e) => {
                                task[name2] = e.target.value
                                console.log('changed value =', task[name2])
                            }} />
                    </td>
                </tr>
            </td>
        </>
    )
}
export function OneSelect(props) {
    const { label, name, dataList, ...otherProps } = props
    useSnapshot(taskStore)
    let task = taskStore.current
    if (props.record) task = props.record
    return (
        <>
            <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>{label}</div>
            </td>
            <td {...otherProps}>
                <select style={{ width: "100%" }} {...otherProps} className={`select is-small ${(!taskStore.current.isValid && taskStore.current.isValidfield.indexOf(name) != -1) ? "is-danger" : ""} `} value={task[name]}
                    onChange={(e) => {
                        task[name] = e.target.value
                        console.log('changed value =', task[name])
                    }} >
                    {
                        dataList.map(e => <option value={e}>{e}</option>)
                    }
                </select>
            </td>
        </>
    )
}
export function OneSelect5(props) {
    const { label, name, dataList, ...otherProps } = props
    useSnapshot(taskStore)
    let task = taskStore.current
    if (props.record) task = props.record
    return (
        <>
            <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>{label}</div>
            </td>
            <td {...otherProps} >
                <div className={`select is-small ${(!taskStore.current.isValid && taskStore.current.isValidfield.indexOf(name) != -1) ? "is-danger" : ""} `}>
                    <select style={{ width: "100%" }} {...otherProps} value={task[name]}
                        onChange={(e) => {
                            task[name] = e.target.value
                            console.log('changed value =', task[name])
                        }} >
                        {
                            dataList.map(e => <option value={e}>{e}</option>)
                        }
                    </select>
                </div>

            </td>
        </>
    )
}

export function ShipConSelect(props) {
    const { label, name, val, dataList, ...otherProps } = props;
    const [selectedValue, setSelectedValue] = useState(val); 
    useSnapshot(taskStore); // Assuming taskStore is imported or declared globally
    useEffect(() => {
        setSelectedValue(val);
        task[name] = val
    }, [val]); 

    let task = taskStore.current;
    if (props.record) task = props.record;

    return (
        <>
            <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>{label}</div>
            </td>
            <td {...otherProps}>
                <div className={`select is-small ${(!taskStore.current.isValid && taskStore.current.isValidfield.indexOf(name) !== -1) ? "is-danger" : ""}`}>
                    <select
                        style={{ width: "100%" }}
                        {...otherProps}
                        value={selectedValue} // Use selectedValue state for select value
                        onChange={(e) => {
                            setSelectedValue(e.target.value); // Update selectedValue state when select value changes
                            task[name] = e.target.value;
                            console.log('changed value =', task[name]);
                        }}
                    >
                        {dataList.map((e, index) => (
                            <option key={index} value={e}>{e}</option>
                        ))}
                    </select>
                </div>
            </td>
        </>
    );
}


export function OneSelect2(props) {
    const { label, name1, name2, dataList, ...otherProps } = props
    useSnapshot(taskStore)
    let task = taskStore.current
    if (props.record) task = props.record
    return (
        <>
            <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>{label}</div>
            </td>
            <td {...otherProps}>
                <span>
                    <select {...otherProps} className="select is-small" value={task[name1]}
                        onChange={(e) => {
                            task[name1] = e.target.value
                            console.log('changed value =', task[name1])
                        }} >
                        {
                            dataList.map(e => <option value={e}>{e}</option>)
                        }
                    </select>
                    <select {...otherProps} className="select is-small" value={task[name2]}
                        onChange={(e) => {
                            task[name2] = e.target.value
                            console.log('changed value =', task[name2])
                        }} >
                        {
                            dataList.map(e => <option value={e}>{e}</option>)
                        }
                    </select>
                </span>
            </td>
        </>
    )
}
export function OneLabel(props) {
    const { label, name, ...otherProps } = props
    useSnapshot(taskStore)
    let task = taskStore.current
    if (props.record) task = props.record
    return (
        <>
            <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>{label}</div>
            </td>
            <td {...otherProps}>
                {
                    (name === 'dueDate') ?
                        <div>{Utils.formatToDateTime1(task.dueDate, task.dueTime)}</div>
                        : <div>{task[name]}</div>
                }
            </td>
        </>
    )
}
export function OneLabel1(props) {
    const { label, name, ...otherProps } = props
    useSnapshot(taskStore)
    let task = taskStore.current
    if (props.record) task = props.record
    taskStore.current.totalAmount = Number(taskStore.current.netAmount) + Number(taskStore.current.gstAmount)
    return (
        <>
            <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>{label}</div>
            </td>
            <td {...otherProps}>
                {
                    (name === 'dueDate') ?
                        <div>{Utils.formatToDateTime1(task.dueDate, task.dueTime)}</div>
                        : <div>{task[name]}</div>
                }
            </td>
        </>
    )
}
export function OneCell(props) {
    const { record, name, ...otherProps } = props
    useSnapshot(taskStore)
    return (
        <>
            {
                (name === 'numPackages' || name === 'netWeight' || name === 'grossWeight' || name === 'volume') ? <>
                    <input {...otherProps} type={"number"} className={`input is-small ${(!taskStore.current.isValid && taskStore.current.isValidfield.indexOf(name) != -1) ? "is-danger" : ""} `} value={record[name]}
                        onChange={(e) => {
                            record[name] = e.target.value
                        }} />
                </> : <>
                    <input {...otherProps}  className={`input is-small ${(!taskStore.current.isValid && taskStore.current.isValidfield.indexOf(name) != -1) ? "is-danger" : ""} `} value={record[name]}
                        onChange={(e) => {
                            record[name] = e.target.value
                        }} />
                </>
            }
        </>

    )
}