import { configStore } from "../state/CommonState"
import Quotev2 from './quote-v2'
import QuoteViewv2 from './quote-view-v2'
import { useSnapshot } from "valtio"
import QuoteListv2 from "./quote-list-v2"
import QuoteViewMainv2 from "./quote-view-main-v2"

export default function QuoteMainv2() {
    const a = useSnapshot(configStore)

    switch(a.quoteCurrentView) {
        case 'list': return <QuoteListv2/>
        case 'view': return <QuoteViewMainv2/>
        case 'pureView': return <QuoteViewv2 />
        case 'edit': return <Quotev2 />
    }
}