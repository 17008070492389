import { useSnapshot } from "valtio";
import { ViewCargoDetails2 } from "../mui-components/ViewCargoDetails";
import { bookingsStore, breadCrumbStore, configStore, entityStore, viewBookingStore } from "../state/CommonState";
import { enquiryStore } from "../enquiry/enquiry-data";
import { BookingContainerDetail } from "./BookingContainerDetail";
import { VehicleDetail } from "./VehicleDetails";
import { CancelBookingRequest } from "../mui-components/CancelBookingRequest";
import { Approveddocuments } from "./ApprovedDocuments";
import { BookingDetailsComponent } from "./BookingDetailComponent";
import bookingDetails from "../assets/images/bookingdetails.svg";
import cargoDetails from "../assets/images/cargodetails.svg";
import shipmentDetails from "../assets/images/shipmentdetails.svg";
import vehicleDetails from "../assets/images/vehicledetails.svg";
import completed from "../assets/images/completed.svg";
import containerbox from "../assets/images/containerbox.svg";
import actionIcon from "../assets/images/action.svg";
import { Box, Button, Collapse, IconButton, Typography } from "@mui/material";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { AgMuiSelect } from "../mui-components/AgMuiSelect";
import { VisibilityTracker } from "../mui-components/VisibilityTracker";
import { ShippingDetailsView } from "./ShippingDetailView";
import agraga from "../assets/images/logo.svg";
import { Points } from "../mui-components/Points";
import { GetProgressBar } from "../mui-components/GetProgress";
import { taskStore } from "../mytasks/task-store";
import Taskapi, { Task } from "../mytasks/tasks-api";
import { loader } from "../util/loader";
import BookingsApi from "../state/bookings-api";
import Utils from "../util/Utils";
import { BreadCrumb } from "../mui-components/BreadCrumbComponent";
import cancel from "../assets/images/cancel.svg";
import arrowDropDown from "../assets/images/arrowdropdown.svg";
import { EnquiryDetailsView } from "../mui-components/EnquiryDetailsView";
import Api from "../state/Api";
import { ShareBooking } from "../mui-components/ShareBooking";
import { MyActions } from "./MyActions";
import { getPayload } from "../mytasks/tasks-static-data";
import { CustomsDetail } from "./customsDetails";
import customDetails from "../assets/images/customDetails.svg"




function CustomAccordion({ title, startIcon, open, content, src, setAccordion }) {
    return <Box sx={{ display: "flex", flexDirection: "column", padding: "10px 0px 5px 0px", borderRadius: "10px", backgroundColor: "white", width: "100%" }}>
        <Box onClick={() => {
            setAccordion();
        }} sx={{
            "&:hover": {
                cursor: "pointer",
            }, display: 'flex', flexDirection: "row", justifyContent: "space-between", paddingY: "8px"
        }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center",paddingX:"10px" }}>
                {startIcon && <img style={{ height: "20px", width: "18px" }} src={startIcon} />}
                <Typography sx={{ fontFamily: "Figtree", color: "black", fontWeight: "bold", fontSize: "16px" }}>{title}</Typography>
            </Box>
            {open ? <ExpandLessRounded /> : <ExpandMoreRounded />}
        </Box>
        <Collapse sx={{ paddingY: "8px" }} in={open} timeout="auto" unmountOnExit={true}>
            {content}
        </Collapse>
    </Box>
}



function Accordions({ accordions, selectedIndex, setIndex }) {

    return <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
        {accordions.map((accordion, i) =>
            <div id={accordion.id + "acc"} style={{ width: "100%" }} >
                <CustomAccordion startIcon={accordion.src} title={accordion.title} content={accordion.content} open={i === selectedIndex} setAccordion={() => {
                    if (i === selectedIndex) {
                        setIndex(-1)
                    } else {
                        setIndex(i);
                    }
                }} />
            </div>
        )}
    </Box>
}





const shippingDetails = [
    { title: "Carrier", value: "CMA CGM (CMDU)" },
    { title: "Stuffing Confirmation", value: "13 Jan" },
    { title: "POL Container", value: "BICI123456789" },
    { title: "HBL Number", value: "ACD1234456F" },
    { title: "MBL Number", value: "ACD1234456F" },
    { title: "New Carrier Name", value: "ACD1234456F" },
    { title: "De-stuffing Status at FPOD", value: "11 Feb, 2023" },
    { title: "Last Free Date at FPOD", value: "18 Feb, 2023" },
    { title: "Arrival Notice to Consignee", value: "18 Feb, 2023" },
    { title: "Delivery Order Release", value: "18 Feb, 2023" },
]




const statusTag = {
    submitted: "Yet to commence",
    inprogress: "In-Transit",
    archived: "Completed",
    cancelled: "Cancelled",
    "cancellation requested" : "Cancellation Requested"
}


function getStatusColor(status) {
    const colorFinder = {
        inprogress: {
            backgroundColor: "#EBB02D33",
            color: "#D59A17"
        },
        submitted: {
            backgroundColor: "#717AD233",
            color: "#717AD2"
        },
        archived: {
            backgroundColor: "#2C8A5733",
            color: "#2C8A57"
        },
        cancelled: {
            backgroundColor: "#F25B5B33",
            color: "#F25B5B"
        },
        "cancellation requested" : {
            backgroundColor : "#FF751633",
            color : "#FF7516"
        }
    }
    return colorFinder[status.toLowerCase()];
}


function BookingDetailCard({ booking }) {
    const shipmentType = booking?.booking?.contract?.shipmentType.split(" ")[0];
    let legendColor = getStatusColor(booking.booking.status);
    const [containerIndex,setContainerIndex] = useState(-1);
    const containers = [];
    const isAir = booking?.booking?.contract?.shipmentType === "Air"
    const {isImport,isExport} = Utils.getBookingsImportAndExportStatus(booking);
    const isFClWithContainerList = booking.shipmentType?.includes("FCL") && booking.containerlist && booking.containerlist?.length>1;
    if(isFClWithContainerList){
        containers.push({lable:"All Container",value:-1});
        booking.containerlist?.forEach((container,index)=>{
            containers.push({lable:`Container ${index+1}`,value:index});
        });
    }
    if (!legendColor) {
        legendColor = {
            backgroundColor: "#555555",
            color: "black"
        }
    }


    return <Box sx={{ width: "100%", display: "flex", flexDirection: "column", backgroundColor: "white", borderRadius: "12px", padding: "20px", gap: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", direction: "row", height: "50px", gap: 2 }}>
                <img src={agraga} />
                <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}>
                    {/* <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", color: "#555555" }}>Mode : {<span style={{ fontWeight: "bold", color: "black" }}>{shipmentType}</span>}</Typography> */}
                    <Box sx={{ display: "flex", flexDirection: "row" }}><Typography sx={{ fontFamily: "Figtree", fontSize: "12px", color: "#555555" }}>Status :</Typography> <Box sx={{ display: "flex", marginLeft: "4px", justifyContent: "center", alignItems: "center", fontFamily: "Figtree", borderRadius: "46px", paddingY: "2px", paddingX: "8px", color: legendColor.color, backgroundColor: legendColor.backgroundColor, height: "20px", fontSize: "14px" }}>{statusTag[booking.booking.status.toLowerCase()]}</Box></Box>
                    <Points propertyKey="Booking Ref." value={booking["agraga_booking_#"]} />
                </Box>
            </Box>
            {isFClWithContainerList && <Box sx={{display:"flex",flexDirection:"column",gap:"4px"}}>
            <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px",display: "flex", color: "black" }}>Select Container</Typography>
<AgMuiSelect iconComponent={arrowDropDown} fontSize="14px" onChange={(e)=>{
                    setContainerIndex(e.target.value);
}} value={containerIndex} options={containers.map(({lable})=>lable)} values={containers.map(({value})=>value)} height="30px" width="160px" style={{borderRadius:"7px"}} />
            </Box>}
            <Box sx={{ display: "flex", minWidth: "54%", flexDirection: "column", justifyContent: "space-between", gap: 1 }}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2 }}>
                    {/* <Points propertyKey="Shipper" value={isExport ? booking.booking.shipper : booking?.Consignee?.companyName || "Not Assigned"} /> */}
                    <Points propertyKey="Shipper" value={booking.booking.shipper} />
                    <Points propertyKey={isAir? "HAWB No. " : "HBL No."} value={isAir? booking.hawb_number : booking.hbl_number} />

                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2 }}>
                    {/* <Points propertyKey="Customer Ref." value={booking.cust_ref_number} /> */}
                    {/* <Points propertyKey="Consignee" value={isImport? booking.booking.shipper:  booking?.Consignee?.companyName || "Not Assigned" }  /> */}
                    <Points propertyKey="Consignee" value={ booking.booking.consignee && booking.booking.consignee.length>0 ?  booking.booking.consignee : "Not Assigned"  }  />
                    <Points propertyKey={ isAir ? "MAWB No. " : "MBL No."} value={ isAir? booking.mawb_number : booking.mbl_number} />
                </Box>
            </Box>

            {  booking.booking?.status?.toLowerCase()==="cancelled" || booking.booking?.status?.toLowerCase()==="cancellation requested" ? 
            <Box sx={{width:"241px",display:"flex",flexDirection:"row"}} >
                <Typography sx={{fontFamily:"Figtree",fontSize:"12px",fontWeight:"bold",color:"black"}}>Cancelled Reason :</Typography>
                <Typography sx={{fontFamily:"Figtree",fontSize:"12px"}}>{booking?.booking?.requestCancellationReason}</Typography>
                </Box>
                :
                <></>
                // shipmentType?.toLowerCase() === "air" ?  <GetProgressBar isCancelled={booking.booking?.status?.toLowerCase()==="cancelled" || booking.booking?.status?.toLowerCase()==="cancellation requested" } justifyContent="center"  status={{departureStatus:booking.departureStatus,arrivalStatus:booking.arrivalStatus}}  statusCompleted={booking.status_closed?.toLowerCase()==="y"} ataFpod={booking.ata_at_arrival_airport} etaFpod={booking.eta_at_arrival_airport} ataPol={booking.loaded_on_board} flexDirection="column" /> : <GetProgressBar isCancelled={booking.booking?.status?.toLowerCase()==="cancelled" || booking.booking?.status?.toLowerCase()==="cancellation requested"} justifyContent="center" status={{departureStatus:booking.departureStatus,arrivalStatus:booking.arrivalStatus}}  statusCompleted={booking.status_closed?.toLowerCase()==="y"}  ataFpod={shipmentType.toLowerCase() === "lcl" ? booking.gatein_fpod : Utils.getFCLAtaatFpod(booking)} etaFpod={booking.eta_fpod} ataPol={booking.sob_pol} flexDirection="column" /> 
            }
            {/* <ShareButton/> */}
            { !viewBookingStore.commonView && <ShareBooking bookingId={booking._id} branchId={booking.booking.branchId} entityId={booking.booking.entityId} />}
        </Box>
        {/* { isFClWithContainerList &&  <Box sx={{width:"100%",display:"flex",flexDirection:"row",flexWrap:"wrap",backgroundColor:"#F3F3F3",borderRadius:"5px",alignItems:"center",padding:"10px",marginY:"10px"}}>
                 {containers.map((container)=><Box onClick={()=>{
                    setContainerIndex(container.value);
                 }} sx={{display:"flex",flexDirection:"row",justifyContent:"center",cursor:"pointer",backgroundColor:containerIndex===container.value?"white":"",alignItems:"center",fontFamily:"Figtree",color:"#555555",height:"34px",borderRadius:"5px",gap:2,paddingX:"10px"}}>
                          {container.lable}
                 </Box>)}
        </Box>} */}
        <Box sx={{ width: "100%" }}>
            <VisibilityTracker mode={booking.shipmentType} isViewFull={true} container={isFClWithContainerList && containerIndex>-1 ? <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",color:"black",fontSize:"12px"}}>{containers[containerIndex+1].lable} : <span style={{color:"#555555",fontWeight:"normal"}}>{booking.containerlist[containerIndex].container_types}</span></Typography> :null} booking={Utils.getTrackerPoints(booking,containerIndex)} />
        </Box>
    </Box>
}



export function SelectedBooking({callback}) {
    const accordions = [
        // {
        //     id:"6",
        //     title : "Actions Completed",
        //     src : actionIcon,
        //     content : <MyActions/>
        // },
        {
            id: "0",
            title: "Approved Documents",
            src: completed,
            content: <Approveddocuments callback={callback} />
        },
        {
            id: "1",
            title: "Booking Details",
            src: bookingDetails,
            content: <BookingDetailsComponent />
        },

        {
            id: "3",
            title: "Shipping Details",
            src: shipmentDetails,
            content: <ShippingDetailsView/>

        }


    ]
    const currentAccordions = [...accordions];
    const booking = viewBookingStore.current;
    const [icegateData,seticegateData] = useState("");
    console.log(booking,"booking");
    let scope = enquiryStore.current.shipmentScope.split("-");
    let displayCargo4fcl = Utils.outGaugeContainerCheck(booking.booking.contract.containerList)
    if((scope[0]==="Door" && enquiryStore.current.stuffingType==="Dock" ) || (scope[2]==="Door" && enquiryStore.current.destuffingType==="Dock")||enquiryStore.current.shipmentType.toLowerCase()==="air"||enquiryStore.current.shipmentType.toLowerCase()==="lcl"||displayCargo4fcl){
        currentAccordions.splice(2, 0,  {
            id: "2",
            title: Utils.labelForOutGauge1(booking.booking.contract,booking.booking.contract.containerList)?'Cargo Details for Out-Gauge Containers':"Cargo Details",
            src: cargoDetails,
            content: <ViewCargoDetails2 />
        },)
    }

    if ((booking?.exportClearance?.length > 0 && booking?.importClearance?.length > 0) || (!icegateData?.req?.mismatch && icegateData?.req && icegateData?.res && (icegateData?.req?.type=="sbc"|| icegateData?.req?.type=="boe"))) {
        currentAccordions.push({
            id: "5",
            title: "Customs Details",
            src: customDetails,
            content: <CustomsDetail data={{...icegateData?.req,...icegateData?.res,customerCompletedOn:icegateData?.customerCompletedOn}} />
        }
        )
    }

    if (booking.containerlist) {
        currentAccordions.splice(3, 0, {
            id: "5",
            title: "Container Details",
            src: containerbox,
            content: <BookingContainerDetail />
        })
    }


if((booking.vorgin && booking.vorgin?.length > 0) || (booking.vdes && booking.vdes?.length >0) ){
    currentAccordions.push( {
            id: "4",
            title: "Vehicle Details",
            src: vehicleDetails,
        content: <VehicleDetail/>
        }
        )
    }
    const [selectedIndex, setSelectedAccordion] = useState(currentAccordions.findIndex((acc)=>acc.id===viewBookingStore.accordionToBeOpended.toString()))


    const [open,setOpen] = useState(false);
   

    const isFirst = useRef(1);

    async function getData() {
        await Api.getICEGATEData({id:booking?._id},async(data)=>{
            console.log(data?.data);
            let resData = data?.data
            if (resData?.length > 0 && resData[0]?.icegateResData) {
                let tempData = {
                    res:resData[0]?.icegateResData?.response,
                    req:resData[0]?.icegateReqData,
                    customerCompletedOn:Utils.convertEpochDate(resData[0]?.customerCompletedOn)?.split(',')[0],
                    fullDate:Utils.convertEpochDate(resData[0]?.customerCompletedOn)
                }
                seticegateData(tempData)
                console.log("tempData",tempData);
               const date=  Utils.convertEpochDate(resData[0]?.customerCompletedOn)
               console.log(date.split(',')[0]);
            }
        })
    } 

    useEffect(() => {
        getData()
        const callback1 = (data)=>{
            if(JSON.stringify(data)!=JSON.stringify(configStore.currencyList)){
                configStore.currencyList = data
            }
        }
        breadCrumbStore.onBackArrow = ()=>{
            viewBookingStore.current = {};
        }
        if(breadCrumbStore.breadCrumbs.length>0){
            Api.getLiveExchangerates(callback1)
        }
        return () => {
            // breadCrumbStore.reset();
            viewBookingStore.accordionToBeOpended = -1;
            viewBookingStore.current = {};
            enquiryStore.current.initialize();
        }
    }, [])

    useEffect(() => {
        getTheScreenIntoView();
    }, [selectedIndex])

    useEffect(()=>{
        if(isFirst.current>1){
            viewBookingStore.current = {};
        }
        isFirst.current+=1;
    },[configStore.currentSelectedEntity,configStore.currentSelectedBranch])


    const getTheScreenIntoView = () => {
        if (selectedIndex !== -1) {
            setTimeout(() => {
                const element = document.getElementById(`${selectedIndex}acc`);
                element?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }, 200)
        }
    }
    return <>

        <Box sx={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
            <BreadCrumb />
{ booking.booking.status?.toLowerCase() === "submitted" && !viewBookingStore.commonView && Utils.isMyBooking(booking?.booking?.contract?.entityId) && <Button sx={{textTransform:"capitalize",backgroundColor:"#F25B5B",paddingX:"3px",color:"white",fontSize:"14px",height:"35px",fontFamily:"Figtree",width:"187px",borderRadius:"5px","&:hover":{
backgroundColor:"#F25B5B",color:"white"
}}} onClick={()=>{
                taskStore.current.actionName = 'Request for Booking Cancellation';
                setOpen(true)
            }} startIcon={<img src={cancel} />}>Request Cancellation</Button>}
        </Box>
        <EnquiryDetailsView options={[{ title: "Mode", value: enquiryStore.current.shipmentType }, { title: "Scope", value: enquiryStore.current.shipmentScope }, { title: "Origin", value: enquiryStore.current.enqRoute.origin, width:"300px" }, { title: "Destination", value: enquiryStore.current.enqRoute.destination, width:"300px" }, { title: "Cargo Readiness", value: enquiryStore.current.enqRoute.cargoReadinessDate }, { title: "Customer Ref. No.", value: enquiryStore.current.enqRoute.customerReferenceNum }]} />
        <BookingDetailCard booking={booking} />
        <Accordions accordions={currentAccordions} selectedIndex={selectedIndex} setIndex={setSelectedAccordion} />
   <CancelBookingRequest onSubmit={async()=>{
            if (bookingsStore.requestCancellationReason === null || bookingsStore.requestCancellationReason == '') {
                configStore.setModalMessage(`Invalid Reason.`);
        }else{
            try{

                    loader.show();
                    taskStore.current = new Task('')
                    taskStore.current.bookingNum = booking._id
                    enquiryStore.initializeWithConract(booking.booking.contract);
                    taskStore.current.initialize(bookingsStore.current, entityStore.entityRec)
                    taskStore.current.actionName = "Request for Booking Cancellation"
                    Object.assign(taskStore.current, getPayload("Request for Booking Cancellation"))
                    taskStore.current.reason = bookingsStore.requestCancellationReason
                taskStore.current.entityId=booking.booking.entityId
                taskStore.current.branchId=booking.booking.branchId
                    bookingsStore.current._id = booking._id

                    // taskStore.setCustomerComplete()
                    taskStore.current.stage = 1
                    taskStore.current.pendingWith = 'AGRAGA'
                    await Taskapi.saveTask(taskStore.current);
                BookingsApi.cancelBooking(booking._id, bookingsStore.requestCancellationReason,()=>{
                        loader.hide();
                        bookingsStore.requestCancellationReason = null;
                        setOpen(false);
                        breadCrumbStore.back();
                    });
            }catch(e){
                    configStore.modalMessage("Something went wrong")
                    breadCrumbStore.back();
            }finally{
                    bookingsStore.requestCancellationReason = null;
                    setOpen(false);
                    loader.hide();
                }

            }
   }} open={open} onCancel={()=>{
            setOpen(false);
        }} />
    </>
}
