import { useSnapshot } from "valtio"
import Api from "../state/Api"
import { configStore } from "../state/CommonState"
import { fileStore } from "../state/fileStore"
let Agmongofileparentid = []

export default function AgFile(props) {
    useSnapshot(fileStore)
    const {parent} = props
    if(parent){
        if(fileStore.props[parent]==undefined){
            fileStore.props[parent] = props
        }
    }
    let parentid = ['ta1','tf8']
    console.log('Inside Agfile', props.source, props.cat, props.filetype)
    const {source, cat, filetype, callback, url = '/api/v1/common/receivefile'} = props
    var parent_container=null
    var newHandle = function(e) { 
        let parent_container1 = null
        if(parentid.length>0){
            parent_container = e.target;
            do {
                if (!parent_container) break
                parent_container = parent_container.parentNode;

                if (parent_container && parentid.indexOf(parent_container.id) == -1) {
                    parent_container1 = null
                }else{
                    parent_container1 = parent_container
                }
            }
            while( parent_container1==null && parent_container !== document.body );
            parent_container = parent_container1
        }
    };
    var newHandle1 = function(e) { 
        if(parent && parent_container){
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if(e.clipboardData.files.length>0){
                change(i[0].files,fileStore.props[parent_container.id])
            }
        }
    };
    if(parentid.length>0){
        document.getElementsByTagName('body')[0].onclick = function(e) {newHandle(e)}
        document.getElementsByTagName('body')[0].onpaste = function(e) {newHandle1(e)}
    
    }

    function change (files,props){
        const {parent} = props
        const {source, cat, filetype, callback, url = '/api/v1/common/receivefile'} = props
        const formData = new FormData()
            formData.append('myFile', files[0])
            formData.append('source', source)
            formData.append('category', cat)
            formData.append('filetype', filetype)
            console.log('FORMDATA=')
            console.log()

            fetch(`${process.env.REACT_APP_API_SERVER}${url}`, {
                method: 'POST',
                headers: Api.H1(),
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    console.log('got data', data)
                    if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') { configStore.setModalMessage(data.error) }}
                    else {
                        //console.log('setting the filelink',  `/clientfiles/${files[0].name}`)
                        callback(data.filename)
                        document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                    }
                })
                .catch(error => {
                    console.error(error)
                })
    }
    return (
        <div>
        <input type="file" accept=".pdf,image/*" style={{fontSize: '0.8rem'}} onChange={(e) => {
            const pattern = /^[a-zA-Z0-9_ .()-]+$/;
            if(pattern.test(e.target.files[0]['name'])){
                change(e.target.files,props)
            }else{
                configStore.setModalMessage("Please Check File Name and Format")
                return e.target.value = ""
            }
            
        }} />
        </div>
    )
}

export function AgCustomFile(props) {
    useSnapshot(fileStore)
    const {parent} = props
    if(parent){
        if(fileStore.props[parent]==undefined){
            fileStore.props[parent] = props
        }
    }
    
    console.log('Inside Agfile', props.source, props.cat, props.filetype)
    const {source, cat, filetype, callback, url = '/api/v1/common/receivefile', withBtn = false, multi} = props
    let parentid = ['cwf5', 'cwf6', 'cwf1', 'cwf2', 'cwf3', 'cwf4']
    var parent_container = null
    var newHandle = function(e) { 
        let parent_container1 = null
        if(parentid.length>0 ){
            parent_container = e.target;
            
            do {
                if (!parent_container) break
                parent_container = parent_container.parentNode;
                
                if (parent_container && parentid.indexOf(parent_container.id) == -1) {
                    parent_container1 = null
                }else{
                    parent_container1 = parent_container
                }
            }
            while( parent_container1==null && parent_container !== document.body );
            parent_container = parent_container1
            console.log(parent_container)
        }
    };
    var newHandle1 = function(e) { 
        if(parent && parent_container){
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if(e.clipboardData.files.length>0){
                change(i[0].files,fileStore.props[parent_container.id])
                e.stopPropagation()
            }
        }
    };
    if(parentid.length>0){
        document.getElementsByTagName('body')[0].onclick = function(e) {newHandle(e)}
        document.getElementsByTagName('body')[0].onpaste = function(e) {newHandle1(e)}
    
    }
    function change(files,props){
        const {parent} = props
        const {source, cat, filetype, callback, url = '/api/v1/common/receivefile', withBtn = false, multi} = props
        const formData = new FormData()
            formData.append('myFile', files[0])
            formData.append('source', source)
            formData.append('category', cat)
            formData.append('filetype', filetype)

            fetch(`${process.env.REACT_APP_API_SERVER}${url}`, {
                method: 'POST',
                headers: Api.H1(),
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    console.log('got data', data)
                    if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') { configStore.setModalMessage(data.error) }}
                    else {
                        //console.log('setting the filelink',  `/clientfiles/${files[0].name}`)
                        callback(data.filename)
                        document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                    }
                })
                .catch(error => {
                    console.error(error)
                })
    }
    return (
        <div>
            {
                (multi)?<>
                  {
                withBtn && (<label for={filetype} className="button is-small is-success"> 
                                    <span className="icon has-text-light">
                                        <i className="fas fa-plus"></i>
                                    </span>
                                </label>)
            }
        <input className={withBtn ? "is-hidden" : ""} id={filetype} type="file" accept=".pdf,image/*" style={{fontSize: '0.8rem'}} onChange={(e) => {
            change(e.target.files,props)
            
        }} />
                </>:<>
                <input type="file" accept=".pdf,image/*" style={{fontSize: '0.8rem'}} onChange={(e) => {
            // change(e.target.files,props)
            const pattern = /^[a-zA-Z0-9_ .()-]+$/;
            if(pattern.test(e.target.files[0]['name'])){
                change(e.target.files,props)
            }else{
                configStore.setModalMessage("Please Check File Name and Format")
                return e.target.value = ""
            }
            
        }} />
                </>
            }
         
              
           
        </div>
    )
}

export function AgscheduleFile(props) {
    useSnapshot(fileStore)
    const {parent} = props
    if(parent){
        if(fileStore.props[parent]==undefined){
            fileStore.props[parent] = props
        }
    }
    console.log('Inside Agfile', props.source, props.cat, props.filetype)
    const {consolidatorlist={}, uploadShipmentType,  callback, url = '/api/v1/myactions/uploadschedules'} = props
    let parentid = ['ts2','ts1']
    var parent_container = null
    var newHandle = function(e) { 
        let parent_container1 = null
        if(parentid.length>0 ){
            parent_container = e.target;
            do {
                if (!parent_container) break
                parent_container = parent_container.parentNode;

                if (parent_container && parentid.indexOf(parent_container.id) == -1) {
                    parent_container1 = null
                }else{
                    parent_container1 = parent_container
                }
            }
            while( parent_container1==null && parent_container !== document.body );
            parent_container = parent_container1
        }
    };
    var newHandle1 = function(e) { 
        if(parent && parent_container){
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if(e.clipboardData.files.length>0){
                change(i[0].files,fileStore.props[parent_container.id])
            }
        }
    };
    if(parentid.length>0){
        document.getElementsByTagName('body')[0].onclick = function(e) {newHandle(e)}
        document.getElementsByTagName('body')[0].onpaste = function(e) {newHandle1(e)}
    
    }
    function change(files,props){
        const {parent} = props
        const {consolidatorlist={}, uploadShipmentType,  callback, url = '/api/v1/myactions/uploadschedules'} = props
        const formData = new FormData()
            formData.append('myFile', files[0])
            formData.append('action', 'schedule_upload')
            formData.append('shipmentType', uploadShipmentType)
            formData.append('consolidatorlist', consolidatorlist)

            fetch(`${process.env.REACT_APP_API_SERVER}${url}`, {
                method: 'POST',
                headers: Api.H1(),
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    console.log('got data', data)
                    if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') { configStore.setModalMessage(data.error) }}
                    else {
                        //console.log('setting the filelink',  `/clientfiles/${files[0].name}`)
                        callback(data.filename)
                        document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                    }
                })
                .catch(error => {
                    console.error(error)
                })
    }
    return (
        <div>
        <input type="file"  style={{fontSize: '0.8rem'}} onChange={(e) => {
            // change(e.target.files,props)
            const pattern = /^[a-zA-Z0-9_ .()-]+$/;
            if(pattern.test(e.target.files[0]['name'])){
                change(e.target.files,props)
            }else{
                configStore.setModalMessage("Please Check File Name and Format")
                return e.target.value = ""
            }
            
        }} />
        </div>
    )
}

export function Agmongofile(props) {
    useSnapshot(fileStore)
    const {parent} = props
    if(parent){
        if(fileStore.props[parent]==undefined){
            fileStore.props[parent] = props
        }
    }
    console.log(fileStore.props[parent])
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/filestore/storefile`
    const {label, filekey, disabled = false, callback = f => f} = props
    if(Agmongofileparentid.indexOf(parent)==-1){
        Agmongofileparentid.push(parent)
    }

    var parent_container = null
    var newHandle = function(e) { 
        let parent_container1 = null
        if(Agmongofileparentid.length>0 ){
            parent_container = e.target;
            do {
                if(parent_container){
                    parent_container = parent_container.parentNode;
                }else{
                    break
                }

                if (parent_container && Agmongofileparentid.indexOf(parent_container.id) == -1) {
                    parent_container1 = null
                }else{
                    parent_container1 = parent_container
                }
            }
            while( parent_container1==null && parent_container !== document.body );
            parent_container = parent_container1
        }
    };
    var newHandle1 = function(e) { 
        if(parent && parent_container){
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if(e.clipboardData.files.length>0){
                console.log(fileStore.props[parent_container.id])
                change(i[0].files,fileStore.props[parent_container.id])
            }
        }
    };
    if(Agmongofileparentid.length>0){
        document.getElementsByTagName('body')[0].onclick = function(e) {newHandle(e)}
        document.getElementsByTagName('body')[0].onpaste = function(e) {newHandle1(e)}
    
    }
    function change(files,props){
        const {label, filekey, disabled = false, callback = f => f} = props
        const {parent} = props
        const formData = new FormData()
        formData.append('myFile', files[0])
        formData.append('label', label)
        formData.append('key', filekey)
        fetch(url, {
            method: 'POST',
            headers: Api.H1(),
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                
                console.log('got data', data)
                if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') { configStore.setModalMessage(data.error) }}
                else {
                    //console.log('setting the filelink',  `/clientfiles/${files[0].name}`)
                    callback(data,+parent.split('___')[1])
                    console.log(document.getElementById(parent).getElementsByTagName('input')[0].value)
                    document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                }
            })
            .catch(error => {
                console.error(error)
            })
    }
    return (
        <div>
        <input type="file" disabled={disabled} style={{fontSize: '0.8rem'}} onChange={(e) => {
            const pattern = /^[a-zA-Z0-9_ .()-]+$/;
            if(pattern.test(e.target.files[0]['name'])){
                change(e.target.files,props)
            }else{
                configStore.setModalMessage("Please Check File Name and Format")
                return e.target.value = ""
            }
            
        }} />
        </div>
    )
}

export function Agmongofiledlupload(props) {
    useSnapshot(fileStore)
    const {parent} = props
    if(parent){
        if(fileStore.props[parent]==undefined){
            fileStore.props[parent] = props
        }
    }
    console.log(fileStore.props[parent])
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/filestore/storefile`
    const {label, filekey, disabled = false, callback = f => f} = props
    if(Agmongofileparentid.indexOf(parent)==-1){
        Agmongofileparentid.push(parent)
    }

    var parent_container = null
    var newHandle = function(e) { 
        let parent_container1 = null
        if(Agmongofileparentid.length>0 ){
            parent_container = e.target;
            do {

                if(parent_container){
                    parent_container = parent_container.parentNode;
                }else{
                    break
                }
                

                if (parent_container && Agmongofileparentid.indexOf(parent_container.id) == -1) {
                    parent_container1 = null
                }else{
                    parent_container1 = parent_container
                }
            }
            while( parent_container1==null && parent_container !== document.body );
            parent_container = parent_container1
        }
    };
    var newHandle1 = function(e) { 
        if(parent && parent_container){
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if(e.clipboardData.files.length>0){
                console.log(fileStore.props[parent_container.id])
                change(i[0].files,fileStore.props[parent_container.id])
            }
        }
    };
    if(Agmongofileparentid.length>0){
        document.getElementsByTagName('body')[0].onclick = function(e) {newHandle(e)}
        document.getElementsByTagName('body')[0].onpaste = function(e) {newHandle1(e)}
    
    }
    function change(files,props){
        const {label, filekey, disabled = false, callback = f => f} = props
        const {parent} = props
        const formData = new FormData()
        formData.append('myFile', files[0])
        formData.append('label', label)
        formData.append('key', filekey)
        fetch(url, {
            method: 'POST',
            headers: Api.H1(),
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                
                console.log('got data', data)
                if (data.error) configStore.setModalMessage(data.error)
                else {
                    //console.log('setting the filelink',  `/clientfiles/${files[0].name}`)
                    callback(data,+parent.split('___')[1])
                    console.log(document.getElementById(parent).getElementsByTagName('input')[0].value)
                    document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                }
            })
            .catch(error => {
                console.error(error)
            })
    }
    return (
        <div>
        <input type="file" disabled={disabled} style={{fontSize: '0.8rem'}} onChange={(e) => {
            // change(e.target.files,props)
            const pattern = /^[a-zA-Z0-9_ .()-]+$/;
            if(pattern.test(e.target.files[0]['name'])){
                change(e.target.files,props)
            }else{
                configStore.setModalMessage("Please Check File Name and Format")
                return e.target.value = ""
            }
            
        }} />
        </div>
    )
}

export function AgBtnmongofile(props) {
    useSnapshot(fileStore)
    const {parent} = props
    if(parent){
        if(fileStore.props[parent]==undefined){
            fileStore.props[parent] = props
        }
    }
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/filestore/storefile`
    const {btnname, label, filekey, disabled = false, callback = f => f} = props
    let parentid = ['ta4', 'ta5', 'ta6']
        var parent_container=null
        var newHandle = function(e) { 
            let parent_container1 = null
            if(parentid.length>0 ){
                parent_container = e.target;
                do {
                    if (!parent_container) break
                    parent_container = parent_container.parentNode;
    
                    if (parent_container && parentid.indexOf(parent_container.id) == -1) {
                        parent_container1 = null
                    }else{
                        parent_container1 = parent_container
                    }
                }
                while( parent_container1==null && parent_container !== document.body );
                parent_container = parent_container1
            }
        };
        var newHandle1 = function(e) { 
            if(parent && parent_container){
                let i = parent_container.getElementsByTagName('input')
                i[0].files = e.clipboardData.files
                if(e.clipboardData.files.length>0){
                    change(i[0].files,fileStore.props[parent_container.id])
                }
            }
        };
        if(parentid.length>0){
            document.getElementsByTagName('body')[0].onclick = function(e) {newHandle(e)}
            document.getElementsByTagName('body')[0].onpaste = function(e) {newHandle1(e)}
        
        }
        function change(files,props){
            const {parent} = props
            const {btnname, label, filekey, disabled = false, callback = f => f} = props
            const formData = new FormData()
            formData.append('myFile', files[0])
            formData.append('label', label)
            formData.append('key', filekey)
            fetch(url, {
                method: 'POST',
                headers: Api.H1(),
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    console.log('got data', data)
                    if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') { configStore.setModalMessage(data.error) }}
                    else {
                        //console.log('setting the filelink',  `/clientfiles/${files[0].name}`)
                        callback(data)
                        document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        }
    return (
    
        <div class="file is-justify-content-center" >
    <label class="file-label">
    <input class="file-input" type="file" disabled={disabled} onChange={(e) => {
        //    change(e.target.files,props)
           const pattern = /^[a-zA-Z0-9_ .()-]+$/;
           if(pattern.test(e.target.files[0]['name'])){
               change(e.target.files,props)
           }else{
               configStore.setModalMessage("Please Check File Name and Format")
               return e.target.value = ""
           }
           
        }} />
    <span class="file-cta islink is-justify-content-center" style={{width:"25rem"}}>
      <span class="file-icon">
        <i class="fas fa-upload"></i>
      </span>
      <span class="file-label">
      {btnname}
      </span>
    </span>
    </label>
    </div>
    )
    }
export function AgBtnmongofilenew(props) {
        useSnapshot(fileStore)
        const {parent} = props
        if(parent){
            if(fileStore.props[parent]==undefined){
                fileStore.props[parent] = props
            }
        }
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/filestore/storefile`
        const {btnname, label, filekey, disabled = false, callback = f => f} = props
        let parentid = ['ta4', 'ta5', 'ta6','vin']
            var parent_container=null
            var newHandle = function(e) { 
                let parent_container1 = null
                if(parentid.length>0 ){
                    parent_container = e.target;
                    do {
                        if (!parent_container) break
                        parent_container = parent_container.parentNode;
        
                        if (parent_container && parentid.indexOf(parent_container.id) == -1) {
                            parent_container1 = null
                        }else{
                            parent_container1 = parent_container
                        }
                    }
                    while( parent_container1==null && parent_container !== document.body );
                    parent_container = parent_container1
                }
            };
            var newHandle1 = function(e) { 
                if(parent && parent_container){
                    let i = parent_container.getElementsByTagName('input')
                    i[0].files = e.clipboardData.files
                    if(e.clipboardData.files.length>0){
                        change(i[0].files,fileStore.props[parent_container.id])
                    }
                }
            };
            if(parentid.length>0){
                document.getElementsByTagName('body')[0].onclick = function(e) {newHandle(e)}
                document.getElementsByTagName('body')[0].onpaste = function(e) {newHandle1(e)}
            
            }
            function change(files,props){
                const {parent} = props
                const {btnname, label, filekey, disabled = false, callback = f => f} = props
                const formData = new FormData()
                formData.append('myFile', files[0])
                formData.append('label', label)
                formData.append('key', filekey)
                fetch(url, {
                    method: 'POST',
                    headers: Api.H1(),
                    body: formData
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('got data', data)
                        if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') { configStore.setModalMessage(data.error) }}
                        else {
                            //console.log('setting the filelink',  `/clientfiles/${files[0].name}`)
                            callback(data)
                            document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                        }
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
        return (
        <>
            <input class="file-input" type="file" id={parent+"file"} disabled={disabled} onChange={(e) => {
                const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                if(pattern.test(e.target.files[0]['name'])){
                    change(e.target.files,props)
                }else{
                    configStore.setModalMessage("Please Check File Name and Format")
                    return e.target.value = ""
                }
             }} />
             <label for={parent+"file"} class="btn-3">
                  <span class="d-flex aligncenter"><img src="./assets/file (1).png" alt="File" class="file-img" /> Upload</span>
                  <img src="./assets/upload(1).png" alt="Upload" class="upload-img" />
                </label>
                </>
        )
        }
export function Agmongofilenew(props) {
            useSnapshot(fileStore)
            const {parent} = props
            if(parent){
                if(fileStore.props[parent]==undefined){
                    fileStore.props[parent] = props
                }
            }
            console.log(fileStore.props[parent])
            let url = `${process.env.REACT_APP_API_SERVER}/api/v1/filestore/storefile`
            const {label, filekey, disabled = false, callback = f => f} = props
            if(Agmongofileparentid.indexOf(parent)==-1){
                Agmongofileparentid.push(parent)
            }
        
            var parent_container = null
            var newHandle = function(e) { 
                let parent_container1 = null
                if(Agmongofileparentid.length>0 ){
                    parent_container = e.target;
                    do {
                        if(parent_container){
                            parent_container = parent_container.parentNode;
                        }else{
                            break
                        }
        
                        if (parent_container && Agmongofileparentid.indexOf(parent_container.id) == -1) {
                            parent_container1 = null
                        }else{
                            parent_container1 = parent_container
                        }
                    }
                    while( parent_container1==null && parent_container !== document.body );
                    parent_container = parent_container1
                }
            };
            var newHandle1 = function(e) { 
                if(parent && parent_container){
                    let i = parent_container.getElementsByTagName('input')
                    i[0].files = e.clipboardData.files
                    if(e.clipboardData.files.length>0){
                        console.log(fileStore.props[parent_container.id])
                        change(i[0].files,fileStore.props[parent_container.id])
                    }
                }
            };
            if(Agmongofileparentid.length>0){
                document.getElementsByTagName('body')[0].onclick = function(e) {newHandle(e)}
                document.getElementsByTagName('body')[0].onpaste = function(e) {newHandle1(e)}
            
            }
            function change(files,props){
                const {label, filekey, disabled = false, callback = f => f} = props
                const {parent} = props
                const formData = new FormData()
                formData.append('myFile', files[0])
                formData.append('label', label)
                formData.append('key', filekey)
                fetch(url, {
                    method: 'POST',
                    headers: Api.H1(),
                    body: formData
                })
                    .then(response => response.json())
                    .then(data => {
                        
                        console.log('got data', data)
                        if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') { configStore.setModalMessage(data.error) }}
                        else {
                            //console.log('setting the filelink',  `/clientfiles/${files[0].name}`)
                            callback(data,+parent.split('___')[1])
                            console.log(document.getElementById(parent).getElementsByTagName('input')[0].value)
                            document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                        }
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
            return (
                <>
                <input type="file" id={parent+"file"} disabled={disabled} onChange={(e) => {
                    const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                    if(pattern.test(e.target.files[0]['name'])){
                        change(e.target.files,props)
                    }else{
                        configStore.setModalMessage("Please Check File Name and Format")
                        return e.target.value = ""
                    }
                }}/>
    <label for={parent+"file"} class="btn-3 width-200 height-45">
      <span class="d-flex aligncenter"><img src="./assets/file (1).png" alt="File" class="file-img" /> Upload</span>
      <img src="./assets/upload(1).png" alt="Upload" class="upload-img" />
    </label>
                </>
               
            )
        }