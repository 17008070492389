import { useSnapshot } from "valtio";
import { configStore, dataListStore, modalStore } from "../state/CommonState";
import { myactionsstore } from "./store-myactions";
import { loader } from "../util/loader";
import { OneDate, OneInput5, OneSelect4 } from "../mytasks/act-common";
import { TsModal } from "./tsmodal";
import { useEffect, useState } from "react";
import { CnModal } from "./cnmodal";
import Utils from "../util/Utils";
import Api from "../state/Api";
import { Vmodal } from "./vmodal";
import { Rwmodal } from "./rwmodal";
import { Agyesno } from "../components/Agyesno";
import { AgUnlocdsr, TableSelect5 } from "../components/AgComponents";
import { TsairModal } from "./tsairmodal";
import { dsrstatic_Air, dsrstatic_Air_keys, dsrstatic_FCLDockDock, dsrstatic_FCLDockDock_keys, dsrstatic_FCLDockFactory, dsrstatic_FCLDockFactory_keys, dsrstatic_FCLFactoryDock, dsrstatic_FCLFactoryDock_keys, dsrstatic_FCLFactoryFactory, dsrstatic_FCLFactoryFactory_keys, dsrstatic_LCL, dsrstatic_LCL_keys } from "./dsr_static";
import { getStaticConsolidatorList } from "../StaticData";
import { CustomModal } from "./customModal";

export function DsrModal(props){
    useSnapshot(configStore)
    useSnapshot(dataListStore)
    useSnapshot(modalStore)
    useSnapshot(myactionsstore)
    useSnapshot(loader)
    console.log(myactionsstore);
    const [ret, setRet] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    
    const [carrierlist, setCarrierlist] = useState([])
    let modalClass = 'modal'
    let st = ret.shipmentType
    let sc = ret.shipmentScope
    let pod = ret.pod
    let fpod = ret.fpod
    let por = ret.por
    let pol = ret.pol
    const close = async (d) => {
        configStore.quotationModalVisible = false
        configStore.modalvisibledata = {}
        if(myactionsstore.recheck==0){
            myactionsstore.recheck = 1
        }else{
            myactionsstore.recheck = 0
        }
        loader.hide()
    }
    const close1 = async () => {
        configStore.quotationModalVisible = false
        configStore.modalvisibledata = {}
        if(myactionsstore.recheck==0){
            myactionsstore.recheck = 1
        }else{
            myactionsstore.recheck = 0
        }
    }
    
    let obj1 = []
    let dd1 = {}
        
    
    const addts = () =>{
        if(st && st.toLowerCase().indexOf("air") != -1){
            myactionsstore.tsairModalVisible = true
            myactionsstore.uploadShipmentType = "AIR"
            myactionsstore.uploadSchedulesType = "AIR"
        }else if(st && st.toLowerCase().indexOf("lcl") != -1){
            myactionsstore.tsModalVisible = true
            myactionsstore.uploadShipmentType = "LCL"
            myactionsstore.uploadSchedulesType = "LCL"
        }
        else{
            myactionsstore.tsModalVisible = true
            myactionsstore.uploadShipmentType = "FCL"
            myactionsstore.uploadSchedulesType = "FCL"
        }
        
    }

    if("FCL (Factory/Factory)"==st){
        let k = Object.keys(dsrstatic_FCLFactoryFactory)
        let l = {}
        for (let i = 0; i < k.length; i++) {
            l[k[i]]= dsrstatic_FCLFactoryFactory[k[i]]
        }
        dd1 = l
        obj1 = dsrstatic_FCLFactoryFactory_keys
        if(pod!=fpod){
            if(dd1['eta_at_pod']==undefined){
                dd1['eta_at_pod']='ETA @ POD'
            }
            if(dd1['pod_arrival']==undefined){
                dd1['pod_arrival']='ATA @ POD'
            }
            if(dd1['etd_pod']==undefined){
                dd1['etd_pod']='ETD from POD'
            }
        }
        dd1 = myactionsstore.addts(dd1,ret)
        if(ret['shipper_name'] == undefined && ret.booking){
            ret['shipper_name'] = ret.booking.shipper
        }
        if(ret['consignee_name'] == undefined && ret.booking){
            ret['consignee_name'] = ret.booking.consignee
        }
        if((ret.booking && ret.booking.contract.customerReferenceNum && ret['cust_ref_number'] == undefined || ret['cust_ref_number'].trim().length==0 || ret['cust_ref_number'].trim()!=ret.booking.contract.customerReferenceNum)){
            ret['cust_ref_number'] = ret.booking.contract.customerReferenceNum
        }
        if(ret.Branches.country?.toLowerCase() === 'india'){
            if((ret.booking  && ret.Branches?.gst && ret['branch_name'] == undefined || ret['branch_name'].trim().length==0 || ret['branch_name'].trim()!=ret.Branches?.gst)){
                ret['branch_name'] = ret.Branches.state?.split("/")[0]+'-'+ret.Branches.gst
            }
        }else  if(ret.Branches.country?.toLowerCase() !== 'india'){
            if((ret.booking  && ret.Branches?.branchId && ret['branch_name'] == undefined )){
                ret['branch_name'] = ret.Branches.state?.split("/")[0]+'-'+ret.Entities.tinNum
            }
        }
        
        
    }


    if("FCL (Dock/Factory)"==st){
        let k = Object.keys(dsrstatic_FCLDockFactory)
        let l = {}
        for (let i = 0; i < k.length; i++) {
            l[k[i]]= dsrstatic_FCLDockFactory[k[i]]
        }
        dd1 = l
        obj1 = dsrstatic_FCLDockFactory_keys
        if(pod!=fpod){
            if(dd1['eta_at_pod']==undefined){
                dd1['eta_at_pod']='ETA @ POD'
            }
            if(dd1['pod_arrival']==undefined){
                dd1['pod_arrival']='ATA @ POD'
            }
            if(dd1['etd_pod']==undefined){
                dd1['etd_pod']='ETD from POD'
            }
        }
        dd1 = myactionsstore.addts(dd1,ret)
        if(ret['shipper_name'] == undefined && ret.booking){
            ret['shipper_name'] = ret.booking.shipper
        }
        if(ret['consignee_name'] == undefined && ret.booking){
            ret['consignee_name'] = ret.booking.consignee
        }
        if((ret.booking && ret.booking.contract.customerReferenceNum && ret['cust_ref_number'] == undefined || ret['cust_ref_number'].trim().length==0 || ret['cust_ref_number'].trim()!=ret.booking.contract.customerReferenceNum)){
            ret['cust_ref_number'] = ret.booking.contract.customerReferenceNum
        }
        if(ret.Branches.country?.toLowerCase() === 'india'){
            if((ret.booking  && ret.Branches?.gst && ret['branch_name'] == undefined || ret['branch_name'].trim().length==0 || ret['branch_name'].trim()!=ret.Branches?.gst)){
                ret['branch_name'] = ret.Branches.state?.split("/")[0]+'-'+ret.Branches.gst
            }
        }else  if(ret.Branches.country?.toLowerCase() !== 'india'){
            if((ret.booking  && ret.Branches?.branchId && ret['branch_name'] == undefined )){
                ret['branch_name'] = ret.Branches.state?.split("/")[0]+'-'+ret.Entities.tinNum
            }
        }
        
    }

    if("FCL (Factory/Dock)"==st){
        let k = Object.keys(dsrstatic_FCLFactoryDock)
        let l = {}
        for (let i = 0; i < k.length; i++) {
            l[k[i]]= dsrstatic_FCLFactoryDock[k[i]]
        }
        dd1 = l
        obj1 = dsrstatic_FCLFactoryDock_keys
        if(pod!=fpod){
            if(dd1['eta_at_pod']==undefined){
                dd1['eta_at_pod']='ETA @ POD'
            }
            if(dd1['pod_arrival']==undefined){
                dd1['pod_arrival']='ATA @ POD'
            }
            if(dd1['etd_pod']==undefined){
                dd1['etd_pod']='ETD from POD'
            }
        }
        dd1 = myactionsstore.addts(dd1,ret)
        if(ret['shipper_name'] == undefined && ret.booking){
            ret['shipper_name'] = ret.booking.shipper
        }
        if(ret['consignee_name'] == undefined && ret.booking){
            ret['consignee_name'] = ret.booking.consignee
        }
        if((ret.booking && ret.booking.contract.customerReferenceNum && ret['cust_ref_number'] == undefined || ret['cust_ref_number'].trim().length==0 || ret['cust_ref_number'].trim()!=ret.booking.contract.customerReferenceNum)){
            ret['cust_ref_number'] = ret.booking.contract.customerReferenceNum
        }
        if(ret.Branches.country?.toLowerCase() === 'india'){
            if((ret.booking  && ret.Branches?.gst && ret['branch_name'] == undefined || ret['branch_name'].trim().length==0 || ret['branch_name'].trim()!=ret.Branches?.gst)){
                ret['branch_name'] = ret.Branches.state?.split("/")[0]+'-'+ret.Branches.gst
            }
        }else  if(ret.Branches.country?.toLowerCase() !== 'india'){
            if((ret.booking  && ret.Branches?.branchId && ret['branch_name'] == undefined )){
                ret['branch_name'] = ret.Branches.state?.split("/")[0]+'-'+ret.Entities.tinNum
            }
        }
        
    }
    if("FCL (Dock/Dock)"==st){
        let k = Object.keys(dsrstatic_FCLDockDock)
        let l = {}
        for (let i = 0; i < k.length; i++) {
            l[k[i]]= dsrstatic_FCLDockDock[k[i]]
        }
        dd1 = l
        obj1 = dsrstatic_FCLDockDock_keys
        if(pod!=fpod){
            if(dd1['eta_at_pod']==undefined){
                dd1['eta_at_pod']='ETA @ POD'
            }
            if(dd1['pod_arrival']==undefined){
                dd1['pod_arrival']='ATA @ POD'
            }
            if(dd1['etd_pod']==undefined){
                dd1['etd_pod']='ETD from POD'
            }
        }
        dd1 = myactionsstore.addts(dd1,ret)
        if(ret['shipper_name'] == undefined && ret.booking){
            ret['shipper_name'] = ret.booking.shipper
        }
        if(ret['consignee_name'] == undefined && ret.booking){
            ret['consignee_name'] = ret.booking.consignee
        }
        if((ret.booking && ret.booking.contract.customerReferenceNum && ret['cust_ref_number'] == undefined || ret['cust_ref_number'].trim().length==0 || ret['cust_ref_number'].trim()!=ret.booking.contract.customerReferenceNum)){
            ret['cust_ref_number'] = ret.booking.contract.customerReferenceNum
        }
        if(ret.Branches.country?.toLowerCase() === 'india'){
            if((ret.booking  && ret.Branches?.gst && ret['branch_name'] == undefined || ret['branch_name'].trim().length==0 || ret['branch_name'].trim()!=ret.Branches?.gst)){
                ret['branch_name'] = ret.Branches.state?.split("/")[0]+'-'+ret.Branches.gst
            }
        }else  if(ret.Branches.country?.toLowerCase() !== 'india'){
            if((ret.booking  && ret.Branches?.branchId && ret['branch_name'] == undefined )){
                ret['branch_name'] = ret.Branches.state?.split("/")[0]+'-'+ret.Entities.tinNum
            }
        }
        
        
    }

    if(st && st.toLowerCase().indexOf("lcl") != -1){
        let k = Object.keys(dsrstatic_LCL)
        let l = {}
        for (let i = 0; i < k.length; i++) {
            l[k[i]]= dsrstatic_LCL[k[i]]
        }
        dd1 = l
        obj1 = dsrstatic_LCL_keys
        if(por!=pol){
            dd1['container_handover']= "Container Handover"
            dd1['por_to_pol_movement'] = 'Rail-out / Truck-out Status'
        }
        if(pod!=fpod){
            dd1['eta_at_pod']= "ETA @ POD"
            dd1['pod_arrival']= "ATA @ POD"
            dd1['etd_pod']= "ETD from POD"
            dd1['gateout_to_fpod']= "Gate-out from POD"
        }
        if(["Door-to-Port","Port-to-Port"].indexOf(sc) != -1){
            dd1['cargo_picked_up_by_consignee']= "Cargo Picked-up by Consignee"
        }
        
        if(ret['reworking_at_pol']){
            if(ret['reworking_at_pol'].toLowerCase()=='yes'){
                dd1['reworking_at_pol'] = 'Yes'
                dd1['rail_yard_to_cfs'] = "Movement from Rail Yard to POL CFS"
                dd1['destuffing_at_pol']= "De-stuffing Status"
                dd1['restuffing_at_pol']= "Re-stuffing Status"
                
            }else{
                let l = {}
                let a = Object.keys(dd1)
                for (let i = 0; i < a.length; i++) {
                    if(['rail_yard_to_cfs','destuffing_at_pol','restuffing_at_pol'].indexOf(a[i])==-1){
                        l[a[i]] = dd1[a[i]]
                    }
                }
                dd1 = l
            }
        }
        dd1 = myactionsstore.addts(dd1,ret)
        if(ret['shipper_name'] == undefined && ret.booking){
            ret['shipper_name'] = ret.booking.shipper
        }
        if(ret['consignee_name'] == undefined && ret.booking){
            ret['consignee_name'] = ret.booking.consignee
        }
        if((ret.booking && ret.booking.contract.customerReferenceNum && ret['cust_ref_number'] == undefined || ret['cust_ref_number'].trim().length==0 || ret['cust_ref_number'].trim()!=ret.booking.contract.customerReferenceNum)){
            ret['cust_ref_number'] = ret.booking.contract.customerReferenceNum
        }
        if(ret.Branches.country?.toLowerCase() === 'india'){
            if((ret.booking  && ret.Branches?.gst && ret['branch_name'] == undefined || ret['branch_name'].trim().length==0 || ret['branch_name'].trim()!=ret.Branches?.gst)){
                ret['branch_name'] = ret.Branches.state?.split("/")[0]+'-'+ret.Branches.gst
            }
        }else  if(ret.Branches.country?.toLowerCase() !== 'india'){
            if((ret.booking  && ret.Branches?.branchId && ret['branch_name'] == undefined )){
                ret['branch_name'] = ret.Branches.state?.split("/")[0]+'-'+ret.Entities.tinNum
            }
        }
       

    }

    if(st && st.toLowerCase().indexOf("air") != -1){
        let k = Object.keys(dsrstatic_Air)
        let l = {}
        for (let i = 0; i < k.length; i++) {
            l[k[i]]= dsrstatic_Air[k[i]]
        }
        dd1 = l
        obj1 = dsrstatic_Air_keys
        dd1 = myactionsstore.addairts(dd1,ret)
        if(ret['shipper_name'] == undefined && ret.booking){
            ret['shipper_name'] = ret.booking.shipper
        }
        if(ret['consignee_name'] == undefined && ret.booking){
            ret['consignee_name'] = ret.booking.consignee
        }
        if((ret.booking && ret.booking.contract.customerReferenceNum && ret['cust_ref_number'] == undefined || ret['cust_ref_number'].trim().length==0 || ret['cust_ref_number'].trim()!=ret.booking.contract.customerReferenceNum)){
            ret['cust_ref_number'] = ret.booking.contract.customerReferenceNum
        }
        if(ret.Branches.country?.toLowerCase() === 'india'){
            if((ret.booking  && ret.Branches?.gst && ret['branch_name'] == undefined || ret['branch_name'].trim().length==0 || ret['branch_name'].trim()!=ret.Branches?.gst)){
                ret['branch_name'] = ret.Branches.state?.split("/")[0]+'-'+ret.Branches.gst
            }
        }else  if(ret.Branches.country?.toLowerCase() !== 'india'){
            if((ret.booking  && ret.Branches?.branchId && ret['branch_name'] == undefined )){
                ret['branch_name'] = ret.Branches.state?.split("/")[0]+'-'+ret.Entities.tinNum
            }
        }
        
    }


    

    const onsubmit = async ()=> {
        if(myactionsstore.error.length>0){
            myactionsstore.error = []
        }
        let kz = 0
        let d = {}
        const dPattern = (a) => {
            var pattern = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
            return pattern.test(a);
        }
    if(st.toLowerCase().indexOf("fcl") != -1){
        for (let i = 0; i < obj1.length; i++) {
            if("booking" != obj1[i]){
                d[obj1[i]] = ret[obj1[i]]
            }
            
            if(["carrier_booking_release",
                                "vgm_cut_off",
                                "shipping_instructions_cut_off",
                                "gate_cut_off",
                                "empty_container_pickup",
                                "container_arrival_factory",
                                "loaded_and_dispatch",
                                "container_handover_por",
                                "por_to_pol_movement",
                                "gatein_pol",
                                'etd_at_pol',
                                "sob_pol",
                                'ts1_arrival_estimate',
            "ts1_arrival",
            'ts1_departure_estimate',
            "ts1_departure",
            'ts2_arrival_estimate',
            "ts2_arrival",
            'ts2_departure_estimate',
            "ts2_departure",
            'ts3_arrival_estimate',
            "ts3_arrival",
            'ts3_departure_estimate',
            "ts3_departure",
            'ts4_arrival_estimate',
            "ts4_arrival",
            'ts4_departure_estimate',
            "ts4_departure",
                                "arrival_notice",
                                'eta_at_pod',
                                "pod_arrival",
                                "etd_pod",
                                "gateout_to_fpod",
                                "eta_fpod",
                                "gatein_fpod",
                                "last_free_date_at_fpod",
                                "delivery_order_release",
                                "container_pickup_fpod",
                                "estimate_delivery_date_to_consignee",
                                "container_delivery",
                                "empty_return"].indexOf(obj1[i])!=-1){
                                    if(ret[obj1[i]]){
                                        if(ret[obj1[i]].length==10 && !dPattern(ret[obj1[i]])){
                                            if(new Date(ret[obj1[i]]) != 'Invalid Date'){
                                                d[obj1[i]] = Utils.ddmmyyyy(new Date(ret[obj1[i]]))
                                                //ret[obj1[i]] = d[obj1[i]]
                                            }else{
                                                d[obj1[i]] = ret[obj1[i]]
                                            }
                                        }else{
                                            d[obj1[i]] = ret[obj1[i]]
                                        }
                                    }else{
                                        ret[obj1[i]] = ""
                                        d[obj1[i]] = ret[obj1[i]]
                                    }
                                }
        }
            
                        
    
        if(d.containerlist != undefined && d.containerlist.length!=0){
            for (let i = 0; i < d.containerlist.length; i++) {
                let k = Object.keys(d.containerlist[i])
                for (let j = 0; j < k.length; j++) {
                    if(['container_number','container_types'].indexOf(k[j]) == -1){
                        let z = d.containerlist[i][k[j]]
                        if(z){
                            if(z.length==10 && !dPattern(z)){
                                if(new Date(z) != 'Invalid Date'){
                                    z = Utils.ddmmyyyy(new Date(z))
                                }else{
                                    z = z
                                }
                            }else{
                                z = z
                            }
                        }else{
                            z = ""
                            z = z
                        }
                        d.containerlist[i][k[j]] = z
                    }
                }
            }
    
        }
    }

    if(st.toLowerCase().indexOf("lcl") != -1){
        for (let i = 0; i < obj1.length; i++) {
            if("booking" != obj1[i]){
                d[obj1[i]] = ret[obj1[i]]
            }
            if(["reworking_pod_container_number",
            "reworking_pol_container_number",
            'pol_container_number'].indexOf(obj1[i])!=-1){
                if(ret[obj1[i]]!=undefined && ret[obj1[i]].length>0){
                    ret[obj1[i]] = ret[obj1[i]].toUpperCase()
                    if(!(/^[A-Z]{1,4}[0-9]{1,7}/.test(ret[obj1[i]])) || ret[obj1[i]].length!=11){
                        kz = 1
                        myactionsstore.error.push(obj1[i])
                    }
                }
            }
            if(['ts1_arrival_estimate',
            "ts1_arrival",
            'ts1_departure_estimate',
            "ts1_departure",
            'ts2_arrival_estimate',
            "ts2_arrival",
            'ts2_departure_estimate',
            "ts2_departure",
            'ts3_arrival_estimate',
            "ts3_arrival",
            'ts3_departure_estimate',
            "ts3_departure",
            'ts4_arrival_estimate',
            "ts4_arrival",
            'ts4_departure_estimate',
            "ts4_departure",
            'reworking_pol_arrival_estimate',
            "reworking_pol_arrival",
            'reworking_pol_departure_estimate',
            "reworking_pol_departure",
            'reworking_pod_arrival_estimate',
            "reworking_pod_arrival",
            'reworking_pod_departure_estimate',
            "reworking_pod_departure",
            "reworking_pod_container_number",
            "reworking_pol_container_number",
            "rail_yard_to_cfs","destuffing_at_pol","restuffing_at_pol","gatein_pol",
            'pol_container_number',
            'hbl_number',
            'mbl_number',
            'stuffing_confirmation',
            'container_handover',
            'por_to_pol_movement',
            "etd_at_pol",
            'sob_pol',
            'gatein_reworking',
            'eta_at_pod',
            'pod_arrival',
            'etd_pod',
            'gateout_to_fpod',
            'eta_fpod',
            'gatein_fpod',
            'destuffing_fpod',
            "last_free_date_at_fpod",
            'arrival_notice',
            'delivery_order_release','cargo_picked_up_by_consignee'].indexOf(obj1[i])!=-1){
                                    if(ret[obj1[i]]){
                                        if(ret[obj1[i]].length==10 && !dPattern(ret[obj1[i]])){
                                            if(new Date(ret[obj1[i]]) != 'Invalid Date'){
                                                d[obj1[i]] = Utils.ddmmyyyy(new Date(ret[obj1[i]]))
                                                //ret[obj1[i]] = d[obj1[i]]
                                            }else{
                                                d[obj1[i]] = ret[obj1[i]]
                                            }
                                        }else{
                                            d[obj1[i]] = ret[obj1[i]]
                                        }
                                    }else{
                                        ret[obj1[i]] = ""
                                        d[obj1[i]] = ret[obj1[i]]
                                    }
                                }
        }
    }

    if(st.toLowerCase().indexOf("air") != -1){
        console.log(ret['mawb_number'])
        if(ret['mawb_number'].trim().length>0){
            if(ret['mawb_number'].length==12){
                let zl = ret['mawb_number'].trim().split('-')
                if(zl.length!=2 || zl[0].length!=3 || zl[1].length!=8){
                    configStore.setModalMessage("Invalid MAWB Number")
                    return
                }
            }else{
                let zl = ret['mawb_number'].trim().replace('-','')
                if(zl.length==11){
                    zl = ret['mawb_number'].slice(0, 3)+'-'+ret['mawb_number'].slice(3, 11)
                    ret['mawb_number'] = zl
                }else{
                    configStore.setModalMessage("Invalid MAWB Number")
                    return
                }
            } 
        }
        for (let i = 0; i < obj1.length; i++) {
            if("booking" != obj1[i]){
                d[obj1[i]] = ret[obj1[i]]
            }
            
            if(["airline_booking_release",
            "etd_airport_of_departure",
            "loaded_on_board",
            "ts1_arrival_estimate",
            "ts1_arrival",
            "ts1_departure_estimate",
            "ts1_departure",
            "ts2_arrival_estimate",
            "ts2_arrival",
            "ts2_departure_estimate",
            "ts2_departure",
            "eta_at_arrival_airport",
            "ata_at_arrival_airport",
            "arrival_notice",
            "last_free_date_at_fpod",
            "delivery_order_release"].indexOf(obj1[i])!=-1){
                                    if(ret[obj1[i]]){
                                        if(ret[obj1[i]].length==10 && !dPattern(ret[obj1[i]])){
                                            if(new Date(ret[obj1[i]]) != 'Invalid Date'){
                                                d[obj1[i]] = Utils.ddmmyyyy(new Date(ret[obj1[i]]))
                                                //ret[obj1[i]] = d[obj1[i]]
                                            }else{
                                                d[obj1[i]] = ret[obj1[i]]
                                            }
                                        }else{
                                            d[obj1[i]] = ret[obj1[i]]
                                        }
                                    }else{
                                        ret[obj1[i]] = ""
                                        d[obj1[i]] = ret[obj1[i]]
                                    }
                                }
        }
    }


        if(d.vorgin != undefined && d.vorgin.length!=0){
            for (let i = 0; i < d.vorgin.length; i++) {
                let k = Object.keys(d.vorgin[i])
                for (let j = 0; j < k.length; j++) {
                    if(['vehicle'].indexOf(k[j]) == -1){
                        let z = d.vorgin[i][k[j]]
                        if(z){
                            if(z.length==10 && !dPattern(z)){
                                if(new Date(z) != 'Invalid Date'){
                                    z = Utils.ddmmyyyy(new Date(z))
                                }else{
                                    z = z
                                }
                            }else{
                                z = z
                            }
                        }else{
                            z = ""
                            z = z
                        }
                        d.vorgin[i][k[j]] = z
                    }
                }
            }
    
        }else{
            d.vorgin = []
        }

        if(d.vdes != undefined && d.vdes.length!=0){
            for (let i = 0; i < d.vdes.length; i++) {
                let k = Object.keys(d.vdes[i])
                for (let j = 0; j < k.length; j++) {
                    if(['vehicle'].indexOf(k[j]) == -1){
                        let z = d.vdes[i][k[j]]
                        if(z){
                            if(z.length==10 && !dPattern(z)){
                                if(new Date(z) != 'Invalid Date'){
                                    z = Utils.ddmmyyyy(new Date(z))
                                }else{
                                    z = z
                                }
                            }else{
                                z = z
                            }
                        }else{
                            z = ""
                            z = z
                        }
                        d.vdes[i][k[j]] = z
                    }
                }
            }
    
        }else{
            d.vdes = []
        }
        
        d['shipmentType'] = st
        d['shipmentScope'] = sc
        d['picked'] = configStore.picked
        d['delivered'] = configStore.delivered
        d['exportClearance'] = ret.exportClearance
        d['importClearance'] = ret.importClearance
        console.log(d,"stdout");
        if(kz==0){
            loader.show()
            await Api.apieditdsr(d, close)
        }
        
    }
    
    useEffect(() => {
        console.log('Inside useEffect of visiblity ')
        Api.getFCLCarrierList()
    }, [])

    useEffect(() => {
        let r = dataListStore["FCLCarrierList"]
        let c = []
        for (let i = 0; i < r.length; i++) {
            if(r[i]['MLO or NVOCC']!='NVOCC'){
                c.push(r[i])
            }
        }
        if(c.length>0 && JSON.stringify(c) != JSON.stringify(carrierlist)){
            console.log("+==",c)
            setCarrierlist(c)
        }
    }, [dataListStore["FCLCarrierList"]])


    useEffect(() => {
        setRet(configStore.modalvisibledata)
        // console.log(configStore.modalvisibledata.booking._id);
    },[configStore.modalvisibledata])
    
    let dd = dd1
    let obj = Object.keys(dd)
    console.log()
    if (configStore.quotationModalVisible) modalClass += ' is-active'
    return (
        <>
        
        {
            (configStore.quotationModalVisible && obj1 && obj1.length>0)?<div className={modalClass} >
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px',width:"75rem" }} >
                <section className="modal-card-body" >
                <h2 style={{ color: "blue" }}>Booking Number : {ret['agraga_booking_#']}</h2>
                <div className="column" style={{width: "100%", display: "flex",justifyContent: "space-between"}}>
                    <button className="button is-small" onClick={addts}  style={{ borderRadius: '5px',background:"#2c358f",color:"white",width:"10rem"}}>Add Transhipment</button>
                    <button className="button is-small" onClick={()=>{
                        // if(myactionsstore.exportClearance.length<0||myactionsstore.importClearance.length<0){
                        //      myactionsstore.exportClearance =[]
                        //      myactionsstore.importClearance =[]
                        // }
                        setIsModalOpen(true)
                        }} style={{ borderRadius: '5px',background:"#2c358f",color:"white",width:"10rem"}}>Customs Details</button>
                    {
                        (st.toLowerCase().indexOf("lcl") != -1)?<button className="button is-small" onClick={()=>myactionsstore.rwModalVisible=true}  style={{ borderRadius: '5px',background:"#2c358f",color:"white",width:"10rem"}}>Reworking Port</button>:""
                    }
                    
                    {
                        (st.toLowerCase().indexOf("fcl") != -1)?<button className="button is-small" onClick={()=>{
                            if(myactionsstore.selectcontainer.length>0){
                                myactionsstore.selectcontainer = []
                            }
                            myactionsstore.cnModalVisible=true
                        }}  style={{ borderRadius: '5px',background:"#2c358f",color:"white",width:"10rem"}}>Container Details</button>:""
                    }
                    {
                        (sc != "Port-to-Port" && (st.toLowerCase().indexOf("air") != -1 || st.toLowerCase().indexOf("lcl") != -1 || (st!='FCL (Factory/Factory)' &&  (st=='FCL (Dock/Dock)' || (st!='FCL (Dock/Dock)' && (sc == "Port-to-Door" && st!='FCL (Dock/Factory)') || (sc == "Door-to-Port" && st!='FCL (Factory/Dock)') || (sc == "Door-to-Door" && st=='FCL (Factory/Dock)') || (sc == "Door-to-Door" && st=='FCL (Dock/Factory)'))))))?<button className="button is-small" onClick={()=>{myactionsstore.vModalVisible=true}}  style={{ borderRadius: '5px',background:"#2c358f",color:"white",width:"10rem"}}>Vehicle Details</button>:""
                    }
                    {(por !== pol && st.toLowerCase().indexOf("lcl") != -1) ? <Agyesno label='Re-working at POL ?' record={ret} name='reworking_at_pol'> </Agyesno> : <></> }
                    {/* <button className="button is-small" onClick={()=>{configStore.quotationModalVisible2 = true}} style={{ borderRadius: '5px',background:"#2c358f",color:"white",width:"10rem"}}>Add Reworking</button> */}
                </div>
                &nbsp;
                {
                    (st.toLowerCase().indexOf("fcl") != -1)?<div className="columns is-multiline is-mobile">
                    {
                        obj1.map((v) => {
                            if(obj.indexOf(v)!=-1){
                                if(["shipper_name",
                                "branch_name",
                                "consignee_name",
                                "cust_ref_number",
                                "agraga_booking_#",
                                "origin",
                                "destination",
                                "por",
                                "pol",
                                "pod",
                                "fpod",
                                'ts1_port','ts2_port','ts3_port','ts4_port'].indexOf(v)!=-1){
                                     return <div className="column is-6 py-2"><OneInput5 disabled={true} name={v} record={ret} label={dd[v]}/></div>
                                }
                                if(["carrier_booking_release",
                                "vgm_cut_off",
                                "shipping_instructions_cut_off",
                                "gate_cut_off",
                                "empty_container_pickup",
                                "container_arrival_factory",
                                "loaded_and_dispatch",
                                "container_handover_por",
                                "por_to_pol_movement",
                                "gatein_pol",
                                'etd_at_pol',
                                "sob_pol",
                                'ts1_arrival_estimate',
                                "ts1_arrival",
                                'ts1_departure_estimate',
                                "ts1_departure",
                                'ts2_arrival_estimate',
                                "ts2_arrival",
                                'ts2_departure_estimate',
                                "ts2_departure",
                                'ts3_arrival_estimate',
                                "ts3_arrival",
                                'ts3_departure_estimate',
                                "ts3_departure",
                                'ts4_arrival_estimate',
                                "ts4_arrival",
                                'ts4_departure_estimate',
                                "ts4_departure",
                                "arrival_notice",
                                'eta_at_pod',
                                "pod_arrival",
                                "etd_pod",
                                "gateout_to_fpod",
                                "eta_fpod",
                                "gatein_fpod",
                                "last_free_date_at_fpod",
                                "delivery_order_release",
                                "container_pickup_fpod",
                                "estimate_delivery_date_to_consignee",
                                "container_delivery",
                                "empty_return"].indexOf(v)!=-1){
                                     return <div className="column is-6 py-2"><OneDate name={v} record={ret} label={dd[v]}/></div>
                                 }
                                 if([ "carrier_booking_ref_number",
                                 "container_number",
                                 "fl_vessel_name",
                                 "fl_vessel_number",
                                 "hbl_number",
                                 "mbl_number",
                                 "ts1_vessel_name",
                                 "ts1_vessel_number",
                                 "ts2_vessel_name",
                                 "ts2_vessel_number",
                                 "ts3_vessel_name",
                                 "ts3_vessel_number",
                                 "ts4_vessel_name",
                                 "ts4_vessel_number",
                                 "remarks"].indexOf(v)!=-1){
                                 return <div className="column is-6 py-2"><OneInput5 name={v} record={ret} label={dd[v]}/></div>
                                 }
                                 if(['mlo'].indexOf(v)!=-1){
                                    return <div className="column is-6 py-2"><TableSelect5  record={ret} name={v} label={dd[v]} dataList={carrierlist.map(carrier =>({key : carrier['Display Name'],value:`${carrier['Display Name']} (${carrier['SCAC code']})`})) } /></div>
                                }
                                 if(['status_closed'].indexOf(v)!=-1){
                                    return <div className="column is-6 py-2"><OneSelect4 record={ret} name={v} label={dd[v]}/></div>
                                }
                            }
                        })
                    }
                </div>:""
                }

{
                    (st.toLowerCase().indexOf("lcl") != -1)?<div className="columns is-multiline is-mobile">
                    {
                        obj1.map((v) => {
                            if(obj.indexOf(v)!=-1){
                                if(["shipper_name",
                                "branch_name",
                                "consignee_name",
                                "cust_ref_number",
                                "agraga_booking_#",
                                "origin",
                                "destination",
                                "por",
                                "pol",
                                "pod",
                                "fpod",
                                'ts1_port','ts2_port','ts3_port','ts4_port','reworking_pol_port','reworking_pod_port'].indexOf(v)!=-1){
                                     return <div className="column is-6 py-2"><OneInput5 disabled={true} name={v} record={ret} label={dd[v]}/></div>
                                }
                                if(['ts1_arrival_estimate',
                                "ts1_arrival",
                                'ts1_departure_estimate',
                                "ts1_departure",
                                'ts2_arrival_estimate',
                                "ts2_arrival",
                                'ts2_departure_estimate',
                                "ts2_departure",
                                'ts3_arrival_estimate',
                                "ts3_arrival",
                                'ts3_departure_estimate',
                                "ts3_departure",
                                'ts4_arrival_estimate',
                                "ts4_arrival",
                                'ts4_departure_estimate',
                                "ts4_departure",
                                'reworking_pol_arrival_estimate',
                                "reworking_pol_arrival",
                                'reworking_pol_departure_estimate',
                                "reworking_pol_departure",
                                'reworking_pod_arrival_estimate',
                                "reworking_pod_arrival",
                                'reworking_pod_departure_estimate',
                                "reworking_pod_departure",
                                // "reworking_pod_container_number",
                                // "reworking_pol_container_number",
                                "rail_yard_to_cfs","destuffing_at_pol","restuffing_at_pol","gatein_pol",
                                // 'hbl_number',
                                // 'mbl_number',
                                'reworking_pol_destuffing',
                                "reworking_pol_restuffing",
                                "reworking_pod_destuffing",
                                "reworking_pod_restuffing",
                                'stuffing_confirmation',
                                'container_handover',
                                'por_to_pol_movement',
                                "etd_at_pol",
                                'sob_pol',
                                'gatein_reworking',
                                'eta_at_pod',
                                'pod_arrival',
                                'etd_pod',
                                'gateout_to_fpod',
                                'eta_fpod',
                                'gatein_fpod',
                                'destuffing_fpod',
                                "last_free_date_at_fpod",
                                'arrival_notice',
                                'delivery_order_release',
                                'cargo_picked_up_by_consignee'
                            ].indexOf(v)!=-1){
                                     return <div className="column is-6 py-2"><OneDate name={v} record={ret} label={dd[v]}/></div>
                                 }
                                //  if([
                                // // 'reworking_pol_destuffing',
                                // // "reworking_pol_restuffing",
                                // 'reworking_pod_destuffing',
                                // "reworking_pod_restuffing",].indexOf(v)!=-1){
                                //      return <div className="column is-6 py-2"><AgUnlocdsr name={v} record={ret} label={dd[v]}/></div>
                                //  }
                                 if([ 
                                 "fl_vessel_name",
                                 "fl_vessel_number",
                                 "ts1_vessel_name",
                                 "ts1_vessel_number",
                                 "ts2_vessel_name",
                                 "ts2_vessel_number",
                                 "ts3_vessel_name",
                                 "ts3_vessel_number",
                                 "ts4_vessel_name",
                                 "ts4_vessel_number",
                                 "reworking_pol_vessel_name",
                                 "reworking_pol_vessel_number",
                                 "reworking_pod_vessel_name",
                                 "reworking_pod_vessel_number",
                                 "reworking_pod_container_number",
                                 "reworking_pol_container_number",
                                 'pol_container_number',
                                 "remarks",
                                 'hbl_number',
                                 'mbl_number','new_carrier_mbl_number'].indexOf(v)!=-1){
                                 return <div className="column is-6 py-2"><OneInput5 name={v} record={ret} label={dd[v]}/></div>
                                 }
                                 if(['carrier','new_carrier_name'].indexOf(v)!=-1){
                                    return <div className="column is-6 py-2"><TableSelect5  record={ret} name={v} label={dd[v]} dataList={dataListStore['FCLCarrierList'].map(carrier =>({key : carrier['Display Name'],value:`${carrier['Display Name']} (${carrier['SCAC code']})`})) } /></div>
                                }
                                if(['consolidator'].indexOf(v)!=-1){
                                    return <div className="column is-6 py-2"><TableSelect5  record={ret} name={v} label={dd[v]} dataList={getStaticConsolidatorList().map(e =>({key : `${e.consolidatorAbbrName} (${e.scacCode})`,value:e.consolidatorAbbrName})) } /></div>
                                }
                                 if(['status_closed'].indexOf(v)!=-1){
                                    return <div className="column is-6 py-2"><OneSelect4 record={ret} name={v} label={dd[v]}/></div>
                                }
                            }
                        })
                    }
                </div>:""
                }

{
                    (st.toLowerCase().indexOf("air") != -1)?<div className="columns is-multiline is-mobile">
                    {
                        obj1.map((v) => {
                            if(obj.indexOf(v)!=-1){
                                if(["shipper_name",
                                "branch_name",
                                "consignee_name",
                                "cust_ref_number",
                                "agraga_booking_#",
                                "origin",
                                "destination",
                                "departure_airport",
                                "arrival_airport",
                                'ts1_airport','ts2_airport'].indexOf(v)!=-1){
                                     return <div className="column is-6 py-2"><OneInput5 disabled={true} name={v} record={ret} label={dd[v]}/></div>
                                }
                                if([
                                    "airline_booking_release",
                                    "etd_airport_of_departure",
                                    "loaded_on_board",
                                    "ts1_arrival_estimate",
                                    "ts1_arrival",
                                    "ts1_departure_estimate",
                                    "ts1_departure",
                                    "ts2_arrival_estimate",
                                    "ts2_arrival",
                                    "ts2_departure_estimate",
                                    "ts2_departure",
                                    "eta_at_arrival_airport",
                                    "ata_at_arrival_airport",
                                    "arrival_notice",
                                    "last_free_date_at_fpod",
                                    "delivery_order_release",
                            ].indexOf(v)!=-1){
                                     return <div className="column is-6 py-2"><OneDate name={v} record={ret} label={dd[v]}/></div>
                                 }
                                 
                                 if([ 
                                    'airline_booking_ref_number',
                                    "flight_number_departure_airport",
                                    'hawb_number',
                                    "mawb_number",
                                    'flight_number_ts1',
                                    "flight_number_ts2",
                                 "remarks"].indexOf(v)!=-1){
                                 return <div className="column is-6 py-2"><OneInput5 name={v} record={ret} label={dd[v]}/></div>
                                 }
                                 if(['status_closed'].indexOf(v)!=-1){
                                    return <div className="column is-6 py-2"><OneSelect4 record={ret} name={v} label={dd[v]}/></div>
                                }
                            }
                        })
                    }
                </div>:""
                }
                
                <button class="button is-danger" style={{ float: "right" }} onClick={onsubmit}>Submit</button>
                        <button class="button is-warning" onClick={close1} >cancel</button>
                </section>
            </div>
        </div>:<></>
        }
        <TsModal />
        <CnModal />
        <CustomModal data={ret} open={isModalOpen} close={closeModal} disable={false}/>
        <Vmodal />
        <Rwmodal />
        <TsairModal />
        </>
        )
}