import { useSnapshot } from "valtio";
import { myactionsstore } from "./store-myactions";
import { bookingsStore, configStore } from "../state/CommonState";
import { OneDatetable, OneInput5table } from "../mytasks/act-common";
import { useEffect, useState } from "react";
import { AntSwitch } from "../mui-components/AntSwitch";
import { Switch } from "@mui/material";

export function Vmodal() {
    useSnapshot(myactionsstore)
    useSnapshot(configStore)
    const [ch, setCh] = useState(0)
    const [orgin, setOrgin] = useState([])
    const [des, setDes] = useState([])
    const [isPicked, setisPicked] = useState(false)
    const [mailPickup, setMailPickup] = useState(false)
    const [mailDelivery, setMailDelivery] = useState(false)
    const [isDelivered, setisDelivered] = useState(false)
    const [deliveryMailed, setDeliveryMailed] = useState(false)
    const [pickupMailed, setPickupMailed] = useState(false)
    // let orgin = []
    // let des = []
    let ret = configStore.modalvisibledata
    let st = ret.shipmentType
    let sc = ret.shipmentScope

    const areAllCargoPickupFilled = () => {
        // Check if any cargo_pickup field is empty
        for (let i = 0; i < orgin.length; i++) {
            if (!orgin[i].cargo_pickup) {
                return false; // Return false if any cargo_pickup field is empty
            }
        }
        return true; // Return true if all cargo_pickup fields are filled
    };
    const areAllCargoDeliveryFilled = () => {
        // Check if any cargo_pickup field is empty
        for (let i = 0; i < des.length; i++) {
            if (!des[i].actual_delivery_date) {
                return false; // Return false if any cargo_pickup field is empty
            }
        }
        return true; // Return true if all cargo_pickup fields are filled
    };

    const handlePicked = () => {
        if (areAllCargoPickupFilled()) {
            setisPicked(!isPicked);
        } else {
            setMailPickup(false)
            setisPicked(false);
        }
    };

    const handlePickToggle = () => {
        if (areAllCargoPickupFilled()) {
            setMailPickup(!mailPickup);
        }else{
            setMailPickup(false)
        }
    }
    const handleDeliveryToggle = () => {
        if (areAllCargoDeliveryFilled()) {
            setMailDelivery(!mailDelivery);
        }else{
            setMailDelivery(false)
        }
    }
    const handleDelivered = () => {
        if (areAllCargoDeliveryFilled()) {
            setisDelivered(!isDelivered);
        } else {
            setisDelivered(false)
        }
    };
    const seorde = () => {
        setPickupMailed(ret.picked !== true)
        setDeliveryMailed(ret.delivered !== true)
        if (sc == "Port-to-Door" || sc == "Door-to-Door") {
            if (ret.vdes != undefined && ret.vdes.length > 0) {
                setDes(ret.vdes)
            }

        }
        if (sc == "Door-to-Port" || sc == "Door-to-Door") {
            if (ret.vorgin != undefined && ret.vorgin.length > 0) {
                setOrgin(ret.vorgin)
            }
        }
        changech()
    }


    const changech = () => {
        if (ch == 0) {
            setCh(1)
        } else {
            setCh(0)
        }
    }

    const add1 = () => {
        let o = orgin
        if (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) {
            o.push({
                vehicle: "",
                arrival_factory: "",
                cargo_pickup: "",
                cargo_arrival_cfs: ""
            })
        } else {
            o.push({
                vehicle: "",
                arrival_factory: "",
                cargo_pickup: "",
                arrival_dispatch_airport: ""
            })
        }
        setOrgin(o)
        changech()
        handlePickToggle()

    }

    const del1 = (id) => {
        let l = []
        for (let i = 0; i < orgin.length; i++) {
            if (i != id) {
                l.push(orgin[i])
            }
        }
        setOrgin(l)
    }

    const del2 = (id) => {
        let l = []
        for (let i = 0; i < des.length; i++) {
            if (i != id) {
                l.push(des[i])
            }
        }
        setDes(l)
    }

    const add2 = () => {
        let d = des
        if (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) {
            d.push({
                vehicle: "",
                number_of_package:"",
                loaded_cfs: "",
                atdfrompod: "",

                estimate_delivery_date_to_consignee: "",
                actual_delivery_date: ""
            })
        } else {
            d.push({
                vehicle: "",
                number_of_package:"",
                dispatched_from_arrival_airport: "",

                estimate_delivery_date_to_consignee: "",
                actual_delivery_date: ""
            })
        }
        setDes(d)
        changech()
        handleDeliveryToggle()
    }


    useEffect(() => {
        setMailPickup(false)
        setMailDelivery(false)
        if (myactionsstore.vModalVisible == true) {
            seorde()
        }
    }, [myactionsstore.vModalVisible])

    const onsubmit2 = () => {
        if(myactionsstore.error.length>0){
            myactionsstore.error = []
        }
        let k = 0
        let np = 0
        configStore.picked = mailPickup
        configStore.delivered = mailDelivery
        if (orgin.length > 0) {
            ret.vorgin = orgin
        } else {
            ret.vorgin = []
        }
        if (des.length > 0) {
            let d = []
            for (let i = 0; i < des.length; i++) {
                des[i]['total_package'] = ret.total_package
                if(des[i]['number_of_package'] != undefined && des[i]['number_of_package'].length>0){
                    np = np + Number(des[i]['number_of_package'])
                }
                if(st.toLowerCase().indexOf("fcl") != -1 ){
                    if(des[i]['container_number_vehicle']){
                        des[i]['container_number_vehicle'] = des[i]['container_number_vehicle'].toUpperCase()
                        if(!(/^[A-Z]{1,4}[0-9]{1,7}/.test(des[i]['container_number_vehicle'])) || des[i]['container_number_vehicle'].length!=11){
                            k = 1
                            myactionsstore.error.push('container_number_vehicle'+i)
                        }
                    }
                }
                d.push(des[i])
            }
            if(np!=0 && Number(ret.total_package) != np){
                k = 1
                myactionsstore.error.push('totalpackagenumber')
            }
            if(k==0){
                ret.vdes = des
            }
        } else {
            ret.vdes = []
        }
        if(k==0){
            configStore.modalvisibledata = ret
            myactionsstore.vModalVisible = false
        }
    }
    useEffect(()=>{
        if(ret.total_package==undefined || ret.total_package.length==0){
            if((configStore.modalvisibledata && configStore.modalvisibledata.booking && configStore.modalvisibledata.booking.contract && configStore.modalvisibledata.booking.contract.cargoTotals && configStore.modalvisibledata.booking.contract.cargoTotals.totNumPackages)){
                ret.total_package = configStore.modalvisibledata.booking.contract.cargoTotals.totNumPackages
            }
        }
    },[configStore.modalvisibledata,ret.vdes])
    
    let modalClass = 'modal'
    if (myactionsstore.vModalVisible) modalClass += ' is-active'
    return (
        <>
            {
                (myactionsstore.vModalVisible) ? <div className={modalClass} onClick={() => { myactionsstore.vModalVisible = false }}>
                    <div className="modal-background" ></div>
                    <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px', width: "90%" }} >
                        <section className="modal-card-body"  >
                            <div className="table-container" onClick={(e) => e.stopPropagation()}>

                                {
                                    ((sc == "Door-to-Port" || sc == "Door-to-Door") && st != 'FCL (Factory/Dock)') ?
                                        <>
                                            <div>
                                                <h1 style={{ float: "left" }}>Origin Pick-up</h1>
                                                {orgin.length > 0?<div>{
                                                    pickupMailed?
                                                    <div style={{ float: "right"}}>
                                                    <div style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    <p style={{ display: 'inline-block', marginRight: '5px' }}>Final pick up vehicle?</p>
                                                <Switch
                                                    checked={mailPickup}
                                                    onChange={handlePickToggle}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'toggle switch' }}
                                                    disabled={!areAllCargoPickupFilled()} // Disable the switch if cargo_pickup fields are not filled
                                                /></div></div>:<p style={{ float: "right" , color:"red"}}>* Mailed already</p>
}</div>:<></>}
                                            </div>
                                            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                                <thead>
                                                    <th>S.No</th>
                                                    <th>Vehicle#</th>
                                                    <th>Arrival @ Factory</th>
                                                    <th>Cargo Pickup</th>
                                                    {
                                                        (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                            <th>Cargo arrival at CFS</th>
                                                        </> : <>
                                                            <th>Arrival @ Dispatch Airport</th>
                                                        </>
                                                    }

                                                    <th><button onClick={add1} className="button is-success is-inverted">
                                                        <span className="icon is-small">
                                                            <i className="fas fa-plus fas fa-lg"></i>
                                                        </span>
                                                    </button></th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        orgin.map((e, i) => {
                                                            return <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td><OneInput5table name='vehicle' record={e} label='Vehicle#' /></td>
                                                                <td><OneDatetable name='arrival_factory' record={e} label='Arrival @ Factory' /></td>
                                                                <td onChange={handlePicked}><OneDatetable name='cargo_pickup' record={e} label='Cargo Pickup' /></td>
                                                                {
                                                                    (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                                        <td><OneDatetable name='cargo_arrival_cfs' record={e} label='Cargo arrival at CFS' /></td>
                                                                    </> : <>
                                                                        <td><OneDatetable name='arrival_dispatch_airport' record={e} label='Arrival @ Dispatch Airport' /></td>
                                                                    </>
                                                                }
                                                                <td>
                                                                    <button className="button is-success is-inverted" onClick={() => del1(i)}>
                                                                        <span class="icon-text has-text-danger">
                                                                            <span class="icon">
                                                                                <i class="fas fa-trash fas fa-lg"></i>
                                                                            </span>
                                                                        </span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>

                                            </table></> : ""
                                }


                                {
                                    ((sc == "Port-to-Door" || sc == "Door-to-Door") && st != 'FCL (Dock/Factory)') ? <>
                                        <h1>Destination Delivery</h1>
                                        <div class="columns m-0 p-0">
  <div class="column is-1 m-0 p-0"><h2>Total Package</h2></div>
  <div class="column is-1 m-0 p-0"><OneInput5table name='total_package' record={ret} label='Total Package' /></div>
  <div class="column is-8 m-0 p-0">{myactionsstore.error.indexOf("totalpackagenumber") != -1?<p className="has-text-danger">Number of Package does not equal Total Package</p>:""}</div>
</div>
                                        {des.length > 0?<div> {
                                                    deliveryMailed?
                                                    <div style={{ float: "right"}}>
                                                        <div style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    <p style={{ display: 'inline-block', marginRight: '5px' }}>Final delivery vehicle?</p>
                                        <Switch
                                                    checked={mailDelivery}
                                                    onChange={handleDeliveryToggle}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'toggle switch' }}
                                                    disabled={!areAllCargoDeliveryFilled()} // Disable the switch if cargo_pickup fields are not filled
                                                /></div></div>:<p style={{ float: "right" , color:"red"}}>* Mailed already</p>}</div>:<></>}
                                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                            <thead>
                                                <th>S.No</th>
                                                <th>Vehicle#</th>
                                                {
                                                    (st.toLowerCase().indexOf("fcl") != -1 )?<th>Container Number</th>:""
                                                }
                                                <th>Number Of Package</th>
                                                {
                                                    (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                        <th>Loaded @ CFS</th>
                                                        {
                                                            (st.toLowerCase().indexOf("lcl") != -1) ? <th>Cargo Picked-up for Delivery</th> : <th>Dispatched from CFS</th>
                                                        }

                                                    </> : <>
                                                        <th>Dispatched from Arrival Airport</th>
                                                    </>
                                                }
                                                <th>Estimated Delivery Date to Consignee</th>
                                                <th>Actual Delivery Date</th>

                                                <th><button onClick={add2} className="button is-success is-inverted">
                                                    <span className="icon is-small">
                                                        <i className="fas fa-plus fas fa-lg"></i>
                                                    </span>
                                                </button></th>
                                            </thead>
                                            <tbody>
                                                {
                                                    des.map((e, i) => {
                                                        return <tr>
                                                            <td>{i + 1}</td>
                                                            <td><OneInput5table name='vehicle' record={e} label='Vehicle#' /></td>
                                                            {
                                                                (st.toLowerCase().indexOf("fcl") != -1 )?<td><OneInput5table name='container_number_vehicle' record={e} label='Container Number' ind={i}/></td>:""
                                                            }
                                                            <td><OneInput5table name='number_of_package' record={e} label='Number Of Package' /></td>
                                                            {
                                                                (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>


                                                                    <td><OneDatetable name='loaded_cfs' record={e} label='Loaded @ CFS' /></td>
                                                                    <td><OneDatetable name='atdfrompod' record={e} label='ATD from POD' /></td>
                                                                </> : <>
                                                                    <th><OneDatetable name='dispatched_from_arrival_airport' record={e} label='Dispatched from Arrival Airport' /></th>
                                                                </>


                                                            }
                                                            <td><OneDatetable onChange={handleDelivered} name='estimate_delivery_date_to_consignee' record={e} label='Estimated Delivery Date to Consignee' /></td>
                                                            <td><OneDatetable name='actual_delivery_date' record={e} label='Actual Delivery Date' /></td>
                                                            <td>
                                                                <button className="button is-success is-inverted" onClick={() => del2(i)}>
                                                                    <span class="icon-text has-text-danger">
                                                                        <span class="icon">
                                                                            <i class="fas fa-trash fas fa-lg"></i>
                                                                        </span>
                                                                    </span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>

                                        </table></> : ""
                                }

                            </div>


                            <button class="button is-warning" onClick={() => myactionsstore.vModalVisible = false} >cancel</button>
                            <button class="button is-danger" style={{ float: "right" }} onClick={onsubmit2}>Submit</button>
                        </section>
                    </div>
                </div> : <></>
            }
        </>
    )
}