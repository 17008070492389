import on from './on.png'
import off from './off.png'
import on1 from './on1.png'
import off1 from './off1.png'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import { enquiryStore } from '../enquiry/enquiry-data'
import { configStore } from '../state/CommonState'

export default function Agswitch(props) {
    const [checked, setChecked] = useState(false)
    useEffect(() => {
        if (props.value) {
            let val = '' + props.value
            val = val.toLowerCase()
            if (val === 'yes') setChecked(true)
        }
    }, [])

    return (
        <div onClick={() => {
            if (props.callback) props.callback(checked ? false : true)
            setChecked(checked ? false : true)
        }}>
            {
                (checked) ?
                    <img {...props} height='36px' width='36px' src={on}></img> :
                    <img {...props} height='36px' width='36px' src={off}></img>
            }
        </div>
    )
}
export function Agswitch1(props) {
    const [checked, setChecked] = useState(false)
    return (
        <div onClick={() => {
            setChecked(checked ? false : true)
        }}>
            {
                (checked) ?
                    <img {...props} height='36px' width='36px' src={off1}></img> :
                    <img {...props} height='36px' width='36px' src={on1}></img>
            }
        </div>
    )
}
export function OriginSwitch(props) {
    useSnapshot(configStore)
    const [checked, setChecked] = useState(false)
    const { callback } = props
    useSnapshot(enquiryStore)
    useEffect(() => {
        configStore.homescreenCurrentShipmentScope = `${enquiryStore.current.originType}-${enquiryStore.current.destinationType}`
    }, [enquiryStore.current.originType])

    return (
        <div className="columns" style={{ alignItems: 'center' }}>
            <div className="p-1" style={{
                fontSize: '0.9rem',
                fontWeight: 'bold', textAlign: 'right'
            }}>
                Port
            </div>
            <div {...props} className="column is-2 p-1" style={{
                zoom: '1.1', textAlign: 'center',
                justifyContent: 'center'
            }} onClick={() => {
                if (props.disabled) return
                let v = enquiryStore.current.originType
                enquiryStore.current.originType = v == 'Port'?'Door':'Port'
            }}>
                {
                    (enquiryStore.current.originType != 'Port') ?
                        <img height='36px' width='36px' src={on}></img> :
                        <img height='36px' width='36px' src={off}></img>
                }
            </div>
            <div className="column is-6 p-1" style={{
                fontSize: '0.9rem',
                fontWeight: 'bold', textAlign: 'left'
            }}>
                Ex-Works
            </div>
        </div>
    )
}
export function DestinationSwitch(props) {
    useSnapshot(configStore)
    useSnapshot(enquiryStore)
    useEffect(() => {
        configStore.homescreenCurrentShipmentScope = `${enquiryStore.current.originType}-${enquiryStore.current.destinationType}`
    }, [enquiryStore.current.destinationType])

    return (
        <div className="columns" style={{ alignItems: 'center' }}>
            <div className="p-1" style={{
                fontSize: '0.9rem',
                fontWeight: 'bold', textAlign: 'right'
            }}>
                Port
            </div>
            <div {...props} className="column is-2 p-1" style={{
                zoom: '1.1', textAlign: 'center',
                justifyContent: 'center'
            }} onClick={() => {
                if (props.disabled) return
                let v = enquiryStore.current.destinationType
                enquiryStore.current.destinationType = v == 'Port'?'Door':'Port'
            }}>
                {
                    (enquiryStore.current.destinationType != 'Port') ?
                        <img height='36px' width='36px' src={on}></img> :
                        <img height='36px' width='36px' src={off}></img>
                }
            </div>
            <div className="column is-6 p-1" style={{
                fontSize: '0.9rem',
                fontWeight: 'bold', textAlign: 'left'
            }}>
                Door Delivery
            </div>
        </div>
    )
}
export function GenericSwitch(props) {
    useSnapshot(props.store)
    let val = props.store[props.property]
    if (!val || (val != props.leftValue && val != props.rightValue)) props.store[props.property] = props.leftValue
    useEffect(() => {
        console.log('\nVALUE=', props.store[props.property], '\n---------------------')
    })
    return (
        <div className="columns" style={{ alignItems: 'center' }}>
            <div className="p-1" style={{
                fontSize: '0.9rem',
                fontWeight: 'bold', textAlign: 'right'
            }}>
                {props.leftValue}
            </div>
            <div className="column is-2 p-1" style={{
                zoom: '1.1', textAlign: 'center',
                justifyContent: 'center'
            }} onClick={() => {
                if (props.disabled) return
                let v = props.store[props.property]
                props.store[props.property] = v == props.leftValue?props.rightValue:props.leftValue
            }}>
                {
                    (props.store[props.property] != props.leftValue) ?
                        <img height='36px' width='36px' src={on}></img> :
                        <img height='36px' width='36px' src={off}></img>
                }
            </div>
            <div className="column is-6 p-1" style={{
                fontSize: '0.9rem',
                fontWeight: 'bold', textAlign: 'left'
            }}>
                {props.rightValue}
            </div>
        </div>
    )
}