import kmtulogo from './scimg/KMTU.jpg'
import anlclogo from './scimg/ANLC.png'
import Avanalogo from './scimg/Avana.jpg'
import cmdulogo from './scimg/CMDU.png'
import cosulogo from './scimg/COSU.png'
import eglvlogo from './scimg/EGLV.png'
import grmulogo from './scimg/GRMU.png'
import hdmulogo from './scimg/HDMU.png'
import hlculogo from './scimg/HLCU.png'
import iaaulogo from './scimg/IAAU.jpg'
import maeulogo from './scimg/MAEU.png'
import msculogo from './scimg/MSCU.png'
import mxculogo from './scimg/MXCU.png'
import naviologo from './scimg/Navio.jpg'
import oneylogo from './scimg/ONEY.png'
import oolulogo from './scimg/OOLU.png'
import regulogo from './scimg/REGU.png'
import sudulogo from './scimg/SUDU.jpg'
import trisealogo from './scimg/Trisea.png'
import trlulogo from './scimg/TRLU.png'
import whlclogo from './scimg/WHLC.jpg'
import ymlulogo from './scimg/YMLU.jpg'
import zimulogo from './scimg/ZIMU.png'
import mosjlogo from './scimg/MOSJ.jpg'
import cklulogo from './scimg/CKLU.png'
import ecnulogo from './scimg/ECNU.jpg'
import radiantlogo from './scimg/Radiant.jpg'
import entrustlogo from './scimg/Entrust.png'
import nsaulogo from './scimg/Bahri.jpg'
import samsaralogo from './scimg/Samsara.jpg'
import younglogo from './scimg/Young.jpg'
import pmlulogo from './scimg/PMLU.png'
import smartlogo from './scimg/Smart.jpg'
import bclulogo from './scimg/BCLU.jpg'
import chnjlogo from './scimg/CHNJ.jpg'
import PILXlogo from './scimg/PILX.jpg'
import AKMRlogo from './scimg/AKMR.jpg'
import ACLUlogo from './scimg/ACLU.jpg'
import AROFlogo from './scimg/AROF.jpg'
import CEGLlogo from './scimg/CEGL.jpg'
import DX11logo from './scimg/11DX.jpg'
import CNIUlogo from './scimg/CNIU.jpg'
import CMCUlogo from './scimg/CMCU.jpg'
import CAMNlogo from './scimg/CAMN.jpg'
import DOLQlogo from './scimg/DOLQ.jpg'
import FESOlogo from './scimg/FESO.jpg'
import GSLUlogo from './scimg/GSLU.jpg'
import GESClogo from './scimg/GESC.jpg'
import UBCUlogo from './scimg/UBCU.jpg'
import IILUlogo from './scimg/IILU.jpg'
import IDMClogo from './scimg/IDMC.jpg'
import LGLTlogo from './scimg/LGLT.jpg'
import MISClogo from './scimg/MISC.jpg'
import MCCQlogo from './scimg/MCCQ.jpg'
import NOSUlogo from './scimg/NOSU.jpg'
import PCIUlogo from './scimg/PCIU.jpg'
import PLLUlogo from './scimg/PLLU.jpg'
import SKIIlogo from './scimg/SKII.jpg'
import SCYElogo from './scimg/SCYE.jpg'
import CHVWlogo from './scimg/CHVW.jpg'
import SCIUlogo from './scimg/SCIU.jpg'
import THZSlogo from './scimg/THZS.jpg'
import TOTElogo from './scimg/TOTE.jpg'
import TRKUlogo from './scimg/TRKU.jpg'
import UALClogo from './scimg/UALC.jpg'
import WLWHlogo from './scimg/WLWH.jpg'
import WECUlogo from './scimg/WECU.jpg'
import WDLSlogo from './scimg/WDLS.png'
import ARKUlogo from './scimg/ARKU.png'
import BTLXlogo from './scimg/BTLX.jpg'
import CULXlogo from './scimg/CULX.jpg'
import DALDlogo from './scimg/DALD.jpg'
import EIMUlogo from './scimg/EIMU.jpg'
import EIMWlogo from './scimg/EIMW.jpg'
import EPIRlogo from './scimg/EPIR.jpg'
import EUKOlogo from './scimg/EUKO.jpg'
import ACSUlogo from './scimg/ACSU.png'
import GRIUlogo from './scimg/GRIU.jpg'
import HALUlogo from './scimg/HALU.jpg'
import HUAUlogo from './scimg/HUAU.jpg'
import HRZUlogo from './scimg/HRZU.jpg'
import HYDUlogo from './scimg/HYDU.jpg'
import KOSLlogo from './scimg/KOSL.png'
import LNLUlogo from './scimg/LNLU.jpg'
import MFTUlogo from './scimg/MFTU.jpg'
import MGSUlogo from './scimg/MGSU.jpg'
import MRUBlogo from './scimg/MRUB.jpg'
import MATSlogo from './scimg/MATS.jpg'
import NODAlogo from './scimg/NODA.jpg'
import OELXlogo from './scimg/OELX.jpg'
import PCLWlogo from './scimg/PCLW.jpg'
import PSHIlogo from './scimg/PSHI.jpg'
import RCKIlogo from './scimg/RCKI.jpg'
import SAFMlogo from './scimg/SAFM.jpg'
import SLAQlogo from './scimg/SLAQ.png'
import SIKUlogo from './scimg/SIKU.png'
import SMLUlogo from './scimg/SMLU.jpg'
import SEJJlogo from './scimg/SEJJ.jpg'
import SITUlogo from './scimg/SITU.png'
import TSQDlogo from './scimg/TSQD.jpg'
import BLJUlogo from './scimg/BLJU.jpg'
import UAFXlogo from './scimg/UAFX.jpg'
import WWSUlogo from './scimg/WWSU.jpg'
import XCLXlogo from './scimg/XCLX.jpg'
import lancerlogo from './scimg/Lancer.jpg'
import Skyconlogo from './scimg/Skycon.jpg'
import byslogo from './scimg/BYS.jpg'
import apjlogo from './scimg/APJ.jpg'
import hindlogo from './scimg/Hind.jpg'
import Cordelialogo from './scimg/Cordelia.jpg'
import Translinerlogo from './scimg/Transliner.jpg'
import INLUlogo from './scimg/INLU.jpg'
import Seaportlogo from './scimg/Seaport.jpg'
import Aladinlogo from './scimg/Aladin.jpg'
import SJKUlogo from './scimg/SJKU.jpg'
import TLXUlogo from './scimg/TLXU.jpg'
import JCSUlogo from './scimg/JCSU.jpg'
import SWENlogo from './scimg/SWEN.jpg'
import AIYERlogo from './scimg/AIYER.png'
import ALADlogo from './scimg/ALAD.svg'
import POSElogo from './scimg/POSE.svg'
import MAJElogo from './scimg/MAJE.png'
import CLIFlogo from './scimg/CLIF.JPG'
import SEAHlogo from './scimg/SEAH.JPG'
import HLLPlogo from './scimg/HLLP.JPG'
import TRANlogo from './scimg/TRAN.JPG'
import ZAHAlogo from './scimg/ZAHA.png'
import CLINElogo from './scimg/CLINE.png'
import PSAPlogo from './scimg/PSAP.png'
import BENLlogo from './scimg/BENL.JPG'
import MGEUlogo from './scimg/MGEU.JPG'
import SLSAlogo from './scimg/sealead.png'
import SBPLlogo from './scimg/SBPL.png'
import OSPLlogo from './scimg/OSPL.png'
import VNV0024logo from './scimg/VNV0024.jpg'
import AKKUlogo from './scimg/AKKU.png'
import VNV0026logo from './scimg/VNV0026.png'
import VNV0028logo from './scimg/VNV0028.png'

const schLogoMap = {
    'KMTU':kmtulogo,
    'ANLC':anlclogo,
    'Avana':Avanalogo,
    'CMDU':cmdulogo,
    'COSU':cosulogo,
    'EGLV':eglvlogo,
    'GRMU':grmulogo,
    'HDMU':hdmulogo,
    'HLCU':hlculogo,
    'IAAU':iaaulogo,
    'MAEU':maeulogo,
    'MSCU':msculogo,
    'MXCU':mxculogo,
    'Navio':naviologo,
    'ONEY':oneylogo,
    'OOLU':oolulogo,
    'REGU':regulogo,
    'SUDU':sudulogo,
    'Trisea':trisealogo,
    'TRLU':trlulogo,
    'WHLC':whlclogo,
    'YMLU':ymlulogo,
    'ZIMU':zimulogo,
    'MOSJ':mosjlogo,
    'CKLU':cklulogo,
    'ECNU':ecnulogo,
    'Radiant': radiantlogo,
    'Entrust': entrustlogo,
    'NSAU': nsaulogo,
    'Samsara': samsaralogo,
    'Young': younglogo,
    'PMLU': pmlulogo,
    'Smart': smartlogo,
    'BCLU': bclulogo,
    'CHNJ': chnjlogo,
    'PILX':PILXlogo,
    'AKMR':AKMRlogo,
    'ACLU':ACLUlogo,
    'AROF':AROFlogo,
    'CEGL':CEGLlogo,
    '11DX':DX11logo,
    'CNIU':CNIUlogo,
    'CMCU':CMCUlogo,
    'CAMN':CAMNlogo,
    'DOLQ':DOLQlogo,
    'FESO':FESOlogo,
    'GSLU':GSLUlogo,
    'GESC':GESClogo,
    'UBCU':UBCUlogo,
    'IILU':IILUlogo,
    'IDMC':IDMClogo,
    'LGLT':LGLTlogo,
    'MISC':MISClogo,
    'MCCQ':MCCQlogo,
    'NOSU':NOSUlogo,
    'PCIU':PCIUlogo,
    'PLLU':PLLUlogo,
    'SKII':SKIIlogo,
    'SCYE':SCYElogo,
    'CHVW':CHVWlogo,
    'SCIU':SCIUlogo,
    'THZS':THZSlogo,
    'TOTE':TOTElogo,
    'TRKU':TRKUlogo,
    'UALC':UALClogo,
    'WLWH':WLWHlogo,
    'WECU':WECUlogo,
    'WDLS':WDLSlogo,
    'ARKU':ARKUlogo,
    'BTLX':BTLXlogo,
    'CULX':CULXlogo,
    'DALD':DALDlogo,
    'EIMU':EIMUlogo,
    'EIMW':EIMWlogo,
    'EPIR':EPIRlogo,
    'EUKO':EUKOlogo,
    'ACSU':ACSUlogo,
    'GRIU':GRIUlogo,
    'HALU':HALUlogo,
    'HUAU':HUAUlogo,
    'HRZU':HRZUlogo,
    'HYDU':HYDUlogo,
    'KOSL':KOSLlogo,
    'LNLU':LNLUlogo,
    'MFTU':MFTUlogo,
    'MGSU':MGSUlogo,
    'MRUB':MRUBlogo,
    'MATS':MATSlogo,
    'NODA':NODAlogo,
    'OELX':OELXlogo,
    'PCLW':PCLWlogo,
    'PSHI':PSHIlogo,
    'RCKI':RCKIlogo,
    'SAFM':SAFMlogo,
    'SLAQ':SLAQlogo,
    'SIKU':SIKUlogo,
    'SMLU':SMLUlogo,
    'SEJJ':SEJJlogo,
    'SITU':SITUlogo,
    'TSQD':TSQDlogo,
    'BLJU':BLJUlogo,
    'UAFX':UAFXlogo,
    'WWSU':WWSUlogo,
    'XCLX':XCLXlogo,
    'Lancer': lancerlogo,
    'Skycon': Skyconlogo,
    'BYS': byslogo,
    'APJ': apjlogo,
    'Hind': hindlogo,
    'Cordelia': Cordelialogo,
    'Transliner': Translinerlogo,
    'INLU': INLUlogo,
    'Seaport': Seaportlogo,
    'Aladin': Aladinlogo,
    'SJKU': SJKUlogo,
    'TLXU': TLXUlogo,
    'JCSU': JCSUlogo,
    'SWEN': SWENlogo,
    'AIYER': AIYERlogo,
    'ALAD': ALADlogo,
    'POSE': POSElogo,
    'MAJE': MAJElogo,
    'CLIF': CLIFlogo,
    'SEAH': SEAHlogo,
    'HLLP': HLLPlogo,
    'TRAN': TRANlogo,
    'ZAHA': ZAHAlogo,
    'CLINE': CLINElogo,
    'PSAP': PSAPlogo,
    'BENL': BENLlogo,
    'MGEU': MGEUlogo,
    'SLSA': SLSAlogo,
    'SBPL': SBPLlogo,
    'OSPL': OSPLlogo,
    'VNV0024': VNV0024logo,
    'AKKU':AKKUlogo,
    'VNV0026':VNV0026logo,
    'VNV0028':VNV0028logo
}
export default schLogoMap
